import Colors from "../../../config/Colors";
import { InterfaceIconSVG } from "../InterfaceIconSVG";
export interface InterfaceBoxStepArrowSVG extends InterfaceIconSVG {

}
export const BoxStepArrowSVG: React.FC<InterfaceBoxStepArrowSVG> = ({ style = {} }) => {
    const { background, color, ...rest } = style;
    return (
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
            viewBox="0 0 21 100" // Adjust the viewBox values based on your SVG content
            scale={2}
            style={{
                ...rest,
            }}>
            <path
                style={{ boxSizing: "border-box" }}
                d="M 0 -0  L 18 45 C 19.441 49.488 19.313 50.771 18 55 L 0 100"
                // d="M 2 0 C 3.747 -0.003 4.494 0.029 5 1.1506 L 18 45 C 19.441 49.488 19.313 50.771 18 55 L 5 98.9 C 4.639 99.936 3.996 99.989 1.994 100"
                stroke={style.color} stroke-width={style.strokeWidth ?? "2"}

                fill={style.background ? `${style.background}` : undefined}
            />
            {/* <path
                style={{ boxSizing: "border-box" }}
                d="M 0 0 L 5 0"
                stroke={style.color} stroke-width="4"

            /> */}
            {/* <path
                style={{ boxSizing: "border-box" }}
                d="M 5 100 L 0 100"
                stroke={style.color} stroke-width="4"

            /> */}
        </svg>
    )
};