import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const SortIcon: React.FC<InterfaceIconSVG> = ({ }) => {
    return (
        <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.70755 9.5H4.97722C5.42679 9.5 5.79463 9.1625 5.79463 8.75C5.79463 8.3375 5.42679 8 4.97722 8H1.70755C1.25797 8 0.890137 8.3375 0.890137 8.75C0.890137 9.1625 1.25797 9.5 1.70755 9.5ZM0.890137 1.25C0.890137 1.6625 1.25797 2 1.70755 2H14.7862C15.2358 2 15.6036 1.6625 15.6036 1.25C15.6036 0.8375 15.2358 0.5 14.7862 0.5H1.70755C1.25797 0.5 0.890137 0.8375 0.890137 1.25ZM1.70755 5.75H9.88171C10.3313 5.75 10.6991 5.4125 10.6991 5C10.6991 4.5875 10.3313 4.25 9.88171 4.25H1.70755C1.25797 4.25 0.890137 4.5875 0.890137 5C0.890137 5.4125 1.25797 5.75 1.70755 5.75Z" fill="#6C73A1" />
        </svg>
    )
};

