import { useEffect, useState } from "react";
import ParentWithHeightAnimation from "../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import { InterfaceAPIResponseGetMetaCheckWalletsTrustiesOfSolutions, InterfaceViewCheckWalletCustomerOfBuyCottage } from "./InterfaceViewCheckWalletsTrustiesOfBuyCottage";
import { Box, Grid, useTheme } from "@mui/material";
import TextFieldSimple from "../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../../../NTTs/InterfaceNTTCurrency";
import SelectCurrencyWithTypeAndLocation from "../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import SubTitleSection from "../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import TextFieldMultiLine from "../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import Colors from "../../../../../../config/Colors";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import SelectUser from "../../../../../../components/complex/Selector/SelectUser/SelectUser";
import { InterfaceNTTUser } from "../../../../../../NTTs/InterfaceNTTUser";
import SelectTrustyOrCustomer from "../../../../../../components/complex/Selector/SelectTrustyAndCustomer/SelectTrustyOrCustomer";
import { InterfaceGetAPITrustyOrCustomer } from "../../../../../../components/complex/Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer";
import { InterfaceNTTRecapMain } from "../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { InterfaceSearchInServer } from "../../../../../../api/interfaces/InterfaceSearchInServer";
import { urlsList, HandlerLangUrl, TypeQueryParams } from "../../../../../../api/UrlsList";
import { EnumStateFetchList } from "../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { InterfaceGetAPICustomersList } from "../../../../../people/TrustyOrCustomer/customers/customersList/InterfaceCustomersList";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Links from "../../../../../../config/Links";
import { InterfaceNTTRecapBuyCottageService } from "../../../../../../NTTs/Recap/InterfaceNTTRecapBuyCottageService";
import KeyTitleH6 from "../../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import ValueP from "../../../../../../components/basic/Texts/Titles/ValueP/ValueP";
import TextDescription from "../../../../../../components/basic/Texts/TextDescription/TextDescription";
import { InterfaceNTTOneStepOfWorkflowBuyCottageRecap } from "../../../../../../NTTs/Recap/WorkflowMainRecap/BuyCottage/InterfaceNTTOneStepOfWorkflowBuyCottageRecap";
import { EnumStatusFormInRecap } from "../../../../../../NTTs/Recap/InterfaceNTTStatusFormInRecap";
import dayjs from "dayjs";
import { ThumbDownRounded, ThumbUpRounded } from "@mui/icons-material";

function ViewCheckWalletsTrustiesOfBuyCottage(paramsComponent: InterfaceViewCheckWalletCustomerOfBuyCottage) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getInfoServiceBuyCottage, setInfoServiceBuyCottage] = useState<InterfaceNTTRecapBuyCottageService>(paramsComponent.infoServiceInMainRecap);
    const [getInfoThisWorkFlow, setInfoThisWorkFlow] = useState<InterfaceNTTOneStepOfWorkflowBuyCottageRecap>();
    const [getInfoThisStep, setInfoThisStep] = useState<InterfaceAPIResponseGetMetaCheckWalletsTrustiesOfSolutions>();
    const callApiForRetrieveFoundControlInfo = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.cottage.buyCottage.steps.addSolutions.metaData.CheckWalletOfTrustiesSolution.getInfo;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getInfoThisWorkFlow?.review_id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIResponseGetMetaCheckWalletsTrustiesOfSolutions;
                setInfoThisStep(t);


                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }


    useEffect(() => {
        if (getInfoThisWorkFlow) {
            callApiForRetrieveFoundControlInfo();
        }
    }, [getInfoThisWorkFlow]);
    useEffect(() => {
        setInfoServiceBuyCottage(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setInfoThisWorkFlow(paramsComponent.thisStepWorkFlow);
    }, [paramsComponent.thisStepWorkFlow])

    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    return (
        <ParentWithHeightAnimation in={getInfoThisWorkFlow !== undefined}
            loading={getInfoThisWorkFlow === undefined}
            style={{
                boxSizing: "border-box",
                width: "100%",
            }}
        >
            <Box sx={{
                boxSizing: "border-box",
                paddingInlineStart: { xs: "0.8rem", sm: "0.9rem", md: "1.0rem", lg: "1.18rem", xl: "1.3rem" },
                paddingInlineEnd: { xs: "0.8rem", sm: "3.33rem", md: "3.7rem", lg: "4.14rem", xl: "6rem" },
                display: "flex", flexDirection: 'column',
                alignItems: 'start',
                width: "100%",
                marginTop: "1.18rem",
            }}>

                {getInfoThisStep &&
                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'start',
                        gap: "0.59rem",
                        background: '#F3F6FC',
                        borderRadius: '0.37rem',
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                        boxShadow: Colors.shadow.blueLightCard,
                        width: '100%',

                    }}>
                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            background: Colors.theme.white.SecondaryLight,
                            borderBottom: `1px solid ${Colors.theme.blue.light}`,
                            width: '100%',
                            paddingBlock: '0.28rem',
                            paddingInline: '0.88rem',
                            boxSizing: 'border-box',
                        }}>
                            <div style={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                justifyContent: 'start',
                                boxSizing: 'border-box',
                                gap: '0.4rem',
                            }}>
                                <p style={{
                                    display: 'inline-flex', flexDirection: 'row', fontFamily: confComps.font.poppins.Medium,
                                    fontSize: '1.03rem',
                                    lineHeight: '1.7rem',
                                    color: Colors.theme.blue.dark,

                                }}>
                                    {`Check wallet all trusties`}
                                </p>
                                <p
                                    style={{
                                        display: 'inline-flex', flexDirection: 'row', fontFamily: confComps.font.poppins.Medium,
                                        fontSize: '1.03rem',
                                        lineHeight: '1.7rem',
                                        color: Colors.theme.blue.blue,
                                        cursor: 'pointer',
                                        // textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                        navigate(`${Links.screens.panel.people.employees.employeeProfile}${getInfoThisStep.submitted_by.user.id}/`)
                                    }}
                                >
                                    {`(@${getInfoThisStep.submitted_by.user.username})`}
                                </p>
                                <p style={{
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    fontFamily: confComps.font.poppins.Regular,
                                    fontSize: '1.03rem',
                                    lineHeight: '1.7rem',
                                    color: Colors.theme.blue.light,
                                }}>
                                    {`${dayjs.unix(getInfoThisStep.created_at).format("YYYY/MM/DD")}`}
                                </p>


                            </div>
                            {/* <div style={{
                                display: 'inline-flex', flexDirection: 'row',
                                alignItems: 'center',
                                gap: '0.4rem',
                            }}>
                                <ThumbUpRounded
                                    style={{
                                        color: Colors.theme.green.dark,
                                        fontSize: '1.1rem',
                                    }} />
                                <p style={{
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    fontFamily: confComps.font.poppins.Medium,
                                    fontSize: '1.03rem',
                                    lineHeight: '1.7rem',
                                    color: Colors.theme.green.dark,
                                }}>

                                    {confComps.translate.agree}
                                </p>

                            </div> */}

                        </div>
                        <p style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'start', width: '100%',
                            fontFamily: confComps.font.poppins.Regular,
                            fontSize: '1.03rem',
                            lineHeight: '1.77rem',
                            paddingBlockEnd: '0.58rem',
                            paddingInline: '0.88rem',
                            boxSizing: 'border-box',
                        }}>

                            {getInfoThisStep?.description}
                        </p>
                    </div>
                }


            </Box>
        </ParentWithHeightAnimation>
    )

}
export default ViewCheckWalletsTrustiesOfBuyCottage;