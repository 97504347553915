import { Priority0Icon } from "../../../assets/icons/svg/Priority/Priority0Icon";
import { Priority1Icon } from "../../../assets/icons/svg/Priority/Priority1Icon";
import { Priority2Icon } from "../../../assets/icons/svg/Priority/Priority2Icon";
import { Priority3Icon } from "../../../assets/icons/svg/Priority/Priority3Icon";
import { Priority4Icon } from "../../../assets/icons/svg/Priority/Priority4Icon";
import Colors from "../../../config/Colors";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { InterfaceShowPriority } from "./InterfaceShowPriority";

function ShowPriority(paramsComponent: InterfaceShowPriority) {
    const confComps = ConfigComps();
    const priorityIcons = [
        <Priority0Icon />, // Lower Priority (value: 1)
        <Priority1Icon />, // Low Priority (value: 2)
        <Priority2Icon />, // Minor Priority (value: 3)
        <Priority3Icon />, // High Priority (value: 4)
        <Priority4Icon />  // Higher Priority (value: 5)
    ];
    const priorityStyles = [
        { color: Colors.theme.blue.blue, backgroundColor: `rgba(56,122,233,0.12)` }, // Lower Priority (value: 1)
        { color: Colors.theme.blue.blue, backgroundColor: `rgba(56,122,233,0.12)` }, // Low Priority (value: 2)
        { color: Colors.theme.green.dark, backgroundColor: "#EAFBEF" }, // Minor Priority (value: 3)
        { color: Colors.theme.orange.sharp, backgroundColor: "#FFF1E5" }, // High Priority (value: 4)
        { color: Colors.theme.orange.sharp, backgroundColor: "#FFF1E5" }  // Higher Priority (value: 5)
    ];
    const icon = priorityIcons[paramsComponent.priority.value - 1]; // Subtract 1 to match array index
    const style = priorityStyles[paramsComponent.priority.value - 1];

    return (
        <div style={{
            display: 'flex', alignItems: 'center',
            paddingBlock: "0.3rem",
            paddingInlineEnd: '0.7rem',
            paddingInlineStart: '0.4rem',
            gap: "0.25rem",
            justifyContent: 'center',
            borderRadius: '800px', ...style
        }}>
            <span style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center',
                width: '17px',
            }}>{icon}</span>
            <span style={{
                fontFamily: confComps.font.poppins.Medium,
                fontWeight: '500',
                lineHeight: '1.48rem',
                fontSize: '0.96rem',
            }}>{paramsComponent.priority.label}</span>
        </div>
    );
}
export default ShowPriority;