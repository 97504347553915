import { Box, Grid, styled, useTheme } from "@mui/material";
import Colors from "../../../../../../../../config/Colors";
import KeyTitleH6 from "../../../../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import { useDispatch, useSelector } from "react-redux";
import { ConfigComps } from "../../../../../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../../../../../store/profileStateSlice";
import ValueP from "../../../../../../../../components/basic/Texts/Titles/ValueP/ValueP";
import { Person2Rounded } from "@mui/icons-material";
import { TrashIcon } from "../../../../../../../../assets/icons/svg/TrashIcon";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../../api/API";
import { isVisibilityProgressBar } from "../../../../../../../../store/ProgressbarInRootSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { InterfaceRowReceptionRate } from "./InterfaceRowReceptionRate";
import { ImageSquare } from "../../../../../../../../components/complex/imageSquare/ImageSquare";
import dayjs from "dayjs";
import { EditPenIcon } from "../../../../../../../../assets/icons/svg/EditPenIcon";
import ShowCurrencyLocationWithValue from "../../../../../../../../components/basic/ShowCurrencyLocationWithValue/ShowCurrencyLocationWithValue";
import { XELogoIcon } from "../../../../../../../../assets/icons/svg/XELogoIcon";
import { AddIcon } from "../../../../../../../../assets/icons/svg/AddIcon";
import { ExchangeMoneyIcon } from "../../../../../../../../assets/icons/svg/ExchangeMoneyIcon";
import { LongArrowRight } from "../../../../../../../../assets/icons/svg/LongArrowRight";
const TagOfUser = styled("p")(({ theme }) => ({
    boxSizing: "border-box",
    fontSize: "1.12rem",
    lineHeight: "1.6rem",
    color: Colors.theme.blue.dark,
    textWrap: "nowrap",
}));

function RowReceptionRate(paramsComponent: InterfaceRowReceptionRate) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const callApiForDeleteChangeRate = async (idRecommended: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.rate.receptionRate.deleteTheReceptionRate;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idRecommended}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackReloadPage();
                dispatch(ShowAlert({ text: "Removed The CashAccount", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <Box sx={{
            width: "100%",
            border: `1px solid ${Colors.theme.blue.border}`,
            paddingInline: { xs: 0.5, sm: 1, md: 2, lg: 2, xl: 2 },
            paddingBlock: { xs: 0.5, sm: 1, md: 1, lg: 2, xl: 2 },
            boxSizing: "border-box",
            borderRadius: "0.8rem",
        }}>
            <div style={{
                display: "flex", flexDirection: "column", alignItems: "start",
                justifyContent: "start",
                gap: "0.5rem",
            }}>
                <TagOfUser>
                    {paramsComponent.currency_unit.country.name}

                </TagOfUser>
                <TagOfUser>
                    {`${paramsComponent.currency_unit.is_cash ? confComps.translate.cash : confComps.translate.noneCash} | ${paramsComponent.currency_unit.currency.symbol}`}
                </TagOfUser>
                <TagOfUser style={{ color: Colors.theme.blue.light, flexDirection: "row", alignItems: "center", justifyContent: "center", display: "flex", gap: "0.3rem" }}>
                    {`${confComps.translate.rate}: `}
                    <TagOfUser style={{ color: Colors.theme.green.dark, }}>
                        {paramsComponent.rate}
                    </TagOfUser>
                </TagOfUser>

            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end", width: "100%", gap: "0.5rem" }}>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                    onClick={paramsComponent.callbackClickOnEdit}
                >
                    <EditPenIcon style={{
                        background: Colors.theme.blue.light,
                        width: "0.75rem",
                        height: "0.75rem",
                        cursor: "pointer"
                    }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                    onClick={() => { callApiForDeleteChangeRate(paramsComponent.id) }}
                >
                    <TrashIcon />
                </div>

            </div>




        </Box >
    );
}
export default RowReceptionRate;