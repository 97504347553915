import React, { useEffect, useRef, useState } from 'react';
import { InterfaceNavbarHorizontal } from './InterfaceNavbarHorizontal';
import Colors from '../../../config/Colors';
import { ArrowSmallRightIcon } from '../../../assets/icons/svg/ArrowSmallRightIcon';
import { ArrowRightIcon } from '../../../assets/icons/svg/ArrowRightIcon';
import { ArrowDown2Line } from '../../../assets/icons/svg/ArrowDown2Line';
import { styled } from '@mui/material';

const borderRadius = "1rem";
const paddingInline = "16px";
export const DropdownItemTitle = styled("div", { shouldForwardProp: (prop) => prop !== 'isLastItem' && prop !== 'isSelected' })<{
    isLastItem: boolean;
    isSelected: boolean;
}>(({
    theme,
    isLastItem,
    isSelected,
}) => {
    return (
        {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            gap: "8px",
            textWrap: "nowrap",
            minWidth: "100%",
            boxSizing: "border-box",
            color: Colors.theme.blue.blue,
            borderBlockEnd: isLastItem ? "unset" : "1px solid #DFDFDF",
            paddingInline: paddingInline,
            paddingBlock: "4px",
            background: isSelected ? Colors.theme.orange.lemonLight : "unset",
            borderEndEndRadius: isLastItem ? borderRadius : "unset",
            borderEndStartRadius: isLastItem ? borderRadius : "unset",
        }
    )
});
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            position: "absolute",
            top: "calc(100% + 0.7rem )",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            minWidth: "100%",
            background: "white",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",

            borderEndEndRadius: borderRadius,
            borderEndStartRadius: borderRadius,
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",



        }
    )
});
const NavbarHorizontal: React.FC<InterfaceNavbarHorizontal> = (paramsComponent) => {
    const [getSelectedIndexParent, setSelectedIndexParent] = useState(0);
    const [getSelectedId, setSelectedId] = useState(paramsComponent.titleList[0].id);
    const [getIsDropDownMustShow, setIsDropDownMustShow] = useState<boolean>(paramsComponent.isUnderLineShow);
    const [getIsUnderLineShow, setIsUnderLineShow] = useState<boolean>(true);
    const underlineRef = useRef<HTMLDivElement>(null);
    const tabsRef = useRef<HTMLDivElement[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    // const [getTabWidthsOfHeader, setTabWidthsOfHeader] = useState<number[]>([]);
    const [getTabWidths, setTabWidths] = useState<string[]>(paramsComponent.titleList.map(title => `${(title.text.length)}rem`));
    const handlePress = (index: number) => {
        setSelectedIndexParent(index);

    };
    const updateStateOfUnderLine = () => {
        if (underlineRef.current && tabsRef.current[getSelectedIndexParent]) {
            const tab = tabsRef.current[getSelectedIndexParent];
            underlineRef.current.style.width = `${tab.offsetWidth}px`;
            underlineRef.current.style.left = `${tab.offsetLeft}px`;
            //reset background color all element header
            for (var index = 0; index < tabsRef.current.length; index++) {
                tabsRef.current[index].style.background = 'unset';
            }

            if (paramsComponent.titleList[getSelectedIndexParent].child.length > 0) {//if it has children

                tabsRef.current[getSelectedIndexParent].style.background = `white`;
            }

        }
    }
    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                updateStateOfUnderLine();
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }

        // Initial size update
        handleResize();

        // Cleanup on unmount
        return () => {
            resizeObserver.disconnect();
        };
    }, [getSelectedIndexParent, updateStateOfUnderLine]);

    useEffect(() => {
        paramsComponent.callbackOnClick(getSelectedId);

    }, [getSelectedId])
    useEffect(() => {
        setIsUnderLineShow(paramsComponent.isUnderLineShow);
    }, [paramsComponent.isUnderLineShow])


    return (
        <div ref={containerRef} style={{
            position: 'relative', display: 'flex', flexDirection: 'column', width: '100%', boxSizing: 'border-box',
            background: Colors.theme.white.white80,
            borderBottom: `1px solid ${Colors.theme.white.SecondaryLight}`,
            boxShadow: Colors.shadow.shadowEditTextBoxClicked,

        }}>
            <div
                style={{
                    display: 'flex',
                    // overflowX: 'auto',
                    flexDirection: 'row',
                    width: '100%',
                    boxSizing: 'border-box',
                    justifyContent: 'space-between',
                    // overflowX: "auto",
                    //   gap: '1rem',
                }}
            >
                {paramsComponent.titleList.map((header, index) => (
                    <div
                        key={index}
                        ref={(el) => (tabsRef.current[index] = el!)}
                        onClick={() => {
                            if (header.child.length === 0) {
                                handlePress(index)
                                setSelectedId(header.id);
                                setIsDropDownMustShow(false);
                                setTabWidths(paramsComponent.titleList.map(title => `${(title.text.length)}rem`))
                            }
                            else {
                                if (getSelectedIndexParent === index) {
                                    setIsDropDownMustShow(!getIsDropDownMustShow);
                                }
                                else {
                                    setIsDropDownMustShow(true);
                                }
                                handlePress(index)
                            }
                        }}
                        style={{
                            alignItems: 'center',
                            textAlign: 'center',
                            padding: '0.4rem 1rem',
                            cursor: 'pointer',
                            flexGrow: '1',
                            boxSizing: 'border-box',
                            fontSize: '16px',
                            color: getSelectedIndexParent === index ? Colors.theme.blue.blue : Colors.theme.blue.dark,
                            gap: "0.5rem",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            position: "relative",
                            width: getTabWidths[index], // Explicitly set the width from state
                            transition: 'all 0.1s ease-in-out',
                        }}
                    >
                        {((header.child.length > 0) && header.child.filter(ch => ch.id === getSelectedId).length > 0) ? header.child.filter(ch => ch.id === getSelectedId)[0].text : header.text}

                        {header.child.length > 0 &&
                            (<ArrowDown2Line style={{
                                color: getSelectedIndexParent === index ? Colors.theme.blue.blue : Colors.theme.blue.dark
                            }} />
                            )
                        }
                        {header.child.length > 0 &&
                            <DropdownBox isDropdownOpen={(getSelectedIndexParent == index) && getIsDropDownMustShow}>
                                {header.child.map((child, indexChild, arr) => {
                                    return (
                                        <DropdownItemTitle className="option" isLastItem={(index + 1) === arr.length}
                                            isSelected={getSelectedId === child.id}
                                            onClick={() => {
                                                setSelectedId(child.id);
                                                // Create a copy of the previous state
                                                const updatedWidths = paramsComponent.titleList.map(title => `${(title.text.length)}rem`);
                                                // Update the specific index with the new value
                                                updatedWidths[index] = `${(child.text.length)}rem`;
                                                setTabWidths(updatedWidths)
                                                setIsDropDownMustShow(false);
                                            }}>
                                            {child.text}
                                        </DropdownItemTitle>
                                    )
                                })}
                            </DropdownBox>
                        }
                    </div>
                ))}
            </div>
            {getIsUnderLineShow &&
                <div
                    ref={underlineRef}
                    style={{
                        position: 'absolute',
                        bottom: -1.5,
                        height: '2px',
                        backgroundColor: Colors.theme.orange.light,
                        transition: 'left 0.2s ease-in-out, width 0.3s ease-in-out',
                    }}
                />
            }
        </div>
    );
};

export default NavbarHorizontal;
