import React, { useEffect, useRef, useState } from 'react';
import Colors from "../../../../../config/Colors";
import { useDispatch, useSelector } from 'react-redux';
import { SelectedProfile } from '../../../../../store/profileStateSlice';
import { keyframes, styled, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigComps } from '../../../../../config/ConfigCOMP';
import { isVisibilityProgressBar } from '../../../../../store/ProgressbarInRootSlice';
import { HandlerLangUrl, urlsList } from '../../../../../api/UrlsList';
import { RequestHandler } from '../../../../../api/API';
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from '../../../../../api/APIErrorResponse';
import { ShowAlert } from '../../../../../store/AlertStateModeSlice';
import { enumTypeOfAlert } from '../../../../MUIConfig/alert_popup/interfaceAlertPopup';
import { InterfaceTextFieldSelectorRoleEmployee } from './InterfaceTextFieldSelectorRoleEmployee';
import { InterfaceNTTRoleEmployee } from '../../../../../NTTs/InterfaceNTTRoleEmployee';
import { SyncRounded } from '@mui/icons-material';
import LineSeparator from '../../../LineSeprator/LineSeparator';


const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
    fontSize: "1rem",
    width: "100%",
    paddingInline: "1rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#F8F8F8",
            marginTop: "-8px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
function TextFieldSelectorRoleEmployee(componentParams: InterfaceTextFieldSelectorRoleEmployee) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const { role } = useParams();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);

    const [getIsFocused, setIsFocused] = useState(false);

    const [getRoleSelected, setRoleSelected] = useState<InterfaceNTTRoleEmployee>();
    const [getResponseAllRoles, setResponseAllRoles] = useState<InterfaceNTTRoleEmployee[]>([]);
    const [getSearchText, setSearchText] = useState<string>(componentParams.selectedRole?.title ?? "");
    const [getStateIsSearching, setStateIsSearching] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        componentParams.selectedRole?.title && setSearchText(componentParams.selectedRole?.title);
        setRoleSelected(componentParams.selectedRole)
    }, [componentParams.selectedRole]);

    const getApiListRoleOfEmployee = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.employee.getRoleOfEmployee;
        setStateIsSearching(true);

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as (InterfaceNTTRoleEmployee[]);
                setResponseAllRoles(t);
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            }
            );
        setStateIsSearching(false);


    }

    useEffect(() => {
        getApiListRoleOfEmployee();
    }, [])
    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside2 = (event: MouseEvent) => {
                if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                    setIsFocused(false);
                    setSearchText(getRoleSelected?.title ?? "");
                }
            };

            document.addEventListener("mousedown", handleClickOutside2);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside2);
            };
        }
    }, [getIsFocused]);
    useEffect(() => {
        getRoleSelected && componentParams.onChangeSelectedRole(getRoleSelected);
    }, [getRoleSelected])
    useEffect(() => {
        componentParams.selectedRole && setRoleSelected(componentParams.selectedRole);
    }, [componentParams.selectedRole])
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                // borderRadius: ConstStyle.borderRadius,
                fontSize: "1rem",
                position: "relative",
                boxShadow: Colors.shadow.shadowTextFieldWithIconBottomLineClicked,
                ...componentParams.styleMain,
                paddingBlockStart: "0.32rem",
                boxSizing: "border-box",

            }}
            ref={containerRef}
        >
            {componentParams.iconStart && (
                <div style={{ display: "flex", alignItems: "center", marginInlineStart: "0.96rem" }}>
                    <componentParams.iconStart style={{ color: getIsFocused ? Colors.theme.blue.dark : Colors.theme.blue.light }} />
                </div>
            )}
            <div style={{
                flex: 1, paddingInlineEnd: "1rem",
                paddingInlineStart: "0.32rem",
                boxSizing: "border-box",
            }}>
                {/* <AutoCompleteWithUnderLine
                    valuesList={getResponseAllActivity}
                    onChangeSelectedItem={(items) => {
                        setActivitySelected(items.map((item, index, arr) => {
                            return ({
                                ...getResponseAllActivity.filter(ac => ac.id == item.id)[0]
                            })

                        }));
                    }}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    selectedValueTitle={getActivitySelected}

                /> */}
                <input
                    type="text"
                    onFocus={() => setIsFocused(true)}
                    style={{
                        background: Colors.theme.white.white90,
                        // paddingInline: '0.64rem',
                        paddingBlock: '0.32rem',
                        // border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                        // boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                        borderRadius: '0.4rem',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        color: Colors.theme.blue.dark,
                        outline: 'none',
                        lineHeight: "1.68rem",
                    }}
                    value={getSearchText}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value)}

                />
                <label
                    style={{
                        position: "absolute",
                        boxSizing: "border-box",
                        left: ((getIsFocused || (getRoleSelected)) || !componentParams.iconStart) ? "0.32rem" : "2.4rem",
                        transition: "all 0.2s ease",
                        pointerEvents: "none",
                        top: getIsFocused || (getRoleSelected) ? '0px' : 'unset',
                        bottom: getIsFocused || (getRoleSelected) ? 'unset' : "0rem",
                        transform: getIsFocused || (getRoleSelected) ? "translate(0%, -50%)" : "translate(0%, 0%)",
                        fontSize: (getIsFocused || (getRoleSelected)) ? "0.75rem" : "1em",
                        color: Colors.theme.blue.light,
                        backgroundColor: "transparent",
                        padding: "0",
                    }}
                >
                    {componentParams.title}
                </label>
            </div>
            {
                <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }}>
                    {
                        (componentParams.iconEnd && (
                            <componentParams.iconEnd
                                style={{
                                    background: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                    width: "0.9rem",
                                }} />
                        ))
                    }
                </div>
            }
            <fieldset
                // aria-hidden="true"
                style={{
                    position: "absolute",
                    boxSizing: "border-box",
                    top: getIsFocused || (getRoleSelected) ? "calc(-1.02rem - 0.5px)" : "0px",
                    // height: "100%",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    borderRadius: componentParams.styleMain?.borderRadius,
                    border: "unset",
                    borderBottom: (getIsFocused) ? `1px solid ${Colors.theme.blue.blue}` : `1px solid #C4C7D9`,
                    pointerEvents: "none",
                    padding: "0",
                    margin: "0",
                    display: "block",
                    paddingInline: "0.75rem",
                    // overflow: "hidden"
                }}
            >
                <legend
                    style={{
                        overflow: "hidden",
                        float: "unset",
                        boxSizing: "border-box",
                        transition: "all 0.2s ease",
                        left: getIsFocused || (getRoleSelected) ? "1rem" : "1rem",
                        pointerEvents: "none",

                        display: (getIsFocused || (getRoleSelected)) ? "block" : "none",
                        // transform: "translate(0%,-60%)",
                        // visibility: "hidden",
                        // background: "red",
                    }}
                >
                    <span style={{
                        opacity: 0,
                        fontSize: "0.75rem",
                        visibility: "visible", display: "inline-block",
                        paddingInlineEnd: "0.5rem",
                        boxSizing: "border-box",
                    }}>
                        {componentParams.title}
                    </span>
                </legend>
                {/* } */}
            </fieldset>
            {

                <DropdownBox
                    isDropdownOpen={getIsFocused || getStateIsSearching}
                >
                    {getStateIsSearching &&
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                            <SpinnerIcon />
                        </div>
                    }
                    {!getStateIsSearching && (getResponseAllRoles.length === 0) &&
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                            {confComps.translate.notFound}
                        </div>
                    }
                    {getResponseAllRoles.filter(role => role.title.toLocaleLowerCase().includes(getSearchText.toLowerCase())).flatMap((role, index, arr) => {
                        if ((index + 1) === arr.length) {
                            return (
                                [
                                    <RowOfList onClick={() => {
                                        setSearchText(role.title);
                                        setRoleSelected(role);
                                        setIsFocused(false);
                                    }}>
                                        {role.title}
                                    </RowOfList>
                                ]
                            )
                        }
                        return (
                            [
                                <RowOfList onClick={() => {
                                    setSearchText(role.title);
                                    setRoleSelected(role);
                                    setIsFocused(false);
                                }}>
                                    {role.title}
                                </RowOfList>
                                ,
                                <LineSeparator />]

                        );
                    })
                    }
                </DropdownBox>
            }
        </div>
    );
}

export default TextFieldSelectorRoleEmployee;
