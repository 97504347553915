import { useDispatch } from "react-redux";
import Colors from "../../../config/Colors";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { InterfacePersonListForSenderAndRecipient } from "./InterfacePersonListForSenderAndRecipient";
import { useNavigate } from "react-router-dom";
import { Box, Grid, styled, useTheme } from "@mui/material";
import { ApartmentRounded, CorporateFareRounded, KeyboardArrowDownRounded, Person2Rounded } from "@mui/icons-material";
import { useState } from "react";
import ParentWithHeightAnimation from "../../basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import { InterfaceNTTProfile } from "../../../NTTs/InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../../../NTTs/InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../../../NTTs/InterfaceNTTProfileIranLegal";
import { EnumProfileTypeNameInServer } from "../../../NTTs/InterfaceNTTProfileType";
import { ImageSquare } from "../imageSquare/ImageSquare";
import { baseUrl } from "../../../api/UrlsList";
const Text = styled("p")(({ theme }) => ({
    lineHeight: "1.55rem",
    fontSize: "1.03rem",
    userSelect: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    color: Colors.theme.blue.light,
    textAlign: "center",
    boxSizing: "border-box",
}));
function PersonListForSenderAndRecipient(paramsComponent: InterfacePersonListForSenderAndRecipient) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const [getIsBoxShow, setIsBoxShow] = useState<boolean>(true);
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
            justifyContent: 'start',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '0.88rem',
            }}
                onClick={() => {
                    setIsBoxShow(prev => !prev);
                }}
            >
                <h6 style={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'start', justifyContent: 'start',
                    color: Colors.theme.blue.dark,
                    fontSize: "1.18rem",
                    lineHeight: "1.77rem",
                    fontFamily: confComps.font.poppins.Medium,
                }}>
                    {paramsComponent.title}{` (${paramsComponent.persons.length})`}
                </h6>

                <KeyboardArrowDownRounded
                    style={{
                        fontSize: "1.9rem",

                        color: Colors.theme.blue.dark,
                        cursor: "pointer",
                        transform: getIsBoxShow ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "all 0.5s ease-in-out",
                    }}
                />
            </div>
            <ParentWithHeightAnimation in={getIsBoxShow} style={{ width: '100%' }}>
                <div style={{
                    width: '100%',
                }}>
                    {paramsComponent.persons.map(person => {
                        return (
                            <div style={{
                                paddingInline: '1.6rem',
                                border: `0.5px solid ${Colors.theme.blue.borderWhite}`,
                                boxSizing: 'border-box',
                                width: '100%',
                                boxShadow: Colors.shadow.cardList,
                                paddingBlock: "1.48rem",
                                borderRadius: '0.38rem',
                            }}>
                                <Grid container
                                    justifyContent="space-between" alignItems="flex-start"
                                    columnSpacing={{ xs: 0, sm: 0, md: "6.6rem", lg: "7.4rem", xl: "8.1rem" }}
                                    rowSpacing={{ xs: "1.2rem", sm: "1.39rem", md: "1.53rem", lg: "1.70rem", xl: "1.87rem" }}
                                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                                >

                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <div style={{
                                            display: 'flex', flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            gap: "0.6rem",
                                        }}>
                                            <Box sx={{
                                                width: { xs: "60%", sm: "3rem", md: "3.3rem", lg: "3.62rem", xl: "3.92rem" },
                                            }}>
                                                <ImageSquare width={"100%"}
                                                    borderRadius="500px"
                                                    style={{ position: "relative", overflow: "visible", boxSizing: "border-box" }}
                                                    iconSvg={person.profile.profile_pic ? undefined :
                                                        person.person_type.value === EnumProfileTypeNameInServer.individualProfile ? Person2Rounded : ApartmentRounded}
                                                    img={person.profile.profile_pic && `${baseUrl}${person.profile.profile_pic}`}
                                                    element={
                                                        person.profile.profile_pic &&
                                                            person.person_type.value !== EnumProfileTypeNameInServer.individualProfile ?
                                                            <CorporateFareRounded
                                                                style={{
                                                                    zIndex: 5,
                                                                    borderRadius: "500px",
                                                                    background: "#fff",
                                                                    position: "absolute",
                                                                    width: "1.4rem",
                                                                    height: "1.4rem",
                                                                    transform: "translate(50%,50%)",
                                                                    bottom: "14.64466094%", right: "14.64466094%",
                                                                }}
                                                            />
                                                            :
                                                            person.profile.profile_pic &&
                                                            <Person2Rounded
                                                                style={{
                                                                    zIndex: 5,
                                                                    borderRadius: "500px",
                                                                    background: "#fff",
                                                                    position: "absolute",
                                                                    width: "1.4rem",
                                                                    height: "1.4rem",
                                                                    transform: "translate(50%,50%)",
                                                                    bottom: "14.64466094%", right: "14.64466094%",
                                                                }}
                                                            />


                                                    }
                                                    elementStyle={{
                                                        zIndex: 4,
                                                        position: "absolute",
                                                        width: "28px",
                                                        transform: "translate(0,0)",
                                                        top: "100px", left: "100%",
                                                    }}
                                                    color={Colors.theme.blue.blue}
                                                    background={Colors.theme.white.PrimaryLight}
                                                />

                                            </Box>
                                            <div style={{
                                                display: 'flex', flexDirection: 'column', alignItems: 'start',
                                                justifyContent: 'start',
                                            }}>
                                                <Text style={{
                                                    color: Colors.theme.blue.dark, fontFamily: confComps.font.poppins.Medium
                                                }}>
                                                    {person.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                                        `${(person.profile as InterfaceNTTProfile).first_name}  ${(person.profile as InterfaceNTTProfile).last_name}`
                                                    }
                                                    {person.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                                        `${(person.profile as InterfaceNTTProfileForeignLegal).name}`
                                                    }
                                                    {person.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                                        `${(person.profile as InterfaceNTTProfileIranLegal).name} (${(person.profile as InterfaceNTTProfileIranLegal).name_fa})`
                                                    }
                                                </Text>
                                                <Text style={{
                                                    textAlign: 'start',
                                                    color: Colors.theme.blue.light,
                                                    fontFamily: confComps.font.poppins.Regular,
                                                    fontSize: "0.88rem",
                                                }}>

                                                    {person.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                                        `${(person.profile as InterfaceNTTProfile).address}`
                                                    }
                                                    {person.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                                        `${(person.profile as InterfaceNTTProfileForeignLegal).city}, ${(person.profile as InterfaceNTTProfileForeignLegal).country}`
                                                    }
                                                    {person.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                                        `${(person.profile as InterfaceNTTProfileIranLegal).address}`
                                                    }
                                                </Text>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                        <div style={{
                                            display: 'flex', flexDirection: 'column', alignItems: 'start',
                                            justifyContent: 'start',
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row', alignItems: 'center', justifyContent: 'start',
                                                gap: "0.5rem",
                                            }}>
                                                <Text style={{
                                                    color: Colors.theme.blue.light, fontFamily: confComps.font.poppins.Medium
                                                }}>
                                                    {`${confComps.translate.credit}:`}
                                                </Text>
                                                <Text style={{
                                                    color: Colors.theme.blue.dark, fontFamily: confComps.font.poppins.Medium
                                                }}>
                                                    {`${person.credit} AED`}
                                                </Text>
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row', alignItems: 'center', justifyContent: 'start',
                                                gap: "0.5rem",
                                            }}>
                                                <Text style={{
                                                    color: Colors.theme.blue.light, fontFamily: confComps.font.poppins.Medium
                                                }}>
                                                    {`${confComps.translate.balance}:`}
                                                </Text>
                                                <Text style={{
                                                    color: Colors.theme.blue.dark, fontFamily: confComps.font.poppins.Medium
                                                }}>
                                                    {`${person.balance} AED`}
                                                </Text>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row', alignItems: 'center', justifyContent: 'start',
                                            flexWrap: 'wrap',
                                            width: '100%',
                                            gap: "0.5rem",
                                        }}>
                                            <Text style={{
                                                display: 'inline-flex',
                                                flexBasis: ((person.activities ?? []).length < 3) ? "100%" : undefined,
                                                color: Colors.theme.blue.light, fontFamily: confComps.font.poppins.Medium
                                            }}>
                                                {`${confComps.translate.activities}:`}
                                            </Text>
                                            {person.activities.map((activity, index) => (
                                                <div key={index} style={{
                                                    background: Colors.theme.orange.light,
                                                    borderRadius: "500px",
                                                    lineHeight: '1.48rem',
                                                    color: Colors.theme.blue.dark,
                                                    paddingInline: "0.59rem",
                                                    fontFamily: confComps.font.poppins.Regular,
                                                    fontSize: "0.96rem",
                                                    boxSizing: "border-box",
                                                }}>
                                                    {activity.title}
                                                </div>
                                            ))}
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    })}
                </div>
            </ParentWithHeightAnimation>

        </div>
    )
};
export default PersonListForSenderAndRecipient;