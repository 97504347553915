import { Box, Grid, useTheme } from "@mui/material";
import Colors from "../../../../../../config/Colors";
import SubTitleSection from "../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { useEffect, useState } from "react";
import TextFieldSimple from "../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import TextFieldMultiLine from "../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import FilesDragAndDrop from "../../../../../../components/complex/FileDragAndDrop/FileDragAndDrop";
import { PaperUploadIcon } from "../../../../../../assets/icons/svg/PaperUploadIcon";
import ListFiles from "../../../../../../components/complex/ListFiles/ListFiles";
import { InterfaceFile } from "../../../../../../components/complex/ListFiles/InterfaceListFiles";
import { DialogAttachDetailOfFile } from "../../../../../../components/complex/Dialog/DialogAttachDetailOfFile/DialogAttachDetailOfFile";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";

import { isVisibilityProgressBar, updateProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../api/API";
import { InterfaceNTTActivity } from "../../../../../../NTTs/InterfaceNTTActivity";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import AutoCompleteSimple from "../../../../../../components/complex/AutoCompleteSimple/AutoCompleteSimple";
import { wait } from "@testing-library/user-event/dist/utils";
import ButtonCancel from "../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import { InterfaceCreateCashAccount } from "./InterfaceCreateCashAccount";
import { InterfaceNTTCurrency } from "../../../../../../NTTs/InterfaceNTTCurrency";
import { InterfaceNTTCountry } from "../../../../../../NTTs/InterfaceNTTCountry";
import { InterfaceNTTCity } from "../../../../../../NTTs/InterfaceNTTCity";
import SelectCity from "../../../../../../components/complex/Selector/SelectCity/SelectCity";
import SelectCountry from "../../../../../../components/complex/Selector/SelectCountry/SelectCountry";
import SelectCurrency from "../../../../../../components/complex/Selector/SelectCurrency/SelectCurrency";
import MultiSelectCurrencies from "../../../../../../components/complex/MultiSelectCurrencies/MultiSelectCurrencies";
import MultiSelectCurrenciesV2 from "../../../../../../components/complex/MultiSelectCurrenciesV2/MultiSelectCurrenciesV2";

function CreateCashAccounts(paramsComponent: InterfaceCreateCashAccount) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getSelectedCurrencies, setSelectedCurrencies] = useState<InterfaceNTTCurrency[]>(paramsComponent.cashAccountInformation?.currencies ?? []);
    const [getSelectedCountry, setSelectedCountry] = useState<InterfaceNTTCountry | undefined>(paramsComponent.cashAccountInformation?.country);
    const [getSelectedCity, setSelectedCity] = useState<InterfaceNTTCity | undefined>(paramsComponent.cashAccountInformation?.city)//paramsComponent.accountInformationForEdit?.city);
    const [getDescription, setDescription] = useState<string>(paramsComponent.cashAccountInformation?.description ?? "");

    const callApiCreateCashAccount = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.cashAccount.postCreateNewCashAccount;
        let dataObj = {
            "currencies": getSelectedCurrencies?.map((cur, index, arr) => cur.id),
            "country": getSelectedCountry?.id,
            "city": getSelectedCity?.id,
            "description": getDescription,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/cash-accounts/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackCancel();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: confComps.translate.createdAccount, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callApiForUpdateCashAccount = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.cashAccount.patchCashAccount;
        let dataObj = {
            "currencies": getSelectedCurrencies.map((cur, index, arr) => cur.id),
            "country": getSelectedCountry?.id,
            "city": getSelectedCity?.id,
            "description": getDescription,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.cashAccountInformation?.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackCancel();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: confComps.translate.createdAccount, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    useEffect(() => {
        if (paramsComponent.cashAccountInformation) {
            setSelectedCountry(paramsComponent.cashAccountInformation.country);
            setSelectedCity(paramsComponent.cashAccountInformation.city);
            setSelectedCurrencies(paramsComponent.cashAccountInformation.currencies);
            setDescription(paramsComponent.cashAccountInformation.description);
        }
    }, [paramsComponent.cashAccountInformation])
    return (
        <Box sx={{
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
            width: "100%",
            boxSizing: "border-box",
            paddingInline: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
            border: `1px solid ${Colors.theme.blue.border}`,
            borderRadius: "0.4rem",
            position: "sticky",
            top: "20px",
            zIndex: 2,
            background: "white",
        }}>
            <Box sx={{
                boxSizing: "border-box",
                width: "100%",
                marginBlockEnd: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" },
            }}>
                <SubTitleSection title={paramsComponent.cashAccountInformation ? confComps.translate.updateCashAccount : confComps.translate.addNewCashAccount} />
                <Box sx={{
                    marginInlineStart: { xs: 1, sm: 2, md: 1, lg: 1.5, xl: 2 },
                    marginInlineEnd: { xs: 1, sm: 2, md: 5, lg: 12, xl: "10vw" },
                    boxSizing: "border-box",
                }}>
                    <Grid container
                        justifyContent="space-between" alignItems="stretch"
                        // columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 7, xl: "9vw" }}
                        // rowSpacing={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }}
                        columnSpacing={{ xs: '1.72rem', sm: '1.9rem', md: '2.17rem', lg: "2.37rem", xl: "2.57rem" }}
                        rowSpacing={{ xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "1.9rem" }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                    >
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <SelectCountry
                                onChangeSelectedCountry={(selectCountry?: InterfaceNTTCountry) => {
                                    setSelectedCountry(selectCountry);
                                }}
                                defaultSelected={getSelectedCountry}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <SelectCity
                                isDisable={getSelectedCountry === undefined}
                                countrySelected={getSelectedCountry}
                                onChangeSelectedCity={(selectCity?: InterfaceNTTCity) => {
                                    setSelectedCity(selectCity);
                                }}
                                defaultSelected={getSelectedCity}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <MultiSelectCurrenciesV2
                                onChangeSelectedCurrency={(SelectCurrency: InterfaceNTTCurrency[]) => {
                                    setSelectedCurrencies(SelectCurrency);
                                }}
                                limitInSelection={-1}
                                defaultSelected={getSelectedCurrencies}

                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldMultiLine
                                title={confComps.translate.explanation}
                                text={getDescription}
                                onChangeText={setDescription}
                                styleParent={{
                                    boxSizing: "border-box",
                                    border: `1px solid ${Colors.theme.blue.border}`,
                                    background: Colors.theme.white.white90,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>

            </Box>

            <div style={{
                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                marginBlock: "1rem",
                width: "100%",
                gap: "0.5rem",
                position: "sticky",
                bottom: "20px",
            }}>
                <ButtonCancel text={confComps.translate.cancel}
                    callbackOnClick={() => {
                        paramsComponent.callbackCancel();
                    }} />
                <ButtonSave text={paramsComponent.cashAccountInformation ? confComps.translate.update : confComps.translate.save}
                    callbackOnClick={() => {
                        paramsComponent.cashAccountInformation ?
                            callApiForUpdateCashAccount() :
                            callApiCreateCashAccount();
                    }} />


            </div>

        </Box>

    );

}
export default CreateCashAccounts;