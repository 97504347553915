import { Box, Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { useEffect, useState } from "react";
import { InterfaceNTTCashAccount } from "../../../../../../NTTs/InterfaceNTTCashAccount";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../../api/API";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../../../api/UrlsList";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { InterfaceAllNestaWallets, InterfaceAPIGetAllNestaWallets } from "./InterfaceAllNestaWallets";
import RowNestaWallet from "./RowNestaWallet/RowNestaWallet";
import CreateNestaWallet from "../CreateNestaWallet/CreateNestaWallet";
import { InterfaceNTTNestaWallet } from "../../../../../../NTTs/InterfaceNTTNestaWallet";
import { InterfacePaginationInServer } from "../../../../../../api/interfaces/InterfaceSearchInServer";
import { EnumStateFetchList } from "../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import ParentWithHeightAnimation from "../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import InfiniteScrollList from "../../../../../../components/basic/InfiniteScrollList/InfiniteScrollList";

function AllNestaWallets(paramsComponent: InterfaceAllNestaWallets) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getResponseGetAllNestaWallet, setResponseGetAllNestaWallet] = useState<InterfaceAPIGetAllNestaWallets>();
    const [getIsActiveCreateNestaWallet, setIsActiveCreateNestaWallet] = useState<boolean>(false);
    const [getSelectedNestaWallet, setSelectedNestaWallet] = useState<InterfaceNTTNestaWallet | undefined>();

    const callApiForGetAllNestaWallet = async (paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.wallet.nestaWallet.getAllNestaWallets;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/nesta-wallets/`, mTheme, paginationParams as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIGetAllNestaWallets;
                if (paginationParams.page === 1) {
                    setResponseGetAllNestaWallet(t);
                }
                else {
                    setResponseGetAllNestaWallet(prev => {
                        return (prev ? {
                            ...t,
                            data: [...prev.data, ...t.data],
                        } : {
                            ...t,
                        });
                    }
                    );
                }
                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }

                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                setStateFetchList(EnumStateFetchList.errorInRequest);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            callApiForGetAllNestaWallet(getPaginationForLastRequest);
        }
    }, [getStateFetchList])

    return (
        <div style={{
            display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center",
            gap: "1.6rem",
        }}>
            <ParentWithHeightAnimation in={!getIsActiveCreateNestaWallet} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                {/* {!getIsActiveCreateNestaWallet && getResponseGetAllNestaWallet?.data && */}
                <Box sx={{
                    width: "100%",
                    boxSizing: "border-box",
                }}>
                    <InfiniteScrollList
                        isDataExist={(getResponseGetAllNestaWallet?.data ?? []).length !== 0}
                        onEndReached={async () => {
                            let oldPage = getPaginationForLastRequest;
                            oldPage = {
                                ...oldPage,
                                page: (oldPage.page + 1)
                            };
                            setPaginationForLastRequest(oldPage);
                            console.log("callback for change state response2:", oldPage);
                            callApiForGetAllNestaWallet(oldPage);
                        }}
                        onRetryClicked={() => {
                            callApiForGetAllNestaWallet(getPaginationForLastRequest);
                        }}
                        stateResponseList={getStateFetchList}
                        styleSpacingMUI={{
                            columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                            rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                            columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                        }}

                    >
                        <Grid container
                            justifyContent="space-between" alignItems="flex-start"
                            columnSpacing={{ xs: 0, sm: 2, md: 2, lg: 2.5, xl: 4 }}
                            rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 2.5, xl: 3 }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >


                            {getResponseGetAllNestaWallet && getResponseGetAllNestaWallet.data.map((changeRate, index, arr) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} lg={6} xl={4}>
                                        <RowNestaWallet
                                            {...changeRate}
                                            callbackClickOnEdit={() => {
                                                setSelectedNestaWallet(changeRate);
                                                setIsActiveCreateNestaWallet(true);
                                            }}
                                            callbackReloadPage={() => {
                                                setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                                                callApiForGetAllNestaWallet(confComps.defaultValue.paginationConfigForServer);
                                            }} />
                                    </Grid>

                                )
                            })
                            }
                        </Grid>
                    </InfiniteScrollList>
                </Box>
                {/* } */}
            </ParentWithHeightAnimation>
            <ParentWithHeightAnimation in={getIsActiveCreateNestaWallet} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                {getIsActiveCreateNestaWallet &&
                    <CreateNestaWallet
                        userInfoBasic={paramsComponent.userInfoBasic}
                        callbackCancel={() => {
                            setIsActiveCreateNestaWallet(false);

                            setSelectedNestaWallet(undefined);
                            setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                            callApiForGetAllNestaWallet(confComps.defaultValue.paginationConfigForServer);
                        }}
                        defaultNestaWallet={getSelectedNestaWallet}
                    />
                }

            </ParentWithHeightAnimation>
            {!getIsActiveCreateNestaWallet &&
                <div style={{
                    display: 'flex',
                    zIndex: 20,
                    flexDirection: "row", position: "sticky", bottom: "1vh", alignItems: "center", justifyContent: "end", width: "100%"
                }}>
                    <ButtonSave text={confComps.translate.addNewNestaWallet}
                        callbackOnClick={() => {
                            setIsActiveCreateNestaWallet(true);
                        }} />
                </div>
            }
        </div>
    );
};
export default AllNestaWallets;
