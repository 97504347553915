import * as React from 'react';
import { ConfigComps } from '../../../../config/ConfigCOMP';
import Colors from '../../../../config/Colors';
import { InterfaceDatePickerMUIWithUnderLine } from './InterfaceDatePickerMUIWithUnderLine';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';


function DatePickerMUIWithUnderLine(componentParams: InterfaceDatePickerMUIWithUnderLine) {
    const confComps = ConfigComps();
    const [getIsFocused, setIsFocused] = useState(false);
    // const [getValue, setValue] = useState<number | undefined>();
    // useEffect(() => {
    //     setValue(componentParams.defaultTimeInSecond);
    // }, [componentParams.defaultTimeInSecond])
    const [getValue, setValue] = useState<Dayjs | null>(
        componentParams.defaultTimeInSecond ? dayjs.unix(componentParams.defaultTimeInSecond) : null
    ); // Modified

    useEffect(() => {
        setValue(componentParams.defaultTimeInSecond ? dayjs.unix(componentParams.defaultTimeInSecond) : null);
    }, [componentParams.defaultTimeInSecond]);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    // borderRadius: ConstStyle.borderRadius,
                    fontSize: "1rem",
                    position: "relative",
                    boxShadow: Colors.shadow.shadowTextFieldWithIconBottomLineClicked,
                    ...componentParams.styleMain,
                    paddingBlockStart: "0.32rem",
                    boxSizing: "border-box",

                }}
            >
                {componentParams.iconStart && (
                    <div style={{ display: "flex", alignItems: "center", marginInlineStart: "0.96rem" }}>
                        <componentParams.iconStart style={{ color: getIsFocused ? Colors.theme.blue.dark : Colors.theme.blue.light }} />
                    </div>
                )}
                <div style={{
                    flex: 1,
                    paddingInlineEnd: "0.44rem",
                    paddingInlineStart: "0.32rem",
                    boxSizing: "border-box",
                }}>

                    <DatePicker
                        format="YYYY/MM/DD"
                        onChange={(newValue: Dayjs | null) => {
                            if (newValue != null) {
                                componentParams.onChangeValue(newValue.unix());//return second
                                setValue(newValue);
                            }
                            else {
                                componentParams.onChangeValue(undefined);
                                setValue(null);
                            }
                        }}
                        value={getValue}
                        // defaultValue={getValue ? dayjs.unix(2000020) : undefined}
                        slotProps={{
                            textField: {
                                onFocus: () => setIsFocused(true),
                                onBlur: () => setIsFocused(false),
                                placeholder: getIsFocused ? undefined : ""
                            },
                        }}
                        sx={{
                            fontSize: '1.12rem',
                            lineHeight: "1.68rem",
                            outline: "unset",
                            width: "100%",
                            "& div": {
                                outline: "unset",
                                paddingInlineEnd: "0rem",
                            },
                            "& input": {
                                background: Colors.theme.white.white90,
                                outline: "unset",
                                paddingBlock: '0rem',
                                paddingInlineStart: '0rem',
                                // border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                                // boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                                // borderRadius: '0.4rem',
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: '1.12rem',
                                color: Colors.theme.blue.dark,
                                lineHeight: "1.68rem",

                            },
                            "& fieldset": {
                                outline: "unset",
                                border: "unset !important", //getIsFocused ? `1px solid ${Colors.theme.blue.blue} !important` : `1px solid ${Colors.theme.blue.border} !important`,
                            },
                            "& button": {
                                marginBottom: "0px",
                                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                // paddingInline: "0px",
                                padding: "0rem",
                            }

                        }}
                    />

                    <label
                        style={{
                            position: "absolute",
                            boxSizing: "border-box",
                            left: ((getIsFocused || getValue) || !componentParams.iconStart) ? "0.32rem" : "2.4rem",
                            transition: "all 0.2s ease",
                            pointerEvents: "none",
                            top: getIsFocused || getValue ? '0px' : 'unset',
                            bottom: getIsFocused || getValue ? 'unset' : "0rem",
                            transform: getIsFocused || getValue ? "translate(0%, -50%)" : "translate(0%, 0%)",
                            fontSize: (getIsFocused || getValue) ? "0.75rem" : "1em",
                            color: Colors.theme.blue.light,
                            backgroundColor: "transparent",
                            padding: "0",
                        }}
                    >
                        {componentParams.title}
                    </label>
                </div>
                {
                    <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }}>
                        {
                            (componentParams.iconEnd && (
                                <componentParams.iconEnd
                                    style={{
                                        background: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                        width: "0.9rem",
                                    }} />
                            ))
                        }
                    </div>
                }
                <fieldset
                    // aria-hidden="true"
                    style={{
                        position: "absolute",
                        boxSizing: "border-box",
                        top: getIsFocused ? "calc(-1.02rem - 0.5px)" : "0px",
                        // height: "100%",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        borderRadius: componentParams.styleMain?.borderRadius,
                        border: "unset",
                        borderBottom: (getIsFocused) ? `1px solid ${Colors.theme.blue.blue}` : `1px solid #C4C7D9`,
                        pointerEvents: "none",
                        padding: "0",
                        margin: "0",
                        display: "block",
                        paddingInline: "0.75rem",
                        // overflow: "hidden"
                    }}
                >
                    <legend
                        style={{
                            overflow: "hidden",
                            float: "unset",
                            boxSizing: "border-box",
                            transition: "all 0.2s ease",
                            left: getIsFocused || getValue ? "1rem" : "1rem",
                            pointerEvents: "none",
                            // color: getIsFocused ? '#000' : '#666',
                            display: (getIsFocused || getValue) ? "block" : "none",
                            // transform: "translate(0%,-60%)",
                            // visibility: "hidden",
                            // background: "red",
                        }}
                    >
                        <span style={{
                            opacity: 0,
                            fontSize: "0.75rem",
                            visibility: "visible", display: "inline-block",
                            paddingInlineEnd: "0.5rem",
                            boxSizing: "border-box",
                        }}>
                            {componentParams.title}
                        </span>
                    </legend>
                    {/* } */}
                </fieldset>
            </div>
        </LocalizationProvider>
    );
}

export default DatePickerMUIWithUnderLine;
