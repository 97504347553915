import { Box, Grid, useTheme } from "@mui/material";
import Colors from "../../../../../../../../config/Colors";
import SubTitleSection from "../../../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import { ConfigComps } from "../../../../../../../../config/ConfigCOMP";
import { useEffect, useState } from "react";
import TextFieldSimple from "../../../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import TextFieldMultiLine from "../../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import FilesDragAndDrop from "../../../../../../../../components/complex/FileDragAndDrop/FileDragAndDrop";
import { PaperUploadIcon } from "../../../../../../../../assets/icons/svg/PaperUploadIcon";
import ListFiles from "../../../../../../../../components/complex/ListFiles/ListFiles";
import { InterfaceFile } from "../../../../../../../../components/complex/ListFiles/InterfaceListFiles";
import { DialogAttachDetailOfFile } from "../../../../../../../../components/complex/Dialog/DialogAttachDetailOfFile/DialogAttachDetailOfFile";
import ButtonSave from "../../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";

import { isVisibilityProgressBar, updateProgressBar } from "../../../../../../../../store/ProgressbarInRootSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../../api/API";
import { InterfaceNTTActivity } from "../../../../../../../../NTTs/InterfaceNTTActivity";
import { ShowAlert } from "../../../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../../api/APIErrorResponse";
import ButtonCancel from "../../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";

import { InterfaceCreateAccountFoTheForeignCompany } from "./InterfaceCreateAccountFoTheForeignCompany";
import { InterfaceNTTCountry } from "../../../../../../../../NTTs/InterfaceNTTCountry";
import { InterfaceNTTCity } from "../../../../../../../../NTTs/InterfaceNTTCity";
import SelectCountry from "../../../../../../../../components/complex/Selector/SelectCountry/SelectCountry";
import SelectCity from "../../../../../../../../components/complex/Selector/SelectCity/SelectCity";
import { InterfaceNTTCurrency } from "../../../../../../../../NTTs/InterfaceNTTCurrency";
import SelectCurrency from "../../../../../../../../components/complex/Selector/SelectCurrency/SelectCurrency";

function CreateAccountFoTheForeignCompany(paramsComponent: InterfaceCreateAccountFoTheForeignCompany) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getBankName, setBankName] = useState<string>(paramsComponent.accountInformationForEdit?.bank_name ?? '');
    const [getBranchName, setBranchName] = useState<string>(paramsComponent.accountInformationForEdit?.branch_name ?? '');
    const [getAccountNumber, setAccountNumber] = useState<string>(paramsComponent.accountInformationForEdit?.account_number ?? '');
    const [getIban, setIban] = useState<string>(paramsComponent.accountInformationForEdit?.iban ?? '');
    const [getSwiftCode, setSwiftCode] = useState<string>(paramsComponent.accountInformationForEdit?.swift_code ?? '');
    const [getSelectedCurrency, setSelectedCurrency] = useState<InterfaceNTTCurrency | undefined>(paramsComponent.accountInformationForEdit?.currency);
    const [getSelectedCountry, setSelectedCountry] = useState<InterfaceNTTCountry | undefined>(paramsComponent.accountInformationForEdit?.country);
    const [getSelectedCity, setSelectedCity] = useState<InterfaceNTTCity | undefined>(paramsComponent.accountInformationForEdit?.city);
    const [getBankAddress, setBankAddress] = useState<string>(paramsComponent.accountInformationForEdit?.bank_address ?? '');
    const [getDescription, setDescription] = useState<string>(paramsComponent.accountInformationForEdit?.description ?? '');
    const callApiCreateAccount = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.foreignBankAccount.postAddBankAccount;
        let dataObj = {
            "bank_name": getBankName,
            "branch_name": getBranchName,
            "account_number": getAccountNumber,
            "iban": getIban,
            "swift_code": getSwiftCode,
            "country": getSelectedCountry?.id,
            "city": getSelectedCity?.id,
            "currency": getSelectedCurrency?.id,
            "bank_address": getBankAddress,
            "description": getDescription,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.companyInformation.id}/bank-accounts/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                paramsComponent.callbackCancel();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: confComps.translate.createdAccount, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForUpdateAccount = async () => {
        dispatch(isVisibilityProgressBar(true));

        let urlObj = urlsList.panel.foreignBankAccount.updateTheBankAccount;
        let dataObj = {
            "bank_name": getBankName,
            "branch_name": getBranchName,
            "account_number": getAccountNumber,
            "iban": getIban,
            "swift_code": getSwiftCode,
            "country": getSelectedCountry?.id,
            "currency": getSelectedCurrency?.id,
            "city": getSelectedCity?.id,
            "bank_address": getBankAddress,
            "description": getDescription,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.accountInformationForEdit?.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(isVisibilityProgressBar(false));
                paramsComponent.callbackCancel();
                dispatch(ShowAlert({ text: confComps.translate.updatedCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                if (paramsComponent.companyInformation) {
                    // updateFiles(paramsComponent.companyInformation.id);
                }



            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }


    useEffect(() => {
        // getApiListActivity();
    }, [])
    return (
        <Box sx={{
            display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
            width: "100%",
            boxSizing: "border-box",
            paddingInline: { xs: 1, sm: 1, md: 2, lg: 3, xl: 3 },
            border: `1px solid ${Colors.theme.blue.border}`,
            borderRadius: "0.4rem",
            position: "sticky",
            top: "20px",
            zIndex: 2,
            background: "white",
        }}>
            <Box sx={{
                boxSizing: "border-box",
                width: "100%",
            }}>
                <SubTitleSection title={confComps.translate.addNewCompany} />
                <Box sx={{
                    marginInlineStart: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 2 },
                    marginInlineEnd: { xs: 0, sm: 8, md: 9, lg: 10, xl: 40 },
                    boxSizing: "border-box",
                }}>
                    <Grid container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 0, sm: 6, md: 10, lg: 12, xl: 30 }}
                        rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                    >
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.bankName} textValue={getBankName}
                                onChangeValue={setBankName}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.branchName} textValue={getBranchName}
                                onChangeValue={setBranchName}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.accountNumber} textValue={getAccountNumber}
                                onChangeValue={setAccountNumber}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.iBan} textValue={getIban}
                                onChangeValue={setIban}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.swiftCode} textValue={getSwiftCode}
                                onChangeValue={setSwiftCode}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldSimple title={confComps.translate.bankAddress} textValue={getBankAddress}
                                onChangeValue={setBankAddress}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <SelectCurrency
                                onChangeSelectedCurrency={(SelectCurrency?: InterfaceNTTCurrency) => {
                                    setSelectedCurrency(SelectCurrency);
                                }}
                                defaultSelected={getSelectedCurrency}
                            />
                        </Grid>


                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <SelectCountry
                                onChangeSelectedCountry={(selectCountry?: InterfaceNTTCountry) => {
                                    setSelectedCountry(selectCountry);
                                }}
                                defaultSelected={getSelectedCountry}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <SelectCity
                                isDisable={getSelectedCountry === undefined}
                                countrySelected={getSelectedCountry}
                                onChangeSelectedCity={(selectCity?: InterfaceNTTCity) => {
                                    setSelectedCity(selectCity);
                                }}
                                defaultSelected={getSelectedCity}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldMultiLine title={confComps.translate.explanation} text={getDescription}
                                onChangeText={setDescription}
                                styleParent={{
                                    boxSizing: "border-box",
                                    border: `1px solid ${Colors.theme.blue.border}`,
                                    background: Colors.theme.white.white90,
                                }}
                            />
                        </Grid>

                    </Grid>
                </Box>

            </Box>

            <div style={{
                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                marginBlock: "1rem",
                width: "100%",
                gap: "0.5rem",
                // position: "sticky",
                // bottom:"20px",
            }}>
                <ButtonCancel text={confComps.translate.cancel}
                    callbackOnClick={() => {
                        paramsComponent.callbackCancel();
                    }} />
                <ButtonSave text={paramsComponent.accountInformationForEdit ? confComps.translate.update : confComps.translate.save}
                    callbackOnClick={() => {
                        paramsComponent.accountInformationForEdit ?
                            callApiForUpdateAccount() :
                            callApiCreateAccount();
                    }} />


            </div>

        </Box>

    );

}
export default CreateAccountFoTheForeignCompany;