import { useEffect, useState } from "react";
import { BottomTriangleIcon } from "../../../../assets/icons/svg/BottomTriangleIcon";
import Colors from "../../../../config/Colors";
import { InterfaceButtonArrowWithAnimation } from "./InterfaceButtonArrowWithAnimation";
import { ConfigComps } from "../../../../config/ConfigCOMP";

function ButtonArrowWithAnimation(paramsComponent: InterfaceButtonArrowWithAnimation) {
    var confComps = ConfigComps();
    const [getIsDropdownBoxOpen, setIsDropdownBoxOpen] = useState(false);
    useEffect(() => {
        if (paramsComponent.isShowList)
            setIsDropdownBoxOpen(paramsComponent.isShowList);
    }, [paramsComponent.isShowList])
    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end", width: "100%", gap: "0.5rem" }}>
            <button style={{
                display: "flex",
                flexDirection: "row", alignItems: 'center', justifyContent: "center",
                borderRadius: "0.4rem",
                border: `1px solid ${Colors.theme.blue.light}`,
                color: Colors.theme.blue.Secondary,
                fontSize: "1.1rem",
                fontFamily: confComps.font.poppins.Regular,
                boxSizing: "border-box",
                paddingInline: "0.64rem",
                paddingBlock: "0.1rem",
                cursor: "pointer",
            }}
                onClick={() => {
                    paramsComponent.callbackClicked();
                    // setIsDropdownBoxOpen(!getIsDropdownBoxOpen);
                }}>
                {paramsComponent.text}
                {paramsComponent.isShowList &&
                    <BottomTriangleIcon style={{
                        transform: getIsDropdownBoxOpen ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.5s ease-in-out",
                    }} />
                }

            </button>
        </div>
    );
}
export default ButtonArrowWithAnimation;