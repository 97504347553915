import { ChangeEvent, useState } from "react";
import { InterfaceTextFieldFixCurrencyToAnother } from "./InterfaceTextFieldFixCurrencyToAnother";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { ChevronRight } from "@mui/icons-material";
import { ArrowRightIcon } from "../../../../assets/icons/svg/ArrowRightIcon";
import { ArrowSmallRightIcon } from "../../../../assets/icons/svg/ArrowSmallRightIcon";
import { BoxStepArrowSVG } from "../../../../assets/icons/svg/BoxStepArrowSVG";
import { Small8RightIcon } from "../../../../assets/icons/svg/Small8RightIcon";

function TextFieldFixCurrencyToAnother(paramsComponent: InterfaceTextFieldFixCurrencyToAnother) {
    const [getIsFocused, setIsFocused] = useState(false);
    var confComps = ConfigComps();
    return (
        <div style={{
            display: 'flex', flexDirection: 'column',
            ...paramsComponent.style,

        }}>
            {paramsComponent.title && <label style={{
                fontWeight: "300",

                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
            }}>
                {paramsComponent.title}
            </label>}
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'start',
                paddingInline: '0.64rem',
                paddingBlock: '0.32rem',
                border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                borderRadius: '0.4rem',
                background: Colors.theme.white.white90,
                gap: "0.4rem",
            }}>
                <p style={{
                    flexGrow: 0,
                    paddingInlineStart: '0.4rem',
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '1.12rem',
                    color: Colors.theme.gray.light,
                    outline: 'none',
                    lineHeight: "1.68rem",

                }}>
                    {`1 ${paramsComponent.fixCurrencyUnit}`}

                </p>
                <Small8RightIcon style={{
                    flexGrow: 0,
                    strokeWidth: "4",
                    width: "5",
                    height: "13",
                    userSelect: "none",
                    boxSizing: "border-box",

                    color: Colors.theme.blue.blue,
                }} />
                <p style={{
                    flexGrow: 0,
                    paddingInlineStart: '0.4rem',
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '1.12rem',
                    color: Colors.theme.blue.dark,
                    outline: 'none',
                    lineHeight: "1.68rem",
                }}
                >
                    {`${paramsComponent.anotherCurrencyUnit}:`}
                </p>
                <input
                    autoComplete="off"
                    type={paramsComponent.type ?? "text"}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    style={{
                        flexGrow: 1,
                        flexBasis: 0,
                        width: 0,
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        color: Colors.theme.blue.dark,
                        outline: 'none',
                        lineHeight: "1.68rem",
                    }}
                    placeholder="1000"
                    value={paramsComponent.value}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        paramsComponent.onChangeValue(event.target.value);
                    }}
                />
            </div>
        </div>
    )
}
export default TextFieldFixCurrencyToAnother;