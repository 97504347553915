
import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const EditPenIcon: React.FC<InterfaceIconSVG> = ({ style = { width: "8", height: "8" } }) => {
    return (
        <svg width={style.width} height={style.height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.66708 0.301758L4.91083 1.05801L6.94208 3.08926L7.69833 2.33301C8.08895 1.94238 8.08895 1.30957 7.69833 0.918945L7.0827 0.301758C6.69208 -0.0888672 6.05927 -0.0888672 5.66864 0.301758H5.66708ZM4.5577 1.41113L0.915517 5.05488C0.753017 5.21738 0.634267 5.41895 0.568642 5.63926L0.0155167 7.51895C-0.0235458 7.65176 0.0123917 7.79395 0.109267 7.89082C0.206142 7.9877 0.348329 8.02363 0.479579 7.98613L2.35927 7.43301C2.57958 7.36738 2.78114 7.24863 2.94364 7.08613L6.58895 3.44238L4.5577 1.41113Z"
                fill={style?.background ? `${style?.background}` : "#2D3250"} />
        </svg>
    )
}