import { Box, Grid, styled, useTheme } from "@mui/material";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { ImageSquare } from "../../../../components/complex/imageSquare/ImageSquare";
import Colors, { ColorThemForButton } from "../../../../config/Colors";
import { AddRounded, CheckCircleOutlineTwoTone, CheckCircleTwoTone, CheckRounded, FileUploadRounded, HistoryToggleOffRounded, Person, Person2Rounded, PlusOneRounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import LineSeparator from "../../../../components/basic/LineSeprator/LineSeparator";
import ArrayText from "../../../../components/basic/Texts/ArrayText/ArrayText";
import { getColorCodeOfBalanceNumber } from "../../../../helper/Helper";
import { useNavigate } from "react-router-dom";
import Links from "../../../../config/Links";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../api/API";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { InterfaceRowRecap } from "./InterfaceRowRecap";
import OpenProfile from "../../../../components/basic/OpenProfile/OpenProfile";
import dayjs from "dayjs";
import ProgressBarGradient from "../../../../components/basic/progressBar/ProgressBarGradient/ProgressBarGradient";
import { TickWithCircleIcon } from "../../../../assets/icons/svg/TickWithCircleIcon";
import ButtonColor from "../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import { ArrowRightIcon } from "../../../../assets/icons/svg/ArrowRightIcon";
import { useEffect, useState } from "react";
import { StepNameMappingToKeyStepForMoneyReceiveRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap";
import { InterfaceSteperGroup } from "../../../../components/complex/SteperGuide/SteperGroup/InterfaceSteperGroup";
import { isKey1BiggerThanKey2 } from "../../../../components/complex/SteperGuide/SteperGuide";
import { StepNameMappingToKeyStepForMoneyPaymentRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/MoneyPayment/InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap";
import { StepNameMappingToKeyStepForSellCottageRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/SellCottage/InterfaceNTTOneStepOfWorkflowSellCottageRecap";
import { StepOfSolutionNameMappingToKeyStepForBuyCottageRecap } from "../../../../NTTs/Recap/solution/InterfaceNTTWorkflowSolution";
import { StepNameMappingToKeyStepForBuyCottageRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/BuyCottage/InterfaceNTTOneStepOfWorkflowBuyCottageRecap";
const ParentDiv = styled("div")(({ theme }) => ({
    width: "100%", padding: "1px",
    background: "white",
    boxShadow: Colors.shadow.cardList,
    borderRadius: "0.5rem",
    cursor: "pointer",
    "&:hover": {
        background: Colors.gradients.blueGreenBlue,
    }
}));
const TagService = styled("div")(({ theme }) => ({
    background: Colors.theme.white.PrimaryLight,
    color: Colors.theme.blue.dark,
    boxShadow: Colors.shadow.cardList,
    paddingInline: "0.5rem",
    paddingBlock: "0.16rem",
    borderRadius: "500px",
    boxSizing: "border-box",
    lineHeight: "1.2rem",
    fontSize: "1rem",


}));
function RowRecap(paramsComponent: InterfaceRowRecap) {
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getPercentage, setPercentage] = useState<number>(0);
    const [getTitleOfPercentage, setTitleOfPercentage] = useState<string>("");
    // const callApiForDeleteEmployee = async (idPerson: number) => {
    //     dispatch(isVisibilityProgressBar(true));
    //     let urlObj = urlsList.panel.employee.deleteTheEmployee;
    //     await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idPerson}/`, mTheme),
    //         urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
    //         .then((response: any) => {
    //             console.log("deleted");
    //             console.log(response);
    //             paramsComponent.callbackReloadPage();
    //             dispatch(ShowAlert({ text: confComps.translate.removedEmployee, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
    //             dispatch(isVisibilityProgressBar(false));
    //         })
    //         .catch((e: any) => {
    //             console.log("response:(:(");
    //             console.log(e);
    //             dispatch(isVisibilityProgressBar(false));
    //             if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
    //                 dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
    //             }
    //             else {
    //                 dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
    //             }
    //         });


    // }
    const findTitleByKey = (list: InterfaceSteperGroup[], key: string): { title: string, key: string } => {
        for (const item of list) {
            if (item.keyStep === key) {
                return {
                    title: item.title,
                    key: key,
                }; // Found the key, return the title
            }
            // If the current item has nested steps, search in those
            if (item.steps.length > 0) {
                const found = findTitleByKey(item.steps, key);
                if (found) {
                    return found; // Return if found in nested steps
                }
            }
        }
        return { title: "", key: key }; // Return null if the key is not found
    };

    useEffect(() => {
        if (paramsComponent.recapMainInfo && paramsComponent.allWorkFlowOfThisRecap?.recap_id) {
            let allWorkflow = paramsComponent.allWorkFlowOfThisRecap;
            //money Receive
            let lastStepNameAllMoneyReceive = allWorkflow.receipts.map(service => service.workflow_reviews.length > 0 ?
                StepNameMappingToKeyStepForMoneyReceiveRecap[service.workflow_reviews[service.workflow_reviews.length - 1].review_ct] :
                "1.1")
                .map(keyStep => findTitleByKey([confComps.steperGuide.recap.steps], keyStep))
            const valueOfMappingMoneyReceive = Object.values(StepNameMappingToKeyStepForMoneyReceiveRecap);
            let lastStepWithRemindStepMoneyReceive = lastStepNameAllMoneyReceive.map((lastStep) => {
                return ({
                    ...lastStep,
                    countStepRemind: valueOfMappingMoneyReceive.filter((currentKey) => isKey1BiggerThanKey2(currentKey, lastStep.key)).length,
                }
                )
            })
            if (lastStepWithRemindStepMoneyReceive.length !== paramsComponent.recapMainInfo.receipts.length) {
                //this service only create so 
                for (let i = 0; i < (paramsComponent.recapMainInfo.receipts.length - lastStepWithRemindStepMoneyReceive.length); i++) {
                    lastStepWithRemindStepMoneyReceive.push({
                        countStepRemind: valueOfMappingMoneyReceive.length - 1,
                        key: "1.1",
                        title: "initiate"
                    })
                }
            }
            //money payment

            let lastStepNameAllMoneyPayment = allWorkflow.payments.map(
                service => service.workflow_reviews.length > 0 ?
                    StepNameMappingToKeyStepForMoneyPaymentRecap[service.workflow_reviews[service.workflow_reviews.length - 1].review_ct] :
                    "1.1")
                .map(keyStep => findTitleByKey([confComps.steperGuide.recap.steps], keyStep))
            const valueOfMappingMoneyPayment = Object.values(StepNameMappingToKeyStepForMoneyPaymentRecap);
            let lastStepWithRemindStepMoneyPayment = lastStepNameAllMoneyPayment.map((lastStep) => {
                return ({
                    ...lastStep,
                    countStepRemind: valueOfMappingMoneyPayment.filter((currentKey) => isKey1BiggerThanKey2(currentKey, lastStep.key)).length,
                }
                )
            })
            if (lastStepWithRemindStepMoneyPayment.length !== paramsComponent.recapMainInfo.payments.length) {
                //this service only create so 
                for (let i = 0; i < (paramsComponent.recapMainInfo.payments.length - lastStepWithRemindStepMoneyPayment.length); i++) {
                    lastStepWithRemindStepMoneyPayment.push({
                        countStepRemind: valueOfMappingMoneyPayment.length - 1,
                        key: "1.1",
                        title: "initiate"
                    })
                }
            }
            //Sell Cottage
            let lastStepNameAllCottageSell = allWorkflow.sell_cottages.map(service => service.workflow_reviews.length > 0 ? StepNameMappingToKeyStepForMoneyReceiveRecap[service.workflow_reviews[service.workflow_reviews.length - 1].review_ct] : "1.1")
                .map(keyStep => findTitleByKey([confComps.steperGuide.recap.steps], keyStep))
            const valueOfMappingCottageSell = Object.values(StepNameMappingToKeyStepForSellCottageRecap);
            let lastStepWithRemindStepCottageSell = lastStepNameAllCottageSell.map((lastStep) => {
                return ({
                    ...lastStep,
                    countStepRemind: valueOfMappingCottageSell.filter((currentKey) => isKey1BiggerThanKey2(currentKey, lastStep.key)).length,
                }
                )
            })
            if (lastStepWithRemindStepCottageSell.length !== paramsComponent.recapMainInfo.sell_cottages.length) {
                //this service only create so 
                for (let i = 0; i < (paramsComponent.recapMainInfo.sell_cottages.length - lastStepWithRemindStepCottageSell.length); i++) {
                    lastStepWithRemindStepCottageSell.push({
                        countStepRemind: valueOfMappingCottageSell.length - 1,
                        key: "1.1",
                        title: "initiate"
                    })
                }
            }
            //Buy Cottage
            let lastStepNameAllCottageBuy = allWorkflow.buy_cottages.map(service => service.workflow_reviews.length > 0 ? StepNameMappingToKeyStepForMoneyReceiveRecap[service.workflow_reviews[service.workflow_reviews.length - 1].review_ct] : "1.1")
                .map(keyStep => findTitleByKey([confComps.steperGuide.recap.steps], keyStep))
            const valueOfMappingCottageBuy = Object.values(StepNameMappingToKeyStepForBuyCottageRecap);
            let lastStepWithRemindStepCottageBuy = lastStepNameAllCottageBuy.map((lastStep) => {
                return ({
                    ...lastStep,
                    countStepRemind: valueOfMappingCottageBuy.filter((currentKey) => isKey1BiggerThanKey2(currentKey, lastStep.key)).length,
                }
                )
            })
            if (lastStepWithRemindStepCottageBuy.length !== paramsComponent.recapMainInfo.buy_cottages.length) {
                //this service only create so 
                for (let i = 0; i < (paramsComponent.recapMainInfo.buy_cottages.length - lastStepWithRemindStepCottageBuy.length); i++) {
                    lastStepWithRemindStepCottageBuy.push({
                        countStepRemind: valueOfMappingCottageBuy.length - 1,
                        key: "1.1",
                        title: "initiate"
                    })
                }
            }
            let array = [
                ...lastStepWithRemindStepMoneyReceive,
                ...lastStepWithRemindStepMoneyPayment,
                ...lastStepWithRemindStepCottageBuy,
                ...lastStepWithRemindStepCottageSell,
            ]
            if (paramsComponent.recapMainInfo.id === 14) {
                console.log("safajoon debug 14:", array)
            }
            const totalStepRemind = array.reduce((sum, item) => sum + item.countStepRemind, 0);
            let totalSteps =
                (valueOfMappingMoneyReceive.length * lastStepWithRemindStepMoneyReceive.length) +
                (valueOfMappingMoneyPayment.length * lastStepWithRemindStepMoneyPayment.length) +
                (valueOfMappingCottageBuy.length * lastStepWithRemindStepCottageBuy.length) +
                (valueOfMappingCottageSell.length * lastStepWithRemindStepCottageSell.length) + 0;
            if (totalSteps === 0) {
                setPercentage(-1);
            }
            else {
                setPercentage((1 - (totalStepRemind / totalSteps)) * 100);
            }

            setTitleOfPercentage(array.map(step => step.title).join(" ,"));
        }
        // return total / array.length;

    }, [paramsComponent.allWorkFlowOfThisRecap, paramsComponent.recapMainInfo])
    return (
        <ParentDiv style={{ height: "100%" }}
            onClick={() => {
                navigate(Links.screens.panel.recap.ShowStateTheRecap + `${paramsComponent.recapMainInfo.id}/`)
            }}>
            <Box

                sx={{
                    background: "white",
                    height: "100%",
                    width: "100%", display: "flex", flexDirection: "column",
                    alignItems: "center", justifyContent: "space-between",
                    borderRadius: "0.5rem",
                    paddingInline: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 },//{{ xs: "0.96rem" }},
                    paddingTop: "1.28rem",
                    paddingBottom: { xs: 1, sm: 1, md: 2, lg: 3, xl: 4 },
                    boxSizing: "border-box",

                }}>
                <Grid container
                    justifyContent="flex-start" alignItems="stretch"
                    columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                    rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 2, xl: 4 }}
                    columns={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
                >
                    {paramsComponent.recapMainInfo.receipts.flatMap((payment, index, arr) => {
                        return [
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <OpenProfile user={payment.customer.user}
                                    profile={payment.customer.profile}
                                    is_trusty={payment.customer.is_trusty}
                                />
                            </Grid>
                            ,
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <TagService>
                                        {confComps.translate.moneyReceive}
                                    </TagService>
                                </div>
                            </Grid>
                        ]
                    })
                    }
                    {paramsComponent.recapMainInfo.payments.flatMap((payment, index, arr) => {
                        return [
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <OpenProfile user={payment.customer.user} is_trusty={payment.customer.is_trusty}
                                    profile={payment.customer.profile}
                                />
                            </Grid>
                            ,
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <TagService>
                                        {confComps.translate.moneyPayment}
                                    </TagService>
                                </div>
                            </Grid>
                        ]
                    })
                    }
                    {paramsComponent.recapMainInfo.buy_cottages.flatMap((payment, index, arr) => {
                        return [
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <OpenProfile user={payment.customer.user} is_trusty={payment.customer.is_trusty}
                                    profile={payment.customer.profile} />
                            </Grid>
                            ,
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <TagService>
                                        {confComps.translate.buyCottage}
                                    </TagService>
                                </div>
                            </Grid>
                        ]
                    })
                    }

                    {paramsComponent.recapMainInfo.sell_cottages.flatMap((payment, index, arr) => {
                        return [
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <OpenProfile user={payment.customer.user} is_trusty={payment.customer.is_trusty}
                                    profile={payment.customer.profile} />
                            </Grid>
                            ,
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <TagService>
                                        {confComps.translate.sellCottage}
                                    </TagService>
                                </div>
                            </Grid>
                        ]
                    })
                    }
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <ArrayText
                            texts={[
                                {
                                    text: `${confComps.translate.recapID}: `,
                                    style: {
                                        color: Colors.theme.blue.light,
                                    }
                                },
                                {
                                    text: `${paramsComponent.recapMainInfo.id}`,
                                    style: {
                                        color: Colors.theme.blue.dark,
                                    }

                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <ArrayText
                            texts={[
                                {
                                    text: `${confComps.translate.date}: `,
                                    style: {
                                        color: Colors.theme.blue.light,
                                    }
                                },
                                {
                                    text: `${dayjs.unix(paramsComponent.recapMainInfo.created_at).format("YYYY/MM/DD")}`,
                                    style: {
                                        color: Colors.theme.blue.dark,
                                    }

                                }
                            ]}
                        />
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        {getPercentage > 0 ?
                            <ProgressBarGradient percentage={parseFloat(getPercentage.toFixed(2))} label={{
                                title: getTitleOfPercentage,
                                link: ""
                            }} />
                            :
                            <p style={{
                                width: '100%',
                                alignItems: 'start',
                                textAlign: 'start',
                                justifyContent: 'start',
                                fontSize: '1rem',
                                color: Colors.theme.orange.sharp,
                            }}>
                                Not initiate a service yet!
                            </p>
                        }
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: "0.32rem" }}>

                            {getPercentage !== 100 ?
                                <div style={{
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.5rem',
                                }}>
                                    <HistoryToggleOffRounded
                                        style={{
                                            fontSize: "1.5rem",
                                            color: Colors.theme.orange.sharp,
                                        }}
                                    />
                                    <h5 style={{
                                        fontSize: "0.8rem", fontFamily: confComps.font.poppins.SemiBold, lineHeight: "1.4rem",
                                        color: Colors.theme.orange.sharp,
                                    }}>
                                        {confComps.translate.inProgress}

                                    </h5>
                                </div>
                                :
                                <div style={{
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'start',
                                    gap: '0.5rem',
                                }}>
                                    <TickWithCircleIcon style={{ color: Colors.theme.green.dark, background: Colors.theme.green.lightLemon }} />
                                    <h5 style={{
                                        fontSize: "0.8rem", fontFamily: confComps.font.poppins.SemiBold, lineHeight: "1.4rem",
                                        color: Colors.theme.green.dark
                                    }}>
                                        {confComps.translate.completed}

                                    </h5>
                                </div>

                            }
                        </div>

                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={2} xl={2}>
                        <div style={{
                            display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: "-1rem",
                            justifyContent: 'end',

                        }}>
                            <ButtonColor text={confComps.translate.showDetails}
                                onclick={function (): void {
                                    navigate(Links.screens.panel.recap.ShowStateTheRecap + `${paramsComponent.recapMainInfo.id}/`)
                                }}
                                iconStart={undefined}
                                color={ColorThemForButton.grayColorAndTransparentBackground}
                                iconEnd={<ArrowRightIcon />}
                            />

                        </div>
                    </Grid>


                </Grid>

            </Box>
        </ParentDiv >
    )
}
export default RowRecap;