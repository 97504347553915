import { InterfaceIconSVG } from "../InterfaceIconSVG"

export const ProfileIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="29" height="29" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0002 1.33337C6.55666 1.33337 1.3335 6.55654 1.3335 13C1.3335 19.4435 6.55666 24.6667 13.0002 24.6667C19.4437 24.6667 24.6668 19.4435 24.6668 13C24.6668 6.55654 19.4437 1.33337 13.0002 1.33337Z"
                stroke={style?.color}
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.98291 20.4037C3.98291 20.4037 6.58341 17.0833 13.0001 17.0833C19.4167 17.0833 22.0184 20.4037 22.0184 20.4037M13.0001 13C13.9283 13 14.8186 12.6313 15.475 11.9749C16.1313 11.3185 16.5001 10.4283 16.5001 9.5C16.5001 8.57174 16.1313 7.6815 15.475 7.02513C14.8186 6.36875 13.9283 6 13.0001 6C12.0718 6 11.1816 6.36875 10.5252 7.02513C9.86883 7.6815 9.50008 8.57174 9.50008 9.5C9.50008 10.4283 9.86883 11.3185 10.5252 11.9749C11.1816 12.6313 12.0718 13 13.0001 13Z"
                stroke={style?.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

