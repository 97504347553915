import { useEffect, useState } from "react";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useTheme } from "@mui/material";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../api/API";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../api/UrlsList";
import { InterfacePaginationInServer, InterfaceSearchInServer } from "../../../api/interfaces/InterfaceSearchInServer";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { GridFromMUI } from "../../../components/MUIConfig/GridFromMUI";
import ContainerPage from "../../../components/basic/containerPage/ContainerPage";
import ShowStateFetchList from "../../../components/basic/ShowStateFetchList/ShowStateFetchList";
import { EnumStateFetchList } from "../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import AdvanceSearchBox from "../../../components/complex/advanceSearchBox/AdvanceSearchBox";
import SortingBox from "../../../components/complex/SortingBox/SortingBox";
import AddButtonWithShadowEffect from "../../../components/basic/Buttons/AddButtonWithShadowEffect/AddButtonWithShadowEffect";
import Colors from "../../../config/Colors";
import { EnumRolePerson } from "../../../config/enums/EnumRolePerson";
import Links from "../../../config/Links";
import { InterfaceGetAPIRecapsList, InterfaceRecapsList } from "./InterfaceRecapsList";
import RowEmployee from "./RowRecap/RowRecap";
import { EnumSortingType, InterfaceItemOfSorting } from "../../../components/complex/SortingBox/InterfaceSortingBox";
import { InterfaceAdvanceBox, InterfaceAdvanceSearchParams } from "../../../components/complex/advanceSearchBox/InterfaceAdvanceSearchBox";
import SkeletonLoader from "../../../components/basic/SkeletonLoader/SkeletonLoader";
import InfiniteScrollList from "../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import RowRecap from "./RowRecap/RowRecap";
import { InterfaceNTTWorkFlowMainRecap } from "../../../NTTs/Recap/WorkflowMainRecap/InterfaceNTTWorkFlowMainRecap";
import { InterfaceNTTRecapMain } from "../../../NTTs/Recap/InterfaceNTTRecapMain";

function RecapsList(paramsComponent: InterfaceRecapsList) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const [getResponseRecaps, setResponseRecaps] = useState<(InterfaceNTTRecapMain & { allWorkFlowOfThisRecap: InterfaceNTTWorkFlowMainRecap })[]>();
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getSmartSearch, setSmartSearch] = useState<InterfaceAdvanceSearchParams>({
        smartSearch: "", ...confComps.component.advanceSearchBox.allKinds.recap,

    });
    const [getSelectedItemOfSorting, setSelectedItemOfSorting] = useState<InterfaceItemOfSorting[]>(confComps.sorting.recapList);
    const profileInStore = useSelector(SelectedProfile);

    const callApiForRetrieveAllWorkflowTheMainRecap = async (sortingFilter: InterfaceItemOfSorting[], advanceSearch: InterfaceAdvanceSearchParams, paginationParams: InterfacePaginationInServer, newRecaps: InterfaceGetAPIRecapsList) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.getAllWorkFlowOfRecap;
        const bodyReq = {
            "recap_ids": newRecaps.data.map(recap => recap.id),
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyReq, profileInStore)
            .then((response: any) => {
                console.log("response:)AllWorkflow:)");
                console.log(response);
                let workflows = response as InterfaceNTTWorkFlowMainRecap[];

                let recapsWithWorkflow = newRecaps.data.reduce<(InterfaceNTTRecapMain & { allWorkFlowOfThisRecap: InterfaceNTTWorkFlowMainRecap })[]>((acc, recap) => {
                    const matchingBItem = workflows.find((workflow) => workflow.recap_id === recap.id);
                    if (matchingBItem) {
                        // Combine the matching items from both lists
                        acc.push({ ...recap, allWorkFlowOfThisRecap: matchingBItem });
                    }
                    return acc;
                }, []);

                // dispatch(isVisibilityProgressBar(false));


                if (paginationParams.page === 1) {
                    setResponseRecaps(recapsWithWorkflow);
                }
                else {
                    setResponseRecaps(prev => {
                        return (prev ?
                            [...prev, ...recapsWithWorkflow]
                            : recapsWithWorkflow
                        );
                    }
                    );
                }
                console.log("safajoon list recap:", recapsWithWorkflow);



                if (newRecaps.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getApiListOfRecaps = async (sortingFilter: InterfaceItemOfSorting[], advanceSearch: InterfaceAdvanceSearchParams, paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.recap.getAllRecaps;
        let bodyObj: InterfaceSearchInServer = ConvertSmartSearchItemToItemSearchForServer(sortingFilter, advanceSearch)

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme, paginationParams as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceGetAPIRecapsList;
                callApiForRetrieveAllWorkflowTheMainRecap(sortingFilter, advanceSearch, paginationParams, t);
                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));



            })
            .catch((e: any) => {
                setStateFetchList(EnumStateFetchList.errorInRequest);
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            getApiListOfRecaps(getSelectedItemOfSorting, getSmartSearch, getPaginationForLastRequest);
        }
    }, [getStateFetchList])
    return (
        <ContainerPage title={"Recap List"}>
            <div style={{
                width: "100%", display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
                gap: "0.96rem"
            }}>
                <AdvanceSearchBox
                    defaultValue={getSmartSearch}
                    doneSearching={(params: InterfaceAdvanceSearchParams) => {
                        getApiListOfRecaps(getSelectedItemOfSorting, params, confComps.defaultValue.paginationConfigForServer);
                        setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                        setSmartSearch(params);
                    }}
                />
                <div style={{
                    flex: 0.33,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    boxSizing: "border-box",
                    gap: "0.96rem",

                }}>
                    <SortingBox
                        items={getSelectedItemOfSorting}
                        onclickDone={(items: InterfaceItemOfSorting[]) => {
                            getApiListOfRecaps(items, getSmartSearch, confComps.defaultValue.paginationConfigForServer);
                            setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                            setSelectedItemOfSorting(items);
                        }}
                        limitClick={-1}
                    />
                    <AddButtonWithShadowEffect
                        text={confComps.translate.recap}
                        callbackOnclick={() => {
                            navigate(Links.screens.panel.recap.addRecap)
                        }}
                        styleMainDiv={{
                            flex: 1,
                            boxSizing: 'border-box',
                            paddingInline: 0,
                        }}
                    />
                </div>
            </div>
            <div style={{
                display: "flex", boxSizing: "border-box", flexDirection: "column", alignItems: "center", justifyContent: "space-between",
                width: "100%", flexGrow: 1,
                background: Colors.theme.white.white80,
                border: `1px solid ${Colors.theme.white.white70}`,
                padding: "1.6rem",
                borderRadius: "0.32rem",
            }}
            >
                <InfiniteScrollList
                    isDataExist={(getResponseRecaps ?? []).length !== 0}
                    onEndReached={async () => {
                        let oldPage = getPaginationForLastRequest;
                        oldPage = {
                            ...oldPage,
                            page: (oldPage.page + 1)
                        };
                        setPaginationForLastRequest(oldPage);
                        console.log("callback for change state response2:", oldPage);
                        getApiListOfRecaps(getSelectedItemOfSorting, getSmartSearch, oldPage);
                    }}
                    onRetryClicked={() => {
                        getApiListOfRecaps(getSelectedItemOfSorting, getSmartSearch, getPaginationForLastRequest);
                    }}
                    stateResponseList={getStateFetchList}
                    styleSpacingMUI={{
                        columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                        rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                        columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                    }}

                >
                    <GridFromMUI container
                        justifyContent="flex-start" alignItems="stretch"
                        columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                        rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 3, xl: 4 }}
                        columns={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                    >
                        {getResponseRecaps && getResponseRecaps.map((recap, index: number) => {
                            return (
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    <RowRecap
                                        callbackReloadPage={() => {
                                            getApiListOfRecaps(getSelectedItemOfSorting, getSmartSearch, confComps.defaultValue.paginationConfigForServer);
                                            setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                                        }}
                                        isSelected={false}
                                        recapMainInfo={recap}
                                        allWorkFlowOfThisRecap={recap.allWorkFlowOfThisRecap}
                                        key={index}
                                    />
                                </Grid>
                            )
                        })}
                    </GridFromMUI>
                </InfiniteScrollList>
            </div >
        </ContainerPage >
    )
}
export default RecapsList;