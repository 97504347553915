import { Component, useEffect, useState } from "react";
import { BoxStepArrowSVG } from "../../../../../assets/icons/svg/BoxStepArrowSVG";
import Colors from "../../../../../config/Colors";
import { InterfaceArrowStepHeader } from "./InterfaceArrowStepHeader";
import { styled, useTheme } from "@mui/material/styles";
import { CircleIcon } from "../../../../../assets/icons/svg/CircleIcon";
import { ImageSquare } from "../../../imageSquare/ImageSquare";
import { EnumStateStepGroup, InterfaceColors } from "../../../../basic/ArrowStep/InterfaceArrowStep";
import { ConvertPercentToGradientWhiteAndGreen } from "../../../../../helper/ConvertPercentToColor";
import { getKeyOfParentOfKey, isKey1BiggerThanKey2 } from "../../SteperGuide";
import { InterfaceKeyStepForShowStateAllServices } from "../../InterfaceSteperGuide";

export const ParentDiv = styled("div")(({
  theme,
}) => {
  return (
    {
      // flexGrow: 1,
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',
      position: "relative",
      boxSizing: "border-box",
      // marginInlineStart: isFirstBox ? undefined : "-21px",


    }
  )
});



const configColors = (state: EnumStateStepGroup): InterfaceColors => {
  var color: InterfaceColors;
  if (state === EnumStateStepGroup.completed) {
    color = {
      background: "#D8EFE0",
      color: Colors.theme.green.dark,
    };
  }
  else {
    throw " we do not set other state color"
  }
  return color;
}
function ArrowStepHeader(paramsComponent: InterfaceArrowStepHeader) {
  const [getPercentageOfChildren, setPercentageOfChildren] = useState<number>(0);
  const [getKeyOfOneStepThatSelected, setKeyOfOneStepThatSelected] = useState<string>(paramsComponent.keyOfOneStepThatSelected);
  const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>(paramsComponent.keyOfGroupThatSelected);
  const [getKeyOfStepThatLastCompleted, setKeyOfStepThatLastCompleted] = useState<InterfaceKeyStepForShowStateAllServices>(paramsComponent.keyOfStepThatLastCompleted);
  const [visible, setVisible] = useState(true);
  useEffect(() => {
    setKeyOfStepThatLastCompleted(paramsComponent.keyOfStepThatLastCompleted);
  }, [paramsComponent.keyOfStepThatLastCompleted])
  useEffect(() => {
    setKeyOfOneStepThatSelected(paramsComponent.keyOfOneStepThatSelected);
  }, [paramsComponent.keyOfOneStepThatSelected])
  useEffect(() => {
    setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected);
  }, [paramsComponent.keyOfGroupThatSelected])
  // const [getColor, setColor] = useState<InterfaceColors>(configColors(paramsComponent.state));


  useEffect(() => {
    // setColor(configColors(paramsComponent.state));
    let numberChildThatCompilited = 0;
    for (let i = 0; i < paramsComponent.steps.length; i++) {
      if (isKey1BiggerThanKey2(paramsComponent.keyOfStepThatLastCompleted.maxStepThatCompleted, paramsComponent.keyStep)
        ||
        (!isKey1BiggerThanKey2(paramsComponent.keyOfStepThatLastCompleted.maxStepThatCompleted, paramsComponent.keyStep) &&
          !isKey1BiggerThanKey2(paramsComponent.keyStep, paramsComponent.keyOfStepThatLastCompleted.maxStepThatCompleted))) {
        numberChildThatCompilited++;
      }
    }
    setPercentageOfChildren((numberChildThatCompilited / paramsComponent.steps.length) * 100);
  }, [paramsComponent.keyOfStepThatLastCompleted])
  useEffect(() => {

  }, [paramsComponent.keyOfOneStepThatSelected])
  const isStepMustShow = () => {
    return (getKeyOfGroupThatSelected.indexOf(getKeyOfParentOfKey(paramsComponent.keyStep)) === 0)
  }
  useEffect(() => {
    if (!isStepMustShow()) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 400); // Wait for 2 seconds, the duration of the animation

      return () => clearTimeout(timer); // Clean up the timer if the component unmounts
    }
    else {
      setVisible(true);
    }
  }, [isStepMustShow()]);
  return (
    <div style={{
      display: visible ? "flex" : "none", position: "relative", flexDirection: 'row', alignItems: 'center',
      cursor: "pointer",
      height: isStepMustShow() ? '4.0rem' : '0rem',
      opacity: isStepMustShow() ? 1 : 0,

      transition: "all 0.2s ease",
      // zIndex: -1,
    }}
      onClick={paramsComponent.callbackOnClickHeader}
    >
      <ParentDiv style={{
        // zIndex: paramsComponent.zIndex,
        height: "100%",
        cursor: "pointer",
        // animation: isStepMustShow() ? 'fadeInAndScaleArr 0.5s ease-in-out forwards' : 'fadeOnAndScaleArr 0.5s ease-in-out forwards',
        transformOrigin: 'left', // Ensures scaling happens from the left side
        borderStartStartRadius: paramsComponent.keyStep === "1" ? "0.2rem" : undefined,
        borderEndStartRadius: paramsComponent.keyStep === "1" ? "0.2rem" : undefined,
        background: ConvertPercentToGradientWhiteAndGreen(getPercentageOfChildren),//getColor.background,
      }}
      >
        <div style={{
          height: "100%",
          flexGrow: 1,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          paddingInlineStart: "32px",
          alignItems: "center",
          zIndex: -1,
          userSelect: "none",
        }}>
          <h6 style={{
            background: ConvertPercentToGradientWhiteAndGreen(getPercentageOfChildren),//getColor.background,
            display: "flex", flexDirection: "row", alignItems: "center",
            justifyContent: "center",
            userSelect: "none",

            color: "transparent",
          }}>
            {paramsComponent.title}
            {/* fake */}
          </h6>
        </div>

        {/* </div> */}



      </ParentDiv>
      <h6 style={{
        display: "inline-flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        userSelect: "none",
        zIndex: 100,
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}>
        {paramsComponent.title}
      </h6>
      {isStepMustShow() &&
        <BoxStepArrowSVG style={{
          flexGrow: 0,
          position: "absolute",
          right: "1px",
          userSelect: "none",
          width: "auto",
          zIndex: 40,
          transform: "translate(100%,0%)",
          scale: "1.05",
          boxSizing: "border-box",
          background: ConvertPercentToGradientWhiteAndGreen(getPercentageOfChildren),
          // background: "red",
          color: Colors.theme.blue.light,
        }} />

      }
      {isStepMustShow() &&

        <BoxStepArrowSVG style={{
          flexGrow: 0,
          width: "auto",
          opacity: (getKeyOfGroupThatSelected.indexOf(paramsComponent.keyStep) !== 0) ? 1 : 0,
          position: "absolute",
          right: "0",
          userSelect: "none",
          transition: "all 0.5s ease",
          // transform: "translate(90%,-48%)",
          zIndex: 40,
          // transform: "translate(calc(98% - 2px),-55%)",
          transform: "translate(calc(98% - 2px),0%)",
          scale: "0.9",
          // marginLeft: "-1px",//do not change it because the last block wrong shape
          boxSizing: "border-box",
          // background: ConvertPercentToGradientWhiteAndGreen(getPercentageOfChildren),
          color: Colors.theme.blue.blue,
        }} />

      }
    </div>
  )


}
export default ArrowStepHeader;