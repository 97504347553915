import { Box, Grid, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { InterfaceCustomerBasicInformation } from "./InterfaceCustomerBasicInformation";
import TextFieldWithIconBottomLine from "../../../../../components/basic/TextField/TextFieldWithIconBottomLine/TextFieldWithIconBottomLine";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { formatDateGregorian } from "../../../../../helper/Helper";
import { EditPenIcon } from "../../../../../assets/icons/svg/EditPenIcon";
import SubTitleSection from "../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import Colors from "../../../../../config/Colors";
import KeyTitleH6 from "../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import TextFieldMultiLine from "../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import { AddIcon } from "../../../../../assets/icons/svg/AddIcon";
import { ImageSquare } from "../../../../../components/complex/imageSquare/ImageSquare";
import ButtonSave from "../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { InterfaceNTTActivity } from "../../../../../NTTs/InterfaceNTTActivity";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
// import DatePickerMUIWithUnderLine from "../../../../../components/basic/Dates/DatePickerMUIWithUnderLine";
import TextFieldMultiSelectActivity from "../../../../../components/basic/TextField/ActivitiesJob/TextFieldMultiSelectActivity/TextFieldMultiSelectActivity";
import { CheckRounded } from "@mui/icons-material";
import DatePickerMUIWithUnderLine from "../../../../../components/basic/Dates/DatePickerMUIWithUnderLine/DatePickerMUIWithUnderLine";
import { InterfaceNTTProfile } from "../../../../../NTTs/InterfaceNTTProfile";
import { EnumTypeInput } from "../../../../../components/basic/TextField/TextFieldWithIconBottomLine/InterfaceTextFieldWithIconBottomLine";

function CustomerBasicInformation(paramsComponent: InterfaceCustomerBasicInformation) {
    const confComps = ConfigComps();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getIsModeAddActivityActive, setIsModeAddActivityActive] = useState<boolean>(false);

    const [getUserInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        firstNameFa: "",
        lastNameFa: "",
        nationalId: "",
        phone: "",
        landline: "",
        dob: undefined as (undefined | number),
        email: "",
        dateJoined: undefined as (undefined | number),
        address: "",
        bio: "",
        activities: [] as InterfaceNTTActivity[],
        description: "",
    });

    useEffect(() => {
        setUserInfo({
            firstName: (paramsComponent.userInfoBasic.profile as InterfaceNTTProfile).first_name,
            lastName: (paramsComponent.userInfoBasic.profile as InterfaceNTTProfile).last_name,
            firstNameFa: (paramsComponent.userInfoBasic.profile as InterfaceNTTProfile).first_name_fa,
            lastNameFa: (paramsComponent.userInfoBasic.profile as InterfaceNTTProfile).last_name_fa,
            nationalId: (paramsComponent.userInfoBasic.profile as InterfaceNTTProfile).national_id,
            phone: (paramsComponent.userInfoBasic.profile as InterfaceNTTProfile).phone,
            email: paramsComponent.userInfoBasic.user.email,
            landline: (paramsComponent.userInfoBasic.profile as InterfaceNTTProfile).landline,
            dob: (paramsComponent.userInfoBasic.profile as InterfaceNTTProfile).dob,
            dateJoined: paramsComponent.userInfoBasic.user.date_joined,
            address: (paramsComponent.userInfoBasic.profile as InterfaceNTTProfile).address,
            bio: (paramsComponent.userInfoBasic.profile as InterfaceNTTProfile).bio,
            activities: paramsComponent.userInfoBasic.activities,
            description: paramsComponent.userInfoBasic.description,
        });
    }, [paramsComponent.userInfoBasic]);

    const handleChange = (key: keyof typeof getUserInfo, value: string) => {
        setUserInfo(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };
    const callApiUpdateTheProfile = async () => {
        dispatch(isVisibilityProgressBar(true));

        let urlObj = urlsList.panel.customer.updateCustomerList;

        let dataObj = {
            "first_name": getUserInfo.firstName,
            "first_name_fa": getUserInfo.firstNameFa,
            "last_name": getUserInfo.lastName,
            "last_name_fa": getUserInfo.lastNameFa,
            "phone": getUserInfo.phone,
            "email": getUserInfo.email,
            "national_id": getUserInfo.nationalId,
            "landline": getUserInfo.landline,
            "dob": getUserInfo.dob,
            "address": getUserInfo.address,
            "bio": getUserInfo.bio,
            "activities": getUserInfo.activities.map(act => act.id),
            "description": getUserInfo.description,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(isVisibilityProgressBar(false));
                paramsComponent.callbackReload();
                // let t = response as (InterfaceNTTActivity[]);
                // setResponseAllActivity(t);
                dispatch(ShowAlert({ text: confComps.translate.updatedProfile, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    return (
        <Box sx={{ boxSizing: "border-box", width: "100%" }}>
            <SubTitleSection title={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.personalInformation} />
            <Box sx={{
                marginInlineStart: { xs: 1, sm: 2, md: 1, lg: 1.5, xl: 2 },
                marginInlineEnd: { xs: 1, sm: 2, md: 5, lg: 12, xl: "10vw" },
                boxSizing: "border-box",
            }}>
                <Grid container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 0, sm: 0, md: "8.81rem", lg: "14.81rem", xl: "9vw" }}
                    rowSpacing={{ xs: "1.2rem", sm: "1.39rem", md: "1.53rem", lg: "1.70rem", xl: "1.87rem" }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                >
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.name}
                            defaultValue={getUserInfo.firstName}
                            onChangeValue={(value) => handleChange("firstName", value)}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.family}
                            defaultValue={getUserInfo.lastName}
                            onChangeValue={(value) => handleChange("lastName", value)}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.persianName}
                            defaultValue={getUserInfo.firstNameFa}
                            onChangeValue={(value) => handleChange("firstNameFa", value)}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.persianFamily}
                            defaultValue={getUserInfo.lastNameFa}
                            onChangeValue={(value) => handleChange("lastNameFa", value)}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.nationalId}
                            defaultValue={getUserInfo.nationalId}
                            onChangeValue={(value) => handleChange("nationalId", value)}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                            customType={EnumTypeInput.nationalID}
                            maxCountCharacter={10}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.phone}
                            defaultValue={getUserInfo.phone}
                            onChangeValue={(value) => handleChange("phone", value)}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            type="phone"
                            iconEnd={EditPenIcon}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.landline}
                            defaultValue={getUserInfo.landline}
                            onChangeValue={(value) => handleChange("landline", value)}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            type="phone"
                            iconEnd={EditPenIcon}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.email}
                            defaultValue={getUserInfo.email}
                            onChangeValue={(value) => handleChange("email", value)}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            type="email"
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <DatePickerMUIWithUnderLine
                            title={confComps.translate.dateOfBirth}
                            onChangeValue={(timeSecond: number | undefined) => {
                                setUserInfo(prevState => ({
                                    ...prevState,
                                    dob: timeSecond,
                                }))
                            }}
                            defaultTimeInSecond={getUserInfo.dob}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.DateJoined}
                            defaultValue={getUserInfo.dateJoined ? formatDateGregorian(getUserInfo.dateJoined) : ""}
                            isLockValue={true}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                            onChangeValue={(value: string) => {

                            }}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.address}
                            defaultValue={getUserInfo.address}
                            onChangeValue={(value) => handleChange("address", value)}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.bio}
                            defaultValue={getUserInfo.bio}
                            onChangeValue={(value) => handleChange("bio", value)}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{
                marginTop: { xs: 3, sm: 4, md: 5, lg: 6, xl: 6 },
                width: "100%",
                boxSizing: "border-box",
            }}>
                <SubTitleSection title={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.recentJob} />
                <Box sx={{
                    boxSizing: "border-box",
                    border: `1px solid ${Colors.theme.blue.border}`,
                    borderRadius: "0.4rem",
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    paddingInline: { xs: 1, sm: 3, md: 4, lg: 5, xl: 6 },
                    paddingBlockStart: { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 },
                    paddingBlockEnd: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 1.5 },
                }}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.activities} />
                        {!getIsModeAddActivityActive && getUserInfo.activities.map((activity, index) => (
                            <div key={index} style={{
                                background: Colors.theme.blue.white,
                                borderRadius: "500px",
                                paddingInline: "1.28rem",
                                paddingBlock: "0.1rem",
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: "0.96rem",
                                boxSizing: "border-box",
                            }}>
                                {activity.title}
                            </div>
                        ))
                        }
                        {getIsModeAddActivityActive &&
                            <TextFieldMultiSelectActivity
                                styleItems={{
                                    background: Colors.theme.blue.light_2,
                                    borderRadius: "500px",
                                }}
                                styleMain={{
                                    width: "100%",
                                    maxWidth: "20rem",
                                }}
                                onChangeSelectedActivities={(items: InterfaceNTTActivity[]) => {
                                    console.log("selected activities updagted", items)
                                    setUserInfo(prevState => ({
                                        ...prevState,
                                        activities: items,
                                    }));
                                }} selectedActivities={getUserInfo.activities}
                            />
                        }
                        <ImageSquare
                            width={"1.9rem"}
                            callbackOnclick={() => { setIsModeAddActivityActive(prev => !prev) }}
                            borderRadius="500px"
                            background={getIsModeAddActivityActive ? Colors.theme.green.dark : undefined}
                            border={`2px solid ${getIsModeAddActivityActive ? Colors.theme.green.dark : Colors.theme.blue.blue}`}
                            element={getIsModeAddActivityActive ? <CheckRounded style={{ color: Colors.theme.white.white80 }} /> : <AddIcon style={{ color: Colors.theme.blue.blue }} />}
                            elementStyle={{ background: "red" }}
                        />
                    </div>
                    <TextFieldMultiLine
                        text={getUserInfo.description}
                        hint={confComps.translate.description}
                        onChangeText={(newText) => handleChange("description", newText)}
                        styleParent={{ marginTop: "2%", boxSizing: "border-box" }}
                    />
                </Box>
            </Box>
            <Box sx={{
                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end",
                marginTop: "3.33rem",
                width: "100%",
                boxSizing: "border-box",
            }}>
                <ButtonSave
                    text={confComps.translate.save}
                    callbackOnClick={callApiUpdateTheProfile}
                />
            </Box>
        </Box>
    );
}

export default CustomerBasicInformation;
