import { useTheme } from "@mui/material";
import { InterfaceViewCheckWalletOfTrusty } from "./InterfaceViewCheckWalletOfTrusty";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import Colors from "../../../../../../../../../../config/Colors";
import { ConfigComps } from "../../../../../../../../../../config/ConfigCOMP";
import { InterfaceNTTSolutionRisk } from "../../../../../../../../../../NTTs/Recap/solution/steps/InterfaceNTTSolutionRisk";
import { ShowAlert } from "../../../../../../../../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../../../../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../../../../../../store/ProgressbarInRootSlice";
import { InterfaceNTTSolutionCheckWalletsTrusties } from "../../../../../../../../../../NTTs/Recap/solution/steps/InterfaceNTTSolutionCheckWalletsTrusties";

function ViewCheckWalletOfTrusty(paramsComponent: InterfaceViewCheckWalletOfTrusty) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getInfoThisStepOfSolution, setInfoThisStepOfSolution] = useState<InterfaceNTTSolutionCheckWalletsTrusties>();
    const callApiForGetInfoThisStep = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.cottage.sellCottage.steps.addSolutions.stepSolution.stepCheckWalletOfTrusties.getCheckWalletOfTrustiesForSolution;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.infoThisStepOfSolution.review_id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                let t = response as InterfaceNTTSolutionCheckWalletsTrusties;
                setInfoThisStepOfSolution(t);
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'Your Comment Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        callApiForGetInfoThisStep();
    }, [paramsComponent.infoThisStepOfSolution])
    return (
        <div
            style={{
                display: 'flex', flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'start',
                borderRadius: '0.74rem',
                width: '100%',
                border: !getInfoThisStepOfSolution?.is_approved ? `1px solid ${Colors.theme.orange.sharp}` : `1px solid ${Colors.theme.green.dark}`,
                boxSizing: 'border-box',
                paddingBlock: '0.29rem',
                paddingInline: '0.88rem',
                boxShadow: !getInfoThisStepOfSolution?.is_approved ? Colors.shadow.orangeCard : Colors.shadow.greenCard,
                marginTop: '0.88rem',
                gap: '0.2rem',
            }}
        >
            <p style={{
                display: 'flex', flexDirection: 'row', alignItems: 'start',
                justifyContent: 'start',
                color: Colors.theme.blue.dark,
                fontFamily: confComps.font.poppins.Regular,
                fontSize: '1.03rem',
                lineHeight: '1.77rem',
            }}>
                {getInfoThisStepOfSolution?.description}
            </p>
            <div
                style={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center', justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <p style={{
                    display: "inline-flex", alignItems: 'center', justifyContent: 'end',
                    color: !getInfoThisStepOfSolution?.is_approved ? Colors.theme.orange.sharp : Colors.theme.green.dark,
                    fontSize: '1.037rem',
                    lineHeight: '1.55rem',
                }}>
                    Checked budget of Trusties
                </p>
                <p style={{
                    display: "inline-flex", alignItems: 'center', justifyContent: 'end',
                    color: !getInfoThisStepOfSolution?.is_approved ? Colors.theme.orange.sharp : Colors.theme.green.dark,
                    fontSize: '1.037rem',
                    lineHeight: '1.55rem',
                }}>
                    {getInfoThisStepOfSolution?.is_approved ? confComps.translate.agree : confComps.translate.disagree}
                </p>

            </div>


        </div>

    );
}
export default ViewCheckWalletOfTrusty;