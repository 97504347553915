import { DownloadRounded, FileOpenRounded, UploadFileRounded } from "@mui/icons-material";
import Colors from "../../../config/Colors";
import { InterfaceListFiles } from "./InterfaceListFiles";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { getTypeOfFileFromLink } from "../../../helper/Helper";
import { TrashIcon } from "../../../assets/icons/svg/TrashIcon";

function ListFiles(paramsComponent: InterfaceListFiles) {
    var confComps = ConfigComps();
    return (
        <div style={{
            display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "start",
            gap: "1rem",
        }}>
            {paramsComponent.Files.map((file, index, arr) => {
                return (
                    <div style={{
                        display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start",
                        border: `1px solid ${Colors.theme.blue.border}`,
                        borderRadius: "0.8rem",
                        paddingInline: "1rem",
                        paddingBlock: "1.5rem",
                        width: "100%",
                        boxSizing: "border-box",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "space-between",
                            width: "100%",
                            boxSizing: "border-box",
                        }}>
                            <div style={{
                                display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
                                gap: "1rem",
                            }}>
                                {!file.document && <UploadFileRounded style={{ color: Colors.theme.blue.blue }} />}
                                {file.document && <DownloadRounded style={{ color: Colors.theme.blue.blue }} />}
                                {file.file &&
                                    (<p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.dark,
                                    }}>{file.title.length > 0 ? `${(file.title)}${getTypeOfFileFromLink(file.file.name)}` : file.file.name}</p>)
                                }
                                {file.document &&
                                    (<p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.dark,
                                    }}>{file.title}{getTypeOfFileFromLink(file.document ?? "")}</p>
                                    )
                                }
                                <div style={{
                                    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
                                    gap: "0.2rem",
                                }}>

                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.light,
                                    }}>
                                        {confComps.translate.description}
                                        {":  "}
                                    </p>
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: "0.96rem",
                                        color: Colors.theme.blue.dark,
                                    }}>

                                        {file.description}
                                    </p>
                                </div>

                            </div>
                            <div style={{ paddingInline: "0.1rem", cursor: "pointer" }}
                                onClick={() => {
                                    paramsComponent.callbackRemoveFiles(index);
                                }}
                            >
                                <TrashIcon style={{ color: Colors.theme.orange.sharp }} />
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
    );
}
export default ListFiles;