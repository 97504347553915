import { DoneRounded, CircleOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TitleMedium16 from '../../../../components/basic/Texts/Titles/TitleMedium16/TitleMedium16';
import BoxRecap from '../../../../components/basic/‌Box/BoxRecap/BoxRecap';
import Colors from '../../../../config/Colors';
import { ConfigComps } from '../../../../config/ConfigCOMP';
import { SelectedProfile } from '../../../../store/profileStateSlice';
import { InterfaceParentRecapBuyCottage } from './InterfaceParentRecapBuyCottage';
import { InterfaceNTTRecapMain } from '../../../../NTTs/Recap/InterfaceNTTRecapMain';
import { InterfaceNTTRecapBuyCottageService } from '../../../../NTTs/Recap/InterfaceNTTRecapBuyCottageService';
import ControllerInitiateBuyCottage from '../StepersBox/BuyCottage/InitiateBuyCottage/ControllerInitiateBuyCottage';
import { InterfaceNTTOneServiceWorkflowBuyCottageRecap } from '../../../../NTTs/Recap/WorkflowMainRecap/BuyCottage/InterfaceNTTOneServiceWorkflowBuyCottageRecap';
import { isKey1BiggerThanKey2 } from '../../../../components/complex/SteperGuide/SteperGuide';
import { EnumSTepsParent, EnumSTepsRecap } from '../../../../config/enums/EnumSteps';
import { StepNameMappingToKeyStepForBuyCottageRecap, EnumRecapServiceStepNameInServerForBuyCottage } from '../../../../NTTs/Recap/WorkflowMainRecap/BuyCottage/InterfaceNTTOneStepOfWorkflowBuyCottageRecap';
import ControllerAddSolutionForBuyCottage from '../StepersBox/BuyCottage/AddSolutionForBuyCottage/ControllerAddSolutionForBuyCottage';
import ViewAddSolutionForBuyCottage from '../StepersBox/BuyCottage/AddSolutionForBuyCottage/ViewAddSolutionForBuyCottage/ViewAddSolutionForBuyCottage';
import CreateApproveSolutionByManagerForBuyCottage from '../StepersBox/BuyCottage/ApproveSolutionByManagerForBuyCottage/CreateApproveSolutionByManagerForBuyCottage/CreateApproveSolutionByManagerForBuyCottage';
import ViewApproveSolutionByManagerForBuyCottage from '../StepersBox/BuyCottage/ApproveSolutionByManagerForBuyCottage/ViewApproveSolutionByManagerForBuyCottage/ViewApproveSolutionByManagerForBuyCottage';
import ControllerCheckWalletCustomerOfBuyCottage from '../StepersBox/BuyCottage/CheckWalletCustomerOfBuyCottage/ControllerCheckWalletCustomerOfBuyCottage';
import CreateChooseOneSolutionForBuyCottage from '../StepersBox/BuyCottage/ChooseOneSolutionForBuyCottage/CreateChooseOneSolutionForBuyCottage/CreateChooseOneSolutionForBuyCottage';
import ViewChooseOneSolutionForBuyCottage from '../StepersBox/BuyCottage/ChooseOneSolutionForBuyCottage/ViewChooseOneSolutionForBuyCottage/ViewChooseOneSolutionForBuyCottage';
import ControllerRiskMeasurementCustomerOfBuyCottage from '../StepersBox/BuyCottage/RiskMeasurementCustomerOfBuyCottage/ControllerRiskMeasurementCustomerOfBuyCottage';
import ViewCheckByTrustiesOfSolutionsOfBuyCottage from '../StepersBox/BuyCottage/ViewCheckByTrustiesOfSolutionsOfBuyCottage/ViewCheckByTrustiesOfSolutionsOfBuyCottage';
import ViewCheckRiskOfSolutionsOfBuyCottage from '../StepersBox/BuyCottage/ViewCheckRiskOfSolutionsOfBuyCottage/ViewCheckRiskOfSolutionsOfBuyCottage';
import ViewCheckWalletsTrustiesOfBuyCottage from '../StepersBox/BuyCottage/ViewCheckWalletsTrustiesOfBuyCottage/ViewCheckWalletsTrustiesOfBuyCottage';
function ParentRecapBuyCottage(paramsComponent: InterfaceParentRecapBuyCottage) {
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>('1');
    const [getIsCreateBuyCottageActive, setIsCreateBuyCottageActive] = useState<boolean>(false);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getInfoServiceInMainRecap, setInfoServiceInMainRecap] = useState<InterfaceNTTRecapBuyCottageService>();
    const [getInfoWorkFlowService, setInfoWorkFlowService] = useState<InterfaceNTTOneServiceWorkflowBuyCottageRecap>();
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setInfoServiceInMainRecap(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setInfoWorkFlowService(paramsComponent.infoWorkFlowService);
    }, [paramsComponent.infoWorkFlowService])

    return (
        <BoxRecap>
            <div
                style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'space-between',
                    boxSizing: 'border-box',
                }}
            >
                <TitleMedium16>
                    {confComps.translate.buyCottage}
                </TitleMedium16>
                {getKeyOfStepThatSelected === '1.1' &&
                    <div
                        style={{
                            cursor: 'pointer',
                            display: 'inline-flex',
                        }}
                        onClick={() => {
                            if ((getRecapMain?.buy_cottages ?? []).length === 0)
                                setIsCreateBuyCottageActive(prev => !prev)
                        }}
                    >
                        {getIsCreateBuyCottageActive ?
                            <DoneRounded style={{
                                color: '#fff', background: Colors.theme.blue.blue,
                                borderRadius: '500px', fontSize: '1.5rem',
                                padding: '0.15rem',
                            }} /> :
                            <CircleOutlined style={{ color: Colors.theme.blue.blue, fontSize: '1.8rem' }} />
                        }
                    </div>
                }
            </div>
            <ControllerInitiateBuyCottage
                infoServiceInMainRecap={getInfoServiceInMainRecap}
                callBackCreateMainRecap={paramsComponent.callBackCreateMainRecap}
                recapMain={getRecapMain}
                isCreateBuyCottageActive={getIsCreateBuyCottageActive}
                stepThatSelected={getKeyOfStepThatSelected}
                callBackReLoad={() => {
                    console.log('callback reload caled:)')
                    paramsComponent.callBackReLoad();
                }}
            />
            {getInfoServiceInMainRecap &&
                getInfoWorkFlowService?.workflow_reviews
                    .filter(step => isKey1BiggerThanKey2(getKeyOfStepThatSelected, StepNameMappingToKeyStepForBuyCottageRecap[step.review_ct])
                        || getKeyOfStepThatSelected === StepNameMappingToKeyStepForBuyCottageRecap[step.review_ct])//check that view by step that clicked in top guide
                    // .sort((a, b) => {
                    //     if (a.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.addSolution) {
                    //         return 0.5;
                    //     }
                    //     else if (b.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.addSolution) {
                    //         return -0.5;
                    //     }
                    //     return a.created_at - b.created_at;
                    // })
                    .map((oneStep, index, arr) => {
                        if (oneStep.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.fundControlCheckWalletCustomer) {
                            return (
                                <ControllerCheckWalletCustomerOfBuyCottage
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    recapMain={getRecapMain}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    callBackReLoad={() => {
                                        paramsComponent.callBackReLoad();
                                    }}
                                    StepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.checkRisk) {
                            return (
                                <ControllerRiskMeasurementCustomerOfBuyCottage
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    StepWorkFlow={oneStep}
                                    callBackReLoad={() => {
                                        paramsComponent.callBackReLoad();
                                    }}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.addSolution) {
                            return (
                                <ViewAddSolutionForBuyCottage
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    callBackReLoad={function (): void {
                                        paramsComponent.callBackReLoad();
                                    }}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.checkRiskOfSolution) {
                            return (
                                <ViewCheckRiskOfSolutionsOfBuyCottage
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.checkWalletOfTrustByFinanceForSolution) {
                            return (
                                <ViewCheckWalletsTrustiesOfBuyCottage
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.checkSolutionByTrusties) {
                            return (
                                <ViewCheckByTrustiesOfSolutionsOfBuyCottage
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.chooseOneSolution) {
                            return (
                                <ViewChooseOneSolutionForBuyCottage
                                    recapMain={getRecapMain}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.approveSolution) {
                            return (
                                <ViewApproveSolutionByManagerForBuyCottage
                                    recapMain={getRecapMain}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }

                    })
            }

            {/* step that now must be fill */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.checkWalletOfTheRequesterByFundControl}` && // step guide click this step
                StepNameMappingToKeyStepForBuyCottageRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForBuyCottage.undefined }).review_ct
                ] === StepNameMappingToKeyStepForBuyCottageRecap[EnumRecapServiceStepNameInServerForBuyCottage.undefined]
                &&
                <ControllerCheckWalletCustomerOfBuyCottage
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    recapMain={getRecapMain}
                    stepThatSelected={getKeyOfStepThatSelected}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    StepWorkFlow={undefined}
                />
            }
            {/* fill the risk measurement of customer that selected at first step */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}` && // step guide click this step
                StepNameMappingToKeyStepForBuyCottageRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForBuyCottage.undefined }).review_ct
                ] === StepNameMappingToKeyStepForBuyCottageRecap[EnumRecapServiceStepNameInServerForBuyCottage.fundControlCheckWalletCustomer]//the last step that completed must the previous step
                &&
                <ControllerRiskMeasurementCustomerOfBuyCottage
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={undefined}
                />
            }

            {getInfoServiceInMainRecap &&
                isKey1BiggerThanKey2(getKeyOfStepThatSelected, `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.0`) && //check step that selected in guide line
                isKey1BiggerThanKey2(`${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions + 1}.-1`, getKeyOfStepThatSelected) &&
                (isKey1BiggerThanKey2(StepNameMappingToKeyStepForBuyCottageRecap[
                    (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForBuyCottage.undefined }).review_ct
                ], `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.0`) ||
                    StepNameMappingToKeyStepForBuyCottageRecap[
                    (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForBuyCottage.undefined }).review_ct
                    ] === `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}`) &&//check last step that completed
                <ControllerAddSolutionForBuyCottage
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={getInfoServiceInMainRecap &&
                        getInfoWorkFlowService?.workflow_reviews.filter(steps => steps.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.addSolution)[0]}
                />
            }
            {/* choose One of  solutions */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.chooseSolution}` && // step guide click this step
                StepNameMappingToKeyStepForBuyCottageRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForBuyCottage.undefined }).review_ct
                ] === StepNameMappingToKeyStepForBuyCottageRecap[EnumRecapServiceStepNameInServerForBuyCottage.checkSolutionByTrusties]//the last step that completed must the previous step
                &&
                <CreateChooseOneSolutionForBuyCottage
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={getInfoServiceInMainRecap &&
                        getInfoWorkFlowService?.workflow_reviews.filter(steps => steps.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.addSolution)[0]}
                />
            }
            {/* Comment of manager */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.acceptByManager}` && // step guide click this step
                StepNameMappingToKeyStepForBuyCottageRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForBuyCottage.undefined }).review_ct
                ] === StepNameMappingToKeyStepForBuyCottageRecap[EnumRecapServiceStepNameInServerForBuyCottage.chooseOneSolution]//the last step that completed must the previous step
                &&
                <CreateApproveSolutionByManagerForBuyCottage
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={getInfoServiceInMainRecap &&
                        getInfoWorkFlowService?.workflow_reviews.filter(steps => steps.review_ct === EnumRecapServiceStepNameInServerForBuyCottage.addSolution)[0]}
                />
            }

        </BoxRecap>
    )

}
export default ParentRecapBuyCottage;