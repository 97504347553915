import { useNavigate, useParams } from "react-router-dom";
import ContainerPage from "../../../../components/basic/containerPage/ContainerPage";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { EnumRolePerson } from "../../../../config/enums/EnumRolePerson";
import { Box, Grid, styled } from "@mui/material";
import Colors from "../../../../config/Colors";
import { ImageSquare } from "../../../../components/complex/imageSquare/ImageSquare";
import { Person2Rounded } from "@mui/icons-material";
import { EditPenCircle } from "../../../../assets/icons/svg/EditPenCircleIcon";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import TextFieldWithIconBottomLine from "../../../../components/basic/TextField/TextFieldWithIconBottomLine/TextFieldWithIconBottomLine";
import { EditPenIcon } from "../../../../assets/icons/svg/EditPenIcon";
import ButtonSave from "../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { RequestHandler } from "../../../../api/API";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import DatePickerMUIWithUnderLine from "../../../../components/basic/Dates/DatePickerMUIWithUnderLine/DatePickerMUIWithUnderLine";
import { InterfaceEmployeeProfile, InterfaceResponseGetTheEmployeeInfo } from "./InterfaceEmployeeProfile";
import TextFieldSelectorRoleEmployee from "../../../../components/basic/TextField/RoleEmployee/TextFieldSelectorRoleEmployee/TextFieldSelectorRoleEmployee";
import { InterfaceNTTRoleEmployee } from "../../../../NTTs/InterfaceNTTRoleEmployee";
import FilesDragAndDrop from "../../../../components/complex/FileDragAndDrop/FileDragAndDrop";
import { EnumTypeInput } from "../../../../components/basic/TextField/TextFieldWithIconBottomLine/InterfaceTextFieldWithIconBottomLine";
export const MainCard = styled('div')(({ theme }) => ({
    width: "100%",
    borderRadius: "0.5rem",
    boxShadow: Colors.shadow.dropShadow,
    boxSizing: "border-box",
    paddingInline: "1.44rem",
    paddingBlock: "0.8rem",
    [theme.breakpoints.up('xs')]: {
        paddingInline: "2.44rem",
        paddingBlock: "1rem",
    },
    [theme.breakpoints.up('sm')]: {
        paddingInline: "3.44rem",
        paddingBlock: "1.84rem",
    },
    [theme.breakpoints.up('md')]: {
        paddingInline: "3.74rem",
        paddingBlock: "2.14rem",

    },
    [theme.breakpoints.up('lg')]: {
        paddingInline: "3.84rem",
        paddingBlock: "2.24rem",
    },
    [theme.breakpoints.up('xl')]: {
        paddingInline: "3.84rem",
        paddingBlock: "2.24rem",
    },

}));
export const DivImageProfile = styled('div')(({ theme }) => ({
    boxSizing: "border-box",
    flexGrow: "0",
    width: "2.54rem",
    [theme.breakpoints.up('xs')]: {
        width: "3.04rem",
    },
    [theme.breakpoints.up('sm')]: {
        width: "4.04rem",
    },
    [theme.breakpoints.up('md')]: {
        width: "5.04rem",

    },
    [theme.breakpoints.up('lg')]: {
        width: "6rem",
    },
    [theme.breakpoints.up('xl')]: {
        width: "6rem",
    },

}));
function EmployeeProfile(paramsPage: InterfaceEmployeeProfile) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const mTheme = useTheme();
    const { idEmployee } = useParams();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);


    const [getUsername, setUsername] = useState<string>("");
    const [getFirstName, setFirstName] = useState<string>("");
    const [getFirstNameFa, setFirstNameFa] = useState<string>("");
    const [getLastName, setLastName] = useState<string>("");
    const [getLastNameFa, setLastNameFa] = useState<string>("");
    const [getPhone, setPhone] = useState<string>("");
    const [getEmail, setEmail] = useState<string>("");
    const [getNationalId, setNationalId] = useState<string>("");
    const [getLandline, setLandline] = useState<string>("");
    const [getDob, setDob] = useState<number | undefined>();
    const [getAddress, setAddress] = useState<string>("");
    const [getBio, setBio] = useState<string>("");
    const [getRoleSelected, setRoleSelected] = useState<InterfaceNTTRoleEmployee>();
    const [getPassword, setPassword] = useState<string>("");
    const [getDescription, setDescription] = useState<string>("");
    const [getFileForImageProfile, setFileForImageProfile] = useState<{ file?: File, urlServer?: string }>();


    // Function to cast the role to EnumRolePerson

    const callApiForGetInformationOfTheEmployee = async () => {
        dispatch(isVisibilityProgressBar(true));

        let urlObj = urlsList.panel.employee.getTheEmployeeInfo;


        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idEmployee}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceResponseGetTheEmployeeInfo;
                dispatch(isVisibilityProgressBar(false));
                setUsername(t.user.username);
                setFirstName(t.profile.first_name);
                setFirstNameFa(t.profile.first_name_fa);
                setLastName(t.profile.last_name);
                setLastNameFa(t.profile.last_name_fa);
                setPhone(t.profile.phone);
                setEmail(t.user.email);
                setNationalId(t.profile.national_id);
                setLandline(t.profile.landline);
                setDob(t.profile.dob);
                setAddress(t.profile.address);
                setBio(t.profile.bio);
                setRoleSelected(t.role);
                setDescription(t.description);
                setFileForImageProfile({ file: undefined, urlServer: t.profile.profile_pic ? `${baseUrl}/${t.profile.profile_pic}` : undefined })
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    const callApiForUploadImageOfProfile = async (idEmployee: number) => {
        if (!getFileForImageProfile?.file) {
            dispatch(isVisibilityProgressBar(false));
            return;
        }
        dispatch(isVisibilityProgressBar(true));


        let urlObj = urlsList.panel.employee.postAddProfileImageEmployee;

        const formData = new FormData();
        formData.append("profile_pic", getFileForImageProfile.file);

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idEmployee}/profile-pic/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, formData, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdEmployee, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }


    const callApiCreateProfile = async () => {
        dispatch(isVisibilityProgressBar(true));

        let urlObj = urlsList.panel.employee.postAddEmployee;

        let dataObj = {
            "username": getUsername,
            "first_name": getFirstName,
            "first_name_fa": getFirstNameFa,
            "last_name": getLastName,
            "last_name_fa": getLastNameFa,
            "phone": getPhone,
            "email": getEmail,
            "national_id": getNationalId,
            "landline": getLandline,
            "dob": getDob,
            "address": getAddress,
            "bio": getBio,
            "role": getRoleSelected?.id ?? -1,
            "password": getPassword,
            "description": getDescription,

        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // dispatch(isVisibilityProgressBar(false));
                callApiForUploadImageOfProfile(response.data.profile.id);
                // let t = response as (InterfaceNTTActivity[]);
                // setResponseAllActivity(t);
                dispatch(ShowAlert({ text: confComps.translate.createdEmployee, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiUpdateTheProfile = async () => {
        dispatch(isVisibilityProgressBar(true));

        let urlObj = urlsList.panel.employee.updateTheEmployeeProfile;

        let dataObj1 = {
            "username": getUsername,
            "first_name": getFirstName,
            "first_name_fa": getFirstNameFa,
            "last_name": getLastName,
            "last_name_fa": getLastNameFa,
            "phone": getPhone,
            "email": getEmail,
            "national_id": getNationalId,
            "landline": getLandline,
            "dob": getDob,
            "address": getAddress,
            "bio": getBio,
            "role": getRoleSelected?.id ?? -1,
            "description": getDescription,

        }
        let dataObj;
        if (getPassword.length > 0) {
            dataObj = {
                "password": getPassword,
                ...dataObj1
            }

        }
        else {
            dataObj = {
                ...dataObj1
            }
        }

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idEmployee}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // dispatch(isVisibilityProgressBar(false));
                callApiForUploadImageOfProfile(response.data.profile.id);
                // let t = response as (InterfaceNTTActivity[]);
                // setResponseAllActivity(t);
                dispatch(ShowAlert({ text: confComps.translate.updatedProfile, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        idEmployee && callApiForGetInformationOfTheEmployee();
    }, [idEmployee])
    useEffect(() => {
        if (getFileForImageProfile && getFileForImageProfile.file) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setFileForImageProfile(prev => {
                    return ({
                        ...prev,
                        urlServer: event.target.result as string
                    })
                });
            };

            reader.readAsDataURL(getFileForImageProfile.file);
        }
    }, [getFileForImageProfile]);

    return (
        <ContainerPage title={idEmployee ? confComps.translate.employee : confComps.translate.addEmployee} >
            <MainCard>
                <div style={{
                    display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "stretch",
                    boxSizing: "border-box",
                    gap: "2.56rem",
                    marginBottom: "0.52rem",
                }}>
                    <DivImageProfile>
                        <FilesDragAndDrop
                            onUpload={(files) => {
                                files.forEach((value) => {
                                    setFileForImageProfile({ file: value });
                                })
                            }}
                            count={1}
                            formats={['jpg', 'png']}
                            containerStyles={{
                                borderRadius: "4px",
                                boxSizing: "border-box",

                            }}
                            openDialogOnClick
                        >
                            <ImageSquare width={"100%"}
                                borderRadius="500px"
                                style={{ position: "relative", overflow: "visible", boxSizing: "border-box" }}
                                iconSvg={getFileForImageProfile?.urlServer ? undefined : Person2Rounded}
                                img={getFileForImageProfile?.urlServer}
                                element={
                                    <EditPenCircle style={{
                                        zIndex: 5, borderRadius: "500px", background: "#fff",
                                        position: "absolute",
                                        width: "16",
                                        height: "16",
                                        transform: "translate(50%,50%)",
                                        bottom: "14.64466094%", right: "14.64466094%",

                                    }}
                                    />

                                }
                                elementStyle={{
                                    zIndex: 4,
                                    position: "absolute",
                                    width: "28px",
                                    transform: "translate(0,0)",
                                    top: "100px", left: "100%",
                                }}
                                border={`2px solid ${Colors.theme.blue.blue}`}
                                color={Colors.theme.blue.light}
                                background={Colors.theme.white.PrimaryLight}
                            />
                        </FilesDragAndDrop>


                    </DivImageProfile>

                </div>

                <h5 style={{
                    fontFamily: confComps.font.poppins.Medium,
                    fontSize: "1.28rem",
                    lineHeight: "1.92rem",
                    color: Colors.theme.blue.dark,
                    fontWeight: "100",
                    marginBlock: "0px",
                    marginBottom: "2.4rem",
                }}>
                    {confComps.panel.myProfile.pageInfo.fields.header}
                </h5>
                <Box sx={{
                    marginInlineStart: { xs: 1, sm: 1, md: 0, lg: 1.5, xl: 2 },
                    marginInlineEnd: { xs: 0, sm: 1, md: 0, lg: 5, xl: 10 },
                    boxSizing: "border-box",
                }}>
                    <Grid container
                        justifyContent="space-between" alignItems="flex-start"
                        columnSpacing={{ xs: 0, sm: 0, md: "8.81rem", lg: "14.81rem", xl: "9vw" }}
                        rowSpacing={{ xs: "1.2rem", sm: "1.39rem", md: "1.53rem", lg: "1.70rem", xl: "1.87rem" }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                    >

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.translate.username}
                                defaultValue={getUsername}
                                onChangeValue={setUsername}
                                isDataMustLTR={true}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />
                        </Grid>

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.translate.firstName}
                                defaultValue={getFirstName}
                                onChangeValue={setFirstName}
                                isDataMustLTR={true}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />
                        </Grid>

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                defaultValue={getFirstNameFa}
                                hintText={confComps.translate.firstNameFa}
                                onChangeValue={setFirstNameFa}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />
                        </Grid>

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                defaultValue={getLastName}
                                hintText={confComps.translate.lastName}
                                onChangeValue={setLastName}
                                isDataMustLTR={true}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />

                        </Grid>

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                defaultValue={getLastNameFa}
                                hintText={confComps.translate.lastNameFa}
                                onChangeValue={setLastNameFa}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />
                        </Grid>

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                defaultValue={getPhone}
                                hintText={confComps.translate.phone}
                                onChangeValue={setPhone}
                                type="tel"
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />
                        </Grid>

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                defaultValue={getEmail}
                                hintText={confComps.translate.email}
                                type="email"
                                onChangeValue={setEmail}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />
                        </Grid>

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                defaultValue={getNationalId}
                                hintText={confComps.translate.nationalID}
                                onChangeValue={setNationalId}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                                customType={EnumTypeInput.nationalID}
                                maxCountCharacter={10}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>

                            <TextFieldWithIconBottomLine
                                defaultValue={getLandline}
                                hintText={confComps.translate.landline}
                                onChangeValue={setLandline}
                                type="tel"
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />

                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>

                            <TextFieldWithIconBottomLine
                                defaultValue={getPassword}
                                hintText={confComps.translate.password}
                                onChangeValue={setPassword}
                                type="password"
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                            />

                        </Grid>

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <DatePickerMUIWithUnderLine
                                title={confComps.translate.dateOfBirth}
                                onChangeValue={(timeSecond: number | undefined) => {
                                    setDob(timeSecond);
                                }}
                                defaultTimeInSecond={getDob}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            {/* <TextFieldMultiSelectActivity
                                title={confComps.translate.activities}
                                onChangeSelectedActivities={(activities) => {
                                    setActivitySelected(activities)
                                }}
                                selectedActivities={[]}
                            /> */}
                            <TextFieldSelectorRoleEmployee
                                title={confComps.translate.role}
                                onChangeSelectedRole={(selectRole?: InterfaceNTTRoleEmployee) => {
                                    setRoleSelected(selectRole);
                                }}
                                iconEnd={EditPenIcon}
                                selectedRole={getRoleSelected}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.translate.address}
                                isDataMustLTR={false}
                                onChangeValue={setAddress}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                                defaultValue={getAddress}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.bio}
                                onChangeValue={setBio}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                                defaultValue={getBio}

                                iconEnd={EditPenIcon}
                            />

                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.translate.description}
                                onChangeValue={setDescription}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                                defaultValue={getDescription}
                                iconEnd={EditPenIcon}
                            />

                        </Grid>

                    </Grid>
                </Box>

                <div style={{
                    display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "end", width: "100%",
                    marginTop: "6rem",
                }}>

                    <ButtonSave text={idEmployee ? confComps.translate.update : confComps.translate.save}
                        callbackOnClick={() => {
                            idEmployee ? callApiUpdateTheProfile() : callApiCreateProfile();
                        }}
                    />
                    {/* <p style={{
                        color: Colors.theme.white.PrimaryLight, fontFamily: confComps.font.poppins.Medium,
                        fontWeight: "200",
                        fontSize: "1.12rem",
                        cursor: "pointer",
                        background: Colors.theme.green.dark,
                        paddingInline: "1.52rem",
                        paddingBlock: "0.32rem",
                        borderRadius: "100.32rem",
                    }}>
                        
                    </p> */}
                </div>

            </MainCard>

        </ContainerPage>
    )
};
export default EmployeeProfile;