import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../../api/UrlsList";
import ParentWithHeightAnimation from "../../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import Colors from "../../../../../../../config/Colors";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import Links from "../../../../../../../config/Links";
import { InterfaceNTTRecapMain } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { InterfaceNTTRecapMoneyPaymentService } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMoneyPaymentService";
import { InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap } from "../../../../../../../NTTs/Recap/WorkflowMainRecap/MoneyPayment/InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { InterfaceAPIResponseGetMetaCheckByTrustiesOfSolutionsOfMoneyPayment } from "../../ViewCheckByTrustiesOfSolutionsOfMoneyPayment/InterfaceViewCheckByTrustiesOfSolutionsOfMoneyPayment";
import { InterfaceAPIResponseGetInfoOfStepChooseOneStep, InterfaceViewChooseOneSolutionForMoneyPayment } from "./InterfaceViewChooseOneSolutionForMoneyPayment";
import { EnumRecapServiceStepSolutionNameInServer, InterfaceNTTWorkflowSolution, StepOfSolutionNameMappingToKeyStepForMoneyPaymentRecap } from "../../../../../../../NTTs/Recap/solution/InterfaceNTTWorkflowSolution";
import { isKey1BiggerThanKey2 } from "../../../../../../../components/complex/SteperGuide/SteperGuide";
import { InterfaceNTTSolutionCheckByTrusties } from "../../../../../../../NTTs/Recap/solution/steps/InterfaceNTTSolutionCheckByTrusties";
import ViewCheckByTrustiesOfSolution from "../../AddSolutionForMoneyPayment/ParentOfAllSolutions/ParentOfOneSolution/CheckByTrustiesOfSolution/ViewCheckByTrustiesOfSolution/ViewCheckByTrustiesOfSolution";
import ViewCheckWalletOfTrusty from "../../AddSolutionForMoneyPayment/ParentOfAllSolutions/ParentOfOneSolution/CheckWalletOfTrustyInSolution/ViewCheckWalletOfTrustyInSolution/ViewCheckWalletOfTrusty";
import ViewRiskMeasurementSolution from "../../AddSolutionForMoneyPayment/ParentOfAllSolutions/ParentOfOneSolution/RiskMeasurementSolution/ViewRiskMeasurementSolution/ViewRiskMeasurementSolution";
import { InterfaceAPIResponseGetOneSolution } from "../../AddSolutionForMoneyPayment/ViewAddSolutionForMoneyPayment/InterfaceViewAddSolutionForMoneyPayment";

function ViewChooseOneSolutionForMoneyPayment(paramsComponent: InterfaceViewChooseOneSolutionForMoneyPayment) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getInfoServiceMoneyPayment, setInfoServiceMoneyPayment] = useState<InterfaceNTTRecapMoneyPaymentService>(paramsComponent.infoServiceInMainRecap);
    const [getInfoThisWorkFlow, setInfoThisWorkFlow] = useState<InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap>();
    const [getInfoThisStep, setInfoThisStep] = useState<InterfaceAPIResponseGetInfoOfStepChooseOneStep>();
    const [getWorkFlowOfSolution, setWorkFlowOfSolution] = useState<InterfaceNTTWorkflowSolution[]>([]);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>('1');
    const [getInfoSolutionThatSelected, setInfoSolutionThatSelected] = useState<InterfaceAPIResponseGetOneSolution>();
    const callApiForRetrieveInfoThisStep = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyPayment.steps.chooseOneSolution.getInfoThisStep;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getInfoThisWorkFlow?.review_id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIResponseGetInfoOfStepChooseOneStep;
                setInfoThisStep(t);


                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callApiForRetrieveWorkflowSolutionThatSelected = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyPayment.steps.addSolutions.stepSolution.getWorkFlowSolution;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getInfoThisStep?.chosen_solution}/workflow-reviews/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceNTTWorkflowSolution[];
                setWorkFlowOfSolution(t);


                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForRetrieveAllSolutionsOfThisServiceInfo = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyPayment.steps.addSolutions.stepSolution.getListSolutions;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${getInfoServiceMoneyPayment?.id}/solutions/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIResponseGetOneSolution[];
                setInfoSolutionThatSelected(t.filter(sol => sol.id === (getInfoThisStep?.chosen_solution ?? -1))[0]);
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    useEffect(() => {
        if (getInfoThisStep?.chosen_solution) {
            callApiForRetrieveWorkflowSolutionThatSelected();

        }
        if (getInfoThisStep?.chosen_solution && getInfoServiceMoneyPayment?.id) {
            callApiForRetrieveAllSolutionsOfThisServiceInfo();
        }
    }, [getInfoThisStep?.chosen_solution, getInfoServiceMoneyPayment?.id]);
    useEffect(() => {
        if (getInfoThisWorkFlow) {
            callApiForRetrieveInfoThisStep();
        }
    }, [getInfoThisWorkFlow]);
    useEffect(() => {
        setInfoServiceMoneyPayment(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setInfoThisWorkFlow(paramsComponent.thisStepWorkFlow);
    }, [paramsComponent.thisStepWorkFlow])

    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    return (
        <ParentWithHeightAnimation in={getInfoThisWorkFlow !== undefined}
            loading={getInfoThisWorkFlow === undefined}
            style={{
                boxSizing: "border-box",
                width: "100%",
            }}
        >
            <Box sx={{
                boxSizing: "border-box",
                paddingInlineStart: { xs: "0.8rem", sm: "0.9rem", md: "1.0rem", lg: "1.18rem", xl: "1.3rem" },
                paddingInlineEnd: { xs: "0.8rem", sm: "3.33rem", md: "3.7rem", lg: "4.14rem", xl: "6rem" },
                display: "flex", flexDirection: 'column',
                alignItems: 'start',
                width: "100%",
                marginTop: "1.18rem",
            }}>

                {getInfoThisStep &&
                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'start',
                        gap: "0.59rem",
                        background: '#F3F6FC',
                        borderRadius: '0.37rem',
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                        boxShadow: Colors.shadow.blueLightCard,
                        width: '100%',

                    }}>
                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            background: Colors.theme.white.SecondaryLight,
                            borderBottom: `1px solid ${Colors.theme.blue.light}`,
                            width: '100%',
                            paddingBlock: '0.28rem',
                            paddingInline: '0.88rem',
                            boxSizing: 'border-box',
                        }}>
                            <div style={{
                                display: 'flex', flexDirection: 'row', alignItems: 'center',
                                justifyContent: 'start',
                                boxSizing: 'border-box',
                                gap: '0.4rem',
                            }}>
                                <p style={{
                                    display: 'inline-flex', flexDirection: 'row', fontFamily: confComps.font.poppins.Medium,
                                    fontSize: '1.03rem',
                                    lineHeight: '1.7rem',
                                    color: Colors.theme.blue.dark,

                                }}>
                                    {`Selected One Solution By`}
                                </p>
                                <p
                                    style={{
                                        display: 'inline-flex', flexDirection: 'row', fontFamily: confComps.font.poppins.Medium,
                                        fontSize: '1.03rem',
                                        lineHeight: '1.7rem',
                                        color: Colors.theme.blue.blue,
                                        cursor: 'pointer',
                                        // textDecoration: 'underline',
                                    }}
                                    onClick={() => {
                                        navigate(`${Links.screens.panel.people.employees.employeeProfile}${getInfoThisStep.submitted_by.user.id}/`)
                                    }}
                                >
                                    {`(@${getInfoThisStep.submitted_by.user.username})`}
                                </p>
                                <p style={{
                                    display: 'inline-flex',
                                    flexDirection: 'row',
                                    fontFamily: confComps.font.poppins.Regular,
                                    fontSize: '1.03rem',
                                    lineHeight: '1.7rem',
                                    color: Colors.theme.blue.light,
                                }}>
                                    {`${dayjs.unix(getInfoThisStep.created_at).format("YYYY/MM/DD")}`}
                                </p>


                            </div>

                        </div>
                        <p style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'start', width: '100%',
                            fontFamily: confComps.font.poppins.Regular,
                            fontSize: '1.03rem',
                            lineHeight: '1.77rem',
                            paddingBlockEnd: '0.58rem',
                            paddingInline: '0.88rem',
                            boxSizing: 'border-box',
                        }}>

                            {getInfoThisStep?.description}
                        </p>
                    </div>
                }


                {/*  */}
                {getInfoSolutionThatSelected &&
                    <div style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'start',
                        justifyContent: 'start',
                        gap: '1rem',
                        width: '100%',
                        paddingInline: '1.48rem',
                        paddingBlock: '1.33rem',
                        boxSizing: 'border-box',
                        boxShadow: Colors.shadow.BoxShadow,
                        borderRadius: '1.11rem',
                        marginTop: "1.4rem",
                    }}>
                        <h4 style={{
                            color: Colors.theme.orange.light,
                            fontFamily: confComps.font.poppins.Medium,
                            fontSize: '1.05rem',
                            fontWeight: 600,
                            lineHeight: '1.55rem',
                        }}>
                            {`Solution #${getInfoSolutionThatSelected.id}`}
                        </h4>
                        {getInfoSolutionThatSelected.description_list.length >= (getInfoSolutionThatSelected.user_orders ?? []).length &&
                            <p style={{
                                display: 'flex', flexDirection: 'row', alignItems: 'start',
                                justifyContent: 'start',
                                width: '100%',
                                boxSizing: 'border-box',
                                fontSize: '1.03rem',
                                lineHeight: '1.62rem',
                                color: Colors.theme.blue.dark,
                            }}>
                                {getInfoSolutionThatSelected.description_list.map((text, index, arr) => {
                                    if (index < (getInfoSolutionThatSelected.user_orders ?? []).length) {
                                        return `${text} @${(getInfoSolutionThatSelected.user_orders ?? [])[index].user.username}`
                                    }
                                    else {
                                        return `${text}`
                                    }
                                }).join("")}
                            </p>
                        }
                        {getInfoSolutionThatSelected.description_list.length < (getInfoSolutionThatSelected.user_orders ?? []).length &&
                            <p style={{
                                display: 'flex', flexDirection: 'row', alignItems: 'start',
                                justifyContent: 'start',
                                width: '100%',
                                boxSizing: 'border-box',
                                fontSize: '1.03rem',
                                lineHeight: '1.62rem',
                                color: Colors.theme.blue.dark,
                            }}>
                                {(getInfoSolutionThatSelected.user_orders ?? []).map((userMentioned, index, arr) => {
                                    if (index < getInfoSolutionThatSelected.description_list.length) {
                                        return `${getInfoSolutionThatSelected.description_list[index]} @${userMentioned.user.username}`
                                    }
                                    else {
                                        return `@${userMentioned.user.username}`
                                    }
                                }).join("")}
                            </p>
                        }

                        <div style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'start',
                            justifyContent: 'start',
                            width: '100%',
                        }}>
                            {getWorkFlowOfSolution
                                .filter(step => isKey1BiggerThanKey2(getKeyOfStepThatSelected, StepOfSolutionNameMappingToKeyStepForMoneyPaymentRecap[step.review_ct])
                                    || getKeyOfStepThatSelected === StepOfSolutionNameMappingToKeyStepForMoneyPaymentRecap[step.review_ct])//check that view by step that clicked in top guide
                                .map((stepOfSolution, index, arr) => {
                                    if (stepOfSolution.review_ct === EnumRecapServiceStepSolutionNameInServer.checkRiskOfSolution) {
                                        return (
                                            <ViewRiskMeasurementSolution
                                                infoThisStepOfSolution={stepOfSolution}
                                            />
                                        )
                                    }
                                    else if (stepOfSolution.review_ct === EnumRecapServiceStepSolutionNameInServer.checkWalletOfTrustyForSolution) {
                                        return (
                                            <ViewCheckWalletOfTrusty
                                                infoThisStepOfSolution={stepOfSolution}
                                            />

                                        )
                                    }
                                    else if (stepOfSolution.review_ct === EnumRecapServiceStepSolutionNameInServer.checkSolutionByTrusty) {
                                        return (
                                            <ViewCheckByTrustiesOfSolution
                                                infoThisStepOfSolution={stepOfSolution}
                                                callBackInfoThisStep={(params: InterfaceNTTSolutionCheckByTrusties) => {
                                                }}
                                            />

                                        )
                                    }
                                })}


                        </div>
                    </div>
                }


            </Box>
        </ParentWithHeightAnimation>
    )
};
export default ViewChooseOneSolutionForMoneyPayment;