import React, { useEffect, useRef, useState } from 'react';
import Colors from "../../../../../config/Colors";
import { useDispatch, useSelector } from 'react-redux';
import { SelectedProfile } from '../../../../../store/profileStateSlice';
import { keyframes, styled, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigComps } from '../../../../../config/ConfigCOMP';
import { isVisibilityProgressBar } from '../../../../../store/ProgressbarInRootSlice';
import { HandlerLangUrl, urlsList } from '../../../../../api/UrlsList';
import { RequestHandler } from '../../../../../api/API';
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from '../../../../../api/APIErrorResponse';
import { ShowAlert } from '../../../../../store/AlertStateModeSlice';
import { enumTypeOfAlert } from '../../../../MUIConfig/alert_popup/interfaceAlertPopup';

import { InterfaceNTTRoleEmployee } from '../../../../../NTTs/InterfaceNTTRoleEmployee';
import { SyncRounded } from '@mui/icons-material';
import LineSeparator from '../../../LineSeprator/LineSeparator';
import { InterfaceTextFieldSelectorRolesEmployeeSimple } from './InterfaceTextFieldSelectorRolesEmployeeSimple';
import AutoCompleteWithUnderLine from '../../../../complex/AutoCompleteWithUnderLine/AutoCompleteWithUnderLine';


const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
    fontSize: "1rem",
    width: "100%",
    paddingInline: "1rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#F8F8F8",
            marginTop: "-8px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
function TextFieldSelectorRolesEmployeeSimple(componentParams: InterfaceTextFieldSelectorRolesEmployeeSimple) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);

    const [getIsFocused, setIsFocused] = useState(false);

    const [getRolesSelected, setRolesSelected] = useState<number[]>([]);
    const [getResponseAllRoles, setResponseAllRoles] = useState<InterfaceNTTRoleEmployee[]>([]);
    // const [getSearchText, setSearchText] = useState<string>(componentParams.selectedRole?.title ?? "");
    const [getStateIsSearching, setStateIsSearching] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const getApiListRoleOfEmployee = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.employee.getRoleOfEmployee;
        setStateIsSearching(true);

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {

                console.log("response:):)");
                console.log(response);
                let t = response as (InterfaceNTTRoleEmployee[]);
                setResponseAllRoles(t);
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            }
            );
        setStateIsSearching(false);


    }

    useEffect(() => {
        getApiListRoleOfEmployee();
    }, [])
    useEffect(() => {
        componentParams.selectedRole && setRolesSelected(componentParams.selectedRole)
    }, [componentParams.selectedRole]);

    useEffect(() => {
        getRolesSelected && componentParams.onChangeSelectedRoles(getRolesSelected);
    }, [getRolesSelected])
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                // borderRadius: ConstStyle.borderRadius,
                fontSize: "1rem",
                position: "relative",
                // boxShadow: Colors.shadow.shadowTextFieldWithIconBottomLineClicked,
                ...componentParams.styleMain,
                paddingBlockStart: "0.32rem",
                boxSizing: "border-box",

            }}
            ref={containerRef}
        >
            {componentParams.iconStart && (
                <div style={{ display: "flex", alignItems: "center", marginInlineStart: "0.96rem" }}>
                    <componentParams.iconStart style={{ color: getIsFocused ? Colors.theme.blue.dark : Colors.theme.blue.light }} />
                </div>
            )}
            <div style={{
                flex: 1, paddingInlineEnd: "1rem",
                paddingInlineStart: "0.32rem",
                boxSizing: "border-box",
            }}>
                {/* <AutoCompleteWithUnderLine
                    valuesList={getResponseAllActivity}
                    onChangeSelectedItem={(items) => {
                        setActivitySelected(items.map((item, index, arr) => {
                            return ({
                                ...getResponseAllActivity.filter(ac => ac.id == item.id)[0]
                            })

                        }));
                    }}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    selectedValueTitle={getActivitySelected}

                /> */}
                <AutoCompleteWithUnderLine
                    valuesList={getResponseAllRoles}
                    styleItems={componentParams.styleItems}
                    onChangeSelectedItem={(items) => {
                        componentParams.onChangeSelectedRoles(items.map((item, index, arr) => {
                            return ({
                                ...getResponseAllRoles.filter(ac => ac.id === item.id)[0]
                            })
                        }).map(select => select.id));
                    }}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    selectedValueTitle={getResponseAllRoles.filter(role => getRolesSelected.filter(idSelect => idSelect === role.id).length > 0)}

                />

            </div>
            {
                <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }}>
                    {
                        (componentParams.iconEnd && (
                            <componentParams.iconEnd
                                style={{
                                    background: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                    width: "0.9rem",
                                }} />
                        ))
                    }
                </div>
            }

        </div>
    );
}

export default TextFieldSelectorRolesEmployeeSimple;
