import { DoneRounded, CircleOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TitleMedium16 from "../../../../components/basic/Texts/Titles/TitleMedium16/TitleMedium16";
import BoxRecap from "../../../../components/basic/‌Box/BoxRecap/BoxRecap";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { InterfaceParentRecapMoneyPayment } from "./InterfaceParentRecapMoneyPayment";
import { InterfaceNTTRecapMain } from "../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { InterfaceNTTRecapMoneyPaymentService } from "../../../../NTTs/Recap/InterfaceNTTRecapMoneyPaymentService";
import ControllerInitiateMoneyPayment from "../StepersBox/MoneyPayment/InitiateMoneyPayment/ControllerInitiateMoneyPayment";
import { isKey1BiggerThanKey2 } from "../../../../components/complex/SteperGuide/SteperGuide";
import { EnumSTepsParent, EnumSTepsRecap } from "../../../../config/enums/EnumSteps";
import { StepNameMappingToKeyStepForMoneyPaymentRecap, EnumRecapServiceStepNameInServerForMoneyPayment } from "../../../../NTTs/Recap/WorkflowMainRecap/MoneyPayment/InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap";
import ControllerAddSolutionForMoneyPayment from "../StepersBox/MoneyPayment/AddSolutionForMoneyPayment/ControllerAddSolutionForMoneyPayment";
import ViewAddSolutionForMoneyPayment from "../StepersBox/MoneyPayment/AddSolutionForMoneyPayment/ViewAddSolutionForMoneyPayment/ViewAddSolutionForMoneyPayment";
import CreateApproveSolutionByManagerForMoneyPayment from "../StepersBox/MoneyPayment/ApproveSolutionByManagerForMoneyPayment/CreateApproveSolutionByManagerForMoneyPayment/CreateApproveSolutionByManagerForMoneyPayment";
import ViewApproveSolutionByManagerForMoneyPayment from "../StepersBox/MoneyPayment/ApproveSolutionByManagerForMoneyPayment/ViewApproveSolutionByManagerForMoneyPayment/ViewApproveSolutionByManagerForMoneyPayment";
import ControllerCheckWalletCustomerOfMoneyPayment from "../StepersBox/MoneyPayment/CheckWalletCustomerOfMoneyPayment/ControllerCheckWalletCustomerOfMoneyPayment";
import CreateChooseOneSolutionForMoneyPayment from "../StepersBox/MoneyPayment/ChooseOneSolutionForMoneyPayment/CreateChooseOneSolutionForMoneyPayment/CreateChooseOneSolutionForMoneyPayment";
import ViewChooseOneSolutionForMoneyPayment from "../StepersBox/MoneyPayment/ChooseOneSolutionForMoneyPayment/ViewChooseOneSolutionForMoneyPayment/ViewChooseOneSolutionForMoneyPayment";
import ControllerRiskMeasurementCustomerOfMoneyPayment from "../StepersBox/MoneyPayment/RiskMeasurementCustomerOfMoneyPayment/ControllerRiskMeasurementCustomerOfMoneyPayment";
import ViewCheckByTrustiesOfSolutionsOfMoneyPayment from "../StepersBox/MoneyPayment/ViewCheckByTrustiesOfSolutionsOfMoneyPayment/ViewCheckByTrustiesOfSolutionsOfMoneyPayment";
import ViewCheckRiskOfSolutionsOfMoneyPayment from "../StepersBox/MoneyPayment/ViewCheckRiskOfSolutionsOfMoneyPayment/ViewCheckRiskOfSolutionsOfMoneyPayment";
import ViewCheckWalletsTrustiesOfMoneyPayment from "../StepersBox/MoneyPayment/ViewCheckWalletsTrustiesOfMoneyPayment/ViewCheckWalletsTrustiesOfMoneyPayment";
import { InterfaceNTTOneServiceWorkflowMoneyPaymentRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/MoneyPayment/InterfaceNTTOneServiceWorkflowMoneyPaymentRecap";

function ParentRecapMoneyPayment(paramsComponent: InterfaceParentRecapMoneyPayment) {
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1");
    const [getIsCreateMoneyPaymentActive, setIsCreateMoneyPaymentActive] = useState<boolean>(false);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getInfoServiceInMainRecap, setInfoServiceInMainRecap] = useState<InterfaceNTTRecapMoneyPaymentService>();
    const [getInfoWorkFlowService, setInfoWorkFlowService] = useState<InterfaceNTTOneServiceWorkflowMoneyPaymentRecap>();
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setInfoServiceInMainRecap(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setInfoWorkFlowService(paramsComponent.infoWorkFlowService);
    }, [paramsComponent.infoWorkFlowService])

    return (
        <BoxRecap>
            <div
                style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'space-between',
                    boxSizing: "border-box",
                }}
            >
                <TitleMedium16>
                    {confComps.translate.moneyPayment}
                </TitleMedium16>
                {getKeyOfStepThatSelected === "1.1" &&
                    <div
                        style={{
                            cursor: "pointer",
                            display: 'inline-flex',
                        }}
                        onClick={() => {
                            if ((getRecapMain?.payments ?? []).length === 0)
                                setIsCreateMoneyPaymentActive(prev => !prev)
                        }}
                    >
                        {getIsCreateMoneyPaymentActive ?
                            <DoneRounded style={{
                                color: "#fff", background: Colors.theme.blue.blue,
                                borderRadius: "500px", fontSize: "1.5rem",
                                padding: "0.15rem",
                            }} /> :
                            <CircleOutlined style={{ color: Colors.theme.blue.blue, fontSize: "1.8rem" }} />
                        }
                    </div>
                }
            </div>
            <ControllerInitiateMoneyPayment
                infoServiceInMainRecap={getInfoServiceInMainRecap}
                callBackCreateMainRecap={paramsComponent.callBackCreateMainRecap}
                recapMain={getRecapMain}
                isCreateMoneyPaymentActive={getIsCreateMoneyPaymentActive}
                stepThatSelected={getKeyOfStepThatSelected}
                callBackReLoad={() => {
                    console.log("callback reload caled:)")
                    paramsComponent.callBackReLoad();
                }} />

            {getInfoServiceInMainRecap &&
                getInfoWorkFlowService?.workflow_reviews
                    .filter(step => isKey1BiggerThanKey2(getKeyOfStepThatSelected, StepNameMappingToKeyStepForMoneyPaymentRecap[step.review_ct])
                        || getKeyOfStepThatSelected === StepNameMappingToKeyStepForMoneyPaymentRecap[step.review_ct])//check that view by step that clicked in top guide
                    // .sort((a, b) => {
                    //     if (a.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.addSolution) {
                    //         return 0.5;
                    //     }
                    //     else if (b.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.addSolution) {
                    //         return -0.5;
                    //     }
                    //     return a.created_at - b.created_at;
                    // })
                    .map((oneStep, index, arr) => {
                        if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.fundControlCheckWalletCustomer) {
                            return (
                                <ControllerCheckWalletCustomerOfMoneyPayment
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    recapMain={getRecapMain}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    callBackReLoad={() => {
                                        paramsComponent.callBackReLoad();
                                    }}
                                    StepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.checkRisk) {
                            return (
                                <ControllerRiskMeasurementCustomerOfMoneyPayment
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    StepWorkFlow={oneStep}
                                    callBackReLoad={() => {
                                        paramsComponent.callBackReLoad();
                                    }}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.addSolution) {
                            return (
                                <ViewAddSolutionForMoneyPayment
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    callBackReLoad={function (): void {
                                        paramsComponent.callBackReLoad();
                                    }}
                                />
                                // <ControllerAddSolutionForMoneyPayment
                                //     recapMain={getRecapMain}
                                //     infoServiceInMainRecap={getInfoServiceInMainRecap}
                                //     stepThatSelected={getKeyOfStepThatSelected}
                                //     allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                //     StepWorkFlow={oneStep}
                                //     callBackReLoad={() => {
                                //         paramsComponent.callBackReLoad();
                                //     }}
                                // />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.checkRiskOfSolution) {
                            return (
                                <ViewCheckRiskOfSolutionsOfMoneyPayment
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.checkWalletOfTrustByFinanceForSolution) {
                            return (
                                <ViewCheckWalletsTrustiesOfMoneyPayment
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.checkSolutionByTrusties) {
                            return (
                                <ViewCheckByTrustiesOfSolutionsOfMoneyPayment
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.chooseOneSolution) {
                            return (
                                <ViewChooseOneSolutionForMoneyPayment
                                    recapMain={getRecapMain}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.approveSolution) {
                            return (
                                <ViewApproveSolutionByManagerForMoneyPayment
                                    recapMain={getRecapMain}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }

                    })
            }

            {/* step that now must be fill */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.checkWalletOfTheRequesterByFundControl}` && // step guide click this step
                StepNameMappingToKeyStepForMoneyPaymentRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForMoneyPayment.undefined }).review_ct
                ] === StepNameMappingToKeyStepForMoneyPaymentRecap[EnumRecapServiceStepNameInServerForMoneyPayment.undefined]
                &&
                <ControllerCheckWalletCustomerOfMoneyPayment
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    recapMain={getRecapMain}
                    stepThatSelected={getKeyOfStepThatSelected}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    StepWorkFlow={undefined}
                />
            }
            {/* fill the risk measurement of customer that selected at first step */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}` && // step guide click this step
                StepNameMappingToKeyStepForMoneyPaymentRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForMoneyPayment.undefined }).review_ct
                ] === StepNameMappingToKeyStepForMoneyPaymentRecap[EnumRecapServiceStepNameInServerForMoneyPayment.fundControlCheckWalletCustomer]//the last step that completed must the previous step
                &&
                <ControllerRiskMeasurementCustomerOfMoneyPayment
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={undefined}
                />
            }

            {getInfoServiceInMainRecap &&
                isKey1BiggerThanKey2(getKeyOfStepThatSelected, `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.0`) && //check step that selected in guide line
                isKey1BiggerThanKey2(`${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions + 1}.-1`, getKeyOfStepThatSelected) &&
                (isKey1BiggerThanKey2(StepNameMappingToKeyStepForMoneyPaymentRecap[
                    (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForMoneyPayment.undefined }).review_ct
                ], `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.0`) ||
                    StepNameMappingToKeyStepForMoneyPaymentRecap[
                    (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForMoneyPayment.undefined }).review_ct
                    ] === `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}`) &&//check last step that completed
                <ControllerAddSolutionForMoneyPayment
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={getInfoServiceInMainRecap &&
                        getInfoWorkFlowService?.workflow_reviews.filter(steps => steps.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.addSolution)[0]}
                />
            }
            {/* choose One of  solutions */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.chooseSolution}` && // step guide click this step
                StepNameMappingToKeyStepForMoneyPaymentRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForMoneyPayment.undefined }).review_ct
                ] === StepNameMappingToKeyStepForMoneyPaymentRecap[EnumRecapServiceStepNameInServerForMoneyPayment.checkSolutionByTrusties]//the last step that completed must the previous step
                &&
                <CreateChooseOneSolutionForMoneyPayment
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={getInfoServiceInMainRecap &&
                        getInfoWorkFlowService?.workflow_reviews.filter(steps => steps.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.addSolution)[0]}
                />
            }
            {/* Comment of manager */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.acceptByManager}` && // step guide click this step
                StepNameMappingToKeyStepForMoneyPaymentRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForMoneyPayment.undefined }).review_ct
                ] === StepNameMappingToKeyStepForMoneyPaymentRecap[EnumRecapServiceStepNameInServerForMoneyPayment.chooseOneSolution]//the last step that completed must the previous step
                &&
                <CreateApproveSolutionByManagerForMoneyPayment
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={getInfoServiceInMainRecap &&
                        getInfoWorkFlowService?.workflow_reviews.filter(steps => steps.review_ct === EnumRecapServiceStepNameInServerForMoneyPayment.addSolution)[0]}
                />
            }

        </BoxRecap>
    )

}
export default ParentRecapMoneyPayment;