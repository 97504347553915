import { Box, useTheme } from "@mui/material";
import { InterfaceCreateChooseOneSolutionForMoneyReceive } from "./InterfaceCreateChooseOneSolutionForMoneyReceive";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { InterfaceNTTRecapMain } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { InterfaceNTTRecapMoneyReceiveService } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMoneyReceiveService";
import { InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap } from "../../../../../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import ParentOfViewerSomeSolutionsThatAcceptedByTrusties from "../ParentOfViewerSomeSolutionsThatAcceptedByTrusties/ParentOfViewerSomeSolutionsThatAcceptedByTrusties";
import { InterfaceAPIResponseGetOneSolution } from "../../AddSolutionForMoneyReceive/ViewAddSolutionForMoneyReceive/InterfaceViewAddSolutionForMoneyReceive";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, urlsList } from "../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import TextFieldMultiLine from "../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import Colors from "../../../../../../../config/Colors";
import ButtonSave from "../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";

function CreateChooseOneSolutionForMoneyReceive(paramsComponent: InterfaceCreateChooseOneSolutionForMoneyReceive) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>('1');
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getIsWaitingForCreate, setIsWaitingForCreate] = useState<boolean>(false);
    const [getInfoServiceInMainRecap, setInfoServiceInMainRecap] = useState<InterfaceNTTRecapMoneyReceiveService>();
    const [getInfoThisWorkFlow, setInfoThisWorkFlow] = useState<InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap>();
    const [getAllWorkflowReviews, setAllWorkflowReviews] = useState<InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap[]>([]);
    const [getSelectedSolution, setSelectedSolution] = useState<InterfaceAPIResponseGetOneSolution>();
    const [getDescriptionValue, setDescriptionValue] = useState<string>("");
    const callApiForCreateCommentForChooseSolution = async () => {
        if (!getSelectedSolution) {
            dispatch(ShowAlert({ text: 'You must select one of the solutions', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyReceive.steps.chooseOneSolution.createSteps;
        let bodyObj = {
            "previous_review": {
                "review_type": getAllWorkflowReviews[getAllWorkflowReviews.length - 1].review_ct, // or recapservicefundcontrol
                "object_id": getAllWorkflowReviews[getAllWorkflowReviews.length - 1].review_id,
            },
            'solution': getSelectedSolution?.id,
            'description': getDescriptionValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.infoServiceInMainRecap.id}/meta/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                paramsComponent.callBackReLoad();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'Your Comment Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setInfoServiceInMainRecap(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setInfoThisWorkFlow(paramsComponent.StepWorkFlow);
    }, [paramsComponent.StepWorkFlow])
    useEffect(() => {
        setAllWorkflowReviews(paramsComponent.allWorkflowReviews);
    }, [paramsComponent.allWorkflowReviews])
    return (
        <div>
            {getInfoServiceInMainRecap &&
                <Box sx={{
                    boxSizing: "border-box",
                    paddingInlineStart: { xs: "0.8rem", sm: "0.9rem", md: "1.0rem", lg: "1.18rem", xl: "1.3rem" },
                    paddingInlineEnd: { xs: "0.8rem", sm: "3.33rem", md: "3.7rem", lg: "4.14rem", xl: "6rem" },
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'start',
                    width: "100%",
                    marginTop: "1.18rem",
                    gap: "1.18rem",
                }}>
                    <ParentOfViewerSomeSolutionsThatAcceptedByTrusties
                        stepThatSelected={getKeyOfStepThatSelected}
                        recapMain={getRecapMain}
                        infoServiceInMainRecap={getInfoServiceInMainRecap}
                        thisStepWorkFlow={getInfoThisWorkFlow}
                        allWorkflowReviews={getAllWorkflowReviews}
                        callBackReLoad={function (): void {
                            paramsComponent.callBackReLoad();
                        }}
                        callbackChangedSelectedSolution={(selected?: InterfaceAPIResponseGetOneSolution) => {
                            setSelectedSolution(selected);
                        }}
                    />
                    <TextFieldMultiLine
                        title={confComps.translate.explanation}
                        text={getDescriptionValue}
                        onChangeText={setDescriptionValue}
                        styleParent={{
                            boxSizing: 'border-box',
                            border: `1px solid ${Colors.theme.blue.border}`,
                            background: Colors.theme.white.white90,
                        }}
                    />
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        justifyContent: 'end',
                        width: '100%',
                    }}>
                        <ButtonSave
                            text={confComps.translate.submit}
                            callbackOnClick={() => {
                                callApiForCreateCommentForChooseSolution();
                            }}
                            style={{
                                background: '#fff',
                                color: Colors.theme.blue.dark,
                                borderColor: Colors.theme.blue.dark,
                                paddingInline: '1.77rem',
                                borderRadius: '0.37rem',
                            }}
                        />
                    </div>

                </Box>
            }
        </div>
    )
};
export default CreateChooseOneSolutionForMoneyReceive;