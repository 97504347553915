import { CreateMUIStyled, styled, useTheme } from "@mui/material/styles";
import Colors from "../../../../../config/Colors";

export const MainCard = styled('div')(({ theme }) => ({
    width: "100%",
    borderRadius: "0.5rem",
    boxShadow: Colors.shadow.dropShadow,
    boxSizing: "border-box",
    paddingBlock: "0.8rem",
    [theme.breakpoints.up('xs')]: {
        paddingBlock: "1rem",
    },
    [theme.breakpoints.up('sm')]: {
        paddingBlock: "1.84rem",
    },
    [theme.breakpoints.up('md')]: {
        paddingBlock: "2.14rem",

    },
    [theme.breakpoints.up('lg')]: {
        paddingBlock: "2.24rem",
    },
    [theme.breakpoints.up('xl')]: {
        paddingBlock: "2.24rem",
    },

}));
export const DivImageProfile = styled('div')(({ theme }) => ({
    boxSizing: "border-box",
    flexGrow: "0",
    width: "2.54rem",
    [theme.breakpoints.up('xs')]: {
        width: "3.04rem",
    },
    [theme.breakpoints.up('sm')]: {
        width: "4.04rem",
    },
    [theme.breakpoints.up('md')]: {
        width: "5.04rem",

    },
    [theme.breakpoints.up('lg')]: {
        width: "6rem",
    },
    [theme.breakpoints.up('xl')]: {
        width: "6rem",
    },

}));