import { useEffect, useState } from "react";
import { InterfaceAPIGetForeignCompanies, InterfaceForeignCompany, InterfacePageForeignCompanies } from "./InterfaceForeignCompany";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import CreateForeignCompany from "./CreateForeignCompany/CreateForeignCompany";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, urlsList } from "../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../api/API";
import { useTheme } from "@mui/material";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import RowForeignCompany from "./RowForeignCompany/RowForeignCompany";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import ShowAccountsOfTheCompany from "./ShowAccountsOfTheCompany/ShowAccountsOfTheCompany";
import { EnumStateFetchList } from "../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfacePaginationInServer } from "../../../../../../api/interfaces/InterfaceSearchInServer";
import ParentWithHeightAnimation from "../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import InfiniteScrollList from "../../../../../../components/basic/InfiniteScrollList/InfiniteScrollList";

function ForeignCompany(paramsComponent: InterfacePageForeignCompanies) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getResponseGetForeignCompanies, setResponseGetForeignCompanies] = useState<InterfaceAPIGetForeignCompanies>();
    const [getIsActiveCreateCompany, setIsActiveCreateCompany] = useState<boolean>(false);
    const [getIsShowAccountOfCompany, setIsShowAccountOfCompany] = useState<boolean>(false);
    const [getSelectedCompany, setSelectedCompany] = useState<InterfaceForeignCompany>();

    const callApiForGetForeignCompanies = async (paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.company.foreignCompany.postGetAllForeignCompany;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/foreign-companies/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIGetForeignCompanies;

                if (paginationParams.page === 1) {
                    setResponseGetForeignCompanies(t);
                }
                else {
                    setResponseGetForeignCompanies(prev => {
                        return (prev ? {
                            ...t,
                            data: [...prev.data, ...t.data],
                        } : {
                            ...t,
                        });
                    }
                    );
                }
                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }

                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                setStateFetchList(EnumStateFetchList.errorInRequest);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            callApiForGetForeignCompanies(getPaginationForLastRequest);
        }
    }, [getStateFetchList])

    return (
        <div style={{
            display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center",
        }}>
            <ParentWithHeightAnimation in={!getIsActiveCreateCompany && !getIsShowAccountOfCompany} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                <InfiniteScrollList
                    isDataExist={(getResponseGetForeignCompanies?.data ?? []).length !== 0}
                    onEndReached={async () => {
                        let oldPage = getPaginationForLastRequest;
                        oldPage = {
                            ...oldPage,
                            page: (oldPage.page + 1)
                        };
                        setPaginationForLastRequest(oldPage);
                        console.log("callback for change state response2:", oldPage);
                        callApiForGetForeignCompanies(oldPage);
                    }}
                    onRetryClicked={() => {
                        callApiForGetForeignCompanies(getPaginationForLastRequest);
                    }}
                    stateResponseList={getStateFetchList}
                    styleSpacingMUI={{
                        columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                        rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                        columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                    }}

                >
                    {getResponseGetForeignCompanies && getResponseGetForeignCompanies.data //&& !getIsShowAccountOfCompany 
                        &&
                        getResponseGetForeignCompanies.data.map((foreignCompany, index, arr) => {
                            return (
                                <RowForeignCompany {...foreignCompany}
                                    callbackClickOnEdit={() => {
                                        setSelectedCompany(foreignCompany);
                                        setIsActiveCreateCompany(true);
                                    }}
                                    callbackReloadPage={() => {
                                        setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                                        callApiForGetForeignCompanies(confComps.defaultValue.paginationConfigForServer);
                                    }}
                                    callbackShowAccounts={() => {
                                        setIsShowAccountOfCompany(true);
                                        setSelectedCompany(foreignCompany);
                                        setIsActiveCreateCompany(false);
                                    }} />

                            )
                        })
                    }
                </InfiniteScrollList>
            </ParentWithHeightAnimation>
            <ParentWithHeightAnimation in={getIsActiveCreateCompany} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                {getIsActiveCreateCompany &&
                    <CreateForeignCompany 
                    userInfoBasic={paramsComponent.userInfoBasic}
                        callbackCancel={() => {
                            setIsActiveCreateCompany(false);
                            setSelectedCompany(undefined);
                            setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                            callApiForGetForeignCompanies(confComps.defaultValue.paginationConfigForServer);
                            setIsShowAccountOfCompany(false);
                        }}
                        isShowListOfAccount={getIsShowAccountOfCompany}


                        companyInformation={getSelectedCompany}
                    />
                }
            </ParentWithHeightAnimation>
            <ParentWithHeightAnimation in={getIsShowAccountOfCompany && (getSelectedCompany !== undefined)} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                {getIsShowAccountOfCompany && getSelectedCompany &&
                    <ShowAccountsOfTheCompany
                        {...getSelectedCompany}
                        callbackHideAccount={() => {
                            setIsShowAccountOfCompany(false)
                            setSelectedCompany(undefined);
                        }}
                        callbackReload={() => {
                            setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                            callApiForGetForeignCompanies(confComps.defaultValue.paginationConfigForServer);
                        }}
                    />

                }
            </ParentWithHeightAnimation>

            {!getIsActiveCreateCompany && !getIsShowAccountOfCompany &&
                <div style={{ display: 'flex', flexDirection: "row", position: "sticky", bottom: "1vh", alignItems: "center", justifyContent: "end", width: "100%" }}>
                    <ButtonSave text={confComps.translate.addNewCompany}
                        callbackOnClick={() => {
                            setIsActiveCreateCompany(true);
                        }} />
                </div>
            }
        </div>
    );
}
export default ForeignCompany;