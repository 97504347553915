import { Box, Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { InterfaceAPIGetCashAccounts, InterfaceCashAccounts } from "./InterfaceCashAccounts";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { useEffect, useState } from "react";
import { InterfaceNTTCashAccount } from "../../../../../NTTs/InterfaceNTTCashAccount";
import RowCashAccount from "./RowCashAccount/RowCashAccount";
import ButtonSave from "../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import CreateCashAccounts from "./CreateCashAccounts/CreateCashAccount";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../api/API";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../../api/UrlsList";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { EnumStateFetchList } from "../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfacePaginationInServer } from "../../../../../api/interfaces/InterfaceSearchInServer";
import InfiniteScrollList from "../../../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import ParentWithHeightAnimation from "../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";

function CashAccounts(paramsComponent: InterfaceCashAccounts) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getResponseGetCashAccounts, setResponseGetCashAccounts] = useState<InterfaceAPIGetCashAccounts>();
    const [getIsActiveCreateCashAccount, setIsActiveCreateCashAccount] = useState<boolean>(false);
    const [getSelectedCashAccount, setSelectedCashAccount] = useState<InterfaceNTTCashAccount>();

    const callApiForGetCashAccounts = async (paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.cashAccount.getAllCashAccounts;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/cash-accounts/`, mTheme, paginationParams as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIGetCashAccounts;
                if (paginationParams.page === 1) {
                    setResponseGetCashAccounts(t);
                }
                else {
                    setResponseGetCashAccounts(prev => {
                        return (prev ? {
                            ...t,
                            data: [...prev.data, ...t.data],
                        } : {
                            ...t,
                        });
                    }
                    );
                }
                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }
                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                setStateFetchList(EnumStateFetchList.errorInRequest);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            callApiForGetCashAccounts(getPaginationForLastRequest);
        }
    }, [getStateFetchList])

    return (
        <div style={{
            display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center",
            gap: "1.6rem",
        }}>
            <ParentWithHeightAnimation in={!getIsActiveCreateCashAccount} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>



                <Box sx={{
                    width: "100%",
                    boxSizing: "border-box",
                }}>
                    <InfiniteScrollList
                        isDataExist={(getResponseGetCashAccounts?.data ?? []).length !== 0}
                        onEndReached={async () => {
                            let oldPage = getPaginationForLastRequest;
                            oldPage = {
                                ...oldPage,
                                page: (oldPage.page + 1)
                            };
                            setPaginationForLastRequest(oldPage);
                            console.log("callback for change state response2:", oldPage);
                            callApiForGetCashAccounts(oldPage);
                        }}
                        onRetryClicked={() => {
                            callApiForGetCashAccounts(getPaginationForLastRequest);
                        }}
                        stateResponseList={getStateFetchList}
                        styleSpacingMUI={{
                            columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                            rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                            columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                        }}

                    >
                        <Grid container
                            justifyContent="space-between" alignItems="stretch"
                            columnSpacing={{ xs: 0, sm: 0, md: "1.34rem", lg: "1.48rem", xl: "1.6rem" }}
                            rowSpacing={{ xs: "1.09rem", sm: "1.21rem", md: "1.34rem", lg: "1.48rem", xl: "1.6rem" }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >


                            {getResponseGetCashAccounts && getResponseGetCashAccounts.data.map((cashAccount, index, arr) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <RowCashAccount {...cashAccount}
                                            callbackClickOnEdit={() => {
                                                setSelectedCashAccount(cashAccount);
                                                setIsActiveCreateCashAccount(true);
                                            }}
                                            callbackReloadPage={() => {
                                                setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                                                callApiForGetCashAccounts(confComps.defaultValue.paginationConfigForServer);
                                            }}
                                        />
                                    </Grid>

                                )
                            })}
                        </Grid>
                    </InfiniteScrollList>

                </Box>



            </ParentWithHeightAnimation>
            {/* {!getIsActiveCreateCashAccount &&
                
            } */}
            {/* {getIsActiveCreateCashAccount && */}

            <ParentWithHeightAnimation in={getIsActiveCreateCashAccount} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                <CreateCashAccounts
                    userInfoBasic={paramsComponent.userInfoBasic}
                    callbackCancel={() => {
                        setIsActiveCreateCashAccount(false);
                        setSelectedCashAccount(undefined);
                        setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                        callApiForGetCashAccounts(confComps.defaultValue.paginationConfigForServer);
                    }}
                    cashAccountInformation={getSelectedCashAccount}
                />

            </ParentWithHeightAnimation>
            {!getIsActiveCreateCashAccount &&
                <div style={{
                    display: 'flex', flexDirection: "row", position: "sticky", bottom: "1vh",
                    alignItems: "center", justifyContent: "end", width: "100%"
                }}>
                    <ButtonSave text={confComps.translate.addNewCashAccount}
                        callbackOnClick={() => {
                            setIsActiveCreateCashAccount(true);
                        }}
                    />
                </div>

            }
        </div>
    );
};
export default CashAccounts;
