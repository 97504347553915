import { useEffect, useState } from "react";
import { InterfaceProgressBarGradient } from "./InterfaceProgressBarGradient";

function ProgressBarGradient(paramsComponent: InterfaceProgressBarGradient) {
    const [getPercentage, setPercentage] = useState<number>(0);
    const getColor = () => {
        if (getPercentage < 50) return '#81C784'; // Green
        if (getPercentage < 70) return '#FFD54F'; // Yellow
        return '#FFB74D'; // Orange
    };
    useEffect(() => {
        let start = 0;
        const duration = 30; // duration of the animation in ms
        // const increment = (paramsComponent.percentage - start) / duration;
        // let currentPercentage = start;

        // const animateProgress = () => {
        //     currentPercentage += increment;
        //     if (currentPercentage >= paramsComponent.percentage) {
        //         currentPercentage = paramsComponent.percentage;
        //         setPercentage(currentPercentage);
        //     } else {
        //         setPercentage(Math.floor(currentPercentage));
        //         requestAnimationFrame(animateProgress);
        //     }
        // };

        // requestAnimationFrame(animateProgress);
        setPercentage(paramsComponent.percentage);
    }, [paramsComponent.percentage])

    return (
        <div style={{ width: 'min(100%, 22rem)', marginTop: "-1rem" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <a
                    href={paramsComponent.label.link}
                    style={{ textDecoration: 'none', color: 'inherit', fontSize: "0.9rem" }}
                >
                    {paramsComponent.label.title}
                </a>
                <span style={{fontSize: "0.9rem"}}>{getPercentage}%</span>
            </div>
            <div style={{ width: '100%', backgroundColor: '#eee', borderRadius: '100px' }}>
                <div
                    style={{
                        width: `${getPercentage}%`,
                        background: `linear-gradient(to right, #81C784, #FFD54F, #FFB74D)`,
                        borderRadius: '8px',
                        height: '0.4rem',
                        transition: 'width 1s ease-in-out',
                    }}
                />
            </div>
        </div>
    );
}
export default ProgressBarGradient;