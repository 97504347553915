import { useTheme } from "@mui/material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { useEffect, useState } from "react";
import { InterfaceDialogAttachDetailOfFile } from "./InterfaceDialogAttachDetailOfFile";
import { DialogFromMUI } from "../../../MUIConfig/DialogFromMUI";
import TextFieldSimple from "../../../basic/TextField/TextFieldSimple/TextFieldSimple";
import TextFieldWithIconBox from "../../../basic/TextField/TextFieldWithIconBox/TextFieldWithIconBox";
import ButtonSave from "../../../basic/Buttons/ButtonSave/ButtonSave";
import Colors from "../../../../config/Colors";

export const DialogAttachDetailOfFile = (paramsDialog: InterfaceDialogAttachDetailOfFile) => {
    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getOpen, setOpen] = useState<boolean>(false);
    const [getTitle, setTitle] = useState<string>(paramsDialog.file.title);
    const [getDescription, setDescription] = useState<string>(paramsDialog.file.description);
    useEffect(() => {
        setOpen(paramsDialog.isOpen);
        setTitle(paramsDialog.file.title);
        setDescription(paramsDialog.file.description);
    }, [paramsDialog])
    const handleClose = async () => {
        // setOpen(false);
        paramsDialog.callbackOnCloseDialog();
    };


    const handleOnclickCancelDialog = () => {
        paramsDialog.callbackOnCloseDialog();
    }



    return (
        <DialogFromMUI onClose={handleClose} open={getOpen} dir={mTheme.direction}
        // fullWidth
        >
            <div style={{
                display: "flex", flexDirection: "column",
                justifyContent: "space-between", alignItems: "start",
                gap: "1rem",
                paddingInline: "2rem",
                paddingBlock: "2.3rem",
            }}>
                <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "end" }}>
                    <h3 style={{ fontFamily: confComps.font.poppins.SemiBold, color: Colors.theme.blue.blue }}>
                        Set Details for File
                    </h3>
                </div>

                <TextFieldSimple title={confComps.translate.title} textValue={getTitle}
                    onChangeValue={(newText: string) => {
                        setTitle(newText);
                    }}
                />
                <TextFieldSimple title={confComps.translate.description} textValue={getDescription}
                    onChangeValue={(newText: string) => {
                        setDescription(newText);
                    }}
                />

                <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "center", width: "100%" }}>
                    <ButtonSave text={confComps.translate.save}
                        callbackOnClick={() => {
                            paramsDialog.callbackSave({
                                ...paramsDialog.file, title: getTitle,
                                description: getDescription,
                            });
                        }}
                    />

                </div>

            </div>

        </DialogFromMUI>
    );
}
