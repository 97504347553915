import { AddIcon } from "../../../../assets/icons/svg/AddIcon";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { InterfaceAddButtonWithShadowEffect } from "./InterfaceAddButtonWithShadowEffect";

function AddButtonWithShadowEffect(paramsComponent: InterfaceAddButtonWithShadowEffect) {
    var confComps = ConfigComps();
    return (
        <div style={{
            display: "flex", flexDirection: "row",
            alignItems: "center", justifyContent: "center",
            gap: "0.24rem",
            borderRadius: "1.2rem",
            border: `1px solid ${Colors.theme.blue.blue}`,
            background: "white",
            flexGrow: "0",
            paddingInline: "0.96rem",
            paddingBlock: "0.96rem",
            boxShadow: Colors.shadow.shadowEditTextBoxClicked,
            cursor: "pointer",
            userSelect: "none",
            ...paramsComponent.styleMainDiv,
        }}
            onClick={paramsComponent.callbackOnclick}>
            <AddIcon />
            <p style={{
                font: confComps.font.poppins.Medium,
                fontSize: "1.12rem",
                lineHeight: "1.28rem",
                userSelect: "none",
            }}>
                {paramsComponent.text}
            </p>

        </div>
    )
}
export default AddButtonWithShadowEffect;