import { Box, Grid, styled, useTheme } from "@mui/material";
import Colors from "../../../../../../../../config/Colors";
import KeyTitleH6 from "../../../../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import { useDispatch, useSelector } from "react-redux";
import { ConfigComps } from "../../../../../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../../../../../store/profileStateSlice";
import ValueP from "../../../../../../../../components/basic/Texts/Titles/ValueP/ValueP";
import { Person2Rounded } from "@mui/icons-material";
import { TrashIcon } from "../../../../../../../../assets/icons/svg/TrashIcon";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../../api/API";
import { isVisibilityProgressBar } from "../../../../../../../../store/ProgressbarInRootSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { InterfaceRowChangeRate } from "./InterfaceRowChangeRate";
import { ImageSquare } from "../../../../../../../../components/complex/imageSquare/ImageSquare";
import dayjs from "dayjs";
import { EditPenIcon } from "../../../../../../../../assets/icons/svg/EditPenIcon";
import ShowCurrencyLocationWithValue from "../../../../../../../../components/basic/ShowCurrencyLocationWithValue/ShowCurrencyLocationWithValue";
import { XELogoIcon } from "../../../../../../../../assets/icons/svg/XELogoIcon";
import { AddIcon } from "../../../../../../../../assets/icons/svg/AddIcon";
import { ExchangeMoneyIcon } from "../../../../../../../../assets/icons/svg/ExchangeMoneyIcon";
import { LongArrowRight } from "../../../../../../../../assets/icons/svg/LongArrowRight";
const TagOfUser = styled("p")(({ theme }) => ({
    boxSizing: "border-box",
    fontSize: "0.96rem",
    lineHeight: "1.44rem",
    marginInline: "0.4rem",
}));

function RowChangeRate(paramsComponent: InterfaceRowChangeRate) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const callApiForDeleteChangeRate = async (idRecommended: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.rate.changeRates.deleteTheChangeRate;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idRecommended}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackReloadPage();
                dispatch(ShowAlert({ text: "Removed the change rate.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <Box sx={{
            width: "100%",
            border: `1px solid ${Colors.theme.blue.border}`,
            paddingInline: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
            paddingBlock: { xs: 0.5, sm: 1, md: 1, lg: 2, xl: 2 },
            boxSizing: "border-box",
            borderRadius: "0.8rem",
        }}>
            <Box sx={{
                paddingInlineEnd: { xs: 0, sm: 2, md: 4, lg: 5, xl: 6 },
            }}>
                <div style={{
                    display: "flex", flexDirection: "row", alignItems: "stretch",
                    justifyContent: "center",
                    width: "100%",
                    boxSizing: "border-box",
                    flexWrap: "wrap",
                    gap: "2rem",

                }}>
                    <ShowCurrencyLocationWithValue
                        currency={paramsComponent.from_currency_unit}
                        value={"1"}
                        style={{ alignItems: "stretch", marginBlock: "0.5rem", boxSizing: "border-box" }} />
                    <div
                        style={{
                            display: "flex", flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.4rem",
                        }}
                    >
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "0.3rem",
                            minWidth: "4rem",
                        }}>
                            {paramsComponent.use_xe && <XELogoIcon />}
                            {paramsComponent.use_xe && <AddIcon />}
                            {paramsComponent.use_xe && paramsComponent.add_to_xe}
                            {!paramsComponent.use_xe && paramsComponent.rate && paramsComponent.rate}
                        </div>
                        <div style={{ position: "relative" }}>
                            <div style={{
                                position: "absolute", width: "100%", zIndex: "-1", bottom: "0%", left: "-50%",
                                transform: 'translate(-60%, -20%)',
                            }}>
                                <LongArrowRight />
                            </div>
                            <div style={{ background: "white" }}>
                                <ExchangeMoneyIcon />
                            </div>

                        </div>
                    </div>
                    <ShowCurrencyLocationWithValue
                        currency={paramsComponent.to_currency_unit}
                        value={"1"}
                        style={{ alignItems: "stretch", marginBlock: "0.5rem", boxSizing: "border-box" }}
                    />



                </div>


            </Box>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end", width: "100%", gap: "0.5rem" }}>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                    onClick={paramsComponent.callbackClickOnEdit}
                >
                    <EditPenIcon style={{
                        background: Colors.theme.blue.light,
                        width: "0.75rem",
                        height: "0.75rem",
                        cursor: "pointer"
                    }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                    onClick={() => { callApiForDeleteChangeRate(paramsComponent.id) }}
                >
                    <TrashIcon />
                </div>

            </div>




        </Box >
    );
}
export default RowChangeRate;