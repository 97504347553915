import Colors from "../../../config/Colors";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { InterfaceContainerPage } from "./InterfaceContainerPage";

function ContainerPage(componentsParams: InterfaceContainerPage) {
    var confComps = ConfigComps();
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: '1',
            flexBasis: '0',
            alignItems: "start",
            justifyContent: "start",
            boxSizing: "border-box",
            gap: "24px",
            paddingInline: "1.7rem",
            paddingBottom: "2.3rem",
            // background: "red",
        }}>
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                boxSizing: "border-box",
            }}>
                <h1 style={{
                    lineHeight: "3.5rem",
                    fontSize: "2.37rem",
                    fontFamily: confComps.font.poppins.Medium,
                    color: Colors.theme.blue.dark,
                }}>
                    {componentsParams.title}
                </h1>
            </div>
            <div style={{
                width: '100%',
                gap: "24px",
                display: "flex",
                flexDirection: "column",
                flexGrow: '1',
                flexBasis: '0',
                alignItems: "start",
                justifyContent: "start",
                boxSizing: "border-box",
                opacity: componentsParams.isLoading ? 0.5 : 1,
            }}>
                {componentsParams.children}
            </div>
        </div>
    );
}
export default ContainerPage;