
import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const DescDownIcon: React.FC<InterfaceIconSVG> = ({ style = { width: "20", height: "20" } }) => {
    return (

        <svg height="20" version="1.1" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" >
            <g id="layer1">
                <path d="M 3 0 L 3 18 L 0 15 L 0 16.5 L 3.5 20 L 7 16.5 L 7 15 L 4 18 L 4 0 L 3 0 z M 10 1 L 10 2 L 13.859375 2 L 10 6.8242188 L 10 8 L 15 8 L 15 7 L 11.140625 7 L 15 2.1757812 L 15 1 L 10 1 z M 11.638672 11 L 9.5253906 17.341797 L 10.474609 17.658203 L 11.027344 16 L 13.972656 16 L 14.525391 17.658203 L 15.474609 17.341797 L 13.359375 11 L 11.638672 11 z M 12.359375 12 L 12.638672 12 L 13.638672 15 L 11.359375 15 L 12.359375 12 z "
                    // style="fill:#222222; fill-opacity:1; stroke:none; stroke-width:0px;"
                    fill={style?.color ?? "#222222"}
                    strokeWidth={0}
                />
            </g>
        </svg>
    )
};