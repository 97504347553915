import { Box } from "@mui/material";
import { InterfaceBoxRecap } from "./InterfaceBoxRecap";
import Colors from "../../../../config/Colors";

function BoxRecap(paramsComponent: InterfaceBoxRecap) {
    return (
        <Box sx={{
            border: `1px solid ${Colors.theme.blue.border}`,
            borderRadius: "0.74rem",
            paddingBlock: "1.48rem",
            paddingInline: "1.77rem",
            width: '100%',
            boxSizing:'border-box',
            ...paramsComponent.style,
        }}>
            {paramsComponent.children}
        </Box>

    )
};
export default BoxRecap;