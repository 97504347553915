import { useEffect, useState } from "react";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { EnumStateStepGroup } from "../../../basic/ArrowStep/InterfaceArrowStep";
import { InterfaceSteperGuideForFreestyle } from "./InterfaceSteperGuideForFreestyle";
import OneStep from "../SteperGroup/OneStep/OneStep";
import SteperGroup from "../SteperGroup/SteperGroup";
import Colors from "../../../../config/Colors";
import { InterfaceNTTWorkFlowMainRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/InterfaceNTTWorkFlowMainRecap";
import { StepNameMappingToKeyStepForMoneyReceiveRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap";
import { StepNameMappingToKeyStepForSellCottageRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/SellCottage/InterfaceNTTOneStepOfWorkflowSellCottageRecap";
import { StepNameMappingToKeyStepForBuyCottageRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/BuyCottage/InterfaceNTTOneStepOfWorkflowBuyCottageRecap";
import { StepNameMappingToKeyStepForMoneyPaymentRecap } from "../../../../NTTs/Recap/WorkflowMainRecap/MoneyPayment/InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap";
import { InterfaceNTTRecapMain } from "../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { EnumStateValueOfFreestyle, InterfaceNTTFreestyleMain } from "../../../../NTTs/Freestyle/InterfaceNTTFreestyleMain";
import { EnumSTepsParent, EnumStepsFreestyle } from "../../../../config/enums/EnumSteps";
export function isTwoKeyHasSameParent(key1: string, key2: string) {
    let parents1 = key1.split(".");
    let parents2 = key2.split(".");
    let lengthThatMustReview = Math.min(parents1.length, parents2.length);

    for (let i = 0; i < (lengthThatMustReview - 1); i++) {
        if (parents1[i] != parents2[i]) {
            return false;
        }
    }
    return true;

}
export function getKeyOfParentOfKey(key1: string) {
    if (!key1.includes(".")) {
        return "";
    }
    let parents1 = key1.split(".");
    parents1.pop();
    return parents1.join(".");





}
export function isKey1BiggerThanKey2(key1: string, key2: string) {
    let parents1 = key1.split(".");
    let parents2 = key2.split(".");
    let maxLength = Math.max(parents1.length, parents2.length);
    const paddedList1 = parents1.concat(Array(maxLength - parents1.length).fill("0"));
    const paddedList2 = parents2.concat(Array(maxLength - parents2.length).fill("0"));
    if (key1 === key2) {
        return false;
    }
    for (let i = 0; i < paddedList1.length; i++) {
        if (+(`${paddedList1[i]}`) < +(`${paddedList2[i]}`)) {
            return false;
        }
        else if (+(`${paddedList1[i]}`) > +(`${paddedList2[i]}`)) {
            return true;
        }
    }
    return true;
}
function SteperGuideForFreestyle(paramsComponent: InterfaceSteperGuideForFreestyle) {
    var confComps = ConfigComps();
    const [getKeyOfOneStepThatSelected, setKeyOfOneStepThatSelected] = useState<string>(paramsComponent.keyOfOneStepThatSelected);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>(paramsComponent.keyOfGroupThatSelected);
    const [getKeysStepThatShowStateServices, setKeysStepThatShowStateServices] = useState<string>("1.1");
    const findMaxStepThatCompleted = (freestyle: InterfaceNTTFreestyleMain) => {
        if (freestyle.state.value === EnumStateValueOfFreestyle.created) {
            setKeysStepThatShowStateServices(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.selectRecap}`)
        }
        else if (freestyle.state.value === EnumStateValueOfFreestyle.pendingForCheckByManager) {
            setKeysStepThatShowStateServices(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.addSteps}`)
        }
        else if (freestyle.state.value === EnumStateValueOfFreestyle.approvedByManager) {
            setKeysStepThatShowStateServices(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.approval}`)
        }
        else if (freestyle.state.value === EnumStateValueOfFreestyle.completed) {
            setKeysStepThatShowStateServices(`${EnumSTepsParent.freestyle}.${EnumStepsFreestyle.uploadDocumentsForSteps}`)
        }


    }
    useEffect(() => {
        if (paramsComponent.freestyle) {
            findMaxStepThatCompleted(paramsComponent.freestyle);
        }
    }, [paramsComponent.freestyle])
    useEffect(() => {
        setKeyOfOneStepThatSelected(paramsComponent.keyOfOneStepThatSelected);
    }, [paramsComponent.keyOfOneStepThatSelected])
    useEffect(() => {
        setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected);
    }, [paramsComponent.keyOfGroupThatSelected])
    return (
        <div style={{
            display: "inline-flex",
            position: "relative",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flexGrow: 0,
            height: "calc(4rem + 4px)",
            borderTop: `2px solid ${Colors.theme.blue.light}`,
            borderBottom: `2px solid ${Colors.theme.blue.light}`,
            borderInlineStart: `2px solid ${Colors.theme.blue.light}`,
            borderStartStartRadius: "0.37rem",
            borderEndStartRadius: "0.37rem",
            boxSizing: "border-box",
        }}>
            {paramsComponent.steps.map((steps, index, arr) => {
                if (steps.steps.length === 0) {
                    return (
                        <OneStep
                            callbackOnClick={function (): void {
                                paramsComponent.callbackOnClickOneStep(steps.keyStep);
                                paramsComponent.callbackOnClickOneGroup("1");
                            }}
                            keyOfOneStepThatSelected={getKeyOfOneStepThatSelected}
                            keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                            keyOfStepThatLastCompleted={{
                                lastKeyStepThatAllServiceCompleted: getKeysStepThatShowStateServices,
                                maxStepThatCompleted: getKeysStepThatShowStateServices
                            }}
                            {...steps}
                        />
                    )
                }
                else {
                    return (
                        <SteperGroup
                            {...steps}
                            callbackOnClickItem={(keySelected) => {
                                paramsComponent.callbackOnClickOneStep(keySelected);
                            }}
                            callbackOnClickGroup={(keySelected) => {
                                paramsComponent.callbackOnClickOneGroup(keySelected);
                            }}
                            keyOfOneStepThatSelected={getKeyOfOneStepThatSelected}
                            keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                            keyOfStepThatLastCompleted={{
                                lastKeyStepThatAllServiceCompleted: getKeysStepThatShowStateServices,
                                maxStepThatCompleted: getKeysStepThatShowStateServices
                            }}
                        />
                    )

                }
            })
            }

        </div>
    )
};
export default SteperGuideForFreestyle;