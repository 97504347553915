import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ConfigComps } from "../../../config/ConfigCOMP";
import Colors from "../../../config/Colors";
import { keyframes, styled, useTheme } from "@mui/material";
import { SyncRounded } from "@mui/icons-material";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { RequestHandler } from "../../../api/API";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../MUIConfig/alert_popup/interfaceAlertPopup";
import LineSeparator from "../../basic/LineSeprator/LineSeparator";
import { InterfaceNTTCity } from "../../../NTTs/InterfaceNTTCity";
import { InterfaceShowCurrencyLocationWithValue } from "./InterfaceShowCurrencyLocationWithValue";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../NTTs/InterfaceNTTCurrency";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row", alignItems: "center", justifyContent: "start",
    fontSize: "1rem",
    width: "100%",
    paddingInline: "0.5rem",
    boxSizing: "border-box",
}));
const TagOfUser = styled("p")(({ theme }) => ({
    boxSizing: "border-box",
    fontSize: "1.12rem",
    lineHeight: "1.6rem",
    marginInlineStart: "0.5rem",
    color: Colors.theme.orange.light,

}));
const VerticalSeparator = styled("p")(({ theme }) => ({
    width: "1px",
    height: "1rem",
    boxSizing: "border-box",
    marginInline: "0.2rem",
    background: Colors.theme.blue.verticalSeparator,
}));



export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#fff",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
function ShowCurrencyLocationWithValue(paramsComponent: InterfaceShowCurrencyLocationWithValue) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);


    const [getSelectedCurrency, setSelectedCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getValue, setValue] = useState<string>("");










    useEffect(() => {
        setValue(paramsComponent.value);
        setSelectedCurrency(paramsComponent.currency)

    }, [paramsComponent]);


    return (
        <div
            style={{
                display: 'flex', flexDirection: 'column', position: "relative",
                ...paramsComponent.style
            }}
        >
            {paramsComponent.title && < label style={{
                fontWeight: "300",

                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: Colors.theme.blue.light,
                marginBottom: '0.22rem'
            }}>
                {paramsComponent.title}
            </label>}
            <div
                style={{
                    flexGrow: "1",
                    background: "#F3F7FD",
                    width: "100%",
                    border: `1px solid ${Colors.theme.blue.light}`,
                    borderRadius: '0.8rem',
                    boxSizing: "border-box",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    overflow: "hidden",
                    justifyContent: "space-between",
                }}

            >
                {getSelectedCurrency &&
                    <div>
                        <RowOfList style={{ justifyContent: "space-between" }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                fontSize: "1rem",
                                boxSizing: "border-box",
                            }}>
                                {getSelectedCurrency.is_cash ? confComps.translate.cash : confComps.translate.noneCash}
                                <VerticalSeparator />
                                {getSelectedCurrency.country.name}
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "start",
                                marginInlineStart: "2rem",
                                color: Colors.theme.blue.Secondary,
                            }}>
                                {getValue}
                                {" "}
                                {getSelectedCurrency.currency.symbol}
                            </div>
                        </RowOfList>
                    </div>
                }
            </div>
        </div >

    );
}
export default ShowCurrencyLocationWithValue;