import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const ExchangeMoneyIcon: React.FC<InterfaceIconSVG> = ({ style = { width: "28", height: "21" } }) => {
    return (
        <svg width={style.width} height={style.height} viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.4169 2.62532L23.4062 1.68196C22.995 1.29641 22.995 0.672976 23.4062 0.29153C23.8175 -0.089915 24.4825 -0.0940165 24.8894 0.29153L27.6894 2.91653C27.8862 3.1011 27.9956 3.3513 27.9956 3.6138C27.9956 3.8763 27.8862 4.12649 27.6894 4.31106L24.8894 6.93606C24.4781 7.32161 23.8131 7.32161 23.4062 6.93606C22.9994 6.55052 22.995 5.92708 23.4062 5.54563L24.4125 4.60227H21.5862H7V5.25852C7 6.70637 5.74437 7.88352 4.2 7.88352H3.5V11.7185L2.21812 12.9079L1.4 13.679V5.25032C1.4 3.80247 2.65562 2.62532 4.2 2.62532H16.7825H16.8H24.4169ZM24.5 13.1253V9.29036L25.7819 8.0886L26.6 7.32161V15.7503C26.6 17.1982 25.3444 18.3753 23.8 18.3753H6.40937H3.58312L4.58937 19.3187C5.00062 19.7042 5.00062 20.3277 4.58937 20.7091C4.17812 21.0906 3.51312 21.0947 3.10625 20.7091L0.30625 18.0882C0.109375 17.9036 0 17.6534 0 17.3909C0 17.1284 0.109375 16.8783 0.30625 16.6937L3.10625 14.0687C3.5175 13.6831 4.1825 13.6831 4.58937 14.0687C4.99625 14.4542 5.00062 15.0777 4.58937 15.4591L3.58312 16.4025L11.2 16.4066C11.2044 16.4066 11.2131 16.4066 11.2175 16.4066H21V15.7503C21 14.3025 22.2556 13.1253 23.8 13.1253H24.5ZM14 6.56282C15.1139 6.56282 16.1822 6.97766 16.9698 7.71609C17.7575 8.45451 18.2 9.45603 18.2 10.5003C18.2 11.5446 17.7575 12.5461 16.9698 13.2846C16.1822 14.023 15.1139 14.4378 14 14.4378C12.8861 14.4378 11.8178 14.023 11.0302 13.2846C10.2425 12.5461 9.8 11.5446 9.8 10.5003C9.8 9.45603 10.2425 8.45451 11.0302 7.71609C11.8178 6.97766 12.8861 6.56282 14 6.56282Z"
                fill={style.color ?? "#22357E"} />
        </svg>
    )
}
