import { DoneRounded, CircleOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TitleMedium16 from '../../../../components/basic/Texts/Titles/TitleMedium16/TitleMedium16';
import BoxRecap from '../../../../components/basic/‌Box/BoxRecap/BoxRecap';
import Colors from '../../../../config/Colors';
import { ConfigComps } from '../../../../config/ConfigCOMP';
import { SelectedProfile } from '../../../../store/profileStateSlice';
import { InterfaceParentRecapSellCottage } from './InterfaceParentRecapSellCottage';
import { InterfaceNTTRecapMain } from '../../../../NTTs/Recap/InterfaceNTTRecapMain';
import { InterfaceNTTRecapSellCottageService } from '../../../../NTTs/Recap/InterfaceNTTRecapSellCottageService';
import ControllerInitiateSellCottage from '../StepersBox/SellCottage/InitiateSellCottage/ControllerInitiateSellCottage';
import { InterfaceNTTOneServiceWorkflowSellCottageRecap } from '../../../../NTTs/Recap/WorkflowMainRecap/SellCottage/InterfaceNTTOneServiceWorkflowSellCottageRecap';
import { isKey1BiggerThanKey2 } from '../../../../components/complex/SteperGuide/SteperGuide';
import { EnumSTepsParent, EnumSTepsRecap } from '../../../../config/enums/EnumSteps';
import { StepNameMappingToKeyStepForSellCottageRecap, EnumRecapServiceStepNameInServerForSellCottage } from '../../../../NTTs/Recap/WorkflowMainRecap/SellCottage/InterfaceNTTOneStepOfWorkflowSellCottageRecap';
import ControllerAddSolutionForSellCottage from '../StepersBox/SellCottage/AddSolutionForSellCottage/ControllerAddSolutionForSellCottage';
import ViewAddSolutionForSellCottage from '../StepersBox/SellCottage/AddSolutionForSellCottage/ViewAddSolutionForSellCottage/ViewAddSolutionForSellCottage';
import CreateApproveSolutionByManagerForSellCottage from '../StepersBox/SellCottage/ApproveSolutionByManagerForSellCottage/CreateApproveSolutionByManagerForSellCottage/CreateApproveSolutionByManagerForSellCottage';
import ViewApproveSolutionByManagerForSellCottage from '../StepersBox/SellCottage/ApproveSolutionByManagerForSellCottage/ViewApproveSolutionByManagerForSellCottage/ViewApproveSolutionByManagerForSellCottage';
import ControllerCheckWalletCustomerOfSellCottage from '../StepersBox/SellCottage/CheckWalletCustomerOfSellCottage/ControllerCheckWalletCustomerOfSellCottage';
import CreateChooseOneSolutionForSellCottage from '../StepersBox/SellCottage/ChooseOneSolutionForSellCottage/CreateChooseOneSolutionForSellCottage/CreateChooseOneSolutionForSellCottage';
import ViewChooseOneSolutionForSellCottage from '../StepersBox/SellCottage/ChooseOneSolutionForSellCottage/ViewChooseOneSolutionForSellCottage/ViewChooseOneSolutionForSellCottage';
import ControllerRiskMeasurementCustomerOfSellCottage from '../StepersBox/SellCottage/RiskMeasurementCustomerOfSellCottage/ControllerRiskMeasurementCustomerOfSellCottage';
import ViewCheckByTrustiesOfSolutionsOfSellCottage from '../StepersBox/SellCottage/ViewCheckByTrustiesOfSolutionsOfSellCottage/ViewCheckByTrustiesOfSolutionsOfSellCottage';
import ViewCheckRiskOfSolutionsOfSellCottage from '../StepersBox/SellCottage/ViewCheckRiskOfSolutionsOfSellCottage/ViewCheckRiskOfSolutionsOfSellCottage';
import ViewCheckWalletsTrustiesOfSellCottage from '../StepersBox/SellCottage/ViewCheckWalletsTrustiesOfSellCottage/ViewCheckWalletsTrustiesOfSellCottage';
function ParentRecapSellCottage(paramsComponent: InterfaceParentRecapSellCottage) {
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>('1');
    const [getIsCreateSellCottageActive, setIsCreateSellCottageActive] = useState<boolean>(false);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getInfoServiceInMainRecap, setInfoServiceInMainRecap] = useState<InterfaceNTTRecapSellCottageService>();
    const [getInfoWorkFlowService, setInfoWorkFlowService] = useState<InterfaceNTTOneServiceWorkflowSellCottageRecap>();
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setInfoServiceInMainRecap(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setInfoWorkFlowService(paramsComponent.infoWorkFlowService);
    }, [paramsComponent.infoWorkFlowService])


    return (
        <BoxRecap>
            <div
                style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'space-between',
                    boxSizing: 'border-box',
                }}
            >
                <TitleMedium16>
                    {confComps.translate.sellCottage}
                </TitleMedium16>
                {getKeyOfStepThatSelected === '1.1' &&
                    <div
                        style={{
                            cursor: 'pointer',
                            display: 'inline-flex',
                        }}
                        onClick={() => {
                            if ((getRecapMain?.sell_cottages ?? []).length === 0)
                                setIsCreateSellCottageActive(prev => !prev)
                        }}
                    >
                        {getIsCreateSellCottageActive ?
                            <DoneRounded style={{
                                color: '#fff', background: Colors.theme.blue.blue,
                                borderRadius: '500px', fontSize: '1.5rem',
                                padding: '0.15rem',
                            }} /> :
                            <CircleOutlined style={{ color: Colors.theme.blue.blue, fontSize: '1.8rem' }} />
                        }
                    </div>
                }
            </div>
            <ControllerInitiateSellCottage
                infoServiceInMainRecap={getInfoServiceInMainRecap}
                callBackCreateMainRecap={paramsComponent.callBackCreateMainRecap}
                recapMain={getRecapMain}
                isCreateSellCottageActive={getIsCreateSellCottageActive}
                stepThatSelected={getKeyOfStepThatSelected}
                callBackReLoad={() => {
                    console.log('callback reload caled:)')
                    paramsComponent.callBackReLoad();
                }}
            />
            {getInfoServiceInMainRecap &&
                getInfoWorkFlowService?.workflow_reviews
                    .filter(step => isKey1BiggerThanKey2(getKeyOfStepThatSelected, StepNameMappingToKeyStepForSellCottageRecap[step.review_ct])
                        || getKeyOfStepThatSelected === StepNameMappingToKeyStepForSellCottageRecap[step.review_ct])//check that view by step that clicked in top guide
                    // .sort((a, b) => {
                    //     if (a.review_ct === EnumRecapServiceStepNameInServerForSellCottage.addSolution) {
                    //         return 0.5;
                    //     }
                    //     else if (b.review_ct === EnumRecapServiceStepNameInServerForSellCottage.addSolution) {
                    //         return -0.5;
                    //     }
                    //     return a.created_at - b.created_at;
                    // })
                    .map((oneStep, index, arr) => {
                        if (oneStep.review_ct === EnumRecapServiceStepNameInServerForSellCottage.fundControlCheckWalletCustomer) {
                            return (
                                <ControllerCheckWalletCustomerOfSellCottage
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    recapMain={getRecapMain}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    callBackReLoad={() => {
                                        paramsComponent.callBackReLoad();
                                    }}
                                    StepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForSellCottage.checkRisk) {
                            return (
                                <ControllerRiskMeasurementCustomerOfSellCottage
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    StepWorkFlow={oneStep}
                                    callBackReLoad={() => {
                                        paramsComponent.callBackReLoad();
                                    }}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForSellCottage.addSolution) {
                            return (
                                <ViewAddSolutionForSellCottage
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                                    callBackReLoad={function (): void {
                                        paramsComponent.callBackReLoad();
                                    }}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForSellCottage.checkRiskOfSolution) {
                            return (
                                <ViewCheckRiskOfSolutionsOfSellCottage
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForSellCottage.checkWalletOfTrustByFinanceForSolution) {
                            return (
                                <ViewCheckWalletsTrustiesOfSellCottage
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForSellCottage.checkSolutionByTrusties) {
                            return (
                                <ViewCheckByTrustiesOfSolutionsOfSellCottage
                                    recapMain={getRecapMain}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForSellCottage.chooseOneSolution) {
                            return (
                                <ViewChooseOneSolutionForSellCottage
                                    recapMain={getRecapMain}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }
                        else if (oneStep.review_ct === EnumRecapServiceStepNameInServerForSellCottage.approveSolution) {
                            return (
                                <ViewApproveSolutionByManagerForSellCottage
                                    recapMain={getRecapMain}
                                    stepThatSelected={getKeyOfStepThatSelected}
                                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                                    thisStepWorkFlow={oneStep}
                                />
                            )
                        }

                    })
            }

            {/* step that now must be fill */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.checkWalletOfTheRequesterByFundControl}` && // step guide click this step
                StepNameMappingToKeyStepForSellCottageRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForSellCottage.undefined }).review_ct
                ] === StepNameMappingToKeyStepForSellCottageRecap[EnumRecapServiceStepNameInServerForSellCottage.undefined]
                &&
                <ControllerCheckWalletCustomerOfSellCottage
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    recapMain={getRecapMain}
                    stepThatSelected={getKeyOfStepThatSelected}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    StepWorkFlow={undefined}
                />
            }
            {/* fill the risk measurement of customer that selected at first step */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}` && // step guide click this step
                StepNameMappingToKeyStepForSellCottageRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForSellCottage.undefined }).review_ct
                ] === StepNameMappingToKeyStepForSellCottageRecap[EnumRecapServiceStepNameInServerForSellCottage.fundControlCheckWalletCustomer]//the last step that completed must the previous step
                &&
                <ControllerRiskMeasurementCustomerOfSellCottage
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={undefined}
                />
            }

            {getInfoServiceInMainRecap &&
                isKey1BiggerThanKey2(getKeyOfStepThatSelected, `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.0`) && //check step that selected in guide line
                isKey1BiggerThanKey2(`${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions + 1}.-1`, getKeyOfStepThatSelected) &&
                (isKey1BiggerThanKey2(StepNameMappingToKeyStepForSellCottageRecap[
                    (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForSellCottage.undefined }).review_ct
                ], `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.0`) ||
                    StepNameMappingToKeyStepForSellCottageRecap[
                    (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForSellCottage.undefined }).review_ct
                    ] === `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}`) &&//check last step that completed
                <ControllerAddSolutionForSellCottage
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={getInfoServiceInMainRecap &&
                        getInfoWorkFlowService?.workflow_reviews.filter(steps => steps.review_ct === EnumRecapServiceStepNameInServerForSellCottage.addSolution)[0]}
                />
            }
            {/* choose One of  solutions */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.chooseSolution}` && // step guide click this step
                StepNameMappingToKeyStepForSellCottageRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForSellCottage.undefined }).review_ct
                ] === StepNameMappingToKeyStepForSellCottageRecap[EnumRecapServiceStepNameInServerForSellCottage.checkSolutionByTrusties]//the last step that completed must the previous step
                &&
                <CreateChooseOneSolutionForSellCottage
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={getInfoServiceInMainRecap &&
                        getInfoWorkFlowService?.workflow_reviews.filter(steps => steps.review_ct === EnumRecapServiceStepNameInServerForSellCottage.addSolution)[0]}
                />
            }
            {/* Comment of manager */}
            {getInfoServiceInMainRecap &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.acceptByManager}` && // step guide click this step
                StepNameMappingToKeyStepForSellCottageRecap[
                (getInfoWorkFlowService?.workflow_reviews?.[getInfoWorkFlowService.workflow_reviews.length - 1] ?? { review_ct: EnumRecapServiceStepNameInServerForSellCottage.undefined }).review_ct
                ] === StepNameMappingToKeyStepForSellCottageRecap[EnumRecapServiceStepNameInServerForSellCottage.chooseOneSolution]//the last step that completed must the previous step
                &&
                <CreateApproveSolutionByManagerForSellCottage
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    stepThatSelected={getKeyOfStepThatSelected}
                    allWorkflowReviews={getInfoWorkFlowService?.workflow_reviews ?? []}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    StepWorkFlow={getInfoServiceInMainRecap &&
                        getInfoWorkFlowService?.workflow_reviews.filter(steps => steps.review_ct === EnumRecapServiceStepNameInServerForSellCottage.addSolution)[0]}
                />
            }

        </BoxRecap>
    )

}
export default ParentRecapSellCottage;