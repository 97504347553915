import zIndex from "@mui/material/styles/zIndex";
import { EnumStateStepGroup } from "../../../../../components/basic/ArrowStep/InterfaceArrowStep";
import SteperGroups from "../../../../../components/complex/SteperGuide/SteperGroup/SteperGroup";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { InterfaceChooseCustomer } from "./InterfaceChooseCustomer";
import Colors from "../../../../../config/Colors";

function ChooseCustomer(paramsPage: InterfaceChooseCustomer) {
    var confComps = ConfigComps();
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            flexGrow: '1',
            alignItems: "start",
            justifyContent: "start",
            boxSizing: "border-box",
            gap: "24px",
            // background: "red",
        }}>
            <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
            }}>
                <h1>{confComps.panel.services.receipt.header.title}</h1>
            </div>
            <div style={{
                width: "100%",
                height: "100px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "start",
                boxSizing: "border-box",
            }}>
                {/* <SteperGroups
                    whichGroupOpen={0}
                    steps={[
                        {
                            state: EnumStateStepGroup.completed,
                            title: "Recap",
                            isGroupFirst: true,
                            zIndexGroup: 3,
                            subGroupItems: [...confComps.panel.services.receipt.steps.subGroupItems.map((item, index, arr) => {
                                return ({
                                    groupNumber: 3,
                                    title: item.title,
                                    subStep: item.subStep,
                                    state: EnumStateStepGroup.completed,
                                    zIndex: (arr.length + 10) - index,
                                    isFirstBox: (index == 0)

                                })
                            })]
                        },
                        {
                            state: EnumStateStepGroup.completed,
                            title: "Project",
                            isGroupFirst: false,
                            zIndexGroup: 2,
                            subGroupItems: [...confComps.panel.services.receipt.steps.subGroupItems.map((item, index, arr) => {
                                return ({
                                    groupNumber: 2,
                                    title: item.title,
                                    subStep: item.subStep,
                                    state: EnumStateStepGroup.completed,
                                    zIndex: (arr.length + 10) - index,
                                    isFirstBox: (index == 0)

                                })
                            })]
                        },
                        {
                            state: EnumStateStepGroup.completed,
                            title: confComps.panel.services.receipt.steps.title,
                            isGroupFirst: false,
                            zIndexGroup: 1,
                            subGroupItems: [...confComps.panel.services.receipt.steps.subGroupItems.map((item, index, arr) => {
                                return ({
                                    groupNumber: 1,
                                    title: item.title,
                                    subStep: item.subStep,
                                    state: EnumStateStepGroup.completed,
                                    zIndex: (arr.length + 10) - index,

                                })
                            })]
                        }]} /> */}
            </div>
            <div style={{ width: "100%", flexGrow: "1" }}>

            </div>

        </div>
    )
}
export default ChooseCustomer;