import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const EditPenCircle: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill={"none"} xmlns="http://www.w3.org/2000/svg" style={{
            background: style?.background,
            borderRadius: style?.borderRadius,
            ...style,
        }}>
            <path d="M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.1812 4.36562L11.6313 4.81563C12.1188 5.30313 12.1188 6.09375 11.6313 6.58437L10.9625 7.25313L8.74375 5.03438L9.4125 4.36562C9.9 3.87812 10.6906 3.87812 11.1812 4.36562ZM4.74687 9.03125L8.03438 5.74375L10.2531 7.9625L6.96562 11.2469C6.8375 11.375 6.67812 11.4656 6.5 11.5094L4.62187 11.9781C4.45 12.0219 4.27187 11.9719 4.14687 11.8469C4.02187 11.7219 3.97188 11.5437 4.01562 11.3719L4.48438 9.49375C4.52812 9.31875 4.61875 9.15625 4.74687 9.02812V9.03125Z"
                fill={style?.color ? `${style?.color}` : "#22357E"}
            />
        </svg>
    )
}
