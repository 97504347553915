import axios from "axios";
import { SupportedMethods, urlsList } from "./UrlsList";
import { InitialProfilesState, SetTokenAndRefreshToken } from "../store/profileStateSlice";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Token } from "@mui/icons-material";
import { EnumSortingType, InterfaceItemOfSorting } from "../components/complex/SortingBox/InterfaceSortingBox";
import { InterfaceAdvanceBox, InterfaceAdvanceSearchParams } from "../components/complex/advanceSearchBox/InterfaceAdvanceSearchBox";
import { InterfaceSearchInServer } from "./interfaces/InterfaceSearchInServer";
import { group } from "console";
import { EnumTypeOfGroupInAdvanceSearchBox } from "../config/ConfigCOMP";



// import config from "../config/config";
// import { UserInfoProperties } from "../interfaces/auth/AuthLoginStructure";
// import { SupportedMethods, endpoints } from "./Endpoint";

/**
 * @class Api
 * @param {string} _token as private user JWT
 * @param {string} _refreshToken as alternative user JWT
 */

/**
 * Request Handler method
 * @param {string} path
 * @param {string} method
 * @param {object} data
 * @param {necessary} boolean
 * @returns Response of data or errors.
 */
export const ConvertSmartSearchItemToItemSearchForServer = (sortingFilter: InterfaceItemOfSorting[], advanceSearchObj: InterfaceAdvanceSearchParams): InterfaceSearchInServer => {
    let selectedGroup = {};
    if (advanceSearchObj.groupList.items.filter(group => group.isSelectedInFilter).length > 0) {
        // if (advanceSearchObj.groupList.config.typeKeyGroup === EnumTypeOfGroupInAdvanceSearchBox.string) {
        //     selectedGroup = {
        //         key: advanceSearchObj.groupList.items[0].key,//all key is similar:)
        //         value: advanceSearchObj.groupList.items.filter(group => group.isSelectedInFilter)[0].valueSelectedForServer,
        //         title: "",
        //     }
        // }
        if (advanceSearchObj.groupList.config.typeKeyGroup === EnumTypeOfGroupInAdvanceSearchBox.string) {
            selectedGroup = advanceSearchObj.groupList.items.filter(group => group.isSelectedInFilter).reduce((acc, stringItem) => {
                acc[stringItem.key] = stringItem.valueSelectedForServer as string;
                return acc;
            }, {} as { [key: string]: string });
        }
        else if (advanceSearchObj.groupList.config.typeKeyGroup === EnumTypeOfGroupInAdvanceSearchBox.boolean) {
            selectedGroup = advanceSearchObj.groupList.items.filter(group => group.isSelectedInFilter).reduce((acc, booleanItem) => {
                acc[booleanItem.key] = booleanItem.valueSelectedForServer as boolean;
                return acc;
            }, {} as { [key: string]: boolean });
        }

    }
    let advanceSearch = {
        smartSearch: advanceSearchObj.smartSearch,
        advanceParams: {
            relationIDsItem: [...advanceSearchObj.advanceBox.relationIDsItem, ...advanceSearchObj.groupList.items.flatMap(group => group.advanceBox.relationIDsItem)],
            dateItem: [...advanceSearchObj.advanceBox.dateItem, ...advanceSearchObj.groupList.items.flatMap(group => group.advanceBox.dateItem)],
            numbersItem: [...advanceSearchObj.advanceBox.numbersItem, ...advanceSearchObj.groupList.items.flatMap(group => group.advanceBox.numbersItem)],
            stringsItem: [...advanceSearchObj.advanceBox.stringsItem, ...advanceSearchObj.groupList.items.flatMap(group => group.advanceBox.stringsItem)],
            booleanItem: [...advanceSearchObj.advanceBox.booleanItem, ...advanceSearchObj.groupList.items.flatMap(group => group.advanceBox.booleanItem)],
        }
    } as {
        advanceParams: InterfaceAdvanceBox,
        smartSearch: string
    };
    let stringsObject = advanceSearch.advanceParams.stringsItem.filter(acc => acc.value.length > 0).reduce((acc, strItem) => {
        acc[strItem.key] = strItem.value;
        return acc;
    }, {} as { [key: string]: string });
    if (advanceSearchObj.groupList.config.typeKeyGroup === EnumTypeOfGroupInAdvanceSearchBox.string
        && (Object.keys(selectedGroup).length > 0)) {
        stringsObject = { ...stringsObject, ...selectedGroup };

    }
    let booleansObject = advanceSearch.advanceParams.booleanItem.filter(acc => acc.value !== undefined).reduce((acc, strItem) => {
        acc[strItem.key] = strItem.value ? true : false;
        return acc;
    }, {} as { [key: string]: boolean });
    if (advanceSearchObj.groupList.config.typeKeyGroup === EnumTypeOfGroupInAdvanceSearchBox.boolean
        && (Object.keys(selectedGroup).length > 0)) {
        booleansObject = { ...booleansObject, ...selectedGroup };

    }
    const relatedObject = advanceSearch.advanceParams.relationIDsItem.reduce((acc, relationItem) => {
        acc[relationItem.key] = relationItem.id;
        return acc;
    }, {} as { [key: string]: number[] });
    const DateObject = advanceSearch.advanceParams.dateItem.reduce((acc, dateItem) => {
        acc[dateItem.key] = {
            from: dateItem.start,
            to: dateItem.end,
        };
        return acc;
    }, {} as { [key: string]: { from?: number, to?: number } });
    const numberObject = advanceSearch.advanceParams.numbersItem.reduce((acc, dateItem) => {
        acc[dateItem.key] = {
            min: dateItem.min,
            max: dateItem.max,
        };
        return acc;
    }, {} as { [key: string]: { min?: string, max?: string } });

    return (
        {
            search: advanceSearch.smartSearch.split(" "),
            string: {
                ...stringsObject
            },
            related: {
                ...relatedObject
            },
            number: {
                ...numberObject,
            },
            date: {
                ...DateObject,
            },
            boolean: {
                ...booleansObject,
            },
            order_asc: sortingFilter.filter((sort) => sort.typeOrder === EnumSortingType.asc).map(sort => sort.key),
            order_desc: sortingFilter.filter((sort) => sort.typeOrder === EnumSortingType.desc).map(sort => sort.key),
        } as InterfaceSearchInServer);
}
export const RequestHandler = async (
    dispatch: Dispatch<AnyAction>,
    path: string = "",
    method: SupportedMethods,
    necessary: boolean,
    data?: object,
    selectorProfile?: InitialProfilesState,
    isFileInclude?: boolean,
    tokenOverWrite?: string
    // criterion?: (data : any) => boolean,// (default: data=>true)
    // exception: instanceof Error, // default=Error
) => {
    let userData = selectorProfile;
    if (necessary && tokenOverWrite == undefined) {
        if (userData == undefined) {
            throw Error("why the userData is null :(");
        }
        else if (userData.lastUpdatePosixTime < 0) {
            throw Error("not user exist in store :(");
        }


    }

    // Define headers like here.
    if (necessary && userData) {
        console.log("token:) for send request");
        console.log(userData.token);
        axios.defaults.headers.common["Authorization"] = tokenOverWrite ? `Bearer ${tokenOverWrite}` : `Bearer ${userData.token}`;
    }
    if (tokenOverWrite != undefined) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${tokenOverWrite}`;
    }
    if (isFileInclude ?? false) {
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    }
    else {
        axios.defaults.headers.common["Content-Type"] = "application/json";
    }

    // Prepare response
    //   const response = await axios[method](`${config.serverIP}${path}`, data);
    // let responseive = await axios[method](path, method.toLowerCase().includes("delete") ? { data: data } : data)
    //     .then((response: any) => {
    //         console.log("response api :)");
    //         console.log(response.data);
    //         return response.data.data;
    //     })
    //     .catch(async (error: any) => {
    //         console.log("error in api:(");
    //         console.log(error);
    //         let errorData = {
    //             status: 0,
    //             data: null,
    //         };

    //         if (error.response) {
    //             errorData = {
    //                 status: error.response.status,
    //                 data: error.response.data,
    //             };
    //         } else if (error.request) {
    //             errorData = {
    //                 ...errorData,
    //                 data: error.request,
    //             };
    //         } else {
    //             errorData = {
    //                 ...errorData,
    //                 data: error.message,
    //             };
    //         }

    //         // Handle unauthorized status code 401
    //         // console.log("error data:")
    //         // console.log(errorData);
    //         if (errorData.status === 401) {
    //             // console.log(errorData.status);
    //             if (selectorProfile) {
    //                 if (selectorProfile.refreshToken.length > 1) {
    //                     let res = await _refreshAccessToken(dispatch, selectorProfile, path.includes("/en/"));
    //                     throw res;
    //                 }
    //                 else {
    //                     throw errorData;
    //                 }
    //             }
    //             else {
    //                 dispatch(SetTokenAndRefreshToken({ token: "", refreshToken: "", lastUpdatePosixTime: -1 }));
    //                 throw errorData;
    //             }
    //         }
    //         else {
    //             throw errorData;
    //         }

    //         // if custom criteria in the inputs ((data)=>criteria, exception=Error), failed: throw exception, else return data.
    //         // console.log("omad:)");


    //     });
    // console.log("what ?:)");
    // console.log(responseive)
    // return responseive;
    try {
        // Choose the correct configuration based on the HTTP method
        const config = method.toLowerCase().includes("delete") ? { data: data } : data;

        // Make the API request
        const response = await axios[method](path, config);

        // Log and return the response data
        console.log("response api :)");
        console.log(response.data);

        return response.data.data;
    } catch (error: any) {
        console.log("error in api:(");
        console.log(error);

        let errorData = {
            status: 0,
            data: null,
        };

        // Populate errorData based on the error type
        if (error.response) {
            errorData = {
                status: error.response.status,
                data: error.response.data,
            };
        } else if (error.request) {
            errorData = {
                ...errorData,
                data: error.request,
            };
        } else {
            errorData = {
                ...errorData,
                data: error.message,
            };
        }

        // Handle unauthorized status code 401
        if (errorData.status === 401) {
            if (selectorProfile) {
                if (selectorProfile.refreshToken.length > 1) {
                    try {
                        const res = await _refreshAccessToken(dispatch, selectorProfile, path.includes("/en/"));
                        throw res;
                    } catch (refreshError) {
                        throw refreshError;
                    }
                } else {
                    throw errorData;
                }
            } else {
                dispatch(SetTokenAndRefreshToken({ token: "", refreshToken: "", lastUpdatePosixTime: -1 }));
                throw errorData;
            }
        } else {
            throw errorData;
        }
    }

    // } catch (error: any) {
    //     // Define Error object to error handle of view
    //     console.log("error in api2:(");
    //     let errorData = {
    //         status: 0,
    //         data: null,
    //     };

    //     if (error.response) {
    //         errorData = {
    //             status: error.response.status,
    //             data: error.response.data,
    //         };
    //     } else if (error.request) {
    //         errorData = {
    //             ...errorData,
    //             data: error.request,
    //         };
    //     } else {
    //         errorData = {
    //             ...errorData,
    //             data: error.message,
    //         };
    //     }

    //     // Handle unauthorized status code 401
    //     if (errorData.status === 401) {
    //         // console.log(errorData.status);
    //         _refreshAccessToken();
    //     }

    //     // if custom criteria in the inputs ((data)=>criteria, exception=Error), failed: throw exception, else return data.
    //     return errorData;
    // }
};

const _refreshAccessToken = async (dispatch: Dispatch<AnyAction>,
    selectorProfile: InitialProfilesState, isRequestEN: boolean) => {
    // const dispatch = useDispatch();
    /// This function will work when we get error related to refresh
    /// your token.
    /// In local storage we have accessToken and refreshToken.
    /// Getting refresh token from refresh token endpoint
    /// by passing refresh token to Authorization header.
    /// Will response two tokens: accessToken and refreshToken.
    /// During try to refresh token if i get this response i will
    /// logout user by clear LocalStorage (status will be 401):
    /// {
    ///   "status": 401,
    ///   "data": {
    ///     "success": false,
    ///     "errors": {
    ///       "token": [
    ///         "Given token not valid for any token type"
    ///       ]
    ///     }
    ///   }
    /// }
    /// If i get valid access from refreshToken endpoint,
    /// I will get object of local user data from LocalStorage
    let userData = selectorProfile;
    console.log("refresh token:")
    if (userData == undefined)
        return;

    try {
        let refreshEndpoint = urlsList.auth.refreshToken;


        const response: any = await RequestHandler(
            dispatch,
            isRequestEN ? "/en" + refreshEndpoint.url : "/fa" + refreshEndpoint.url,
            refreshEndpoint.method,
            refreshEndpoint.isTokenNecessary,
            { refresh: userData.refreshToken }
        );
        console.log("refresh token response:")
        console.log(response)

        const renewToken = response.data.access;
        const renewRefreshToken = response.data.refresh;
        if (renewToken) {
            // After get access token we will update LocalStorage accessToken value.
            // And reload view to get new access token from LocalStorage.
            userData.token = renewToken;
            userData.refreshToken = renewRefreshToken;
            dispatch(SetTokenAndRefreshToken(userData));
        } else {
            dispatch(SetTokenAndRefreshToken({ token: "", refreshToken: "", lastUpdatePosixTime: -1 }));
        }
    } catch (e) {
        dispatch(SetTokenAndRefreshToken({ token: "", refreshToken: "", lastUpdatePosixTime: -1 }));
    }
};

