import { useEffect, useState } from 'react';
import ParentWithHeightAnimation from '../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation';
import { InterfaceControllerCheckWalletCustomerOfMoneyPayment } from './InterfaceControllerCheckWalletCustomerOfMoneyPayment';
import { Box, Grid, useTheme } from '@mui/material';
import TextFieldSimple from '../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple';
import { ConfigComps } from '../../../../../../config/ConfigCOMP';
import { InterfaceNTTCurrencyWithTypeAndLocation } from '../../../../../../NTTs/InterfaceNTTCurrency';
import SelectCurrencyWithTypeAndLocation from '../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation';
import SubTitleSection from '../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection';
import TextFieldMultiLine from '../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine';
import Colors from '../../../../../../config/Colors';
import ButtonSave from '../../../../../../components/basic/Buttons/ButtonSave/ButtonSave';
import SelectUser from '../../../../../../components/complex/Selector/SelectUser/SelectUser';
import { InterfaceNTTUser } from '../../../../../../NTTs/InterfaceNTTUser';
import SelectTrustyOrCustomer from '../../../../../../components/complex/Selector/SelectTrustyAndCustomer/SelectTrustyOrCustomer';
import { InterfaceGetAPITrustyOrCustomer } from '../../../../../../components/complex/Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer';
import { InterfaceNTTRecapMain } from '../../../../../../NTTs/Recap/InterfaceNTTRecapMain';
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from '../../../../../../api/API';
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from '../../../../../../api/APIErrorResponse';
import { InterfaceSearchInServer } from '../../../../../../api/interfaces/InterfaceSearchInServer';
import { urlsList, HandlerLangUrl, TypeQueryParams } from '../../../../../../api/UrlsList';
import { EnumStateFetchList } from '../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList';
import { enumTypeOfAlert } from '../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup';
import { ShowAlert } from '../../../../../../store/AlertStateModeSlice';
import { isVisibilityProgressBar } from '../../../../../../store/ProgressbarInRootSlice';
import { InterfaceGetAPICustomersList } from '../../../../../people/TrustyOrCustomer/customers/customersList/InterfaceCustomersList';
import { SelectedProfile } from '../../../../../../store/profileStateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Links from '../../../../../../config/Links';
import CreateCheckWalletCustomerOfMoneyPayment from './CreateCheckWalletCustomerOfMoneyPayment/CreateCheckWalletCustomerOfMoneyPayment';
import { InterfaceNTTRecapMoneyPaymentService } from '../../../../../../NTTs/Recap/InterfaceNTTRecapMoneyPaymentService';
import ViewCheckWalletCustomerOfMoneyPayment from './ViewCheckWalletCustomerOfMoneyPayment/ViewCheckWalletCustomerOfMoneyPayment';
import { InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap } from '../../../../../../NTTs/Recap/WorkflowMainRecap/MoneyPayment/InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap';
import { isKey1BiggerThanKey2 } from '../../../../../../components/complex/SteperGuide/SteperGuide';

function ControllerCheckWalletCustomerOfMoneyPayment(paramsComponent: InterfaceControllerCheckWalletCustomerOfMoneyPayment) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>('1');
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getIsWaitingForCreate, setIsWaitingForCreate] = useState<boolean>(false);
    const [getInfoServiceInMainRecap, setInfoServiceInMainRecap] = useState<InterfaceNTTRecapMoneyPaymentService>();
    const [getInfoThisWorkFlow, setInfoThisWorkFlow] = useState<InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap>();
    const [getAllWorkflowReviews, setAllWorkflowReviews] = useState<InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap[]>([]);
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setAllWorkflowReviews(paramsComponent.allWorkflowReviews);
    }, [paramsComponent.allWorkflowReviews])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setInfoServiceInMainRecap(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setInfoThisWorkFlow(paramsComponent.StepWorkFlow);
    }, [paramsComponent.StepWorkFlow])

    return (
        <div style={{ width: '100%' }}>
            {(getIsWaitingForCreate || (getInfoThisWorkFlow === undefined && getKeyOfStepThatSelected === '1.2')) &&
                <CreateCheckWalletCustomerOfMoneyPayment
                    recapMain={getRecapMain}

                    callBackSetWaiting={(isWaiting: boolean) => {
                        setIsWaitingForCreate(isWaiting);
                    }}
                    callBackReLoad={() => {
                        paramsComponent.callBackReLoad();
                    }}
                    infoServiceInMainRecap={paramsComponent.infoServiceInMainRecap}
                    allWorkflowReviews={getAllWorkflowReviews}
                />
            }
            {
                getInfoServiceInMainRecap &&
                (isKey1BiggerThanKey2(getKeyOfStepThatSelected, '1.2') || getKeyOfStepThatSelected === '1.2') &&//check step that selected is big or equal this step
                <ViewCheckWalletCustomerOfMoneyPayment
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceInMainRecap}
                    thisStepWorkFlow={getInfoThisWorkFlow}
                />


            }
            {((getInfoServiceInMainRecap === undefined) ||
                getIsWaitingForCreate) && getKeyOfStepThatSelected !== '1.1' &&
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: Colors.theme.orange.sharp,
                    fontFamily: confComps.font.poppins.Medium,
                    fontSize: '1.4rem',
                }}
                >
                    No This Service CheckWalletCustomerOfd!
                </div>

            }
        </div>


    )

}
export default ControllerCheckWalletCustomerOfMoneyPayment;