
import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const TrashIcon: React.FC<InterfaceIconSVG> = ({ style= { width: "14", height: "16" }}) => {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={style}>
            <path d="M5.13125 1.23438L4.65312 2H9.34688L8.86875 1.23438C8.77812 1.0875 8.61563 1 8.44375 1H5.55312C5.38125 1 5.22188 1.0875 5.12813 1.23438H5.13125ZM9.71875 0.70625L10.5281 2H12H13H13.5C13.775 2 14 2.225 14 2.5C14 2.775 13.775 3 13.5 3H13V13.5C13 14.8813 11.8813 16 10.5 16H3.5C2.11875 16 1 14.8813 1 13.5V3H0.5C0.225 3 0 2.775 0 2.5C0 2.225 0.225 2 0.5 2H1H2H3.47187L4.28125 0.70625C4.55625 0.265625 5.0375 0 5.55312 0H8.44375C8.9625 0 9.44063 0.265625 9.71562 0.70625H9.71875ZM2 3V13.5C2 14.3281 2.67188 15 3.5 15H10.5C11.3281 15 12 14.3281 12 13.5V3H2ZM4.64687 6.64687C4.84062 6.45312 5.15938 6.45312 5.35313 6.64687L7 8.29375L8.64688 6.64687C8.84063 6.45312 9.15937 6.45312 9.35312 6.64687C9.54688 6.84062 9.54688 7.15938 9.35312 7.35313L7.70625 9L9.35312 10.6469C9.54688 10.8406 9.54688 11.1594 9.35312 11.3531C9.15937 11.5469 8.84063 11.5469 8.64688 11.3531L7 9.70625L5.35313 11.3531C5.15938 11.5469 4.84062 11.5469 4.64687 11.3531C4.45312 11.1594 4.45312 10.8406 4.64687 10.6469L6.29375 9L4.64687 7.35313C4.45312 7.15938 4.45312 6.84062 4.64687 6.64687Z"
                fill={style?.color ?? "#FF7900"} />
        </svg>
    )
};
