import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { InterfaceSelectCountry } from "./InterfaceSelectCountry";
import Colors from "../../../../config/Colors";
import { keyframes, styled, useTheme } from "@mui/material";
import { InterfaceNTTCity } from "../../../../NTTs/InterfaceNTTCity";
import { InterfaceNTTCountry } from "../../../../NTTs/InterfaceNTTCountry";
import { SyncRounded } from "@mui/icons-material";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { RequestHandler } from "../../../../api/API";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../MUIConfig/alert_popup/interfaceAlertPopup";
import LineSeparator from "../../../basic/LineSeprator/LineSeparator";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
    fontSize: "1rem",
    width: "100%",
    gap: "1rem",
    paddingInline: "1rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#F8F8F8",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
function SelectCountry(paramsComponent: InterfaceSelectCountry) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getResponseAllCountry, setResponseAllCountry] = useState<InterfaceNTTCountry[]>([]);
    const [getSelectedCountry, setSelectedCountry] = useState<InterfaceNTTCountry | undefined>(paramsComponent.defaultSelected);
    const [getSearchText, setSearchText] = useState<string>(paramsComponent.defaultSelected?.name ?? "");
    const [getStateIsSearching, setStateIsSearching] = useState<boolean>(false);
    const [getIsFocused, setIsFocused] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);


    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("call changed", event.target.value)
        // Clear the previous timeout if it exists
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        // Get the current value from the input
        const { value } = event.target;
        // Set a new timeout
        const newTimeoutId = setTimeout(() => {
            // Call your API function here with the updated value
            callApiForGetListCountries(value);
        }, 1000); // Adjust the delay time as needed (e.g., 1000ms = 1 second)
        // Update state to store the timeout ID
        setTimeoutId(newTimeoutId);
        // Update the input value state
        setSearchText(value);
    };




    const callApiForGetListCountries = async (searchText: string) => {
        dispatch(isVisibilityProgressBar(true));
        setStateIsSearching(true);
        let urlObj = urlsList.panel.location.getCountry;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${searchText}`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response.data as (InterfaceNTTCountry[]);
                setResponseAllCountry(t);

                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
        setStateIsSearching(false);


    }
    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside = (event: MouseEvent) => {
                if (containerRef.current && getIsFocused && !containerRef.current.contains(event.target as Node)) {
                    setIsFocused(false);
                    setSearchText(getSelectedCountry?.name ?? "");
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [getIsFocused]);
    useEffect(() => {
        callApiForGetListCountries("");
    }, [])
    useEffect(() => {
        if (paramsComponent.defaultSelected) {
            setSelectedCountry(paramsComponent.defaultSelected);
            setSearchText(paramsComponent.defaultSelected.name);
        }
    }, [paramsComponent.defaultSelected])
    useEffect(() => {
        getSelectedCountry && paramsComponent.onChangeSelectedCountry(getSelectedCountry);
    }, [getSelectedCountry])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', position: "relative" }}
            ref={containerRef}
        >
            <label style={{
                fontWeight: "300",

                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
            }}>
                {confComps.translate.country}
            </label>
            <div style={{
                background: Colors.theme.white.white90,
                border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                borderRadius: '0.4rem',
                fontFamily: confComps.font.poppins.Regular,
                fontSize: '1.12rem',
                width: "100%",
                color: Colors.theme.blue.dark,
                outline: 'none',
                lineHeight: "1.68rem",
                display: "flex", flexDirection: "row",
                alignItems: 'center',
                paddingInline: '0.64rem',
                boxSizing: "border-box",
            }}>
                {getSelectedCountry && getSelectedCountry.iso && <img
                    src={`https://flagcdn.com/w320/${getSelectedCountry.iso}.png`} // Fallback image
                    alt={`${getSelectedCountry.iso}`}
                    style={{ width: '2rem', height: 'auto' }}
                />}
                <input

                    autoComplete="off"
                    type="text"
                    onFocus={() => setIsFocused(true)}
                    style={{
                        width: 0,
                        boxSizing: "border-box",
                        paddingInline: '0.64rem',
                        paddingBlock: '0.32rem',
                        flexGrow: "1",
                        cursor: "pointer",
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        color: Colors.theme.blue.dark,
                        outline: 'none',
                        lineHeight: "1.68rem",
                    }}
                    value={getSearchText}
                    onChange={handleChangeInput}

                />
            </div>
            {

                <DropdownBox
                    isDropdownOpen={getIsFocused || getStateIsSearching}
                >
                    {getStateIsSearching &&
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                            <SpinnerIcon />
                        </div>
                    }
                    {!getStateIsSearching && (getResponseAllCountry.length === 0) &&
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                            {confComps.translate.notFound}
                        </div>
                    }
                    {getResponseAllCountry.flatMap((country, index, arr) => {
                        if ((index + 1) === arr.length) {
                            return (
                                [<RowOfList onClick={() => {
                                    setSearchText(country.name);
                                    setSelectedCountry(country);
                                    setIsFocused(false);
                                }}>
                                    {country.iso && <img
                                        src={`https://flagcdn.com/w320/${country.iso}.png`} // Fallback image
                                        alt={`${country.iso}`}
                                        style={{ width: '1rem', height: 'auto' }}
                                    />}
                                    {!country.iso && "??  "}
                                    {country.name}
                                </RowOfList>
                                ]
                            )
                        }
                        return (
                            [<RowOfList onClick={() => {
                                setSearchText(country.name);
                                setSelectedCountry(country);
                                setIsFocused(false);
                            }}>
                                {country.iso && <img
                                    src={`https://flagcdn.com/w320/${country.iso}.png`} // Fallback image
                                    alt={`${country.iso}`}
                                    style={{ width: '1rem', height: 'auto' }}
                                />}
                                {!country.iso && "??  "}
                                {country.name}
                            </RowOfList>
                                ,
                            <LineSeparator />]

                        );
                    })
                    }
                </DropdownBox>


            }
        </div>

    );
}
export default SelectCountry;