import React, { useEffect, useState } from 'react';
import Colors from "../../../../../config/Colors";
import { InterfaceTextFieldMultiSelectActivity } from './InterfaceTextFieldMultiSelectActivity';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedProfile } from '../../../../../store/profileStateSlice';
import { useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigComps } from '../../../../../config/ConfigCOMP';
import { isVisibilityProgressBar } from '../../../../../store/ProgressbarInRootSlice';
import { HandlerLangUrl, urlsList } from '../../../../../api/UrlsList';
import { RequestHandler } from '../../../../../api/API';
import { InterfaceNTTActivity } from '../../../../../NTTs/InterfaceNTTActivity';
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from '../../../../../api/APIErrorResponse';
import { ShowAlert } from '../../../../../store/AlertStateModeSlice';
import { enumTypeOfAlert } from '../../../../MUIConfig/alert_popup/interfaceAlertPopup';
import AutoCompleteWithUnderLine from '../../../../complex/AutoCompleteWithUnderLine/AutoCompleteWithUnderLine';



function TextFieldMultiSelectActivity(componentParams: InterfaceTextFieldMultiSelectActivity) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const { role } = useParams();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);

    const [getIsFocused, setIsFocused] = useState(false);

    const [getActivitySelected, setActivitySelected] = useState<InterfaceNTTActivity[]>([]);
    const [getResponseAllActivity, setResponseAllActivity] = useState<InterfaceNTTActivity[]>([]);

    const getApiListActivity = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.common.activity.getActivity;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as (InterfaceNTTActivity[]);
                setResponseAllActivity(t);
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        getApiListActivity();
    }, [])
    useEffect(() => {
        componentParams.selectedActivities && setActivitySelected(componentParams.selectedActivities);
    }, [componentParams.selectedActivities])
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                // borderRadius: ConstStyle.borderRadius,
                fontSize: "1rem",
                position: "relative",
                boxShadow: Colors.shadow.shadowTextFieldWithIconBottomLineClicked,
                ...componentParams.styleMain,
                paddingBlockStart: "0.32rem",
                boxSizing: "border-box",

            }}
        >

            <div style={{
                flex: 1,
                // flexGrow: 1,
                paddingInlineStart: "0.32rem",
                
                boxSizing: "border-box",
            }}>
                <AutoCompleteWithUnderLine
                    styleItems={componentParams.styleItems}
                    valuesList={getResponseAllActivity}
                    onChangeSelectedItem={(items) => {
                        componentParams.onChangeSelectedActivities(items.map((item, index, arr) => {
                            return ({
                                ...getResponseAllActivity.filter(ac => ac.id === item.id)[0]
                            })

                        }));
                    }}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    selectedValueTitle={getActivitySelected}
                    isShowIconInEnd={true}

                />
                <label
                    style={{
                        position: "absolute",
                        boxSizing: "border-box",
                        left: ((getIsFocused || (getActivitySelected.length > 0)) || !componentParams.iconStart) ? "0.32rem" : "2.4rem",
                        transition: "all 0.2s ease",
                        pointerEvents: "none",
                        top: getIsFocused || (getActivitySelected.length > 0) ? '0px' : 'unset',
                        bottom: getIsFocused || (getActivitySelected.length > 0) ? 'unset' : "0rem",
                        transform: getIsFocused || (getActivitySelected.length > 0) ? "translate(0%, -50%)" : "translate(0%, 0%)",
                        fontSize: (getIsFocused || (getActivitySelected.length > 0)) ? "0.75rem" : "1em",
                        color: Colors.theme.blue.light,
                        backgroundColor: "transparent",
                        padding: "0",
                    }}
                >
                    {componentParams.title}
                </label>
            </div>
            <fieldset
                // aria-hidden="true"
                style={{
                    position: "absolute",
                    boxSizing: "border-box",
                    top: getIsFocused || (getActivitySelected.length > 0) ? "calc(-1.02rem - 0.5px)" : "0px",
                    // height: "100%",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    borderRadius: componentParams.styleMain?.borderRadius,
                    border: "unset",
                    borderBottom: (getIsFocused) ? `1px solid ${Colors.theme.blue.blue}` : `1px solid #C4C7D9`,
                    pointerEvents: "none",
                    padding: "0",
                    margin: "0",
                    display: "block",
                    paddingInline: "0.75rem",
                    // overflow: "hidden"
                }}
            >
                <legend
                    style={{
                        overflow: "hidden",
                        float: "unset",
                        boxSizing: "border-box",
                        transition: "all 0.2s ease",
                        left: getIsFocused || (getActivitySelected.length > 0) ? "1rem" : "1rem",
                        pointerEvents: "none",

                        display: (getIsFocused || (getActivitySelected.length > 0)) ? "block" : "none",
                        // transform: "translate(0%,-60%)",
                        // visibility: "hidden",
                        // background: "red",
                    }}
                >
                    <span style={{
                        opacity: 0,
                        fontSize: "0.75rem",
                        visibility: "visible", display: "inline-block",
                        paddingInlineEnd: "0.5rem",
                        boxSizing: "border-box",
                    }}>
                        {componentParams.title}
                    </span>
                </legend>
                {/* } */}
            </fieldset>
        </div>
    );
}

export default TextFieldMultiSelectActivity;
