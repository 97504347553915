import { Box, Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { InterfaceAllRecommended, InterfaceAPIGetAllRecommended } from "./InterfaceAllRecommended";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { useEffect, useState } from "react";
import { InterfaceNTTCashAccount } from "../../../../../../NTTs/InterfaceNTTCashAccount";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../../api/API";
import { HandlerLangUrl, urlsList } from "../../../../../../api/UrlsList";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import RowRecommended from "./RowRecommended/RowRecommended";
import CreateRecommend from "../CreateRecommend/CreateRecommend";
import { EnumStateFetchList } from "../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfacePaginationInServer } from "../../../../../../api/interfaces/InterfaceSearchInServer";
import ParentWithHeightAnimation from "../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import InfiniteScrollList from "../../../../../../components/basic/InfiniteScrollList/InfiniteScrollList";

function AllRecommended(paramsComponent: InterfaceAllRecommended) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getResponseGetAllRecommended, setResponseGetAllRecommended] = useState<InterfaceAPIGetAllRecommended>();
    const [getIsActiveCreateRecommended, setIsActiveCreateRecommended] = useState<boolean>(false);

    const callApiForGetAllRecommended = async (paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recommendation.recommended.getAllRecommended;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/recommendations-received/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIGetAllRecommended;
                if (paginationParams.page === 1) {
                    setResponseGetAllRecommended(t);
                }
                else {
                    setResponseGetAllRecommended(prev => {
                        return (prev ? {
                            ...t,
                            data: [...prev.data, ...t.data],
                        } : {
                            ...t,
                        });
                    }
                    );
                }
                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }

                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                setStateFetchList(EnumStateFetchList.errorInRequest);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            callApiForGetAllRecommended(getPaginationForLastRequest);
        }
    }, [getStateFetchList])

    return (
        <div style={{
            display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center",
            gap: "1.6rem",
        }}>
            <ParentWithHeightAnimation in={!getIsActiveCreateRecommended} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>

                <Box sx={{
                    width: "100%",
                    paddingInline: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                    paddingBlock: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                    boxSizing: "border-box",
                }}>
                    <InfiniteScrollList
                        isDataExist={(getResponseGetAllRecommended?.data ?? []).length !== 0}
                        onEndReached={async () => {
                            let oldPage = getPaginationForLastRequest;
                            oldPage = {
                                ...oldPage,
                                page: (oldPage.page + 1)
                            };
                            setPaginationForLastRequest(oldPage);
                            console.log("callback for change state response2:", oldPage);
                            callApiForGetAllRecommended(oldPage);
                        }}
                        onRetryClicked={() => {
                            callApiForGetAllRecommended(getPaginationForLastRequest);
                        }}
                        stateResponseList={getStateFetchList}
                        styleSpacingMUI={{
                            columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                            rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                            columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                        }}

                    >
                        <Grid container
                            justifyContent="space-between" alignItems="flex-start"
                            columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 3, xl: 5 }}
                            rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >


                            {getResponseGetAllRecommended && getResponseGetAllRecommended.data.map((recommended, index, arr) => {
                                return (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <RowRecommended {...recommended}
                                            callbackReloadPage={() => {
                                                setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                                                callApiForGetAllRecommended(confComps.defaultValue.paginationConfigForServer);
                                            }} />
                                    </Grid>

                                )
                            })}
                        </Grid>
                    </InfiniteScrollList>
                </Box>
            </ParentWithHeightAnimation>

            <ParentWithHeightAnimation in={getIsActiveCreateRecommended} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>

                <CreateRecommend
                    isThisUsedForRecommended={true}
                    userInfoBasic={paramsComponent.userInfoBasic}
                    callbackCancel={() => {
                        setIsActiveCreateRecommended(false);
                        // setSelectedr(undefined);
                        setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                        callApiForGetAllRecommended(confComps.defaultValue.paginationConfigForServer);
                    }}
                />
            </ParentWithHeightAnimation>

            {!getIsActiveCreateRecommended &&
                <div style={{ display: 'flex', flexDirection: "row", position: "sticky", bottom: "1vh", alignItems: "center", justifyContent: "end", width: "100%" }}>
                    <ButtonSave text={confComps.translate.addNewRecommender}
                        callbackOnClick={() => {
                            setIsActiveCreateRecommended(true);
                        }} />
                </div>
            }
        </div>
    );
};
export default AllRecommended;
