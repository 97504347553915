export interface InterfaceSortingBox {
    items: InterfaceItemOfSorting[];
    onclickDone: (item: InterfaceItemOfSorting[]) => void;
    limitClick: number;

}
export interface InterfaceItemOfSorting {
    title: string;
    key: string;
    typeOrder: EnumSortingType;
}
export enum EnumSortingType {
    asc = "asc",
    desc = "desc",
    none = "none",
}