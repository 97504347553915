import { Typography } from "@mui/material";
import { InterfaceTitleMedium16 } from "./InterfaceTitleMedium16";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import Colors from "../../../../../config/Colors";

function TitleMedium16(paramsComponent: InterfaceTitleMedium16) {
    var confComps = ConfigComps();
    return (
        <Typography variant="h6" sx={{
            lineHeight: "1.77rem",//{ xs: 1.1, sm: 1.1, md: 1.25, lg: 1.25, xl: 1.25 },
            fontSize: "1.18rem",//{ xs: "0.85rem", sm: "0.90rem", md: "0.96rem", lg: "1.04rem", xl: "1.12rem" },
            marginBlock: { xs: 0 },
            boxSizing: "border-box",
            fontFamily: confComps.font.poppins.Medium,
            // fontWeight: "100",
            color: Colors.theme.blue.Secondary,
            ...paramsComponent.style
        }} >
            {paramsComponent.children}

        </Typography>
    )
}
export default TitleMedium16;