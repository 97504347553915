import { useEffect, useState } from "react";
import ParentWithHeightAnimation from "../../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import { Box, Grid, useTheme } from "@mui/material";
import TextFieldSimple from "../../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../../../../NTTs/InterfaceNTTCurrency";
import SelectCurrencyWithTypeAndLocation from "../../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import SubTitleSection from "../../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import TextFieldMultiLine from "../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import Colors from "../../../../../../../config/Colors";
import ButtonSave from "../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import SelectUser from "../../../../../../../components/complex/Selector/SelectUser/SelectUser";
import { InterfaceNTTUser } from "../../../../../../../NTTs/InterfaceNTTUser";
import SelectTrustyOrCustomer from "../../../../../../../components/complex/Selector/SelectTrustyAndCustomer/SelectTrustyOrCustomer";
import { InterfaceGetAPITrustyOrCustomer } from "../../../../../../../components/complex/Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer";
import { InterfaceNTTRecapMain } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { InterfaceSearchInServer } from "../../../../../../../api/interfaces/InterfaceSearchInServer";
import { urlsList, HandlerLangUrl, TypeQueryParams } from "../../../../../../../api/UrlsList";
import { EnumStateFetchList } from "../../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { InterfaceGetAPICustomersList } from "../../../../../../people/TrustyOrCustomer/customers/customersList/InterfaceCustomersList";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Links from "../../../../../../../config/Links";
import SelectCompanyOfTheUser from "../../../../../../../components/complex/Selector/SelectDomesticCompanyOfTheUser/SelectDomesticCompanyOfTheUser";
import { InterfaceNTTDomesticCompany } from "../../../../../../../NTTs/InterfaceNTTDomesticCompany";
import TextFieldFixCurrencyToAnother from "../../../../../../../components/basic/TextField/TextFieldFixCurrencyToAnother/TextFieldFixCurrencyToAnother";
import SelectGoodsCategory from "../../../../../../../components/complex/Selector/SelectGoodsCategory/SelectGoodsCategory";
import { InterfaceNTTGoodsCategory } from "../../../../../../../NTTs/Recap/InterfaceNTTGoodsCategory";
import DatePickerMUI from "../../../../../../../components/basic/Dates/DatePickerMUI/DatePickerMUI";
import SelectYesOrNo from "../../../../../../../components/complex/Selector/SelectYesOrNo/SelectYesOrNo";
import { InterfaceCreateInitiateSellCottage } from "./InterfaceCreateInitiateSellCottage";

function CreateInitiateSellCottage(paramsComponent: InterfaceCreateInitiateSellCottage) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getIsCreateSellCottageActive, setIsCreateSellCottageActive] = useState<boolean>(false);
    const [getAmountValueIRR, setAmountValueIRR] = useState<string>("");
    const [getAmountValueAED, setAmountValueAED] = useState<string>("");
    const [getDescriptionValue, setDescriptionValue] = useState<string>("");
    const [getSelectedGoodsCategory, setSelectedGoodsCategory] = useState<InterfaceNTTGoodsCategory>();
    const [getExpireDate, setExpireDate] = useState<number>();
    const [getHaveCottage, setHaveCottage] = useState<boolean>();
    const [getCodeTarefe, setCodeTarefe] = useState<string>("");
    const [getSelectedCurrency, setSelectedCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation | undefined>();
    const [getCustomerSelected, setCustomerSelected] = useState<InterfaceGetAPITrustyOrCustomer>();
    const [getSelectedCompany, setSelectedCompany] = useState<InterfaceNTTDomesticCompany>();
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getIsWaitingForRecapMain, setIsWaitingForRecapMain] = useState<boolean>();
    const handleOnClickDone = async () => {
        if (getRecapMain) {
            callApiForCreateSellCottage(getRecapMain);
        }
        else {
            paramsComponent.callBackCreateMainRecap && paramsComponent.callBackCreateMainRecap();
            setIsWaitingForRecapMain(true);
            paramsComponent.callBackSetWaiting(true);
        }
    }

    const callApiForCreateSellCottage = async (recapMain: InterfaceNTTRecapMain) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.cottage.sellCottage.createSellCottage;
        let bodyObj = {
            "customer": getCustomerSelected?.user.id,
            "company": getSelectedCompany?.id,
            "currency_unit": getSelectedCurrency?.id,
            "amount": getAmountValueAED,
            "expected_rate": getAmountValueIRR,
            "category": getSelectedGoodsCategory?.id,
            "tariff_code": getCodeTarefe,
            "is_available": getHaveCottage,
            "deadline": getExpireDate,
            "description": getDescriptionValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${recapMain.id}/sell-cottages/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // let t = response as InterfaceGetAPICustomersList;
                let getOldStateWaiting = getIsWaitingForRecapMain;
                setIsWaitingForRecapMain(false);
                paramsComponent.callBackReLoad();
                paramsComponent.callBackSetWaiting(false);
                dispatch(isVisibilityProgressBar(false));
                if (getOldStateWaiting) {
                    navigate(Links.screens.panel.recap.ShowStateTheRecap + `${recapMain.id}/`);
                }
            })
            .catch((e: any) => {
                setIsWaitingForRecapMain(false);
                paramsComponent.callBackSetWaiting(false);
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        setIsCreateSellCottageActive(paramsComponent.isCreateSellCottageActive);
    }, [paramsComponent.isCreateSellCottageActive])
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
        if (getIsWaitingForRecapMain && paramsComponent.recapMain) {
            callApiForCreateSellCottage(paramsComponent.recapMain)
        }
    }, [paramsComponent.recapMain, getIsWaitingForRecapMain])
    return (
        <ParentWithHeightAnimation in={getIsCreateSellCottageActive}
            loading={getIsWaitingForRecapMain}
            style={{
                boxSizing: "border-box",
                width: "100%",
            }}
        >
            <Box sx={{
                boxSizing: "border-box",
                paddingInlineStart: { xs: "0.8rem", sm: "0.9rem", md: "1.0rem", lg: "1.18rem", xl: "1.3rem" },
                paddingInlineEnd: { xs: "0.8rem", sm: "3.33rem", md: "3.7rem", lg: "4.14rem", xl: "6rem" },
                display: "flex", flexDirection: 'column',
                alignItems: 'start',
                width: "100%",
                marginTop: "1.18rem",
            }}>
                <Grid container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 0, sm: 0, md: "1rem", lg: "3.18rem", xl: "4rem" }}
                    rowSpacing={{ xs: "1.2rem", sm: "1.39rem", md: "1.53rem", lg: "1.70rem", xl: "1.87rem" }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <SelectTrustyOrCustomer
                            title={confComps.translate.customer}
                            defaultUser={getCustomerSelected}
                            onChangeSelectedUser={setCustomerSelected}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SelectCompanyOfTheUser
                            ownerCompany_id={getCustomerSelected?.user.id}
                            title={confComps.translate.domesticCompany}
                            onChangeSelected={(company?: InterfaceNTTDomesticCompany) => {
                                setSelectedCompany(company);
                            }}
                            defaultCompany={getSelectedCompany}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <TextFieldSimple
                            title={`${confComps.translate.amount} (AED)`}
                            textValue={getAmountValueAED}
                            type="number"
                            onChangeValue={function (newText: string): void {
                                setAmountValueAED(newText)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SelectCurrencyWithTypeAndLocation
                            title={confComps.translate.currencyType}
                            onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                setSelectedCurrency(selectCountry)
                            }}
                            style={{
                            }}
                            defaultCurrency={getSelectedCurrency}
                        />

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <TextFieldFixCurrencyToAnother
                            title={`${confComps.translate.expectedCurrencyRate}`}
                            value={getAmountValueIRR}
                            type="number"
                            onChangeValue={function (newText: string): void {
                                setAmountValueIRR(newText);
                            }}
                            fixCurrencyUnit={"AED"}
                            anotherCurrencyUnit="IRR"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SelectGoodsCategory
                            title={confComps.translate.category}
                            style={{}}
                            onChangeSelectedGoodsCategory={(selectGoodsCategory?: InterfaceNTTGoodsCategory) => {
                                setSelectedGoodsCategory(selectGoodsCategory);
                            }}
                            defaultSelected={getSelectedGoodsCategory}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <DatePickerMUI title={confComps.translate.authenticityDate} posixTime={0}
                            onChangeValue={(timeSecond: number | undefined) => {
                                setExpireDate(timeSecond);
                            }}
                            defaultTimeInSecond={getExpireDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <TextFieldSimple
                            title={`${confComps.translate.definitionCode}`}
                            textValue={getCodeTarefe}
                            // type="number"
                            onChangeValue={function (newText: string): void {
                                setCodeTarefe(newText)
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                        <SelectYesOrNo
                            title={confComps.translate.doYouHaveCottage}
                            onChangeSelectYesOrNo={(selected?: boolean) => {
                                setHaveCottage(selected);
                            }}
                            defaultSelected={getHaveCottage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextFieldMultiLine
                            title={confComps.translate.explanation}
                            text={getDescriptionValue}
                            onChangeText={setDescriptionValue}
                            styleParent={{
                                boxSizing: "border-box",
                                border: `1px solid ${Colors.theme.blue.border}`,
                                background: Colors.theme.white.white90,
                            }}
                        />
                    </Grid>
                </Grid>

            </Box>
            <div style={{
                width: "100%",
                alignItems: 'center',
                justifyContent: 'end',
                display: 'flex',
                flexDirection: 'row',
                marginTop: '1.18rem',
            }}>
                <ButtonSave
                    text={confComps.translate.create}
                    callbackOnClick={() => {
                        handleOnClickDone();
                    }}
                />

            </div>
        </ParentWithHeightAnimation>
    )

}
export default CreateInitiateSellCottage;