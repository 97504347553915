import * as React from 'react';
import { useAutocomplete, AutocompleteGetTagProps } from '@mui/base/useAutocomplete';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { InterfaceAutoCompleteSimple, InterfaceItemOfAutoComplete } from './InterfaceAutoCompleteSimple';
import { useState } from 'react';
import Colors from '../../../config/Colors';

const Root = styled('div')(
  ({ theme }) => `
    
  color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'
    };
  font-size: 14px;
`,
);

const Label = styled('label')`
  line-height: 1.68rem;
  display: block;
  margin-bottom: 0.22rem;
  color: ${Colors.theme.blue.light};
  &.focused {
    color: ${Colors.theme.blue.blue};
  }
  marginBottom: '0.22rem';
`;

const InputWrapper = styled('div')(
  ({ theme }) => `
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.4rem;  
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${Colors.theme.blue.border};
  boxShadow: undefined;
  &:hover {
    border: 1px solid ${Colors.theme.blue.blue};
    boxShadow:${Colors.shadow.shadowEditTextBoxClicked};
  }

  &.focused {
    border: 1px solid ${Colors.theme.blue.blue};
    boxShadow:${Colors.shadow.shadowEditTextBoxClicked};
  }

  & input {
    line-height: 1.68rem;
    font-size: 1.12rem;
    box-sizing: border-box;
    font-family: Poppins-Regular;
    padding-block: 0.32rem;
    padding-inline: 0.64rem;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

function Tag(props: TagProps) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

const StyledTag = styled(Tag)<TagProps>(
  ({ theme }) => `
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.08)' : '#fafafa'
    };
  border: 1px solid ${theme.palette.mode === 'dark' ? '#303030' : '#e8e8e8'};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: ${theme.palette.mode === 'dark' ? '#177ddc' : '#40a9ff'};
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`,
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

export default function AutoCompleteSimple(componentsParams: InterfaceAutoCompleteSimple) {
  const [getIsFocused, setIsFocused] = useState(false);
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    defaultValue: componentsParams.selectedValueTitle ?? [],
    multiple: true,
    options: componentsParams.valuesList,
    getOptionLabel: (option) => option.title,
  });
  React.useEffect(() => {
    componentsParams.onChangeSelectedItem(value);
  }, [value])

  // React.useEffect(() => {

  // }, [componentsParams])
  return (
    <Root style={{ position: "relative" }}>
      <div {...getRootProps()}>
        <Label {...getInputLabelProps()}
          className={focused ? 'focused' : ''}
          sx={{


          }}>{componentsParams.title}
        </Label>
        <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''} sx={{

          width: "100%",
        }}>
          {value.map((option: InterfaceItemOfAutoComplete, index: number) => {
            const { key, ...tagProps } = getTagProps({ index });
            return <StyledTag key={key} {...tagProps} label={option.title} />;
          })}
          <input {...getInputProps()}
            autoComplete="off"
          // onFocus={() => { setIsFocused(true) && getInputProps().onFocus }}
          // onBlur={() => {
          //   setIsFocused(false);
          //   if (getInputProps().onBlur()) {
          //     getInputProps().onBlur();
          //   }
          // }}
          />
        </InputWrapper>
      </div>
      {groupedOptions.length > 0 ? (
        <Listbox {...getListboxProps()}>
          {(groupedOptions as InterfaceItemOfAutoComplete[]).map((option, index) => {
            const { ...optionProps } = getOptionProps({ option, index });
            return (
              <li  {...optionProps}>
                <span>{option.title}</span>
                <CheckIcon fontSize="small" />
              </li>
            );
          })}
        </Listbox>
      ) : null}
    </Root>
  );
}

// interface FilmOptionType {
//     title: string;
//     year: number;
// }
