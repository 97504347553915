import { Box, Grid, useTheme } from "@mui/material";
import Colors from "../../../../../../config/Colors";
import KeyTitleH6 from "../../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import { useDispatch, useSelector } from "react-redux";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import ValueP from "../../../../../../components/basic/Texts/Titles/ValueP/ValueP";
import { AddCircleOutline, DocumentScannerRounded, DownloadRounded } from "@mui/icons-material";
import { PaperIcon } from "../../../../../../assets/icons/svg/PaperIcon";
import { DownloadFile, getFileNameFromURL } from "../../../../../../helper/Helper";
import { EditPenIcon } from "../../../../../../assets/icons/svg/EditPenIcon";
import { TrashIcon } from "../../../../../../assets/icons/svg/TrashIcon";
import { HandlerLangUrl, urlsList } from "../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../api/API";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { InterfaceRowCashAccount } from "./InterfaceRowCashAccount";
import { useState } from "react";
import DialogAcceptProcess from "../../../../../../components/complex/Dialog/DialogAcceptProcess/DialogAcceptProcess";

function RowCashAccount(paramsComponent: InterfaceRowCashAccount) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getIsDialogDeleteShow, setIsDialogDeleteShow] = useState<boolean>(false);
    const callApiForDeleteCompany = async (idCashAccount: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.cashAccount.deleteCashAccount;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idCashAccount}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackReloadPage();
                dispatch(ShowAlert({ text: 'Removed The Cash Account', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <Box sx={{
            width: "100%",
            // border: `1px solid ${Colors.theme.blue.border}`,
            boxShadow: Colors.shadow.shadowEditTextBoxClicked,
            height: "100%",
            paddingInline: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
            paddingBlock: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
            boxSizing: "border-box",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: "0.8rem",
            rowGap: paramsComponent.description ? { xs: "1.05rem", sm: "1.2rem", md: "1.34rem", lg: "1.48rem", xl: "1.6rem" } : 0,
        }}>
            <Grid container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 3, xl: 5 }}
                rowSpacing={{ xs: "1.05rem", sm: "1.2rem", md: "1.34rem", lg: "1.48rem", xl: "1.6rem" }}
                columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
            >

                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.country} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.country.name} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.city} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.city.name} />
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        {paramsComponent.currencies.map((cur, index, arr) => {
                            return (
                                <div style={{
                                    background: Colors.theme.orange.light,
                                    paddingInline: "1.52rem",
                                    paddingBlock: "0.14rem",
                                    boxSizing: "border-box",
                                    color: Colors.theme.blue.dark,
                                    fontFamily: confComps.font.poppins.Regular,
                                    borderRadius: "900rem",
                                    lineHeight: "1.55rem",//{ xs: 1.1, sm: 1.1, md: 1.25, lg: 1.25, xl: 1.25 },
                                    fontSize: "1.037rem",
                                }}>
                                    {cur.symbol}

                                </div>
                            )
                        })
                        }
                        <AddCircleOutline style={{
                            fontSize: "1.6rem", color: "orange",
                            cursor: "pointer",
                            boxSizing: "border-box",

                        }}
                            onClick={paramsComponent.callbackClickOnEdit}
                        />

                    </div>
                </Grid>

                {(paramsComponent.description.length > 0) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div style={{
                            width: "100%", borderRadius: "0.4rem",
                            background: "#F3F6FC", padding: "0.5rem",
                            boxSizing: "border-box",
                            border: `1px solid ${Colors.theme.blue.light_2}`
                        }}>
                            {paramsComponent.description}

                        </div>
                    </Grid>}

            </Grid>
            <div style={{
                display: "flex",
                boxSizing: "border-box",
                flexDirection: "row",
                alignItems: "center", justifyContent: "end", width: "100%",
                gap: "1rem"
            }}>
                <div style={{
                    display: 'flex', alignItems: "center",
                    boxSizing: "border-box", justifyContent: "center", cursor: "pointer"
                }}
                    onClick={paramsComponent.callbackClickOnEdit}
                >
                    <EditPenIcon style={{
                        background: Colors.theme.blue.light,
                        width: "0.9rem",
                        height: "0.9rem",
                        cursor: "pointer",
                        boxSizing: "border-box",
                    }}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: "center", boxSizing: "border-box", justifyContent: "center", cursor: "pointer" }}
                    onClick={() => {
                        setIsDialogDeleteShow(true);
                    }}
                >
                    <TrashIcon style={{ width: "1.2rem", height: "1.1rem" }} />
                </div>

            </div>

            <DialogAcceptProcess
                greenButtonTitle={confComps.translate.yes}
                redButtonTitle={confComps.translate.no}
                isOpen={getIsDialogDeleteShow} title={"Are you Sure For Delete?"} description={""}
                callbackClickAccept={function (): void {
                    setIsDialogDeleteShow(false);
                    callApiForDeleteCompany(paramsComponent.id)
                }}
                callbackClickReject={function (): void {
                    setIsDialogDeleteShow(false);
                }}
            >
                <div>
                    {`cash account: ${paramsComponent.currencies.map(cur => cur.symbol).join(",")} of ${paramsComponent.city.name}`}
                </div>
            </DialogAcceptProcess>
        </Box >
    );
}
export default RowCashAccount;