import { useEffect, useRef, useState } from "react";
import { InterfaceShowAllWalletsTheUser } from "./InterfaceShowAllWalletsTheUser";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../api/UrlsList";
import { Box, Grid, styled, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RequestHandler } from "../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { InterfacePaginationInServer } from "../../../api/interfaces/InterfaceSearchInServer";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { InterfaceAPIGetAllNestaWallets } from "../../../screens/people/TrustyOrCustomer/SubInfoProfile/NestaWallets/AllNestaWallets/InterfaceAllNestaWallets";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { EnumStateFetchList } from "../../basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { enumTypeOfAlert } from "../../MUIConfig/alert_popup/interfaceAlertPopup";
import { InterfaceGetAPITheCustomerInfo } from "../../../screens/people/TrustyOrCustomer/customers/CustomerProfile/InterfaceCustomerProfileParent";
import Colors from "../../../config/Colors";
import { WalletIcon } from "../../../assets/icons/svg/WalletIcon";
import { CurrencySymbols } from "../../../helper/CurrencySymbols";
import { getColorCodeOfBalanceNumber } from "../../../helper/Helper";
import { CreditCard, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material";
import { ImageSquare } from "../imageSquare/ImageSquare";
import shadows from "@mui/material/styles/shadows";
import { InterfaceNTTProfile } from "../../../NTTs/InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../../../NTTs/InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../../../NTTs/InterfaceNTTProfileIranLegal";
import { EnumProfileTypeNameInServer } from "../../../NTTs/InterfaceNTTProfileType";

const Text = styled("p")(({ theme }) => ({
    lineHeight: "1.55rem",
    fontSize: "1.03rem",
    userSelect: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    color: Colors.theme.blue.light,
    textAlign: "center",
    boxSizing: "border-box",
}));
function ShowAllWalletsTheUser(paramsComponent: InterfaceShowAllWalletsTheUser) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getResponseGetAllNestaWallet, setResponseGetAllNestaWallet] = useState<InterfaceAPIGetAllNestaWallets>();
    const [getResponseCustomerInfo, setResponseCustomerInfo] = useState<InterfaceGetAPITheCustomerInfo>();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(1); // Default value
    const parentRef = useRef<HTMLDivElement>(null); // Ref to the parent div
    const [translateX, setTranslateX] = useState(0); // To track the translation for smooth scrolling
    const containerRef = useRef<HTMLDivElement>(null); // Ref to the wallet items container


    // Function to calculate how many items can fit based on the parent div's width
    const calculateItemsPerPage = () => {
        if (parentRef.current && containerRef.current) {
            const parentWidth = parentRef.current.offsetWidth;
            const containerWidth = containerRef.current.offsetWidth;
            // const itemWidth = 20 * 16; // Convert rem to pixels (1rem = 16px)
            // const gapWidth = 1.18 * 16; // Gap between items in pixels

            const totalItemWidth = containerWidth / (getResponseGetAllNestaWallet?.data ?? []).length;
            const calculatedItemsPerPage = Math.floor(parentWidth / (totalItemWidth));

            setItemsPerPage(calculatedItemsPerPage > 0 ? calculatedItemsPerPage : 1);
            setTranslateX(-currentIndex * totalItemWidth);
        }

    };
    const handleNext = () => {
        if (currentIndex + itemsPerPage < (getResponseGetAllNestaWallet?.data ?? []).length) {
            setCurrentIndex(currentIndex + 1);
        }
        if ((getResponseGetAllNestaWallet?.total_count ?? 0) > ((getResponseGetAllNestaWallet?.data ?? []).length)) {
            let oldPage = getPaginationForLastRequest;
            oldPage = {
                ...oldPage,
                page: (oldPage.page + 1)
            };
            setPaginationForLastRequest(oldPage);
            callApiForGetAllNestaWallet(oldPage)

        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };
    const callApiForGetAllNestaWallet = async (paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.wallet.nestaWallet.getAllNestaWallets;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.idUser}/nesta-wallets/`, mTheme, paginationParams as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIGetAllNestaWallets;
                if (paginationParams.page === 1) {
                    setResponseGetAllNestaWallet(t);
                }
                else {
                    setResponseGetAllNestaWallet(prev => {
                        return (prev ? {
                            ...t,
                            data: [...prev.data, ...t.data],
                        } : {
                            ...t,
                        });
                    });
                }
                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }

                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                setStateFetchList(EnumStateFetchList.errorInRequest);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }

    const getApiTheCustomerInfo = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.customer.getTheCustomerInfo;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.idUser}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceGetAPITheCustomerInfo;
                setResponseCustomerInfo(t);

                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    // Handle window resize to recalculate itemsPerPage
    useEffect(() => {
        calculateItemsPerPage(); // Calculate initially

        window.addEventListener('resize', calculateItemsPerPage); // Update on window resize

        return () => {
            window.removeEventListener('resize', calculateItemsPerPage); // Cleanup on unmount
        };
    }, []);
    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            callApiForGetAllNestaWallet(getPaginationForLastRequest);
            getApiTheCustomerInfo();
        }
    }, [getStateFetchList])
    // Update translateX whenever currentIndex changes
    useEffect(() => {
        if (containerRef.current && parentRef.current) {
            const parentWidth = parentRef.current.offsetWidth;
            const containerWidth = containerRef.current.offsetWidth;
            // const itemWidth = 20 * 16; // Convert rem to pixels (1rem = 16px)
            // const gapWidth = 1.18 * 16; // Gap between items in pixels

            const totalItemWidth = containerWidth / (getResponseGetAllNestaWallet?.data ?? []).length;


            setTranslateX(-currentIndex * totalItemWidth);
        }
    }, [currentIndex]);
    return (
        <Box sx={{
            border: `1px solid ${Colors.theme.orange.light}`,
            borderRadius: '0.74rem',
            paddingBlock: '1.48rem',

        }}>
            <div style={{
                paddingInline: '1.70rem',
                boxSizing: 'border-box',
            }}>
                <Grid container
                    justifyContent="space-between" alignItems="flex-start"
                    columnSpacing={{ xs: 0, sm: 0, md: "8.81rem", lg: "14.81rem", xl: "9vw" }}
                    rowSpacing={{ xs: "1.2rem", sm: "1.39rem", md: "1.53rem", lg: "1.70rem", xl: "1.87rem" }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >

                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <div style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'start',
                            justifyContent: 'start',
                        }}>
                            <Text style={{
                                color: Colors.theme.blue.dark, fontFamily: confComps.font.poppins.Medium
                            }}>
                                {getResponseCustomerInfo?.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                    `${(getResponseCustomerInfo?.profile as InterfaceNTTProfile).first_name}  ${(getResponseCustomerInfo?.profile as InterfaceNTTProfile).last_name}`
                                }
                                {getResponseCustomerInfo?.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                    `${(getResponseCustomerInfo?.profile as InterfaceNTTProfileForeignLegal).name}`
                                }
                                {getResponseCustomerInfo?.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                    `${(getResponseCustomerInfo?.profile as InterfaceNTTProfileIranLegal).name} (${(getResponseCustomerInfo?.profile as InterfaceNTTProfileIranLegal).name_fa})`
                                }
                            </Text>
                            <Text style={{
                                textAlign: 'start',
                                color: Colors.theme.blue.light,
                                fontFamily: confComps.font.poppins.Regular,
                                fontSize: "0.88rem",
                            }}>

                                {getResponseCustomerInfo?.person_type.value === EnumProfileTypeNameInServer.individualProfile &&
                                    `${(getResponseCustomerInfo?.profile as InterfaceNTTProfile).address}`
                                }
                                {getResponseCustomerInfo?.person_type.value === EnumProfileTypeNameInServer.foreignLegal &&
                                    `${(getResponseCustomerInfo?.profile as InterfaceNTTProfileForeignLegal).city}, ${(getResponseCustomerInfo?.profile as InterfaceNTTProfileForeignLegal).country}`
                                }
                                {getResponseCustomerInfo?.person_type.value === EnumProfileTypeNameInServer.iranLegal &&
                                    `${(getResponseCustomerInfo?.profile as InterfaceNTTProfileIranLegal).address}`
                                }
                            </Text>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <div style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'start',
                            justifyContent: 'start',
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row', alignItems: 'center', justifyContent: 'start',
                                gap: "0.5rem",
                            }}>
                                <Text style={{
                                    color: Colors.theme.blue.light, fontFamily: confComps.font.poppins.Medium
                                }}>
                                    {`${confComps.translate.credit}:`}
                                </Text>
                                <Text style={{
                                    color: Colors.theme.blue.dark, fontFamily: confComps.font.poppins.Medium
                                }}>
                                    {`${getResponseCustomerInfo?.credit} AED`}
                                </Text>
                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row', alignItems: 'center', justifyContent: 'start',
                                gap: "0.5rem",
                            }}>
                                <Text style={{
                                    color: Colors.theme.blue.light, fontFamily: confComps.font.poppins.Medium
                                }}>
                                    {`${confComps.translate.balance}:`}
                                </Text>
                                <Text style={{
                                    color: Colors.theme.blue.dark, fontFamily: confComps.font.poppins.Medium
                                }}>
                                    {`${getResponseCustomerInfo?.balance} AED`}
                                </Text>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row', alignItems: 'center', justifyContent: 'start',
                            flexWrap: 'wrap',
                            width: '100%',
                            gap: "0.5rem",
                        }}>
                            <Text style={{
                                display: 'inline-flex',
                                flexBasis: ((getResponseCustomerInfo?.activities ?? []).length < 3) ? "100%" : undefined,
                                color: Colors.theme.blue.light, fontFamily: confComps.font.poppins.Medium
                            }}>
                                {`${confComps.translate.activities}:`}
                            </Text>
                            {getResponseCustomerInfo?.activities.map((activity, index) => (
                                <div key={index} style={{
                                    background: Colors.theme.orange.light,
                                    borderRadius: "500px",
                                    lineHeight: '1.48rem',
                                    color: Colors.theme.blue.dark,
                                    paddingInline: "0.59rem",
                                    fontFamily: confComps.font.poppins.Regular,
                                    fontSize: "0.96rem",
                                    boxSizing: "border-box",
                                }}>
                                    {activity.title}
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{
                width: '100%',
                height: '1px',
                background: Colors.theme.orange.light,
                borderRadius: '500px',
                marginTop: '1.18rem',
            }} />
            <div style={{
                paddingInline: '1.70rem',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: '1rem',
            }}>
                <Text style={{
                    color: Colors.theme.blue.dark,
                    fontFamily: confComps.font.poppins.Regular,
                    marginTop: '0.88rem',
                }}>
                    {`${confComps.translate.wallets} (${getResponseGetAllNestaWallet?.total_count})`}
                </Text>
                <div style={{
                    width: '100%', display: 'flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1.18rem',
                }}>
                    <button onClick={handlePrevious} disabled={currentIndex === 0}
                        style={{}}>
                        <ImageSquare width={"1.9rem"}
                            borderRadius="500px"
                            background="#fcfcfc"
                            border={currentIndex === 0 ? undefined : "1px solid rgba(119,120,123,0.2)"}
                            style={{
                                boxShadow: Colors.shadow.shadowEditTextBoxClicked


                            }}
                            elementStyle={{

                            }}
                            element={<KeyboardDoubleArrowLeft
                                style={{
                                    opacity: currentIndex === 0 ? 0.5 : 1,
                                    color: Colors.theme.blue.blue,
                                }} />
                            }
                        />
                    </button>

                    <div
                        ref={parentRef}
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'nowrap',
                            alignItems: 'center',
                            justifyContent: 'start',
                            overflow: 'hidden', // Prevent overflow
                        }}
                    >
                        <div
                            ref={containerRef}
                            style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                alignItems: 'center',
                                gap: '1.18rem',
                                transform: `translateX(${translateX}px)`,
                                transition: 'transform 0.5s ease-in-out', // Animation effect
                            }}
                        >
                            {getResponseGetAllNestaWallet?.data
                                // .slice(currentIndex, currentIndex + itemsPerPage) // Show only the wallets for the current page
                                .map((wallet, index, arr) => {
                                    return (
                                        <div style={{
                                            display: 'flex', flexDirection: 'column',
                                            alignItems: 'start',
                                            justifyContent: 'start',
                                            gap: '0.14rem',
                                            border: `1px solid ${Colors.theme.blue.light}`,
                                            borderRadius: '0.59rem',
                                            paddingInline: '0.88rem',
                                            paddingBlock: '0.44rem',
                                            minWidth: '14.51rem',
                                        }}>
                                            <div style={{
                                                display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}>
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', justifyContent: 'start',
                                                    alignItems: 'center',
                                                    gap: '0.29rem',
                                                }}>
                                                    {wallet.currency_unit.is_cash ?
                                                        <WalletIcon style={{
                                                            marginInlineStart: '-1px',
                                                            color: getColorCodeOfBalanceNumber(wallet.balance),
                                                        }} />
                                                        :
                                                        <CreditCard style={{
                                                            marginInlineStart: '-2px',
                                                            fontSize: '1.6rem',
                                                            color: getColorCodeOfBalanceNumber(wallet.balance),
                                                        }} />
                                                    }
                                                    <Text style={{
                                                        textAlign: 'start',
                                                        color: Colors.theme.blue.dark,
                                                        fontFamily: confComps.font.poppins.Regular,
                                                        fontSize: "0.88rem",
                                                    }}>
                                                        {`${wallet.currency_unit.is_cash ? confComps.translate.cash : confComps.translate.noneCash}`}
                                                    </Text>
                                                </div>
                                                <Text style={{
                                                    textAlign: 'start',
                                                    color: getColorCodeOfBalanceNumber(wallet.balance),
                                                    fontFamily: confComps.font.poppins.Regular,
                                                    fontSize: "0.88rem",
                                                }}>
                                                    {wallet.balance}
                                                    {CurrencySymbols[wallet.currency_unit.currency.symbol]}
                                                </Text>
                                            </div>
                                            <div style={{
                                                display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}>
                                                <div style={{
                                                    display: 'flex', flexDirection: 'row', justifyContent: 'start',
                                                    alignItems: 'center',
                                                    gap: '0.29rem',
                                                }}>
                                                    {wallet.currency_unit.country.iso && <img
                                                        src={`https://flagcdn.com/w320/${wallet.currency_unit.country.iso}.png`} // Fallback image
                                                        alt={`${wallet.currency_unit.country.iso}`}
                                                        style={{ width: '1rem', height: 'auto' }}
                                                    />}
                                                    <Text style={{
                                                        textAlign: 'start',
                                                        color: Colors.theme.blue.dark,
                                                        fontFamily: confComps.font.poppins.Regular,
                                                        fontSize: "0.88rem",
                                                    }}>
                                                        {`${wallet.currency_unit.country.name}`}
                                                    </Text>
                                                </div>

                                                <Text style={{
                                                    textAlign: 'start',
                                                    color: Colors.theme.blue.dark,
                                                    fontFamily: confComps.font.poppins.Regular,
                                                    fontSize: "0.88rem",
                                                }}>
                                                    {wallet.currency_unit.currency.symbol}
                                                </Text>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                    <button
                        onClick={handleNext}
                        disabled={currentIndex + itemsPerPage >= (getResponseGetAllNestaWallet?.data ?? []).length}
                        style={{}}
                    >
                        {
                            getStateFetchList === EnumStateFetchList.searching ?
                                <div style={{
                                }}>
                                    <div style={{
                                        border: "4px solid rgba(0, 0, 0, 0.2)",
                                        borderRadius: "50%",
                                        borderTop: "4px solid #000",
                                        width: "1rem",
                                        height: "1rem",
                                        animation: "spin 1s linear infinite"
                                    }} />
                                    <style>{`
                              @keyframes spin {
                                  0% { transform: rotate(0deg); }
                                  100% { transform: rotate(360deg); }
                              }
                                  `}</style>
                                </div>
                                :
                                <ImageSquare width={"1.9rem"}
                                    borderRadius="500px"
                                    background="#fcfcfc"
                                    border={((currentIndex + itemsPerPage) >= (getResponseGetAllNestaWallet?.data ?? []).length) ? undefined : "1px solid rgba(119,120,123,0.2)"}
                                    style={{
                                        boxShadow: Colors.shadow.shadowEditTextBoxClicked
                                    }}
                                    elementStyle={{}}
                                    element={
                                        <KeyboardDoubleArrowRight
                                            style={{
                                                opacity: ((currentIndex + itemsPerPage) >= (getResponseGetAllNestaWallet?.data ?? []).length) ? 0.5 : 1,
                                                color: Colors.theme.blue.blue,
                                            }}
                                        />
                                    }
                                />}
                    </button>
                </div>

            </div>
        </Box>
    )

};
export default ShowAllWalletsTheUser;