import { Box, styled, useTheme } from "@mui/material";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../../api/UrlsList";
import { ImageSquare } from "../../../../../components/complex/imageSquare/ImageSquare";
import Colors from "../../../../../config/Colors";
import { AddRounded, CheckRounded, FileUploadRounded, Person, Person2Rounded, PlusOneRounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import LineSeparator from "../../../../../components/basic/LineSeprator/LineSeparator";
import ArrayText from "../../../../../components/basic/Texts/ArrayText/ArrayText";
import { formatDateGregorian, getColorCodeOfBalanceNumber } from "../../../../../helper/Helper";
import { useNavigate } from "react-router-dom";
import Links from "../../../../../config/Links";
import { InterfaceRowEmployee } from "./InterfaceRowEmployee";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../api/API";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import DialogAcceptProcess from "../../../../../components/complex/Dialog/DialogAcceptProcess/DialogAcceptProcess";
import { useState } from "react";
const ParentDiv = styled("div")(({ theme }) => ({
    width: "100%", padding: "1px",
    height: "100%",
    background: "white",
    boxShadow: Colors.shadow.cardList,
    borderRadius: "0.5rem",
    cursor: "pointer",
    "&:hover": {
        background: Colors.gradients.blueGreenBlue,
    }
}));
function RowEmployee(paramsComponent: InterfaceRowEmployee) {
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getIsDialogDeleteShow, setIsDialogDeleteShow] = useState<boolean>(false);
    const callApiForDeleteEmployee = async (idPerson: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.employee.deleteTheEmployee;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idPerson}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackReloadPage();
                dispatch(ShowAlert({ text: confComps.translate.removedEmployee, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <ParentDiv style={{ height: "100%" }}
            onClick={() => {
                navigate(Links.screens.panel.people.employees.employeeProfile + `${paramsComponent.user.id}/`)
            }}>
            <div style={{
                background: "white",
                height: "100%",
                width: "100%", display: "flex", flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "0.5rem",
                paddingInline: "0.96rem",
                paddingTop: "1.28rem",
                paddingBottom: "0.5rem",
                boxSizing: "border-box",

            }}>
                <div style={{
                    width: "100%", display: "flex", flexDirection: "row", alignItems: "stretch", justifyContent: "start",
                    flexGrow: "1",
                    flexBasis: "0",
                    gap: "0.64rem", boxSizing: "border-box",
                }}>
                    <Box sx={{
                        width: { xs: "60%", sm: "3rem", md: "3.3rem", lg: "3.62rem", xl: "3.92rem" },
                    }}>
                        <ImageSquare width={"100%"}
                            img={(paramsComponent.profile.profile_pic && paramsComponent.profile.profile_pic != null) ? `${baseUrl}${paramsComponent.profile.profile_pic}` : undefined}
                            borderRadius="500px"
                            style={{ boxSizing: "border-box" }}
                            iconSvg={(paramsComponent.profile.profile_pic || paramsComponent.profile.profile_pic != null) ? undefined : Person2Rounded}
                            color={Colors.theme.blue.blue}
                            background={Colors.theme.white.PrimaryLight} />

                    </Box>
                    <div style={{
                        display: "flex", flexDirection: "column",
                        flexGrow: "1",
                        flexBasis: "0",
                        justifyContent: "start",
                        alignItems: "start",
                        boxSizing: "border-box",
                    }}>
                        <div style={{
                            fontFamily: confComps.font.poppins.Medium,
                            color: Colors.theme.blue.dark,
                            fontSize: "0.96rem",
                            lineHeight: "1.44rem",
                            boxSizing: "border-box",
                        }}>
                            {paramsComponent.profile.first_name}
                            {" "}
                            {paramsComponent.profile.last_name}
                        </div>
                        <div style={{
                            boxSizing: "border-box",
                            fontFamily: confComps.font.poppins.Regular,
                            color: Colors.theme.blue.light,
                            fontSize: "0.8rem",
                            lineHeight: "1.2rem",
                        }}>
                            {paramsComponent.profile.address}
                        </div>
                    </div>
                </div>
                <LineSeparator Style={{ marginTop: "1.28rem", marginBottom: "0.96rem", boxSizing: "border-box", }} />
                <div style={{
                    display: "flex", flexDirection: "column", alignItems: "start", width: "100%", justifyContent: "space-between",
                    gap: "0.32rem",
                    boxSizing: "border-box",
                    flexGrow: "0",
                }}>
                    <div style={{
                        display: "flex", flexDirection: "column", alignItems: "start", width: "100%",
                        justifyContent: "space-between",
                        gap: "0.2rem",
                        boxSizing: "border-box",
                        flexGrow: "0",
                    }}>
                        <ArrayText texts={[
                            {
                                text: `${confComps.translate.role}:`,
                            },
                            {
                                text: paramsComponent.role.title,
                                style: {
                                    color: Colors.theme.blue.dark,
                                }
                            }
                        ]}
                        />
                        <ArrayText texts={[
                            {
                                text: `${confComps.translate.DateJoined}:`,
                            },
                            {
                                text: `${formatDateGregorian(paramsComponent.user.date_joined)}`,
                                style: {
                                    color: Colors.theme.blue.dark,
                                }
                            }
                        ]}
                        />
                    </div>



                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center",
                        justifyContent: "space-between", width: "100%",
                        marginTop: "0.32rem",
                        boxSizing: "border-box",
                    }}>
                        <p style={{
                            color: Colors.theme.orange.sharp,
                            cursor: "pointer",
                            fontFamily: confComps.font.poppins.Medium,
                            fontSize: "0.8rem",
                            fontWeight: "200",
                            lineHeight: "1.2rem",
                            boxSizing: "border-box",
                            // background: "red",
                            zIndex: 1,
                            userSelect: "none",
                        }} onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                            event.stopPropagation();
                            setIsDialogDeleteShow(true);
                        }}>
                            {confComps.translate.delete}
                        </p>
                        <p style={{
                            color: Colors.theme.blue.Secondary,
                            cursor: "pointer",
                            fontFamily: confComps.font.poppins.Medium,
                            fontWeight: "200",
                            fontSize: "0.8rem",
                            lineHeight: "1.2rem",
                            boxSizing: "border-box",
                            userSelect: "none",
                        }}>
                            {confComps.panel.people.customerList.pageInfo.item.seeProfile}
                        </p>

                    </div>

                </div>

            </div>
            <DialogAcceptProcess
                greenButtonTitle={confComps.translate.yes}
                redButtonTitle={confComps.translate.no}
                isOpen={getIsDialogDeleteShow} title={"Are you Sure For Delete?"} description={""}
                callbackClickAccept={function (): void {
                    setIsDialogDeleteShow(false);
                    callApiForDeleteEmployee(paramsComponent.user.id)
                }}
                callbackClickReject={function (): void {
                    setIsDialogDeleteShow(false);
                }}
            >
                <div>
                    {`User: ${paramsComponent.profile.first_name} ${paramsComponent.profile.last_name}`}
                </div>
            </DialogAcceptProcess>
        </ParentDiv>
    )
}
export default RowEmployee;