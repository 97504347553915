// import { useEffect, useRef, useState } from "react";
// import { InterfaceParentWithWidthAnimation } from "./InterfaceParentWithWidthAnimation";

// function ParentWithWidthAnimation(paramsComponent: InterfaceParentWithWidthAnimation) {
//     const [width, setWidth] = useState<string>();
//     const [opacity, setOpacity] = useState(0);
//     const [shouldRender, setShouldRender] = useState(paramsComponent.in);
//     const contentRef = useRef<HTMLDivElement>(null);
//     // useEffect(() => {
//     //     if (contentRef && contentRef.current) {
//     //         const scrollWidth = contentRef.current.scrollWidth;
//     //         setWidth(`${scrollWidth}px`);
//     //     }
//     // }, [contentRef])
//     useEffect(() => {
//         if (paramsComponent.in) {
//             setShouldRender(true);
//             console.log("paramsComponent", paramsComponent)

//             if (contentRef.current) {
//                 const scrollWidth = contentRef.current.scrollWidth;
//                 setWidth(`${scrollWidth}px`);
//             }
//             setOpacity(1);

//         } else {
//             if (contentRef.current) {
//                 setWidth('0px');
//                 setOpacity(0);
//             }
//             setTimeout(() => setShouldRender(false), paramsComponent.timeout);
//         }
//     }, [paramsComponent.in, paramsComponent.timeout]);

//     return (
//         <div
//             ref={contentRef}
//             style={{
//                 overflow: paramsComponent.in ? undefined : 'hidden',
//                 width: width,
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "center",
//                 justifyContent: "start",
//                 opacity: opacity,
//                 boxSizing: "border-box",
//                 transition: `all ${paramsComponent.timeout}ms ease-in-out`,
//                 ...paramsComponent.style,
//                 // visibility: shouldRender ? 'visible' : 'hidden', // Ensure visibility is controlled by shouldRender

//             }}
//         >
//             {paramsComponent.children}
//         </div>
//     )


// };
// export default ParentWithWidthAnimation;


import { useEffect, useRef, useState } from "react";
import { InterfaceParentWithWidthAnimation } from "./InterfaceParentWithWidthAnimation";

function ParentWithWidthAnimation(paramsComponent: InterfaceParentWithWidthAnimation) {
    const [width, setWidth] = useState<string>('auto');
    // const [opacity, setOpacity] = useState(0);
    // const [shouldRender, setShouldRender] = useState(paramsComponent.in);
    // const contentRef = useRef<HTMLDivElement>(null);

    // useEffect(() => {
    //     if (paramsComponent.in) {
    //         setShouldRender(true);
    //         // Use requestAnimationFrame to ensure DOM updates have been applied
    //         requestAnimationFrame(() => {
    //             if (contentRef.current) {
    //                 const scrollWidth = contentRef.current.scrollWidth;
    //                 setWidth(`${scrollWidth}px`);
    //                 setOpacity(1);
    //             }
    //         });
    //     } else {
    //         if (contentRef.current) {
    //             setWidth('0px');
    //             setOpacity(0);
    //         }
    //         setTimeout(() => setShouldRender(false), paramsComponent.timeout);
    //     }
    // }, [paramsComponent.in, paramsComponent.timeout]);

    const [opacity, setOpacity] = useState(0);
    const [shouldRender, setShouldRender] = useState(paramsComponent.in);
    const [overflow, setOverflow] = useState(paramsComponent.in ? 'visible' : 'hidden'); // State to manage overflow style
    const contentRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateWidth = () => {
            if (contentRef.current && paramsComponent.in) {
                const scrollWidth = contentRef.current.scrollWidth;
                setWidth(`${scrollWidth}px`);
            }
        };

        if (paramsComponent.in) {
            setShouldRender(true);
            setOverflow('hidden'); // Hide overflow during the animation
            updateWidth();
            setOpacity(1);
            setTimeout(() => {
                setOverflow('visible'); // Make overflow visible after the animation
            }, paramsComponent.timeout);
        } else {
            setWidth('0px');
            setOpacity(0);
            setOverflow('hidden'); // Hide overflow during the animation
            setTimeout(() => {
                setShouldRender(false);
                setOverflow('hidden');
            }, paramsComponent.timeout);
        }

        const resizeObserver = new ResizeObserver(() => {
            updateWidth();
        });

        if (childRef.current) {
            resizeObserver.observe(childRef.current);
        }

        return () => {
            if (childRef.current) {
                resizeObserver.unobserve(childRef.current);
            }
        };
    }, [paramsComponent.in, paramsComponent.timeout]);

    return (
        (
            <div
                ref={contentRef}
                style={{
                    overflow: overflow, // Apply the overflow style conditionally
                    opacity: opacity,
                    width: width,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "start",
                    boxSizing: "border-box",
                    transition: `all ${paramsComponent.timeout}ms ease-in-out`,
                    ...paramsComponent.style,
                }}
            >
                <div
                    ref={childRef}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        ...paramsComponent.style,
                    }}
                >
                    {paramsComponent.children}
                </div>
            </div>
        )
    );
}

export default ParentWithWidthAnimation;
