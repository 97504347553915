import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import Colors from "../../../../config/Colors";
import { keyframes, styled, useTheme } from "@mui/material";
import { SyncRounded } from "@mui/icons-material";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../../api/UrlsList";
import { RequestHandler } from "../../../../api/API";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../MUIConfig/alert_popup/interfaceAlertPopup";
import LineSeparator from "../../../basic/LineSeprator/LineSeparator";
import { InterfaceSelectCompanyTypeOwnershipStructure } from "./InterfaceSelectCompanyTypeOwnershipStructure";
import { InterfaceNTTCompanyTypeOwnershipStructure } from "../../../../NTTs/InterfaceNTTCompanyTypeOwnershipStructure";
import AutoCompleteWithUnderLine from "../../AutoCompleteWithUnderLine/AutoCompleteWithUnderLine";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start",
    fontSize: "1rem",
    width: "100%",
    gap: "1rem",
    paddingInline: "1rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#F8F8F8",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
function SelectCompanyTypeOwnershipStructure(paramsComponent: InterfaceSelectCompanyTypeOwnershipStructure) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getResponseAllItems, setResponseAllItems] = useState<InterfaceNTTCompanyTypeOwnershipStructure[]>([]);
    const [getSelectedItem, setSelectedItem] = useState<InterfaceNTTCompanyTypeOwnershipStructure | undefined>();
    const [getSearchText, setSearchText] = useState<string>("");
    const [getStateIsSearching, setStateIsSearching] = useState<boolean>(false);
    const [getIsFocused, setIsFocused] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);


    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("call changed", event.target.value)
        // Clear the previous timeout if it exists
        // if (timeoutId) {
        //     clearTimeout(timeoutId);
        // }
        // Get the current value from the input
        const { value } = event.target;
        // Set a new timeout
        // const newTimeoutId = setTimeout(() => {
        //     // Call your API function here with the updated value
        //     callApiForGetListItems(value);
        // }, 1000); // Adjust the delay time as needed (e.g., 1000ms = 1 second)
        // // Update state to store the timeout ID
        // setTimeoutId(newTimeoutId);
        // Update the input value state
        setSearchText(value);
    };




    const callApiForGetListItems = async () => {
        dispatch(isVisibilityProgressBar(true));
        setStateIsSearching(true);
        let urlObj = urlsList.panel.company.getAllCompanyTypeOwnerShipStructure;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as (InterfaceNTTCompanyTypeOwnershipStructure[]);
                setResponseAllItems(t);

                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
        setStateIsSearching(false);


    }
    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside = (event: MouseEvent) => {
                if (containerRef.current && getIsFocused && !containerRef.current.contains(event.target as Node)) {
                    setIsFocused(false);
                    setSearchText(getSelectedItem?.title ?? "");
                }
            };

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [getIsFocused]);
    useEffect(() => {
        callApiForGetListItems();
    }, [])
    useEffect(() => {
        if (getResponseAllItems.length > 0) {
            setSelectedItem(getResponseAllItems.find(pre => pre.id === (paramsComponent.defaultIDSelected ?? -1)));
            setSearchText(getResponseAllItems.find((pre: any, index: any, arr: any) => pre.id === (paramsComponent.defaultIDSelected ?? -1))?.title ?? "");
        }
    }, [paramsComponent.defaultIDSelected, getResponseAllItems])
    useEffect(() => {
        getSelectedItem && paramsComponent.onChangeSelectedItem(getSelectedItem);
    }, [getSelectedItem])

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                // borderRadius: ConstStyle.borderRadius,
                fontSize: "1rem",
                position: "relative",
                boxShadow: Colors.shadow.shadowTextFieldWithIconBottomLineClicked,
                // ...componentParams.styleMain,
                paddingBlockStart: "0.32rem",
                boxSizing: "border-box",

            }}
        >
            <div style={{
                flex: 1,
                // paddingInlineEnd: "1rem",
                paddingInlineStart: "0.32rem",
                boxSizing: "border-box",
            }}>
                <AutoCompleteWithUnderLine
                    isShowIconInEnd={true}
                    styleItems={paramsComponent.styleItems}
                    valuesList={getResponseAllItems}
                    onChangeSelectedItem={(items) => {
                        if (items.length > 0) {
                            paramsComponent.onChangeSelectedItem(
                                items.map((item, index, arr) => {
                                    return ({
                                        ...getResponseAllItems.filter(ac => ac.id === item.id)[0]
                                    })

                                })[0]
                            )
                        }
                        else {
                            paramsComponent.onChangeSelectedItem(undefined);
                        }

                    }}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    selectedValueTitle={getSelectedItem ? [getSelectedItem] : []}
                    limitSelect={1}

                />
                <label
                    style={{
                        position: "absolute",
                        boxSizing: "border-box",
                        left: "0.32rem",
                        transition: "all 0.2s ease",
                        pointerEvents: "none",
                        top: getIsFocused || getSelectedItem ? '0px' : 'unset',
                        bottom: getIsFocused || getSelectedItem ? 'unset' : "0rem",
                        transform: getIsFocused || getSelectedItem ? "translate(0%, -50%)" : "translate(0%, 0%)",
                        fontSize: (getIsFocused || getSelectedItem) ? "0.75rem" : "1em",
                        color: Colors.theme.blue.light,
                        backgroundColor: "transparent",
                        padding: "0",
                    }}
                >
                    {paramsComponent.title}
                </label>
            </div>
            {
                // <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }}>
                //     {

                //         <BottomTriangleIcon
                //             style={{
                //                 transform: getIsFocused ? "rotate(180deg)" : "rotate(0deg)",
                //                 transition: "transform 0.5s ease-in-out",
                //                 color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                //                 width: "0.9rem",
                //             }} />

                //     }
                // </div>
            }
            <fieldset
                // aria-hidden="true"
                style={{
                    position: "absolute",
                    boxSizing: "border-box",
                    top: getIsFocused || getSelectedItem ? "calc(-1.02rem - 0.5px)" : "0px",
                    // height: "100%",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    // borderRadius: componentParams.styleMain?.borderRadius,
                    border: "unset",
                    borderBottom: (getIsFocused) ? `1px solid ${Colors.theme.blue.blue}` : `1px solid #C4C7D9`,
                    pointerEvents: "none",
                    padding: "0",
                    margin: "0",
                    display: "block",
                    paddingInline: "0.75rem",
                    // overflow: "hidden"
                }}
            />

        </div>

    );
}
export default SelectCompanyTypeOwnershipStructure;