import React, { useEffect, useState } from 'react';
import Colors from "../../../../../config/Colors";
import { useDispatch, useSelector } from 'react-redux';
import { SelectedProfile } from '../../../../../store/profileStateSlice';
import { styled, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigComps } from '../../../../../config/ConfigCOMP';
import { isVisibilityProgressBar } from '../../../../../store/ProgressbarInRootSlice';
import { HandlerLangUrl, urlsList } from '../../../../../api/UrlsList';
import { RequestHandler } from '../../../../../api/API';
import { InterfaceNTTActivity } from '../../../../../NTTs/InterfaceNTTActivity';
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from '../../../../../api/APIErrorResponse';
import { ShowAlert } from '../../../../../store/AlertStateModeSlice';
import { enumTypeOfAlert } from '../../../../MUIConfig/alert_popup/interfaceAlertPopup';
import AutoCompleteWithUnderLine from '../../../../complex/AutoCompleteWithUnderLine/AutoCompleteWithUnderLine';
import { InterfaceTextFieldMultiSelectorActivitiesJobSimple } from './InterfaceTextFieldMultiSelectorActivitiesJobSimple';

export const ParentDiv = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    // borderRadius: ConstStyle.borderRadius,
    fontSize: "1rem",
    flexGrow: 1,
    position: "relative",
    // boxShadow: Colors.shadow.shadowTextFieldWithIconBottomLineClicked,
    borderRadius: "500px",
    paddingBlockStart: "0.32rem",
    boxSizing: "border-box",

    boxShadow: Colors.shadow.shadowEditTextBoxNC,
    "&:hover": {
        boxShadow: Colors.shadow.shadowEditTextBoxClicked,
    }

}));

function TextFieldMultiSelectorActivitiesJobSimple(componentParams: InterfaceTextFieldMultiSelectorActivitiesJobSimple) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);

    const [getIsFocused, setIsFocused] = useState(false);

    const [getActivitySelected, setActivitySelected] = useState<number[]>([]);
    const [getResponseAllActivity, setResponseAllActivity] = useState<InterfaceNTTActivity[]>([]);

    const getApiListActivity = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.common.activity.getActivity;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as (InterfaceNTTActivity[]);
                setResponseAllActivity(t);
                dispatch(isVisibilityProgressBar(false));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        getApiListActivity();
    }, [])
    useEffect(() => {
        componentParams.selectedActivities && setActivitySelected(componentParams.selectedActivities);
    }, [componentParams.selectedActivities])
    return (
        <ParentDiv
            style={{
                ...componentParams.styleMain,
            }}
        >
            {componentParams.iconStart && (
                <div style={{ display: "flex", alignItems: "center", marginInlineStart: "0.96rem" }}>
                    <componentParams.iconStart style={{ color: getIsFocused ? Colors.theme.blue.dark : Colors.theme.blue.light }} />
                </div>
            )}
            <div style={{
                flex: 1, paddingInlineEnd: "1rem",
                paddingInlineStart: "0.32rem",
                boxSizing: "border-box",
            }}>
                <AutoCompleteWithUnderLine
                    valuesList={getResponseAllActivity}

                    onChangeSelectedItem={(items) => {
                        componentParams.onChangeSelectedRoles(items.map((item, index, arr) => {
                            return ({
                                ...getResponseAllActivity.filter(ac => ac.id === item.id)[0]
                            })
                        }).map(select => select.id));
                    }}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    selectedValueTitle={getResponseAllActivity.filter(role => getActivitySelected.filter(idSelect => idSelect === role.id).length > 0)}

                />
            </div>
            {
                <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }}>
                    {
                        (componentParams.iconEnd && (
                            <componentParams.iconEnd
                                style={{
                                    background: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                    width: "0.9rem",
                                }} />
                        ))
                    }
                </div>
            }
        </ParentDiv>
    );
}

export default TextFieldMultiSelectorActivitiesJobSimple;
