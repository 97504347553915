import { InterfaceNTTActivity } from "../InterfaceNTTActivity";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../InterfaceNTTCurrency";
import { InterfaceNTTProfile } from "../InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../InterfaceNTTProfileIranLegal";
import { InterfaceNTTProfileType } from "../InterfaceNTTProfileType";
import { InterfaceNTTUser } from "../InterfaceNTTUser";
import { InterfaceNTTAttachmentOfFreestyle } from "./InterfaceNTTAttachmentOfFreestyle";
import { InterfaceNTTTypeOfFreestyleStep } from "./InterfaceNTTTypeOfFreestyleStep";
export enum EnumStateValueOfFreestyleStep {
    pendingForManager = 1,
    refinement = 2,
    approvedByManager = 3,
    completed = 4,
}
export interface InterfaceNTTStepOfFreestyle {
    id: number;
    from_person: {
        user: InterfaceNTTUser;
        profile: InterfaceNTTProfile | InterfaceNTTProfileForeignLegal | InterfaceNTTProfileIranLegal;
        person_type: InterfaceNTTProfileType;
        is_customer: boolean;
        is_trusty: boolean;
        balance: string;
        credit: string;
        activities: InterfaceNTTActivity[];
        description: string;
    };
    from_currency_unit: InterfaceNTTCurrencyWithTypeAndLocation;
    from_amount: string;
    from_commission_fix: string;
    from_commission_percent: string;
    from_commission_currency_unit?: InterfaceNTTCurrencyWithTypeAndLocation;
    from_commission_change_rate: string;
    from_commission_total: string;
    to_person: {
        user: InterfaceNTTUser;
        profile: InterfaceNTTProfile | InterfaceNTTProfileForeignLegal | InterfaceNTTProfileIranLegal;
        person_type: InterfaceNTTProfileType;
        is_customer: boolean;
        is_trusty: boolean;
        balance: string;
        credit: string;
        activities: InterfaceNTTActivity[];
        description: string;
    };
    to_currency_unit: InterfaceNTTCurrencyWithTypeAndLocation;
    to_amount_change_rate: string;
    to_amount: string;
    to_commission_fix: string;
    to_commission_percent: string;
    to_commission_currency_unit?: InterfaceNTTCurrencyWithTypeAndLocation;
    to_commission_change_rate: string;
    to_commission_total: string;
    deal_type: InterfaceNTTTypeOfFreestyleStep;
    priority: {
        label: string;
        value: number;
    };
    state: {
        label: string;
        value: EnumStateValueOfFreestyleStep;
    };
    description: string;
    created_at: number;
    updated_at: number;
    attachments: InterfaceNTTAttachmentOfFreestyle[];
}