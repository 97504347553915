import { Box, Input, TextareaAutosize } from "@mui/material";
import { InterfaceTextFieldMultiLine } from "./InterfaceTextFieldMultiLine";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import Colors from "../../../../config/Colors";
import { useEffect, useState } from "react";

// function TextFieldMultiLine(paramsComponent: InterfaceTextFieldMultiLine) {
//     const [getIsFocused, setIsFocused] = useState(false);
//     const [getValue, setValue] = useState(paramsComponent.text);
//     const [textAreasHeight, setTextAreasHeight] = useState('auto');
//     var confComps = ConfigComps();
//     // handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
//     //     this.setState({ value: event.target.value });
//     //   }
//     const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
//         const newValue = e.target.value;
//         paramsComponent.onChangeText(newValue);


//         // Adjust the height of the textarea
//         const textAreaElement = e.target;
//         textAreaElement.style.height = 'auto'; // Reset height to auto for recalculating
//         textAreaElement.style.height = `${textAreaElement.scrollHeight}px`; // Set new height

//         // Update the state for the height
//         setTextAreasHeight((prevHeights) => {
//             let newHeights = `${textAreaElement.scrollHeight}px`;
//             return newHeights;
//         });
//     };
//     useEffect(() => {
//         setValue(paramsComponent.text);
//     }, [paramsComponent.text])
//     return (


//         <div style={{ display: 'flex', flexDirection: 'column', width: "100%", }}>
//             {paramsComponent.title &&
//                 <label style={{
//                     fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
//                     lineHeight: "1.68rem", color: Colors.theme.blue.light, marginBottom: '0.22rem'
//                 }}>
//                     {paramsComponent.title}
//                 </label>
//             }
//             <textarea
//                 onFocus={() => setIsFocused(true)}
//                 onBlur={() => setIsFocused(false)}
//                 value={getValue}
//                 placeholder={paramsComponent.hint}
//                 onChange={handleChange}
//                 style={{
//                     resize: "none",
//                     boxSizing: "border-box",
//                     width: "100%",
//                     background: Colors.theme.blue.white2,
//                     paddingInline: "0.96rem",
//                     paddingBlock: "1rem",
//                     boxShadow: getIsFocused
//                         ? Colors.shadow.shadowEditTextBoxClicked
//                         : Colors.shadow.shadowEditTextMultiLineBoxNC,
//                     borderRadius: "0.4rem",
//                     color: Colors.theme.blue.dark,
//                     textDecoration: "none",
//                     fontFamily: confComps.font.poppins.Regular,
//                     fontSize: "0.96rem",
//                     outline: "none",
//                     border: "none",
//                     ...paramsComponent.styleParent,
//                 }}
//             />
//         </div>





//     )
// };
// export default TextFieldMultiLine;
function TextFieldMultiLine(paramsComponent: InterfaceTextFieldMultiLine) {
    const [getIsFocused, setIsFocused] = useState(false);
    const [getValue, setValue] = useState(paramsComponent.text);
    const [textAreasHeight, setTextAreasHeight] = useState('auto');
    var confComps = ConfigComps();

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        const textAreaElement = e.target;

        // Capture the cursor position before the state update
        const cursorPosition = textAreaElement.selectionStart;

        // Call the onChangeText handler from the parent component
        paramsComponent.onChangeText(newValue);

        // Adjust the height of the textarea
        textAreaElement.style.height = 'auto'; // Reset height to auto for recalculating
        textAreaElement.style.height = `${textAreaElement.scrollHeight}px`; // Set new height

        // Update the value state
        setValue(newValue);

        // Restore the cursor position after the re-render
        requestAnimationFrame(() => {
            textAreaElement.setSelectionRange(cursorPosition, cursorPosition);
        });

        // Update the state for the height
        setTextAreasHeight(`${textAreaElement.scrollHeight}px`);
    };

    useEffect(() => {
        setValue(paramsComponent.text);
    }, [paramsComponent.text]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
            {paramsComponent.title && (
                <label style={{
                    fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                    lineHeight: "1.68rem", color: Colors.theme.blue.light, marginBottom: '0.22rem'
                }}>
                    {paramsComponent.title}
                </label>
            )}
            <textarea
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                value={getValue}
                placeholder={paramsComponent.hint}
                onChange={handleChange}
                style={{
                    resize: "none",
                    boxSizing: "border-box",
                    width: "100%",
                    background: Colors.theme.blue.white2,
                    paddingInline: "0.96rem",
                    paddingBlock: "1rem",
                    boxShadow: getIsFocused
                        ? Colors.shadow.shadowEditTextBoxClicked
                        : Colors.shadow.shadowEditTextMultiLineBoxNC,
                    borderRadius: "0.4rem",
                    color: Colors.theme.blue.dark,
                    textDecoration: "none",
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: "0.96rem",
                    outline: "none",
                    border: "none",
                    ...paramsComponent.styleParent,
                }}
            />
        </div>
    );
}

export default TextFieldMultiLine;
