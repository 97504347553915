import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ConfigComps } from "./../../../config/ConfigCOMP";
import Colors from "./../../../config/Colors";
import { keyframes, styled, useTheme } from "@mui/material";
import { ArrowRightRounded, PaymentsRounded, SyncRounded } from "@mui/icons-material";
import { isVisibilityProgressBar } from "./../../../store/ProgressbarInRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "./../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "./../../../api/UrlsList";
import { RequestHandler } from "./../../../api/API";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "./../../../api/APIErrorResponse";
import { ShowAlert } from "./../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "./../../MUIConfig/alert_popup/interfaceAlertPopup";
import LineSeparator from "./../../basic/LineSeprator/LineSeparator";
import { InterfaceNTTCity } from "./../../../NTTs/InterfaceNTTCity";
import { BottomTriangleIcon } from "./../../../assets/icons/svg/BottomTriangleIcon";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "./../../../NTTs/InterfaceNTTCurrency";
import { InterfaceChangeRateWithAmount } from "./InterfaceChangeRateWithAmount";
import { WalletIcon } from "../../../assets/icons/svg/WalletIcon";
import { CurrencySymbols } from "../../../helper/CurrencySymbols";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row", alignItems: "center", justifyContent: "start",
    fontSize: "1rem",
    width: "100%",
    paddingInline: "0.32rem",
    boxSizing: "border-box",
    paddingBlock: "0.32rem",
    cursor: "pointer",
    lineHeight: "1.68rem",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
const TagOfUser = styled("p")(({ theme }) => ({
    boxSizing: "border-box",
    fontSize: "1.12rem",
    lineHeight: "1.6rem",
    marginInlineStart: "0.5rem",
    color: Colors.theme.orange.light,

}));
const VerticalSeparator = styled("p")(({ theme }) => ({
    width: "1px",
    height: "1rem",
    marginInline: "0.5rem",
    boxSizing: "border-box",
    background: Colors.theme.blue.verticalSeparator,
}));



export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#fff",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
function ChangeRateWithAmount(paramsComponent: InterfaceChangeRateWithAmount) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const [getFromCurrency, setFromCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getToCurrency, setToCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getRate, setRate] = useState<string>("");

    const [getIsFocused, setIsFocused] = useState(false);
    const containerRef2 = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setFromCurrency(paramsComponent.defaultFromCurrency);
    }, [paramsComponent.defaultFromCurrency])
    useEffect(() => {
        setToCurrency(paramsComponent.defaultToCurrency);
    }, [paramsComponent.defaultToCurrency])
    useEffect(() => {
        setRate(paramsComponent.defaultRate);
    }, [paramsComponent.defaultRate])

    return (
        <div style={{
            display: 'flex', flexDirection: 'column', position: "relative",
            ...paramsComponent.style
        }}
            ref={containerRef2}
        >
            {getFromCurrency && getToCurrency &&
                < div style={{
                    fontWeight: "300",
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '1.12rem',
                    lineHeight: "1.68rem",
                    gap: '0.1rem',
                    marginBottom: "0.22rem",
                    color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                }}>
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.4rem',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        lineHeight: "1.68rem",
                        color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                        textWrap: 'wrap',
                    }}>
                        {`${paramsComponent.title} (1X`}
                        {getFromCurrency.is_cash ?
                            <PaymentsRounded style={{
                                fontSize: '1.12rem',
                                lineHeight: "1.68rem",
                            }} />
                            : <WalletIcon
                                style={{
                                    fontSize: '1.12rem',
                                    lineHeight: "1.68rem",
                                }}
                            />
                        }
                        {getFromCurrency.country && getFromCurrency.country.iso && <img
                            src={`https://flagcdn.com/w320/${getFromCurrency.country.iso}.png`} // Fallback image
                            alt={`${getFromCurrency.country.iso}`}
                            style={{ width: 'auto', height: '1.12rem' }}
                        />}
                        {CurrencySymbols[getFromCurrency.currency.symbol]}
                        {")"}
                    </div>
                    {/*  */}
                    =
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.4rem',
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        lineHeight: "1.68rem",
                        color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                    }}>
                        {`(${getRate} X`}
                        {getToCurrency.is_cash ?
                            <PaymentsRounded style={{
                                fontSize: '1.12rem',
                                lineHeight: "1.68rem",
                            }} />
                            :
                            <WalletIcon
                                style={{
                                    fontSize: '1.12rem',
                                    lineHeight: "1.68rem",
                                }}
                            />
                        }
                        {getToCurrency.country && getToCurrency.country.iso && <img
                            src={`https://flagcdn.com/w320/${getToCurrency.country.iso}.png`} // Fallback image
                            alt={`${getToCurrency.country.iso}`}
                            style={{ width: 'auto', height: '1.12rem' }}
                        />}
                        {CurrencySymbols[getToCurrency.currency.symbol]}
                        {")"}
                    </div>
                </div>
            }


            <input
                autoComplete="off"
                type={paramsComponent.type ?? "text"}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                style={{
                    background: Colors.theme.white.white90,
                    paddingInline: '0.64rem',
                    paddingBlock: '0.32rem',
                    border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                    boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                    borderRadius: '0.4rem',
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '1.12rem',
                    color: Colors.theme.blue.dark,
                    outline: 'none',
                    lineHeight: "1.68rem",
                }}
                value={getRate}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    paramsComponent.callbackOnChangeRate(event.target.value);
                }}
            />

        </div >

    );
}
export default ChangeRateWithAmount;