import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const SearchIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
            ...style,
        }}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1863 11.1588C8.95079 12.146 7.38417 12.6226 5.80821 12.4908C4.23225 12.359 2.76658 11.6287 1.71223 10.45C0.65788 9.27128 0.0948836 7.73358 0.138871 6.15273C0.182858 4.57188 0.83049 3.06787 1.94875 1.94961C3.06702 0.831344 4.57102 0.183713 6.15188 0.139725C7.73273 0.0957381 9.27042 0.658734 10.4491 1.71308C11.6279 2.76744 12.3581 4.2331 12.4899 5.80906C12.6218 7.38502 12.1452 8.95164 11.158 10.1872L15.8843 14.9126C15.9519 14.9755 16.006 15.0514 16.0436 15.1358C16.0812 15.2201 16.1014 15.3111 16.103 15.4034C16.1046 15.4957 16.0877 15.5874 16.0531 15.673C16.0185 15.7587 15.967 15.8364 15.9018 15.9017C15.8365 15.967 15.7587 16.0184 15.6731 16.053C15.5875 16.0876 15.4958 16.1046 15.4035 16.103C15.3112 16.1013 15.2201 16.0811 15.1358 16.0435C15.0515 16.006 14.9756 15.9518 14.9126 15.8842L10.1863 11.1588ZM2.92081 9.727C2.24801 9.05413 1.78978 8.19692 1.60403 7.2637C1.41828 6.33049 1.51334 5.36315 1.8772 4.48394C2.24106 3.60473 2.85738 2.85312 3.64829 2.32409C4.43919 1.79506 5.36917 1.51236 6.3207 1.51172C7.27222 1.51108 8.20258 1.79253 8.9942 2.32049C9.78582 2.84846 10.4032 3.59924 10.7682 4.47796C11.1332 5.35667 11.2296 6.32388 11.0451 7.25735C10.8606 8.19082 10.4035 9.04864 9.73164 9.72241L9.72706 9.727L9.72248 9.73066C8.81964 10.6314 7.5962 11.137 6.32088 11.1363C5.04557 11.1356 3.82267 10.6287 2.92081 9.727Z"
                fill={style?.color ? `${style?.color}` : undefined} />
        </svg>
    )
}
