import { CreateMUIStyled, styled, useTheme } from "@mui/material/styles";
import Colors from "../../config/Colors";
import { ImageSquare } from "../../components/complex/imageSquare/ImageSquare";
import { Box } from "@mui/material";

export const MainCard = styled(Box)(({ theme }) => ({
    width: "100%",
    borderRadius: "0.5rem",
    boxShadow: Colors.shadow.dropShadow,
    boxSizing: "border-box",
    paddingInline: "2rem",
    paddingBlock: "1.4rem",
    [theme.breakpoints.up('xs')]: {
        paddingInline: "2.59rem",
        paddingBlock: "1.51rem",
    },
    [theme.breakpoints.up('sm')]: {
        paddingInline: "2.88rem",
        paddingBlock: "1.68rem",
    },
    [theme.breakpoints.up('md')]: {
        paddingInline: "3.19rem",
        paddingBlock: "1.86rem",

    },
    [theme.breakpoints.up('lg')]: {
        paddingInline: "3.55rem",
        paddingBlock: "2.07rem",
    },
    [theme.breakpoints.up('xl')]: {
        paddingInline: "3.91rem",
        paddingBlock: "2.28rem",
    },

}));
export const DivImageProfile = styled('div')(({ theme }) => ({
    boxSizing: "border-box",
    flexGrow: "0",
    width: "2.54rem",
    [theme.breakpoints.up('xs')]: {
        width: "40%",
    },
    [theme.breakpoints.up('sm')]: {
        width: "30%",
    },
    [theme.breakpoints.up('md')]: {
        width: "5.5rem",

    },
    [theme.breakpoints.up('lg')]: {
        width: "6.51rem",
    },
    [theme.breakpoints.up('xl')]: {
        width: "6.8rem",
    },

}));
export const DivNameAndAddressOfProfile = styled('div')(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    [theme.breakpoints.up('xs')]: {
        width: "100%",
        alignItems: "center",
    },
    [theme.breakpoints.up('sm')]: {
        width: "100%",
        alignItems: "center",
    },
    [theme.breakpoints.up('md')]: {
        flexGrow: "1",
        alignItems: "start",
    },
    [theme.breakpoints.up('lg')]: {
        flexGrow: "1",
        alignItems: "start",
    },
    [theme.breakpoints.up('xl')]: {
        flexGrow: "1",
        alignItems: "start",
    },

}));
export const DivCreditAndBalanceAndWalletOfProfile = styled('div')(({ theme }) => ({
    boxSizing: "border-box",
    display: "flex",
    gap: "1.16rem",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "start",
    [theme.breakpoints.up('xs')]: {
        width: "100%",
        alignItems: "center",
    },
    [theme.breakpoints.up('sm')]: {
        flexGrow: 0,
        width: "100%",
        alignItems: "center",
    },
    [theme.breakpoints.up('md')]: {
        flexGrow: 0,
        alignItems: "start",
        flexDirection: "row",
        width: "unset",
    },
    [theme.breakpoints.up('lg')]: {
        flexGrow: 0,
        alignItems: "start",
        flexDirection: "row",
        width: "unset",
    },
    [theme.breakpoints.up('xl')]: {
        flexGrow: 0,
        alignItems: "start",
        flexDirection: "row",
        width: "unset",
    },

}));