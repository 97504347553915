import { useTheme } from "@mui/material";
import { InterfaceParentOfOneSolution } from "./InterfaceParentOfOneSolution";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ConfigComps } from "../../../../../../../../config/ConfigCOMP";
import { InterfaceNTTRecapMain } from "../../../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { InterfaceNTTRecapBuyCottageService } from "../../../../../../../../NTTs/Recap/InterfaceNTTRecapBuyCottageService";
import { InterfaceNTTOneStepOfWorkflowBuyCottageRecap } from "../../../../../../../../NTTs/Recap/WorkflowMainRecap/BuyCottage/InterfaceNTTOneStepOfWorkflowBuyCottageRecap";
import { ShowAlert } from "../../../../../../../../store/AlertStateModeSlice";
import { SelectedProfile } from "../../../../../../../../store/profileStateSlice";
import { isVisibilityProgressBar } from "../../../../../../../../store/ProgressbarInRootSlice";
import { InterfaceAPIResponseGetMetaDataAddSolutions, InterfaceAPIResponseGetOneSolution } from "../../ViewAddSolutionForBuyCottage/InterfaceViewAddSolutionForBuyCottage";
import { EnumRecapServiceStepSolutionNameInServer, InterfaceNTTWorkflowSolution, StepOfSolutionNameMappingToKeyStepForBuyCottageRecap } from "../../../../../../../../NTTs/Recap/solution/InterfaceNTTWorkflowSolution";
import CreateRiskMeasurementSolution from "./RiskMeasurementSolution/CreateRiskMeasurementSolution/CreateRiskMeasurementSolution";
import { EnumSTepsParent, EnumSTepsRecap, EnumSTepsSolutionRecap } from "../../../../../../../../config/enums/EnumSteps";
import { isKey1BiggerThanKey2 } from "../../../../../../../../components/complex/SteperGuide/SteperGuide";
import Colors from "../../../../../../../../config/Colors";
import ViewRiskMeasurementSolution from "./RiskMeasurementSolution/ViewRiskMeasurementSolution/ViewRiskMeasurementSolution";
import CreateCheckWalletOfTrusty from "./CheckWalletOfTrustyInSolution/CreateCheckWalletOfTrustyInSolution/CreateCheckWalletOfTrusty";
import CreateCheckByTrustiesOfSolution from "./CheckByTrustiesOfSolution/CreateCheckByTrustiesOfSolution/CreateCheckByTrustiesOfSolution";
import ViewCheckWalletOfTrusty from "./CheckWalletOfTrustyInSolution/ViewCheckWalletOfTrustyInSolution/ViewCheckWalletOfTrusty";
import ViewCheckByTrustiesOfSolution from "./CheckByTrustiesOfSolution/ViewCheckByTrustiesOfSolution/ViewCheckByTrustiesOfSolution";
import { InterfaceNTTSolutionCheckByTrusties } from "../../../../../../../../NTTs/Recap/solution/steps/InterfaceNTTSolutionCheckByTrusties";
import { EnumRecapServiceNameInServer } from "../../../../../../../../NTTs/Recap/WorkflowMainRecap/InterfaceNTTWorkFlowMainRecap";

function ParentOfOneSolution(paramsComponent: InterfaceParentOfOneSolution) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getInfoServiceBuyCottage, setInfoServiceBuyCottage] = useState<InterfaceNTTRecapBuyCottageService>(paramsComponent.infoServiceInMainRecap);
    const [getInfoThisWorkFlow, setInfoThisWorkFlow] = useState<InterfaceNTTOneStepOfWorkflowBuyCottageRecap>();
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>('1');
    const [getInfoThisSolution, setInfoThisSolution] = useState<InterfaceAPIResponseGetOneSolution>(paramsComponent.infoSolution);
    const [getAllWorkflowReviews, setAllWorkflowReviews] = useState<InterfaceNTTOneStepOfWorkflowBuyCottageRecap[]>([]);
    const [getWorkFlowOfSolution, setWorkFlowOfSolution] = useState<InterfaceNTTWorkflowSolution[]>([]);
    const [getClickInButtonOfParentSubmit, setClickInButtonOfParentSubmit] = useState<{ itemClicked: string; time: number; forWhichStep: string }>({ itemClicked: "", time: -1, forWhichStep: "0.0.0" });
    const callApiForRetrieveWorkflowOfThisSolution = async (params: InterfaceAPIResponseGetOneSolution) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.cottage.buyCottage.steps.addSolutions.stepSolution.getWorkFlowSolution;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${params.id}/workflow-reviews/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceNTTWorkflowSolution[];
                setWorkFlowOfSolution(t);


                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (paramsComponent.infoSolution) {
            callApiForRetrieveWorkflowOfThisSolution(paramsComponent.infoSolution);
            setInfoThisSolution(paramsComponent.infoSolution);
        }
    }, [paramsComponent.infoSolution])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        console.log("safajoon key in final:", paramsComponent.clickOnButtonInParent);
        setClickInButtonOfParentSubmit(paramsComponent.clickOnButtonInParent)
    }, [paramsComponent.clickOnButtonInParent])
    useEffect(() => {
        setAllWorkflowReviews(paramsComponent.allWorkflowReviews);
    }, [paramsComponent.allWorkflowReviews])
    useEffect(() => {
        setInfoThisWorkFlow(paramsComponent.StepWorkFlow);
    }, [paramsComponent.StepWorkFlow])
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setInfoServiceBuyCottage(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    return (
        <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'start',
            justifyContent: 'start',
            width: '100%',
        }}>
            {getWorkFlowOfSolution
                .filter(step => isKey1BiggerThanKey2(getKeyOfStepThatSelected, StepOfSolutionNameMappingToKeyStepForBuyCottageRecap[step.review_ct])
                    || getKeyOfStepThatSelected === StepOfSolutionNameMappingToKeyStepForBuyCottageRecap[step.review_ct])//check that view by step that clicked in top guide
                .map((stepOfSolution, index, arr) => {
                    if (stepOfSolution.review_ct === EnumRecapServiceStepSolutionNameInServer.checkRiskOfSolution) {
                        return (
                            <ViewRiskMeasurementSolution
                                infoThisStepOfSolution={stepOfSolution}
                            />
                        )
                    }
                    else if (stepOfSolution.review_ct === EnumRecapServiceStepSolutionNameInServer.checkWalletOfTrustyForSolution) {
                        return (
                            <ViewCheckWalletOfTrusty
                                infoThisStepOfSolution={stepOfSolution}
                            />

                        )
                    }
                    else if (stepOfSolution.review_ct === EnumRecapServiceStepSolutionNameInServer.checkSolutionByTrusty) {
                        return (
                            <ViewCheckByTrustiesOfSolution
                                infoThisStepOfSolution={stepOfSolution}
                                callBackInfoThisStep={function (params: InterfaceNTTSolutionCheckByTrusties): void {
                                    paramsComponent.callBackInfoCheckByTrustiesStep && paramsComponent.callBackInfoCheckByTrustiesStep(params);
                                }}
                            />

                        )
                    }
                })}
            {getWorkFlowOfSolution.length === 0 &&//not added this step
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}` &&
                //selected step is risk solution
                <CreateRiskMeasurementSolution
                    infoThisSolution={getInfoThisSolution}
                    recapMain={getRecapMain}
                    infoServiceInMainRecap={getInfoServiceBuyCottage}
                    allWorkflowReviews={getAllWorkflowReviews}
                    clickOnButtonInParent={getClickInButtonOfParentSubmit}
                    callbackDataValidation={(isAllDataValid: boolean) => {
                        paramsComponent.callBackAllFieldValidation(getKeyOfStepThatSelected, isAllDataValid);
                    }}
                    callBackStateRequestOfSolution={function (isResponseSuccess: boolean): void {
                        paramsComponent.callBackAllFieldValidation(getKeyOfStepThatSelected, isResponseSuccess);
                    }}
                />
            }
            {getWorkFlowOfSolution && getWorkFlowOfSolution.length > 0 &&
                getWorkFlowOfSolution[getWorkFlowOfSolution.length - 1].review_ct === EnumRecapServiceStepSolutionNameInServer.checkRiskOfSolution &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkWalletOfTrustyForSolution}` &&
                <CreateCheckWalletOfTrusty
                    workFlowOfThisSolution={getWorkFlowOfSolution}
                    infoThisSolution={getInfoThisSolution}
                    clickOnButtonInParent={getClickInButtonOfParentSubmit}
                    callbackDataValidation={(isAllDataValid: boolean) => {
                        paramsComponent.callBackAllFieldValidation(getKeyOfStepThatSelected, isAllDataValid);
                    }}
                    callBackStateRequestOfSolution={function (isResponseSuccess: boolean): void {
                        paramsComponent.callBackAllFieldValidation(getKeyOfStepThatSelected, isResponseSuccess);
                    }}
                />
            }
            {getWorkFlowOfSolution && getWorkFlowOfSolution.length > 0 &&
                getWorkFlowOfSolution[getWorkFlowOfSolution.length - 1].review_ct === EnumRecapServiceStepSolutionNameInServer.checkWalletOfTrustyForSolution &&
                getKeyOfStepThatSelected === `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionByTrusty}` &&
                <CreateCheckByTrustiesOfSolution
                    workFlowOfThisSolution={getWorkFlowOfSolution}
                    infoThisSolution={getInfoThisSolution}
                    clickOnButtonInParent={getClickInButtonOfParentSubmit}
                    callbackDataValidation={(isAllDataValid: boolean) => {
                        paramsComponent.callBackAllFieldValidation(getKeyOfStepThatSelected, isAllDataValid);
                    }}
                    callBackStateRequestOfSolution={function (isResponseSuccess: boolean): void {
                        paramsComponent.callBackAllFieldValidation(getKeyOfStepThatSelected, isResponseSuccess);
                    }}
                />
            }

        </div>
    )
};
export default ParentOfOneSolution;