import { useEffect, useState } from "react";
import { InterfaceListStepsOfFreestyle } from "./InterfaceListStepsOfFreestyle";
import { useTheme } from "@mui/material";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { InterfaceNTTFreestyleMain } from "../../../../../NTTs/Freestyle/InterfaceNTTFreestyleMain";
import Colors from "../../../../../config/Colors";
import ParentWithHeightAnimation from "../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import ButtonSave from "../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import CreateOneStepOfFreestyle from "./OneStepOfFreestyle/CreateOneStepOfFreestyle";
import ViewOneStepOfFreestyle from "./OneStepOfFreestyle/ViewOneStepOfFreestyle/ViewOneStepOfFreestyle";


function ListStepsOfFreestyle(paramsComponent: InterfaceListStepsOfFreestyle) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const [getFreestyleMain, setFreestyleMain] = useState<InterfaceNTTFreestyleMain>();
    const [getIsBoxShow, setIsBoxShow] = useState<boolean>(true);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("1");
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1.1");
    useEffect(() => {
        setFreestyleMain(paramsComponent.freestyle);
    }, [paramsComponent.freestyle])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.keyOfStepThatSelected);
    }, [paramsComponent.keyOfStepThatSelected])
    useEffect(() => {
        setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected);
    }, [paramsComponent.keyOfGroupThatSelected])
    return (
        <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'start',
            justifyContent: 'start',
            width: '100%',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '0.88rem',
            }}
                onClick={() => {
                    setIsBoxShow(prev => !prev);
                }}
            >
                <h6 style={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'start', justifyContent: 'start',
                    color: Colors.theme.blue.dark,
                    fontSize: "1.18rem",
                    lineHeight: "1.77rem",
                    fontFamily: confComps.font.poppins.Medium,
                }}>
                    {`Steps:${getFreestyleMain?.steps.length === 0 ? "" : `(${getFreestyleMain?.steps.length})`}`}
                </h6>

                <KeyboardArrowDownRounded
                    style={{
                        fontSize: "1.9rem",

                        color: Colors.theme.blue.dark,
                        cursor: "pointer",
                        transform: getIsBoxShow ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "all 0.5s ease-in-out",
                    }}
                />
            </div>
            <ParentWithHeightAnimation in={getIsBoxShow} style={{ width: '100%' }}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    gap: "1rem",
                }}>
                    {getFreestyleMain?.steps.map((step, index, arr) => {
                        return (
                            <ViewOneStepOfFreestyle
                                step={step}
                                key={index}
                                index={index + 1}
                                stateOfMainFreestyle={getFreestyleMain.state.value}
                                callbackDeleteThisStep={function (): void {
                                    paramsComponent.callbackOnClickDeleteOneStep();
                                }} />

                        )
                    })}


                </div>
            </ParentWithHeightAnimation>
        </div >
    )
}
export default ListStepsOfFreestyle;