import React, { useEffect, useState } from 'react';
import Colors from "../../../../config/Colors";
import { NoneEyeIcon } from "../../../../assets/icons/svg/NoneEyeIcon";
import { EyeIcon } from "../../../../assets/icons/svg/EyeIcon";
import { EnumTypeInput, InterfaceTextFieldWithIconBottomLine } from './InterfaceTextFieldWithIconBottomLine';


function TextFieldWithIconBottomLine(componentParams: InterfaceTextFieldWithIconBottomLine) {
    const [getValue, setValue] = useState("");
    const [getIsFocused, setIsFocused] = useState(false);
    const [getIsShowPassword, setIsShowPassword] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputElement = e.target;
        const cursorPosition = inputElement.selectionStart; // Store the cursor position

        if (componentParams.maxCountCharacter && inputElement.value.length > componentParams.maxCountCharacter) {
            return;
        }

        if (componentParams.customType === EnumTypeInput.nationalID && !/^\d*$/.test(inputElement.value)) {
            return;
        }

        if (componentParams.type === 'tel') {
            const newValue = inputElement.value;
            if (newValue.length === 0 || newValue.match(/^\+$|^\+[1-9]$|^\+[1-9]\d{1,13}$/)) {
                setValue(newValue);
                componentParams.onChangeValue(newValue);
            } else if (("" + getValue).length > ("" + newValue).length) {
                setValue(newValue);
                componentParams.onChangeValue(newValue);
            }
        } else {
            const newValue = inputElement.value;
            setValue(newValue);
            componentParams.onChangeValue(newValue);
        }

        // Restore the cursor position after updating the state
        setTimeout(() => {
            inputElement.setSelectionRange(cursorPosition, cursorPosition);
        }, 0);
    };
    const handleClickShowPassword = () => {
        setIsShowPassword(!getIsShowPassword);
    };
    useEffect(() => {
        componentParams.defaultValue && setValue(componentParams.defaultValue);
    }, [componentParams.defaultValue])

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                // borderRadius: ConstStyle.borderRadius,
                fontSize: "1rem",
                position: "relative",
                boxShadow: Colors.shadow.shadowTextFieldWithIconBottomLineClicked,
                ...componentParams.styleMain,
                direction: componentParams.isDataMustLTR ? "ltr" : undefined,
                paddingBlockStart: "0.32rem",
                boxSizing: "border-box",

            }}
        >
            {componentParams.iconStart && (
                <div style={{ display: "flex", alignItems: "center", marginInlineStart: "0.96rem" }}>
                    <componentParams.iconStart style={{ color: getIsFocused ? Colors.theme.blue.dark : Colors.theme.blue.light }} />
                </div>
            )}
            <div style={{
                flex: 1, paddingInlineEnd: "1rem",
                paddingInlineStart: "0.32rem",
                boxSizing: "border-box",
            }}>
                <input
                    autoComplete="new-"
                    type={getIsShowPassword ? "text" : componentParams.type}
                    pattern={componentParams.patternInput}
                    value={getValue}
                    onChange={handleChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    disabled={componentParams.isLockValue}
                    style={{
                        cursor: (componentParams.isLockValue ?? false) ? "default" : "pointer",
                        width: "100%",
                        border: "none",
                        outline: "none",
                        fontSize: "1.08rem",
                        lineHeight: "1.54rem",
                        boxSizing: "border-box",
                        color: Colors.theme.blue.dark
                        // padding: "0.5em 0",
                    }}
                />
                <label
                    style={{
                        position: "absolute",
                        boxSizing: "border-box",
                        left: ((getIsFocused || getValue) || !componentParams.iconStart) ? "0.32rem" : "2.4rem",
                        transition: "all 0.2s ease",
                        pointerEvents: "none",
                        top: getIsFocused || getValue ? '0px' : 'unset',
                        bottom: getIsFocused || getValue ? 'unset' : "0rem",
                        transform: getIsFocused || getValue ? "translate(0%, -50%)" : "translate(0%, 0%)",
                        fontSize: (getIsFocused || getValue) ? "0.75rem" : "1em",
                        color: Colors.theme.blue.light,
                        backgroundColor: "transparent",
                        padding: "0",
                    }}
                >
                    {componentParams.hintText}
                </label>
            </div>
            {
                <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }} onClick={handleClickShowPassword}>
                    {
                        (!componentParams.iconEnd) && (((componentParams.type ?? "").toLowerCase()) === "password") &&
                        (getIsShowPassword ?

                            <EyeIcon style={{
                                background: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                width: "1.5rem", height: "1.3rem"
                            }} />
                            : <NoneEyeIcon
                                style={{
                                    background: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                    width: "1.5rem", height: "1.3rem"
                                }} />

                        )

                    }
                    {
                        (componentParams.iconEnd && (
                            <componentParams.iconEnd
                                style={{
                                    background: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                    width: "0.9rem",
                                }} />
                        ))
                    }
                </div>
            }
            <fieldset
                // aria-hidden="true"
                style={{
                    position: "absolute",
                    boxSizing: "border-box",
                    top: getIsFocused || getValue ? "calc(-1.02rem - 0.5px)" : "0px",
                    // height: "100%",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    borderRadius: componentParams.styleMain?.borderRadius,
                    border: "unset",
                    borderBottom: (getIsFocused) ? `1px solid ${Colors.theme.blue.blue}` : `1px solid #C4C7D9`,
                    pointerEvents: "none",
                    padding: "0",
                    margin: "0",
                    display: "block",
                    paddingInline: "0.75rem",
                    // overflow: "hidden"
                }}
            >
                <legend
                    style={{
                        overflow: "hidden",
                        float: "unset",
                        boxSizing: "border-box",
                        transition: "all 0.2s ease",
                        left: getIsFocused || getValue ? "1rem" : "1rem",
                        pointerEvents: "none",
                        // color: getIsFocused ? '#000' : '#666',
                        display: (getIsFocused || getValue) ? "block" : "none",
                        // transform: "translate(0%,-60%)",
                        // visibility: "hidden",
                        // background: "red",
                    }}
                >
                    <span style={{
                        opacity: 0,
                        fontSize: "0.75rem",
                        visibility: "visible", display: "inline-block",
                        paddingInlineEnd: "0.5rem",
                        boxSizing: "border-box",
                    }}>
                        {componentParams.hintText}
                    </span>
                </legend>
                {/* } */}
            </fieldset>
        </div>
    );
}

export default TextFieldWithIconBottomLine;
