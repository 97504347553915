import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const BottomTriangleIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
            ...style
        }}>
            <path d="M8.35487 9.03C8.50813 9.17045 8.71589 9.24934 8.93251 9.24934C9.14912 9.24934 9.35688 9.17045 9.51015 9.03L17.1394 2.03C17.2535 1.92512 17.3313 1.79154 17.3628 1.64614C17.3942 1.50074 17.378 1.35004 17.3162 1.21308C17.2544 1.07612 17.1498 0.959032 17.0155 0.876612C16.8812 0.794191 16.7233 0.750134 16.5617 0.750004L1.30329 0.750004C1.14173 0.750134 0.983829 0.794191 0.849539 0.876612C0.715248 0.959032 0.610588 1.07612 0.548779 1.21308C0.486969 1.35004 0.470785 1.50074 0.502266 1.64614C0.533747 1.79154 0.611481 1.92512 0.725653 2.03L8.35487 9.03Z"
                fill={style?.color ?? "#22357E"}
            />
        </svg>
    )
};

