import { useEffect, useState } from "react";
import { InterfaceProgressBarGreenToRed } from "./InterfaceProgressBarGreenToRed";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";

function ProgressBarGreenToRed(paramsComponent: InterfaceProgressBarGreenToRed) {
    var confComps = ConfigComps();
    const [value, setValue] = useState(paramsComponent.defaultValue);

    // Update the state when defaultValue prop changes
    useEffect(() => {
        setValue(paramsComponent.defaultValue);
    }, [paramsComponent.defaultValue]);

    // Handle slider change
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        setValue(newValue);
        paramsComponent.callbackChangeValue(newValue); // Call the callback function with the new value
    };


    return (
        <div style={{
            width: '100%',
            display: 'flex', flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'start',
            gap: '0.74rem',
        }}>
            <label style={{
                fontSize: '1.037.rem',
                lineHeight: '1.4rem',
                color: Colors.theme.blue.light,
                display: 'block',
                fontFamily: confComps.font.poppins.Regular,
                boxSizing: 'border-box',
            }}>
                {paramsComponent.title}
            </label>
            <div style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center',
                justifyContent: 'start',
                width: '100%',
                gap: '0.88rem',
                boxSizing: 'border-box',
            }}>
                <input
                    type="range"
                    min="0"
                    max="100"
                    value={value}
                    onChange={handleChange}
                    style={{
                        WebkitAppearance: 'none',
                        appearance: 'none',
                        width: '0',
                        flexGrow: 1,
                        flexBasis: 0,
                        height: '1.03rem',
                        borderRadius: '0.5rem',
                        outline: 'none',
                        opacity: 0.8,
                        boxSizing: 'border-box',
                        border: `1px solid #D3D5E1`,
                        boxShadow: Colors.shadow.cardList,
                        transition: 'background 0.3s',
                        background: `linear-gradient(to right, green, yellow, orange, red)`,
                    }}
                />
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.88rem',
                    boxSizing: 'border-box',
                    lineHeight: '1rem',
                    paddingBlock: '0.37rem',
                    paddingInline: '1.11rem',
                    color: Colors.theme.blue.dark,
                    fontFamily: confComps.font.poppins.Regular,
                    borderRadius: '500px',
                    boxShadow: Colors.shadow.shadowEditTextBoxClicked,
                }}>
                    {`${value}% Risk`}

                </div>
            </div>
            {/* Customizing the slider thumb */}
            <style>
                {`
                    input[type='range']::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        appearance: none;
                        width: 1.62rem;
                        height: 1.62rem;
                        border-radius: 50%;
                        background: white;
                        cursor: pointer;
                        border: 2px solid #4a4a4a;
                    }

                    input[type='range']::-moz-range-thumb {
                        width: 1.62rem;
                        height: 1.62rem;
                        border-radius: 50%;
                        background: white;
                        cursor: pointer;
                        border: 2px solid #4a4a4a;
                    }
                `}
            </style>
        </div >
    );
}
export default ProgressBarGreenToRed;