import { useEffect, useState } from "react";
import ParentWithHeightAnimation from "../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import { InterfaceControllerInitiateBuyCottage } from "./InterfaceControllerInitiateBuyCottage";
import { Box, Grid, useTheme } from "@mui/material";
import TextFieldSimple from "../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../../../NTTs/InterfaceNTTCurrency";
import SelectCurrencyWithTypeAndLocation from "../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import SubTitleSection from "../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection";
import TextFieldMultiLine from "../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import Colors from "../../../../../../config/Colors";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import SelectUser from "../../../../../../components/complex/Selector/SelectUser/SelectUser";
import { InterfaceNTTUser } from "../../../../../../NTTs/InterfaceNTTUser";
import SelectTrustyOrCustomer from "../../../../../../components/complex/Selector/SelectTrustyAndCustomer/SelectTrustyOrCustomer";
import { InterfaceGetAPITrustyOrCustomer } from "../../../../../../components/complex/Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer";
import { InterfaceNTTRecapMain } from "../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { InterfaceSearchInServer } from "../../../../../../api/interfaces/InterfaceSearchInServer";
import { urlsList, HandlerLangUrl, TypeQueryParams } from "../../../../../../api/UrlsList";
import { EnumStateFetchList } from "../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { InterfaceGetAPICustomersList } from "../../../../../people/TrustyOrCustomer/customers/customersList/InterfaceCustomersList";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Links from "../../../../../../config/Links";
import CreateInitiateBuyCottage from "./CreateInitiateBuyCottage/CreateInitiateBuyCottage";
import ViewInitiateBuyCottage from "./ViewInitiateBuyCottage/ViewInitiateBuyCottage";
import { InterfaceNTTRecapBuyCottageService } from "../../../../../../NTTs/Recap/InterfaceNTTRecapBuyCottageService";

function ControllerInitiateBuyCottage(paramsComponent: InterfaceControllerInitiateBuyCottage) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1");
    const [getIsCreateBuyCottageActive, setIsCreateBuyCottageActive] = useState<boolean>(false);
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getIsWaitingForCreate, setIsWaitingForCreate] = useState<boolean>(false);
    const [getInfoServiceInMainRecap, setInfoServiceInMainRecap] = useState<InterfaceNTTRecapBuyCottageService>();

    useEffect(() => {
        setIsCreateBuyCottageActive(paramsComponent.isCreateBuyCottageActive);
    }, [paramsComponent.isCreateBuyCottageActive])
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setInfoServiceInMainRecap(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])

    return (
        <div style={{ width: "100%" }}>
            {((getInfoServiceInMainRecap === undefined) ||
                getIsWaitingForCreate) && getKeyOfStepThatSelected === "1.1" &&
                <CreateInitiateBuyCottage
                    isCreateBuyCottageActive={getIsCreateBuyCottageActive}
                    recapMain={getRecapMain}
                    callBackCreateMainRecap={paramsComponent.callBackCreateMainRecap}
                    callBackSetWaiting={(isWaiting: boolean) => {
                        setIsWaitingForCreate(isWaiting);
                    }}
                    callBackReLoad={() => {
                        console.log("callback reload caled:)")
                        paramsComponent.callBackReLoad();
                    }}
                />
            }
            {
                getInfoServiceInMainRecap && (
                    <ViewInitiateBuyCottage
                        recapMain={getRecapMain}
                        infoServiceInMainRecap={getInfoServiceInMainRecap}
                    />
                )

            }
            {((getInfoServiceInMainRecap === undefined) ||
                getIsWaitingForCreate) && getKeyOfStepThatSelected !== "1.1" &&
                <div style={{
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: "center",
                    justifyContent: "center",
                    color: Colors.theme.orange.sharp,
                    fontFamily: confComps.font.poppins.Medium,
                    fontSize: "1.4rem",
                }}
                >
                    No This Service Initiated!
                </div>

            }
        </div>


    )

}
export default ControllerInitiateBuyCottage;