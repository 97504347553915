import { Box, Grid, styled, useTheme } from "@mui/material";
import { InterfaceMergeSelectedRecaps } from "./InterfaceMergeSelectedRecaps";
import Colors, { ColorThemForButton } from "../../../../../config/Colors";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { ArrowRightRounded, CheckBoxOutlineBlankRounded, CheckBoxRounded, CheckCircleOutlineRounded, DoneRounded, HistoryToggleOffRounded, JoinFullRounded, KeyboardArrowDownRounded, PaymentsRounded, RectangleRounded, RemoveCircle, RemoveCircleRounded, VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TickWithCircleIcon } from "../../../../../assets/icons/svg/TickWithCircleIcon";
import ButtonColor from "../../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import OpenProfile from "../../../../../components/basic/OpenProfile/OpenProfile";
import ProgressBarGradient from "../../../../../components/basic/progressBar/ProgressBarGradient/ProgressBarGradient";
import ArrayText from "../../../../../components/basic/Texts/ArrayText/ArrayText";
import Links from "../../../../../config/Links";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { useEffect, useState } from "react";
import ParentWithHeightAnimation from "../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import ShowDescriptionWithUsers from "../../../../../components/complex/ShowDescriptionWithUsers/ShowDescriptionWithUsers";
import { WalletIcon } from "../../../../../assets/icons/svg/WalletIcon";
import { BottomTriangleIcon } from "../../../../../assets/icons/svg/BottomTriangleIcon";
import { CurrencySymbols } from "../../../../../helper/CurrencySymbols";
import { InterfaceFinalRecap } from "../InterfaceCreateFreestyle";
import ButtonSave from "../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { RequestHandler } from "../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../api/APIErrorResponse";
import { urlsList, HandlerLangUrl } from "../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { EnumRolePerson } from "../../../../../config/enums/EnumRolePerson";
import { ShowAlert } from "../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../store/ProgressbarInRootSlice";
import { title } from "process";
import TextFieldMultiLine from "../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
const ParentDiv = styled("div", { shouldForwardProp: (prop) => prop !== 'isActive' })<{ isActive: boolean; }>(({
    theme,
    isActive,
}) => {
    return (
        {
            width: "100%", padding: "1px",
            background: isActive ? Colors.gradients.blueGreenBlue : "white",
            boxShadow: isActive ? Colors.shadow.greenCard : Colors.shadow.cardList,
            borderRadius: "0.5rem",
            // cursor: "pointer",
            "&:hover": {
                background: Colors.gradients.blueGreenBlue,
            }
        }
    )
});
const TagService = styled("div")(({ theme }) => ({
    background: Colors.theme.white.PrimaryLight,
    color: Colors.theme.blue.dark,
    boxShadow: Colors.shadow.cardList,
    paddingInline: "0.5rem",
    paddingBlock: "0.16rem",
    borderRadius: "500px",
    boxSizing: "border-box",
    lineHeight: "1.2rem",
    fontSize: "1rem",


}));
function MergeSelectedRecaps(paramsComponent: InterfaceMergeSelectedRecaps) {
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getRecaps, setRecaps] = useState<InterfaceFinalRecap[]>([]);
    const [getDescription, setDescription] = useState<string>("");
    const [getRecapsShow, setRecapsShow] = useState<number[]>([]);
    const callApiCreateFreestyle = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.freestyle.postCreateFreestyleInitiate;

        let dataObj = {
            'senders': [...getRecaps.flatMap(recap => recap.receipts.flatMap(receipt => receipt.customer.user.id)), ...getRecaps.flatMap(recap => recap.buy_cottages.flatMap(buy => buy.customer.user.id))],
            'recipients': [...getRecaps.flatMap(recap => recap.payments.flatMap(payment => payment.customer.user.id)), ...getRecaps.flatMap(recap => recap.sell_cottages.flatMap(sell => sell.customer.user.id))],
            'recaps': getRecaps.map(recap => recap.id),
            'title': "",
            'description': getDescription,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                navigate(Links.screens.panel.deals.freeStyle.ShowStateTheFreestyle+`${response.id}/`)
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: "Your Freestyle created", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    useEffect(() => {
        setRecaps(paramsComponent.selectedRecap);
    }, [paramsComponent.selectedRecap])
    return (
        <ParentDiv style={{}}
            isActive={getRecaps.length > 0}
        >
            <Box
                sx={{
                    background: "white",
                    height: "100%",
                    width: "100%", display: "flex", flexDirection: "column",
                    alignItems: "center", justifyContent: "space-between",
                    borderRadius: "0.5rem",
                    paddingInline: { xs: '1rem', sm: "1.2rem", md: "1.33rem", lg: "1.48rem", xl: "1.62rem" },//{{ xs: "0.96rem" }},
                    paddingTop: "1.28rem",
                    paddingBottom: { xs: '0.8rem', sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.3rem" },
                    boxSizing: "border-box",

                }}>
                {getRecaps.map((recap, index, arr) => {
                    return (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'start',
                            width: '100%',
                        }}>
                            <div style={{
                                display: 'flex', flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: "100%",
                                gap: "0.4rem",
                                marginBlockEnd: "1.18rem",
                            }}>
                                <div style={{
                                    display: 'inline-flex', flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    gap: "0.4rem",
                                }}>
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Medium,
                                        fontSize: '1.03rem',
                                        lineHeight: '1.55rem',
                                        color: Colors.theme.blue.dark,

                                    }}>
                                        {`${confComps.translate.recapID}: #${recap.id}`}
                                    </p>
                                    <p style={{
                                        color: Colors.theme.blue.Secondary,
                                        fontFamily: confComps.font.poppins.SemiBold,
                                        fontSize: "0.9rem",
                                        lineHeight: "1.6rem",
                                        flexWrap: "nowrap",
                                        textWrap: "nowrap",
                                        cursor: "pointer",
                                        userSelect: "none",
                                    }}
                                        onClick={(event) => {
                                            navigate(Links.screens.panel.recap.ShowStateTheRecap + `${recap.id}/`)
                                            event.stopPropagation();

                                        }}
                                    >
                                        {confComps.translate.showDetails}
                                    </p>
                                    <ArrayText
                                        texts={[
                                            {
                                                text: `(${dayjs.unix(recap.created_at).format("YYYY/MM/DD")})`,
                                                style: {
                                                    color: Colors.theme.blue.dark,
                                                }

                                            }
                                        ]}
                                    />
                                </div>

                                <div style={{
                                    display: 'inline-flex',
                                    gap: "0.2rem",
                                }}>

                                    {(getRecapsShow.findIndex(pred => pred === recap.id) > -1) ?
                                        <div onClick={() => {
                                            setRecapsShow(prev => prev.filter(id => id !== recap.id));
                                        }}>
                                            <VisibilityOffRounded style={{
                                                color: Colors.theme.blue.blue,
                                                fontSize: '2rem'
                                            }} />
                                        </div>
                                        :
                                        <div onClick={() => {
                                            setRecapsShow([recap.id]);
                                        }}>
                                            <VisibilityRounded style={{
                                                color: Colors.theme.blue.dark,
                                                fontSize: '2rem'
                                            }} />
                                        </div>
                                    }
                                    <div onClick={() => {
                                        paramsComponent.callbackOnClickRemoveTheRecap(recap.id);
                                    }}>
                                        <RemoveCircleRounded style={{
                                            color: Colors.theme.red.red,
                                            fontSize: '1.8rem'
                                        }}
                                        />
                                    </div>

                                </div>
                            </div>
                            {/* <Row */}
                        </div>



                    )
                })}
                <div style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'start',
                    justifyContent: "start",
                    width: '100%',
                    gap: '1rem',
                }}>
                    <TextFieldMultiLine
                        title={confComps.translate.explanation}
                        text={getDescription}
                        onChangeText={setDescription}
                        styleParent={{
                            boxSizing: 'border-box',
                            border: `1px solid ${Colors.theme.blue.border}`,
                            background: Colors.theme.white.white90,
                        }}
                    />
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end', width: '100%',
                    }}>

                        <ButtonSave text={"Create Freestyle"}
                            iconStart={<JoinFullRounded />}
                            callbackOnClick={() => {
                                callApiCreateFreestyle();
                            }}
                        />
                    </div>
                </div>
            </Box>

        </ParentDiv >
    );
}
export default MergeSelectedRecaps;