import { Box, useTheme } from "@mui/material";
import { InterfaceCreateApproveSolutionByManagerForMoneyReceive } from "./InterfaceCreateApproveSolutionByManagerForMoneyReceive";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { InterfaceNTTRecapMain } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMain";
import { InterfaceNTTRecapMoneyReceiveService } from "../../../../../../../NTTs/Recap/InterfaceNTTRecapMoneyReceiveService";
import { InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap } from "../../../../../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import { InterfaceAPIResponseGetOneSolution } from "../../AddSolutionForMoneyReceive/ViewAddSolutionForMoneyReceive/InterfaceViewAddSolutionForMoneyReceive";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, urlsList } from "../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import TextFieldMultiLine from "../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import Colors from "../../../../../../../config/Colors";
import ButtonSave from "../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { DeleteRounded, SentimentVeryDissatisfiedRounded, ThumbDownRounded, ThumbUpRounded } from "@mui/icons-material";
import ButtonCancel from "../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel";
import { TrashIcon } from "../../../../../../../assets/icons/svg/TrashIcon";
import Links from "../../../../../../../config/Links";

function CreateApproveSolutionByManagerForMoneyReceive(paramsComponent: InterfaceCreateApproveSolutionByManagerForMoneyReceive) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>('1');
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getIsWaitingForCreate, setIsWaitingForCreate] = useState<boolean>(false);
    const [getInfoServiceInMainRecap, setInfoServiceInMainRecap] = useState<InterfaceNTTRecapMoneyReceiveService>();
    const [getInfoThisWorkFlow, setInfoThisWorkFlow] = useState<InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap>();
    const [getAllWorkflowReviews, setAllWorkflowReviews] = useState<InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap[]>([]);
    const [getDescriptionValue, setDescriptionValue] = useState<string>("");
    const callApiForCreateCommentByManager = async (isAgree: boolean) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyReceive.steps.approvalSolutionForThis.createSteps;
        let bodyObj = {
            "previous_review": {
                "review_type": getAllWorkflowReviews[getAllWorkflowReviews.length - 1].review_ct, // or recapservicefundcontrol
                "object_id": getAllWorkflowReviews[getAllWorkflowReviews.length - 1].review_id,
            },
            "is_approved": isAgree,
            'description': getDescriptionValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.infoServiceInMainRecap.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                paramsComponent.callBackReLoad();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'Your Comment Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const handleOnClickOnButtonDelete = async () => {
        if (((getRecapMain?.buy_cottages.length ?? 0) + (getRecapMain?.sell_cottages.length ?? 0) +
            (getRecapMain?.payments.length ?? 0) + (getRecapMain?.receipts.length ?? 0)) > 1
        ) {
            callApiForDeleteThisService();
        }
        else {
            callApiForDeleteThisRecap();
        }
    }
    const callApiForDeleteThisService = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyReceive.deleteThisService;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.infoServiceInMainRecap.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                paramsComponent.callBackReLoad();
                navigate(Links.screens.panel.recap.listRecap);
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'This Service Removed', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    const callApiForDeleteThisRecap = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.deleteThisRecap;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.recapMain?.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                paramsComponent.callBackReLoad();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'This Service Removed', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    useEffect(() => {
        setRecapMain(paramsComponent.recapMain);
    }, [paramsComponent.recapMain])
    useEffect(() => {
        setKeyOfStepThatSelected(paramsComponent.stepThatSelected);
    }, [paramsComponent.stepThatSelected])
    useEffect(() => {
        setInfoServiceInMainRecap(paramsComponent.infoServiceInMainRecap);
    }, [paramsComponent.infoServiceInMainRecap])
    useEffect(() => {
        setInfoThisWorkFlow(paramsComponent.StepWorkFlow);
    }, [paramsComponent.StepWorkFlow])
    useEffect(() => {
        setAllWorkflowReviews(paramsComponent.allWorkflowReviews);
    }, [paramsComponent.allWorkflowReviews])
    return (
        <div>
            {getInfoServiceInMainRecap &&
                <Box sx={{
                    boxSizing: "border-box",
                    paddingInlineStart: { xs: "0.8rem", sm: "0.9rem", md: "1.0rem", lg: "1.18rem", xl: "1.3rem" },
                    paddingInlineEnd: { xs: "0.8rem", sm: "3.33rem", md: "3.7rem", lg: "4.14rem", xl: "6rem" },
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: 'start',
                    width: "100%",
                    marginTop: "1.18rem",
                    gap: "1.18rem",
                }}>

                    <TextFieldMultiLine
                        title={confComps.translate.explanation}
                        text={getDescriptionValue}
                        onChangeText={setDescriptionValue}
                        styleParent={{
                            boxSizing: 'border-box',
                            border: `1px solid ${Colors.theme.blue.border}`,
                            background: Colors.theme.white.white90,
                        }}
                    />
                    <div style={{
                        display: 'flex', flexDirection: 'row', alignItems: 'center',
                        justifyContent: 'end',
                        width: '100%',
                        gap: '0.88rem',
                    }}>
                        <ButtonCancel
                            text={confComps.translate.disagree}
                            callbackOnClick={() => {

                                callApiForCreateCommentByManager(false);
                                setDescriptionValue("");
                            }}
                            iconStart={<ThumbDownRounded
                                style={{
                                    fontSize: '1.1rem', marginBottom: '-5%'
                                }}
                            />}
                        />
                        <ButtonCancel
                            text={"Delete Service"}
                            callbackOnClick={() => {
                                handleOnClickOnButtonDelete();
                                // callApiForCreateCommentByManager(false);
                            }}
                            iconStart={<DeleteRounded
                                style={{
                                    fontSize: '1.1rem',
                                    color: Colors.theme.orange.lemonLight,
                                }}
                            />}
                            style={{
                                background: Colors.theme.red.red,
                                color: Colors.theme.orange.lemonLight,
                            }}
                        />

                        <ButtonSave
                            text={confComps.translate.agree}
                            callbackOnClick={() => {
                                callApiForCreateCommentByManager(true);
                            }}
                            iconStart={<ThumbUpRounded
                                style={{
                                    fontSize: '1.1rem',
                                }} />
                            }
                        />
                    </div>

                </Box>
            }
        </div>
    )
};
export default CreateApproveSolutionByManagerForMoneyReceive;