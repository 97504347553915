export const CurrencySymbols: { [key: string]: string } = {
  USD: '$',    // US Dollar
  EUR: '€',    // Euro
  GBP: '£',    // British Pound
  JPY: '¥',    // Japanese Yen
  TRY: '₺',    // Turkish Lira
  AUD: 'A$',   // Australian Dollar
  CAD: 'C$',   // Canadian Dollar
  CHF: 'CHF',  // Swiss Franc
  CNY: '¥',    // Chinese Yuan
  SEK: 'kr',   // Swedish Krona
  NZD: 'NZ$',  // New Zealand Dollar
  MXN: 'Mex$', // Mexican Peso
  SGD: 'S$',   // Singapore Dollar
  HKD: 'HK$',  // Hong Kong Dollar
  NOK: 'kr',   // Norwegian Krone
  KRW: '₩',    // South Korean Won
  INR: '₹',    // Indian Rupee
  RUB: '₽',    // Russian Ruble
  ZAR: 'R',    // South African Rand
  BRL: 'R$',   // Brazilian Real
  AED: 'د.إ',  // United Arab Emirates Dirham
  ARS: '$',    // Argentine Peso
  BGN: 'лв',   // Bulgarian Lev
  CLP: '$',    // Chilean Peso
  COP: '$',    // Colombian Peso
  CZK: 'Kč',   // Czech Koruna
  DKK: 'kr',   // Danish Krone
  EGP: '£',    // Egyptian Pound
  HUF: 'Ft',   // Hungarian Forint
  IDR: 'Rp',   // Indonesian Rupiah
  ILS: '₪',    // Israeli New Shekel
  KES: 'KSh',  // Kenyan Shilling
  LKR: 'Rs',   // Sri Lankan Rupee
  MYR: 'RM',   // Malaysian Ringgit
  NGN: '₦',    // Nigerian Naira
  PHP: '₱',    // Philippine Peso
  PKR: '₨',    // Pakistani Rupee
  PLN: 'zł',   // Polish Zloty
  QAR: '﷼',    // Qatari Rial
  RON: 'lei',  // Romanian Leu
  SAR: '﷼',    // Saudi Riyal
  THB: '฿',    // Thai Baht
  TWD: 'NT$',  // New Taiwan Dollar
  UAH: '₴',    // Ukrainian Hryvnia
  VND: '₫',    // Vietnamese Dong
  // Add more currencies as needed
};
