// import { InterfaceTextFieldWithIconBox } from "./InterfaceTextFieldPassword";

// import React, { useState } from 'react';
// import { styled, useTheme } from '@mui/material/styles';
// import { NoneEyeIcon } from "../../../../assets/icons/svg/NoneEyeIcon";
// import { EyeIcon } from "../../../../assets/icons/svg/EyeIcon";
// import Colors from "../../../../config/Colors";

// const Container = styled('div')(({ theme }) => ({
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'flex-start',
//     gap: theme.spacing(1),
//     maxWidth: 300,
// }));

// const StyledInputContainer = styled('div')(({ theme }) => ({
//     width: '100%',
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "space-between",
//     paddingInline: '8px',
//     paddingBlock: '4px',
//     borderRadius: '5px',
//     border: `1px solid rgb(196,199,217)`,
//     '&:hover': {
//         borderColor: theme.palette.primary.dark,
//     },
//     '&:focus': {
//         borderColor: theme.palette.primary.dark,
//     },
// }));

// const StyledInput = styled('input')(({ theme }) => ({
//     flexGrow: "1",

// }));

// const ToggleButton = styled('button')({
//     height: "100%",
//     alignItems: "center",
//     justifyContent: "center",
//     display: "flex",
// });

// const RequirementText = styled('p')(({ theme }) => ({
//     color: theme.palette.error.main,
//     fontSize: '0.875rem',
//     display: 'flex',
//     alignItems: 'center',
//     margin: 0,
//     '&::before': {
//         content: '"•"',
//         marginRight: theme.spacing(1),
//     },
// }));
// function TextFieldWithIconBox(componentParams: InterfaceTextFieldWithIconBox) {
//     const [getValue, setValue] = useState("");
//     const [getIsFocused, setIsFocused] = useState(false);

//     const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//         const newValue = e.target.value;
//         setValue(newValue);
//         componentParams.onChangeValue(newValue);
//     };

//     return (
//         <div
//             style={{
//                 display: "flex",
//                 alignItems: "center",
//                 flexDirection: "row",
//                 border: getIsFocused ? `0.8px solid ${Colors.theme.blue.blue}` : undefined,
//                 borderRadius: "4px",
//                 padding: "0.5em",
//                 fontSize: "1em",
//                 position: "relative",
//                 boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : Colors.shadow.shadowEditTextBoxNC,
//                 ...componentParams.styleMain,
//                 direction: componentParams.isDataMustLTR ? "ltr" : "rtl"
//             }}
//         >
//             {componentParams.iconStart && (
//                 <div style={{ display: "flex", alignItems: "center", marginRight: "0.5em" }}>
//                     <componentParams.iconStart />
//                 </div>
//             )}
//             <div style={{ position: "relative", flex: 1 }}>
//                 <input
//                     type={componentParams.type}
//                     value={getValue}
//                     onChange={handleChange}
//                     onFocus={() => setIsFocused(true)}
//                     onBlur={() => setIsFocused(false)}
//                     style={{
//                         width: "100%",
//                         border: "none",
//                         outline: "none",
//                         fontSize: "1em",
//                         padding: "0.5em 0"
//                     }}
//                 />
//                 <label
//                     style={{
//                         position: "absolute",
//                         left: "0.5em",
//                         transition: "all 0.2s ease",
//                         pointerEvents: "none",
//                         top: getIsFocused || getValue ? '-0.75em' : '50%',
//                         transform: "translate(0%,-50%)",
//                         fontSize: getIsFocused || getValue ? '0.75em' : '1em',
//                         color: getIsFocused ? '#000' : '#666'
//                     }}
//                 >
//                     {componentParams.hintText}
//                 </label>

//                 <legend
//                     style={{
//                         position: "absolute",
//                         left: "0.5em",
//                         transition: "all 0.2s ease",
//                         pointerEvents: "none",
//                         top: getIsFocused || getValue ? '-0.75em' : '50%',
//                         transform: "translate(0%,-50%)",
//                         fontSize: getIsFocused || getValue ? '0.75em' : '1em',
//                         color: getIsFocused ? '#000' : '#666',
//                         display: "block",
//                         // visibility: "hidden",
//                         // background: "red",
//                     }}
//                 >
//                     <span style={{ opacity: 0, visibility: "visible", display: "inline-block" }}>
//                         {componentParams.hintText}
//                     </span>
//                 </legend>
//             </div>
//             {componentParams.iconEnd && (
//                 <div style={{ display: "flex", alignItems: "center", marginLeft: "0.5em" }}>
//                     <componentParams.iconEnd />
//                 </div>
//             )}
//         </div>
//     );

// }
// export default TextFieldWithIconBox;

import { InterfaceTextFieldWithIconBox } from "./InterfaceTextFieldPassword";

import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Colors from "../../../../config/Colors";
import { NoneEyeIcon } from "../../../../assets/icons/svg/NoneEyeIcon";
import { EyeIcon } from "../../../../assets/icons/svg/EyeIcon";


function TextFieldWithIconBox(componentParams: InterfaceTextFieldWithIconBox) {
    const [getValue, setValue] = useState("");
    const [getIsFocused, setIsFocused] = useState(false);
    const [getIsShowPassword, setIsShowPassword] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(newValue);
        componentParams.onChangeValue(newValue);
    };
    const handleClickShowPassword = () => {
        setIsShowPassword(!getIsShowPassword);
    };
    // const ConstStyle = {
    //     borderRadius: "4px",
    // }

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                // borderRadius: ConstStyle.borderRadius,
                fontSize: "1rem",
                position: "relative",
                boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : Colors.shadow.shadowEditTextBoxNC,
                ...componentParams.styleMain,
                direction: componentParams.isDataMustLTR ? "ltr" : "rtl",
                paddingBlock: "0.7rem",
                boxSizing: "border-box",

            }}
        >
            {componentParams.iconStart && (
                <div style={{ display: "flex", alignItems: "center", marginInlineStart: "0.96rem" }}>
                    <componentParams.iconStart style={{ color: getIsFocused ? Colors.theme.blue.dark : Colors.theme.blue.light }} />
                </div>
            )}
            <div style={{
                flex: 1, paddingInlineEnd: "1rem",
                paddingInlineStart: "0.64rem",
                boxSizing: "border-box",
            }}>
                <input
                    ref={componentParams.refInput}
                    onKeyDown={componentParams.onKeyDownInput}
                    autoComplete="off"
                    type={getIsShowPassword ? "text" : componentParams.type}
                    value={getValue}
                    onChange={handleChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        fontSize: "1.28rem",
                        lineHeight: "1.44rem",
                        boxSizing: "border-box",
                        color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                    }}
                />
                <label
                    style={{
                        position: "absolute",
                        boxSizing: "border-box",
                        left: ((getIsFocused || getValue) || !componentParams.iconStart) ? "1rem" : "2.4rem",
                        transition: "all 0.2s ease",
                        pointerEvents: "none",
                        display: getIsFocused || getValue ? "none" : undefined,
                        top: getIsFocused || getValue ? '0px' : '50%',
                        transform: "translate(0%, -50%)",
                        fontSize: (getIsFocused || getValue) ? "0.75rem" : "1em",
                        color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                        backgroundColor: "transparent",
                        padding: "0",
                    }}
                >
                    {componentParams.hintText}
                </label>
            </div>
            {
                <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }} onClick={handleClickShowPassword}>
                    {
                        (((componentParams.type ?? "").toLowerCase()) === "password") &&
                        (getIsShowPassword ?

                            <EyeIcon style={{
                                background: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                width: "1.5rem", height: "1.3rem"
                            }} />
                            : <NoneEyeIcon
                                style={{
                                    background: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                                    width: "1.5rem", height: "1.3rem"
                                }} />

                        )
                    }
                </div>
            }
            <fieldset
                // aria-hidden="true"
                style={{
                    position: "absolute",
                    boxSizing: "border-box",
                    top: getIsFocused || getValue ? "0px" : "0px",
                    // height: "100%",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    borderRadius: componentParams.styleMain?.borderRadius,
                    border: (getIsFocused) ? `1px solid ${Colors.theme.blue.blue}` : (getValue.length > 0) ? `1px solid ${Colors.theme.blue.light_2}` : "unset",
                    pointerEvents: "none",
                    padding: "0",
                    margin: "0",
                    display: "block",
                    paddingInline: "0.75rem",
                    // overflow: "hidden"
                }}
            >
                {!(getIsFocused || getValue) &&
                    (<legend
                        style={{
                            overflow: "hidden",
                            float: "unset",
                            boxSizing: "border-box",
                            transition: "all 0.2s ease",
                            left: getIsFocused || getValue ? "1rem" : "1rem",
                            pointerEvents: "none",
                            // color: getIsFocused ? '#000' : '#666',
                            display: (getIsFocused || getValue) ? "block" : "none",
                            // transform: "translate(0%,-60%)",
                            // visibility: "hidden",
                            // background: "red",
                        }}
                    >
                        <span style={{
                            opacity: 0,
                            fontSize: "0.75rem",
                            visibility: "visible", display: "inline-block",
                            paddingInlineEnd: "0.5rem",
                            boxSizing: "border-box",
                        }}>
                            {componentParams.hintText}
                        </span>
                    </legend>
                    )}
                {/* } */}
            </fieldset>
        </div>
    );
}

export default TextFieldWithIconBox;
