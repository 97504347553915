import { Typography } from "@mui/material";
import { InterfaceTextDescription } from "./InterfaceTextDescription";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import Colors from "../../../../config/Colors";

function TextDescription(paramsComponent: InterfaceTextDescription) {
    var confComps = ConfigComps();
    return (
        <Typography variant="h6" sx={{
            lineHeight: "1.70rem",//{ xs: 1.1, sm: 1.1, md: 1.25, lg: 1.25, xl: 1.25 },
            fontSize: "1.03rem",//{ xs: "0.85rem", sm: "0.90rem", md: "0.96rem", lg: "1.04rem", xl: "1.12rem" },
            boxSizing: "border-box",
            paddingBlock: "0.07rem",
            paddingInline: "1.03rem",
            textAlign: "start",
            background: Colors.theme.blue.white2,
            boxShadow: Colors.shadow.shadowEditTextMultiLineBoxNC,
            fontFamily: confComps.font.poppins.Regular,
            color: Colors.theme.blue.dark,
            ...paramsComponent.style
        }} >
            {paramsComponent.children}

        </Typography>
    );

}
export default TextDescription;