import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const PaperUploadIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1927 1.22171H5.43205C3.00538 1.21241 1.01738 3.14558 0.959046 5.57225V18.0987C0.906546 20.5511 2.85255 22.5811 5.30371 22.6336C5.34688 22.6336 5.39005 22.6347 5.43205 22.6336H14.7514C17.1897 22.5472 19.117 20.5382 19.1031 18.0987V7.37708L13.1927 1.22171Z"
                stroke={style?.color ?? "#2D3250"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
            />

            <path d="M12.8877 1.20801V4.60184C12.8877 6.25851 14.2282 7.60134 15.8849 7.60601H19.0979"
                stroke={style?.color ?? "#2D3250"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.58105 9.55957V16.6074" stroke={style?.color ?? "#2D3250"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.3174 12.308L9.58154 9.56055L6.8457 12.308" stroke={style?.color ?? "#2D3250"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
};
