import { useNavigate, useParams } from "react-router-dom";
import { InterfaceShowStateTheRecap } from "./InterfaceShowStateTheRecap";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../config/ConfigCOMP";
import ContainerPage from "../../../components/basic/containerPage/ContainerPage";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { MainCard } from "../../myProfile/MyProfileStyle";
import { useEffect, useState } from "react";
import { EnumStateStepGroup } from "../../../components/basic/ArrowStep/InterfaceArrowStep";
import SteperGroups from "../../../components/complex/SteperGuide/SteperGroup/SteperGroup";
import SteperGuide from "../../../components/complex/SteperGuide/SteperGuide";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import TitleMedium18 from "../../../components/basic/Texts/Titles/TitleMedium18/TitleMedium18";
import BoxRecap from "../../../components/basic/‌Box/BoxRecap/BoxRecap";
import TitleMedium16 from "../../../components/basic/Texts/Titles/TitleMedium16/TitleMedium16";
import { CheckCircleOutlineRounded, CircleOutlined, DoneRounded } from "@mui/icons-material";
import Colors from "../../../config/Colors";
import ParentWithHeightAnimation from "../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import TextFieldSimple from "../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import ParentRecapMoneyReceive from "../ParentRecapService/ParentRecapMoneyRecieve/ParentRecapMoneyRecieve";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { EnumStateFetchList } from "../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfaceSearchInServer } from "../../../api/interfaces/InterfaceSearchInServer";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../api/UrlsList";
import { RequestHandler } from "../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { InterfaceGetAPICustomersList } from "../../people/TrustyOrCustomer/customers/customersList/InterfaceCustomersList";
import { InterfaceNTTRecapMain } from "../../../NTTs/Recap/InterfaceNTTRecapMain";
import ParentRecapMoneyPayment from "../ParentRecapService/ParentRecapMoneyPayment/ParentRecapMoneyPayment";
import ParentRecapBuyCottage from "../ParentRecapService/ParentRecapBuyCottage/ParentRecapBuyCottage";
import ParentRecapSellCottage from "../ParentRecapService/ParentRecapSellCottage/ParentRecapSellCottage";
import { InterfaceNTTWorkFlowMainRecap } from "../../../NTTs/Recap/WorkflowMainRecap/InterfaceNTTWorkFlowMainRecap";
import { StepNameMappingToKeyStepForBuyCottageRecap } from "../../../NTTs/Recap/WorkflowMainRecap/BuyCottage/InterfaceNTTOneStepOfWorkflowBuyCottageRecap";
import { StepNameMappingToKeyStepForSellCottageRecap } from "../../../NTTs/Recap/WorkflowMainRecap/SellCottage/InterfaceNTTOneStepOfWorkflowSellCottageRecap";
import { StepNameMappingToKeyStepForMoneyReceiveRecap } from "../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap";
import { StepNameMappingToKeyStepForMoneyPaymentRecap } from "../../../NTTs/Recap/WorkflowMainRecap/MoneyPayment/InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap";

function ShowStateTheRecap(paramsComponent: InterfaceShowStateTheRecap) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const { idMainRecap } = useParams();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("1");
    const [getKeyOfStepThatSelected, setKeyOfStepThatSelected] = useState<string>("1.1");
    const [getRecapMain, setRecapMain] = useState<InterfaceNTTRecapMain>();
    const [getWorkFlowOfRecap, setWorkFlowOfRecap] = useState<InterfaceNTTWorkFlowMainRecap[]>([]);
    const [getIsLoading, setIsLoading] = useState<boolean>(true);

    const findLastStepThatUpdated = async (recapMain: InterfaceNTTRecapMain, recapsWorkflow: InterfaceNTTWorkFlowMainRecap[]) => {
        //buy cottage
        let buyCottageWorkFlow = recapsWorkflow.flatMap(recap => recap.buy_cottages.flatMap(service => service.workflow_reviews));
        let lastTimeStepBuyCottageAdded = {
            create_at: -1 as number,
            StepKeyName: undefined as (string | undefined),
        };

        if (buyCottageWorkFlow.length > 0) {
            lastTimeStepBuyCottageAdded = {
                create_at: buyCottageWorkFlow.sort((a, b) => a.created_at - b.created_at)[buyCottageWorkFlow.length - 1].created_at,
                StepKeyName: StepNameMappingToKeyStepForBuyCottageRecap[buyCottageWorkFlow.sort((a, b) => a.created_at - b.created_at)[buyCottageWorkFlow.length - 1].review_ct],
            };
        }
        if (recapMain.buy_cottages.length > 0) {
            if (lastTimeStepBuyCottageAdded.create_at < recapMain.buy_cottages.sort((a, b) => a.created_at - b.created_at)[recapMain.buy_cottages.length - 1].created_at) {
                lastTimeStepBuyCottageAdded = {
                    create_at: recapMain.buy_cottages.sort((a, b) => a.created_at - b.created_at)[recapMain.buy_cottages.length - 1].created_at,
                    StepKeyName: "1.1",
                };

            }
        }
        ///sell cottage
        let sellCottageWorkflow = recapsWorkflow.flatMap(recap => recap.sell_cottages.flatMap(service => service.workflow_reviews));
        let lastTimeStepSellCottageAdded = {
            create_at: -1 as number,
            StepKeyName: undefined as (string | undefined),
        };

        if (sellCottageWorkflow.length > 0) {
            lastTimeStepSellCottageAdded = {
                create_at: sellCottageWorkflow.sort((a, b) => a.created_at - b.created_at)[sellCottageWorkflow.length - 1].created_at,
                StepKeyName: StepNameMappingToKeyStepForSellCottageRecap[sellCottageWorkflow.sort((a, b) => a.created_at - b.created_at)[sellCottageWorkflow.length - 1].review_ct],
            };
        }
        if (recapMain.sell_cottages.length > 0) {
            if (lastTimeStepSellCottageAdded.create_at < recapMain.sell_cottages.sort((a, b) => a.created_at - b.created_at)[recapMain.sell_cottages.length - 1].created_at) {
                lastTimeStepSellCottageAdded = {
                    create_at: recapMain.sell_cottages.sort((a, b) => a.created_at - b.created_at)[recapMain.sell_cottages.length - 1].created_at,
                    StepKeyName: "1.1",
                };

            }
        }
        ///money receive
        let moneyReceiveWorkflow = recapsWorkflow.flatMap(recap => recap.receipts.flatMap(service => service.workflow_reviews));
        let lastTimeStepMoneyReceiveAdded = {
            create_at: -1 as number,
            StepKeyName: undefined as (string | undefined),
        };

        if (moneyReceiveWorkflow.length > 0) {
            lastTimeStepMoneyReceiveAdded = {
                create_at: moneyReceiveWorkflow.sort((a, b) => a.created_at - b.created_at)[moneyReceiveWorkflow.length - 1].created_at,
                StepKeyName: StepNameMappingToKeyStepForMoneyReceiveRecap[moneyReceiveWorkflow.sort((a, b) => a.created_at - b.created_at)[moneyReceiveWorkflow.length - 1].review_ct],
            };
        }
        if (recapMain.receipts.length > 0) {
            if (lastTimeStepMoneyReceiveAdded.create_at < recapMain.receipts.sort((a, b) => a.created_at - b.created_at)[recapMain.receipts.length - 1].created_at) {
                lastTimeStepMoneyReceiveAdded = {
                    create_at: recapMain.receipts.sort((a, b) => a.created_at - b.created_at)[recapMain.receipts.length - 1].created_at,
                    StepKeyName: "1.1",
                };

            }
        }
        ///money payment
        let moneyPaymentWorkflow = recapsWorkflow.flatMap(recap => recap.payments.flatMap(service => service.workflow_reviews));
        let lastTimeStepMoneyPaymentAdded = {
            create_at: -1 as number,
            StepKeyName: undefined as (string | undefined),
        };

        if (moneyPaymentWorkflow.length > 0) {
            lastTimeStepMoneyPaymentAdded = {
                create_at: moneyPaymentWorkflow.sort((a, b) => a.created_at - b.created_at)[moneyPaymentWorkflow.length - 1].created_at,
                StepKeyName: StepNameMappingToKeyStepForMoneyPaymentRecap[moneyPaymentWorkflow.sort((a, b) => a.created_at - b.created_at)[moneyPaymentWorkflow.length - 1].review_ct],
            };
        }
        if (recapMain.payments.length > 0) {
            if (lastTimeStepMoneyPaymentAdded.create_at < recapMain.payments.sort((a, b) => a.created_at - b.created_at)[recapMain.payments.length - 1].created_at) {
                lastTimeStepMoneyPaymentAdded = {
                    create_at: recapMain.payments.sort((a, b) => a.created_at - b.created_at)[recapMain.payments.length - 1].created_at,
                    StepKeyName: "1.1",
                };

            }
        }
        let stepKey = [lastTimeStepMoneyPaymentAdded, lastTimeStepMoneyReceiveAdded, lastTimeStepBuyCottageAdded, lastTimeStepSellCottageAdded].sort((a, b) => a.create_at - b.create_at)[3].StepKeyName;
        if (stepKey) {
            setKeyOfStepThatSelected(stepKey);
            stepKey.slice(0, stepKey.lastIndexOf("."));
            setKeyOfGroupThatSelected(stepKey.slice(0, stepKey.lastIndexOf(".")));
        }


    }
    useEffect(() => {
        if (getWorkFlowOfRecap && getRecapMain) {
            findLastStepThatUpdated(getRecapMain, getWorkFlowOfRecap);
        }
    }, [getWorkFlowOfRecap, getRecapMain])
    const callApiForRetrieveAllWorkflowTheMainRecap = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.getAllWorkFlowOfRecap;
        const bodyReq = {
            "recap_ids": [idMainRecap],
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyReq, profileInStore)
            .then((response: any) => {
                console.log("response:)AllWorkflow:)");
                console.log(response);
                setIsLoading(false);
                let t = response as InterfaceNTTWorkFlowMainRecap[];
                setWorkFlowOfRecap(t);
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForRetrieveTheMainRecap = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.getRecapInitiate;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idMainRecap}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                setRecapMain(response);
                setIsLoading(false);
                // let t = response as InterfaceGetAPICustomersList;

                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        callApiForRetrieveTheMainRecap();
        callApiForRetrieveAllWorkflowTheMainRecap();
    }, [])

    return (
        <ContainerPage title={confComps.translate.recap}
            isLoading={getIsLoading}>
            <SteperGuide
                steps={[confComps.steperGuide.recap.steps]}
                callbackOnClickOneStep={(keySelected: string) => {
                    // if (keySelected !== "1." && keySelected !== "1.1") {
                    //     dispatch(ShowAlert({ text: confComps.translate.youMustCreateAtFirst, typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                    // }
                    console.log(keySelected);
                    setKeyOfStepThatSelected(keySelected);

                }}
                callbackOnClickOneGroup={(keySelected: string) => {
                    setKeyOfGroupThatSelected(keySelected)
                }}
                keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                keyOfOneStepThatSelected={getKeyOfStepThatSelected}
                recapMain={getRecapMain}
                workFlowOfAllService={getWorkFlowOfRecap}
            />

            <MainCard sx={{
                gap: { xs: "1.3rem", sm: "1.4rem", md: "1.6rem", lg: "1.77rem", xl: "6vh" },
                display: 'flex', flexDirection: 'column', alignItems: 'start',
                justifyContent: 'start',
            }}>
                <TitleMedium18 style={{
                    boxSizing: "border-box",
                }}>
                    {confComps.translate.recap}
                </TitleMedium18>

                {/* money receive */}
                {(getRecapMain?.receipts ?? []).length === 0 &&
                    <ParentRecapMoneyReceive
                        infoServiceInMainRecap={undefined}
                        recapMain={getRecapMain}
                        infoWorkFlowService={undefined}
                        callBackCreateMainRecap={undefined}
                        stepThatSelected={getKeyOfStepThatSelected}
                        callBackReLoad={() => {
                            callApiForRetrieveTheMainRecap();
                            callApiForRetrieveAllWorkflowTheMainRecap();
                        }}
                    />}
                {getWorkFlowOfRecap && (getWorkFlowOfRecap.length > 0) && (getRecapMain?.receipts ?? []).map((recapService, index, arr) => {
                    return (
                        <ParentRecapMoneyReceive
                            recapMain={getRecapMain}
                            callBackCreateMainRecap={undefined}
                            infoWorkFlowService={getWorkFlowOfRecap[0].receipts[index]}//first for recap and next the index of service now I search in one recap so fist set 0
                            stepThatSelected={getKeyOfStepThatSelected}
                            infoServiceInMainRecap={recapService}
                            callBackReLoad={() => {
                                callApiForRetrieveTheMainRecap();
                                callApiForRetrieveAllWorkflowTheMainRecap();
                            }}
                        />
                    )
                })}
                {/* money payment */}
                {(getRecapMain?.payments ?? []).length === 0 &&
                    <ParentRecapMoneyPayment
                        infoServiceInMainRecap={undefined}
                        recapMain={getRecapMain}
                        callBackCreateMainRecap={undefined}
                        stepThatSelected={getKeyOfStepThatSelected}
                        callBackReLoad={() => {
                            callApiForRetrieveTheMainRecap();
                            callApiForRetrieveAllWorkflowTheMainRecap();
                        }} infoWorkFlowService={undefined}
                    />}
                {getWorkFlowOfRecap && (getWorkFlowOfRecap.length > 0) && (getRecapMain?.payments ?? []).map((recapService, index, arr) => {
                    return (
                        <ParentRecapMoneyPayment
                            recapMain={getRecapMain}
                            callBackCreateMainRecap={undefined}
                            infoWorkFlowService={getWorkFlowOfRecap[0].payments[index]}
                            stepThatSelected={getKeyOfStepThatSelected}
                            infoServiceInMainRecap={recapService}
                            callBackReLoad={() => {
                                callApiForRetrieveTheMainRecap();
                                callApiForRetrieveAllWorkflowTheMainRecap();
                            }}
                        />
                    )
                })}
                {/* Buy Cottage */}
                {(getRecapMain?.buy_cottages ?? []).length === 0 &&
                    <ParentRecapBuyCottage
                        infoServiceInMainRecap={undefined}
                        recapMain={getRecapMain}
                        callBackCreateMainRecap={undefined}
                        stepThatSelected={getKeyOfStepThatSelected}
                        callBackReLoad={() => {
                            callApiForRetrieveTheMainRecap();
                            callApiForRetrieveAllWorkflowTheMainRecap();
                        }} infoWorkFlowService={undefined}
                    />
                }
                {getWorkFlowOfRecap && (getWorkFlowOfRecap.length > 0) && (getRecapMain?.buy_cottages ?? []).map((recapService, index, arr) => {
                    return (
                        <ParentRecapBuyCottage
                            recapMain={getRecapMain}
                            callBackCreateMainRecap={undefined}
                            stepThatSelected={getKeyOfStepThatSelected}
                            infoWorkFlowService={getWorkFlowOfRecap[0].buy_cottages[index]}
                            infoServiceInMainRecap={recapService}
                            callBackReLoad={() => {
                                callApiForRetrieveTheMainRecap();
                                callApiForRetrieveAllWorkflowTheMainRecap();
                            }}
                        />
                    )
                })}
                {/* sell Cottage */}
                {(getRecapMain?.sell_cottages ?? []).length === 0 &&
                    <ParentRecapSellCottage
                        infoServiceInMainRecap={undefined}
                        recapMain={getRecapMain}
                        callBackCreateMainRecap={undefined}
                        stepThatSelected={getKeyOfStepThatSelected}
                        callBackReLoad={() => {
                            callApiForRetrieveTheMainRecap();
                            callApiForRetrieveAllWorkflowTheMainRecap();
                        }} infoWorkFlowService={undefined}
                    />
                }
                {getWorkFlowOfRecap && (getWorkFlowOfRecap.length > 0) && (getRecapMain?.sell_cottages ?? []).map((recapService, index, arr) => {
                    return (
                        <ParentRecapSellCottage
                            recapMain={getRecapMain}
                            callBackCreateMainRecap={undefined}
                            stepThatSelected={getKeyOfStepThatSelected}
                            infoWorkFlowService={getWorkFlowOfRecap[0].sell_cottages[index]}
                            infoServiceInMainRecap={recapService}
                            callBackReLoad={() => {
                                callApiForRetrieveTheMainRecap();
                                callApiForRetrieveAllWorkflowTheMainRecap();
                            }}
                        />
                    )
                })}
            </MainCard>
        </ContainerPage >

    );
}
export default ShowStateTheRecap;
