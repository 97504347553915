import { InterfaceIconSVG } from "../../InterfaceIconSVG";

export const Priority0Icon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.25 5.25L8.5 8.25L12.75 5.25" stroke="#387ADF" stroke-opacity="0.65" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.25 8.75L8.5 11.75L12.75 8.75" stroke="#387ADF" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
