import { EnumSTepsParent, EnumSTepsRecap, EnumSTepsSolutionRecap } from "../../../../config/enums/EnumSteps";
import { EnumRecapServiceNameInServer } from "../InterfaceNTTWorkFlowMainRecap";

export interface InterfaceNTTOneStepOfWorkflowBuyCottageRecap {
    id: number;
    recap: number;
    recap_service_ct?: EnumRecapServiceNameInServer;
    recap_service_id?: number;
    previous_review_ct: EnumRecapServiceStepNameInServerForBuyCottage | null;
    previous_review_id: number | null;
    review_ct: EnumRecapServiceStepNameInServerForBuyCottage;
    review_id: number;
    created_at: number;

}
export enum EnumRecapServiceStepNameInServerForBuyCottage {
    undefined = "undefined",
    fundControlCheckWalletCustomer = "recapservicefundcontrol",
    checkRisk = "recapservicecompliance",
    addSolution = "recapservicesolutionmeta",
    checkRiskOfSolution = "recapservicesolutionriskmeta",
    checkWalletOfTrustByFinanceForSolution = "recapservicesolutionfinancecheckmeta",
    checkSolutionByTrusties = "recapservicesolutiontradercheckmeta",
    chooseOneSolution = "recapservicesolutionchoose",
    approveSolution = "recapserviceapproval",
}
export const StepNameMappingToKeyStepForBuyCottageRecap: { [key in EnumRecapServiceStepNameInServerForBuyCottage]: string } = {
    [EnumRecapServiceStepNameInServerForBuyCottage.undefined]: `0`,
    [EnumRecapServiceStepNameInServerForBuyCottage.fundControlCheckWalletCustomer]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.checkWalletOfTheRequesterByFundControl}`,
    [EnumRecapServiceStepNameInServerForBuyCottage.checkRisk]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}`,
    [EnumRecapServiceStepNameInServerForBuyCottage.addSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.addSolution}`,
    [EnumRecapServiceStepNameInServerForBuyCottage.checkRiskOfSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}`,
    [EnumRecapServiceStepNameInServerForBuyCottage.checkWalletOfTrustByFinanceForSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkWalletOfTrustyForSolution}`,
    [EnumRecapServiceStepNameInServerForBuyCottage.checkSolutionByTrusties]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionByTrusty}`,
    [EnumRecapServiceStepNameInServerForBuyCottage.chooseOneSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.chooseSolution}`,
    [EnumRecapServiceStepNameInServerForBuyCottage.approveSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.acceptByManager}`,
};
