import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const TickWithCircleIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill={style?.background ? `${style?.background}` : "none"} xmlns="http://www.w3.org/2000/svg">
            <path d="M7.50017 14.6667C8.37581 14.6678 9.24305 14.4959 10.052 14.1608C10.861 13.8257 11.5958 13.334 12.2142 12.714C12.8342 12.0956 13.3258 11.3608 13.6609 10.5519C13.996 9.74287 14.168 8.87563 14.1668 7.99999C14.168 7.12434 13.996 6.25711 13.6609 5.44813C13.3258 4.63914 12.8341 3.90435 12.2142 3.28599C11.5958 2.66599 10.861 2.17432 10.052 1.83921C9.24305 1.50411 8.37581 1.33218 7.50017 1.33332C6.62453 1.3322 5.7573 1.50414 4.94831 1.83924C4.13933 2.17434 3.40454 2.66601 2.78617 3.28599C2.16619 3.90435 1.67453 4.63914 1.33942 5.44813C1.00432 6.25711 0.832385 7.12434 0.833502 7.99999C0.832367 8.87563 1.00429 9.74287 1.3394 10.5519C1.6745 11.3608 2.16618 12.0956 2.78617 12.714C3.40454 13.334 4.13933 13.8256 4.94831 14.1607C5.7573 14.4958 6.62453 14.6678 7.50017 14.6667Z" stroke-width="1.5" stroke-linejoin="round"
                stroke={style?.color ?? "#74E291"}
            />
            <path d="M4.8335 8L6.8335 10L10.8335 6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"
                stroke={style?.color ?? "#74E291"} />
        </svg>
    )
};

