import { useNavigate } from "react-router-dom";
import { DialogFromMUI } from "../../../MUIConfig/DialogFromMUI";
import { InterfaceDialogAcceptProcess } from "./InterfaceDialogAcceptProcess";
import { ButtonBase, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { useEffect, useState } from "react";
import ButtonColor from "../../../basic/Buttons/ButtonColor/ButtonColor";
import { CancelRounded, DoneAllRounded, DoneRounded } from "@mui/icons-material";
import Colors, { ColorThemForButton } from "../../../../config/Colors";

function DialogAcceptProcess(paramsDialog: InterfaceDialogAcceptProcess) {

    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getOpen, setOpen] = useState<boolean>(false);


    useEffect(() => {
        setOpen(paramsDialog.isOpen);
    }, [paramsDialog.isOpen])
    const handleClose = async () => {
        // setOpen(false);
        paramsDialog.callbackClickReject();
    };


    // const handleOnclickCancelDialog = () => {
    //     paramsDialog.callbackClickReject();
    // }



    return (
        <DialogFromMUI onClose={handleClose} open={getOpen} dir={mTheme.direction}
            onClick={(event) => {
                event.stopPropagation();
            }}
        // fullWidth
        >
            <div style={{
                display: "flex", flexDirection: "column",
                justifyContent: "space-between", alignItems: "center",
                gap: "1rem",
                paddingInline: "2rem",
                paddingBlock: "2.3rem",
            }}>
                <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "end" }}>
                    <h3 style={{ fontFamily: confComps.font.poppins.SemiBold, color: Colors.theme.blue.blue }}>
                        {paramsDialog.title}
                    </h3>
                </div>
                {paramsDialog.children}
                <div>
                    {paramsDialog.description}
                </div>



                <div style={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "space-between", gap: "20vh" }}>
                    <ButtonColor text={paramsDialog.redButtonTitle ?? confComps.translate.cancel}
                        onclick={paramsDialog.callbackClickReject}
                        iconStart={<CancelRounded />}
                        color={ColorThemForButton.orangeColorAndTransparentBackground}
                    />
                    <ButtonColor
                        text={paramsDialog.greenButtonTitle ?? confComps.translate.save}
                        onclick={paramsDialog.callbackClickAccept}
                        iconStart={<DoneRounded />}
                        color={ColorThemForButton.greenColorAndTransparentBackground}
                    />
                </div>

            </div>

        </DialogFromMUI>
    );

};
export default DialogAcceptProcess;