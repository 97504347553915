import { useNavigate, useParams } from 'react-router-dom';
import ContainerPage from '../../../../components/basic/containerPage/ContainerPage';
import { ConfigComps } from '../../../../config/ConfigCOMP';
import { InterfaceCreateProfileNoneStaff } from './InterfaceCreateProfileNoneStaff';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { EnumRolePerson } from '../../../../config/enums/EnumRolePerson';
import { Box, Grid, styled } from '@mui/material';
import Colors from '../../../../config/Colors';
import { ImageSquare } from '../../../../components/complex/imageSquare/ImageSquare';
import { Person2Rounded } from '@mui/icons-material';
import { EditPenCircle } from '../../../../assets/icons/svg/EditPenCircleIcon';
import { GridFromMUI } from '../../../../components/MUIConfig/GridFromMUI';
import TextFieldWithIconBottomLine from '../../../../components/basic/TextField/TextFieldWithIconBottomLine/TextFieldWithIconBottomLine';
import { EditPenIcon } from '../../../../assets/icons/svg/EditPenIcon';
import ButtonSave from '../../../../components/basic/Buttons/ButtonSave/ButtonSave';
import TextFieldMultiLine from '../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine';
import { AddIcon } from '../../../../assets/icons/svg/AddIcon';
import KeyTitleH6 from '../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6';
import SubTitleSection from '../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection';
import { InterfaceNTTActivity } from '../../../../NTTs/InterfaceNTTActivity';
import { isVisibilityProgressBar } from '../../../../store/ProgressbarInRootSlice';
import { HandlerLangUrl, urlsList } from '../../../../api/UrlsList';
import { RequestHandler } from '../../../../api/API';
import { SelectedProfile } from '../../../../store/profileStateSlice';
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from '../../../../api/APIErrorResponse';
import { ShowAlert } from '../../../../store/AlertStateModeSlice';
import { enumTypeOfAlert } from '../../../../components/MUIConfig/alert_popup/interfaceAlertPopup';
import AutoCompleteSimple from '../../../../components/complex/AutoCompleteSimple/AutoCompleteSimple';
import AutoCompleteWithUnderLine from '../../../../components/complex/AutoCompleteWithUnderLine/AutoCompleteWithUnderLine';
import TextFieldMultiSelectActivity from '../../../../components/basic/TextField/ActivitiesJob/TextFieldMultiSelectActivity/TextFieldMultiSelectActivity';
import DatePickerMUIWithUnderLine from '../../../../components/basic/Dates/DatePickerMUIWithUnderLine/DatePickerMUIWithUnderLine';
import { InterfaceSubProfileInformationIndividualUpdate } from './SubProfile/SubProfileIndividual/CreateSubProfileIndividual/InterfaceCreateSubProfileIndividual';
import CreateSubProfileIndividual from './SubProfile/SubProfileIndividual/CreateSubProfileIndividual/CreateSubProfileIndividual';
import SelectGloballyWithUnderLine from '../../../../components/complex/Selector/SelectGloballyWithUnderLine/SelectGloballyWithUnderLine';
import { InterfaceNTTGlobalSelectorArray } from '../../../../NTTs/InterfaceNTTGlobalSelectorArray';
import CreateSubProfileForeignLegal from './SubProfile/SubProfileForeignLegal/CreateSubProfileForeignLegal/CreateSubProfileForeignLegal';
import { InterfaceSubProfileInformationForeignLegalUpdate } from './SubProfile/SubProfileForeignLegal/CreateSubProfileForeignLegal/InterfaceCreateSubProfileForeignLegal';
import FilesDragAndDrop from '../../../../components/complex/FileDragAndDrop/FileDragAndDrop';
import CreateSubProfileIranLegal from './SubProfile/SubProfileDomesticLegal/CreateSubProfileIranLegal/CreateSubProfileIranLegal';
import { InterfaceSubProfileInformationIranLegalUpdate } from './SubProfile/SubProfileDomesticLegal/CreateSubProfileIranLegal/InterfaceCreateSubProfileIranLegal';
export const MainCard = styled('div')(({ theme }) => ({
    width: '100%',
    borderRadius: '0.5rem',
    boxShadow: Colors.shadow.dropShadow,
    boxSizing: 'border-box',
    paddingInline: '1.44rem',
    paddingBlock: '0.8rem',
    [theme.breakpoints.up('xs')]: {
        paddingInline: '2.44rem',
        paddingBlock: '1rem',
    },
    [theme.breakpoints.up('sm')]: {
        paddingInline: '3.44rem',
        paddingBlock: '1.84rem',
    },
    [theme.breakpoints.up('md')]: {
        paddingInline: '3.74rem',
        paddingBlock: '2.14rem',

    },
    [theme.breakpoints.up('lg')]: {
        paddingInline: '3.84rem',
        paddingBlock: '2.24rem',
    },
    [theme.breakpoints.up('xl')]: {
        paddingInline: '3.84rem',
        paddingBlock: '2.24rem',
    },

}));
export const DivImageProfile = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    flexGrow: '0',
    width: '2.54rem',
    [theme.breakpoints.up('xs')]: {
        width: '3.04rem',
    },
    [theme.breakpoints.up('sm')]: {
        width: '4.04rem',
    },
    [theme.breakpoints.up('md')]: {
        width: '5.04rem',

    },
    [theme.breakpoints.up('lg')]: {
        width: '6rem',
    },
    [theme.breakpoints.up('xl')]: {
        width: '6rem',
    },

}));
enum EnumTypeProfileNoneStaff {
    legal_iran = 1,
    legal_foreign = 2,
    individual = 3,
}
function CreateProfileNoneStaff(paramsPage: InterfaceCreateProfileNoneStaff) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const { role } = useParams();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getUsername, setUsername] = useState<string>('');
    const [getEmail, setEmail] = useState<string>('');
    const [getCredit, setCredit] = useState<string>('');
    const [getActivitySelected, setActivitySelected] = useState<InterfaceNTTActivity[]>([]);
    const [getDescription, setDescription] = useState<string>('');
    const [getSubProfile, setSubProfile] = useState<InterfaceSubProfileInformationIndividualUpdate | InterfaceSubProfileInformationForeignLegalUpdate | InterfaceSubProfileInformationIranLegalUpdate>({});
    const [getTypeOfProfile, setTypeOfProfile] = useState<EnumTypeProfileNoneStaff>();
    const [getFileForImageProfile, setFileForImageProfile] = useState<{ file?: File, urlServer?: string }>();



    // Function to cast the role to EnumRolePerson
    const getRole = (role: string | undefined): EnumRolePerson | undefined => {
        if (role && Object.values(EnumRolePerson).includes(role as EnumRolePerson)) {
            return role as EnumRolePerson;
        }
        return undefined;
    };

    // Use the function to get the typed role
    const typedRole = getRole(role);



    const callApiCreateProfile = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.customer.postAddCustomer;
        if (getTypeOfProfile === EnumTypeProfileNoneStaff.individual) {
            urlObj = urlsList.panel.customer.postAddCustomer;
        }
        else {
            urlObj = urlsList.panel.customer.postAddCustomer;
        }

        if (typedRole === EnumRolePerson.trusty) {
            urlObj = urlsList.panel.trusty.postAddTrusty;
        }
        let dataObj = {
            ...getSubProfile,
            'username': getUsername,
            'email': getEmail,
            'person_type': getTypeOfProfile === EnumTypeProfileNoneStaff.individual ? "IND" :
                getTypeOfProfile === EnumTypeProfileNoneStaff.legal_foreign ?
                    "FLP" : "DLP",
            'is_customer': typedRole === EnumRolePerson.customer,
            'is_trusty': typedRole === EnumRolePerson.trusty,
            'credit': `${getCredit}`,
            'activities': getActivitySelected.map(ac => ac.id),
            'description': getDescription,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, dataObj, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                // dispatch(isVisibilityProgressBar(false));
                // navigate(-1);
                if (!getFileForImageProfile?.file) {
                    navigate(-1);
                }
                callApiForUploadImageOfProfile(response.user.id);
                // let t = response as (InterfaceNTTActivity[]);
                // setResponseAllActivity(t);
                dispatch(ShowAlert({ text: typedRole === EnumRolePerson.trusty ? confComps.translate.createdTrusty : confComps.translate.customer, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const callApiForUploadImageOfProfile = async (idProfile: number) => {
        if (!getFileForImageProfile?.file) {
            dispatch(isVisibilityProgressBar(false));
            return;
        }
        dispatch(isVisibilityProgressBar(true));


        let urlObj = urlsList.panel.trusty.addProfilePicture;

        const formData = new FormData();
        formData.append("profile_pic", getFileForImageProfile.file);

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idProfile}/profile-pic/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, formData, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                dispatch(isVisibilityProgressBar(false));
                navigate(-1);
                // dispatch(ShowAlert({ text: confComps.translate.createdEmployee, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (getFileForImageProfile && getFileForImageProfile.file) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                setFileForImageProfile(prev => {
                    return ({
                        ...prev,
                        urlServer: event.target.result as string
                    })
                });
            };

            reader.readAsDataURL(getFileForImageProfile.file);
        }
    }, [getFileForImageProfile]);

    return (
        <ContainerPage title={typedRole === EnumRolePerson.trusty ? confComps.translate.addTrusty : confComps.translate.addCustomer} >
            <MainCard>
                <div style={{
                    display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'stretch',
                    boxSizing: 'border-box',
                    gap: '2.56rem',
                    marginBottom: '0.52rem',
                }}>
                    <DivImageProfile>
                        <FilesDragAndDrop
                            onUpload={(files) => {
                                files.forEach((value) => {
                                    setFileForImageProfile({ file: value });
                                })
                            }}
                            count={1}
                            formats={['jpg', 'png']}
                            containerStyles={{
                                borderRadius: "4px",
                                boxSizing: "border-box",

                            }}
                            openDialogOnClick
                        >
                            <ImageSquare width={'100%'}
                                borderRadius='500px'
                                style={{ position: 'relative', overflow: 'visible', boxSizing: 'border-box' }}
                                iconSvg={getFileForImageProfile?.urlServer ? undefined : Person2Rounded}
                                img={getFileForImageProfile?.urlServer}
                                element={
                                    <EditPenCircle style={{
                                        zIndex: 5, borderRadius: '500px', background: '#fff',
                                        position: 'absolute',
                                        width: '16',
                                        height: '16',
                                        transform: 'translate(50%,50%)',
                                        bottom: '14.64466094%', right: '14.64466094%',

                                    }}
                                    />
                                }
                                elementStyle={{
                                    zIndex: 4,
                                    position: 'absolute',
                                    width: '28px',
                                    transform: 'translate(0,0)',
                                    top: '100px', left: '100%',
                                }}
                                border={`2px solid ${Colors.theme.blue.blue}`}
                                color={Colors.theme.blue.light}
                                background={Colors.theme.white.PrimaryLight} />
                        </FilesDragAndDrop>

                    </DivImageProfile>

                </div>

                <h5 style={{
                    fontFamily: confComps.font.poppins.Medium,
                    fontSize: '1.28rem',
                    lineHeight: '1.92rem',
                    color: Colors.theme.blue.dark,
                    fontWeight: '100',
                    marginBlock: '0px',
                    marginBottom: '2.4rem',
                }}>
                    {"General Information"}
                </h5>
                <Box sx={{
                    marginInlineStart: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 2 },
                    marginInlineEnd: { xs: 0, sm: 16, md: 24, lg: 32, xl: 40 },
                    boxSizing: 'border-box',
                }}>
                    <Grid container
                        justifyContent='space-between' alignItems='flex-start'
                        columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 22, xl: 30 }}
                        rowSpacing={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 6 }}
                        columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                    >

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.translate.username}
                                onChangeValue={setUsername}
                                isDataMustLTR={true}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />
                        </Grid>



                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldWithIconBottomLine
                                hintText={confComps.translate.email}
                                type='email'
                                onChangeValue={setEmail}
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />
                        </Grid>

                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>

                            <TextFieldWithIconBottomLine
                                hintText={confComps.translate.credit}
                                onChangeValue={setCredit}
                                type='number'
                                isDataMustLTR={false}
                                isSmallHintShow={true}
                                iconEnd={EditPenIcon}
                            />

                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <TextFieldMultiSelectActivity
                                title={confComps.translate.activities}
                                onChangeSelectedActivities={(activities) => {
                                    setActivitySelected(activities)
                                }}
                                selectedActivities={getActivitySelected}
                            />
                        </Grid>
                        <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                            <SelectGloballyWithUnderLine
                                title={"Type Profile"}
                                onChangeSelectedItems={function (items: InterfaceNTTGlobalSelectorArray[]): void {
                                    if (items.length > 0) {
                                        setTypeOfProfile(+items[0].id);
                                    }
                                    else {
                                        setTypeOfProfile(undefined);
                                    }
                                }}
                                selectedItems={getTypeOfProfile ? [getTypeOfProfile] : []}
                                allItems={[
                                    {
                                        id: EnumTypeProfileNoneStaff.legal_foreign,
                                        title: "Legal Foreign",
                                    },
                                    {
                                        id: EnumTypeProfileNoneStaff.legal_iran,
                                        title: "Legal Iran",
                                    },
                                    {
                                        id: EnumTypeProfileNoneStaff.individual,
                                        title: "Individual",
                                    }
                                ]}
                                limitSelector={1}
                            />
                        </Grid>
                    </Grid>
                </Box>
                {getTypeOfProfile === EnumTypeProfileNoneStaff.individual &&
                    <Box sx={{
                        marginBlock: { xs: 2, sm: 3, md: 4, lg: 6, xl: 6 },
                        boxSizing: 'border-box',
                    }}>
                        <CreateSubProfileIndividual
                            callbackUpdateValues={(params: InterfaceSubProfileInformationIndividualUpdate) => {
                                setSubProfile(params);
                            }}
                            defaultValue={undefined}
                        />
                    </Box>
                }
                {getTypeOfProfile === EnumTypeProfileNoneStaff.legal_foreign &&
                    <Box sx={{
                        marginBlock: { xs: 2, sm: 3, md: 4, lg: 6, xl: 6 },
                        boxSizing: 'border-box',
                    }}>
                        <CreateSubProfileForeignLegal
                            callbackUpdateValues={(params: InterfaceSubProfileInformationForeignLegalUpdate) => {
                                setSubProfile(params);
                            }}
                            defaultValue={undefined}
                        />
                    </Box>
                }
                {getTypeOfProfile === EnumTypeProfileNoneStaff.legal_iran &&
                    <Box sx={{
                        marginBlock: { xs: 2, sm: 3, md: 4, lg: 6, xl: 6 },
                        boxSizing: 'border-box',
                    }}>
                        <CreateSubProfileIranLegal
                            callbackUpdateValues={(params: InterfaceSubProfileInformationIranLegalUpdate) => {
                                setSubProfile(params);
                            }}
                            defaultValue={undefined}
                        />
                    </Box>
                }
                {getTypeOfProfile &&
                    <Box sx={{
                        marginInlineStart: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 2 },
                        marginInlineEnd: { xs: 0, sm: 16, md: 24, lg: 32, xl: 40 },
                        boxSizing: 'border-box',
                    }}>
                        <Grid container
                            justifyContent='space-between' alignItems='flex-start'
                            columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 22, xl: 30 }}
                            rowSpacing={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 6 }}
                            columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                        >

                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                <TextFieldWithIconBottomLine
                                    hintText={confComps.translate.description}
                                    onChangeValue={setDescription}
                                    isDataMustLTR={false}
                                    isSmallHintShow={true}

                                    iconEnd={EditPenIcon}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                }

                <div style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end', width: '100%',
                    marginTop: '6rem',
                }}>

                    <ButtonSave text={confComps.panel.myProfile.pageInfo.saveButton}
                        callbackOnClick={() => {
                            callApiCreateProfile();
                        }}
                    />
                    {/* <p style={{
                        color: Colors.theme.white.PrimaryLight, fontFamily: confComps.font.poppins.Medium,
                        fontWeight: '200',
                        fontSize: '1.12rem',
                        cursor: 'pointer',
                        background: Colors.theme.green.dark,
                        paddingInline: '1.52rem',
                        paddingBlock: '0.32rem',
                        borderRadius: '100.32rem',
                    }}>
                        
                    </p> */}
                </div>

            </MainCard>

        </ContainerPage >
    )
};
export default CreateProfileNoneStaff;