import { ChangeEvent, useEffect, useRef, useState } from "react";
import { ConfigComps } from "../../../config/ConfigCOMP";
import Colors from "../../../config/Colors";
import { keyframes, styled, useTheme } from "@mui/material";
import { CancelRounded, DoneRounded, SyncRounded } from "@mui/icons-material";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { RequestHandler } from "../../../api/API";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../MUIConfig/alert_popup/interfaceAlertPopup";
import LineSeparator from "../../basic/LineSeprator/LineSeparator";
import { InterfaceNTTCity } from "../../../NTTs/InterfaceNTTCity";
import { InterfaceNTTCurrency } from "../../../NTTs/InterfaceNTTCurrency";
import { InterfaceMultiSelectCurrenciesV2 } from "./InterfaceMultiSelectCurrenciesV2";
import AutoCompleteSimple from "../AutoCompleteSimple/AutoCompleteSimple";
import { InterfaceItemOfAutoComplete } from "../AutoCompleteSimple/InterfaceAutoCompleteSimple";
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerIcon = styled(SyncRounded)(({ theme }) => ({
    animation: `${spin} 2s linear infinite`,
}));
const RowOfList = styled("div")(({ theme }) => ({
    display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between",
    fontSize: "1rem",
    width: "100%",
    paddingInline: "1rem",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,

    }
}));
export const DropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            overflow: "hidden",
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            width: "100%",
            background: "#F8F8F8",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: "start",
            borderEndEndRadius: "1rem",
            borderEndStartRadius: "1rem",
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",
        }
    )
});
function MultiSelectCurrenciesV2(paramsComponent: InterfaceMultiSelectCurrenciesV2) {
    var confComps = ConfigComps();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getResponseAllCurrency, setResponseAllCurrency] = useState<InterfaceNTTCurrency[]>([]);
    const [getSelectedCurrencies, setSelectedCurrencies] = useState<InterfaceNTTCurrency[]>(paramsComponent.defaultSelected);
    const [getSearchText, setSearchText] = useState<string>("");
    const [getStateIsSearching, setStateIsSearching] = useState<boolean>(false);
    const [getIsFocused, setIsFocused] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const containerBoxRef = useRef<HTMLDivElement>(null);


    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log('call changed', event.target.value)
        // Clear the previous timeout if it exists
        // if (timeoutId) {
        //     clearTimeout(timeoutId);
        // }
        // Get the current value from the input
        const { value } = event.target;
        // Set a new timeout
        // const newTimeoutId = setTimeout(() => {
        //     // Call your API function here with the updated value
        //     callApiForGetListRegions({ origin_country_id: (paramsComponent.origin_country?.id ?? -1), term: value });
        // }, 1000); // Adjust the delay time as needed (e.g., 1000ms = 1 second)
        // Update state to store the timeout ID
        // setTimeoutId(newTimeoutId);
        // Update the input value state
        setSearchText(value);
    };


    const callApiForGetListCurrencies = async (searchText: string) => {
        dispatch(isVisibilityProgressBar(true));
        setStateIsSearching(true);
        let urlObj = urlsList.panel.currency.getCurrenciesType;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as (InterfaceNTTCurrency[]);
                setResponseAllCurrency(t);

                dispatch(isVisibilityProgressBar(false));
                // dispatch(ShowAlert({ text: confComps.translate.createdCompany, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
        setStateIsSearching(false);


    }
    // useEffect(() => {
    //     if (getIsFocused) {
    //         const handleClickOutside2 = (event: MouseEvent) => {
    //             if (containerRefCurrency.current && !containerRefCurrency.current.contains(event.target as Node)) {
    //                 setIsFocused(false);
    //                 setSearchText(getSelectedCurrency?.symbol ?? "");
    //             }
    //         };

    //         document.addEventListener("mousedown", handleClickOutside2);
    //         return () => {
    //             document.removeEventListener("mousedown", handleClickOutside2);
    //         };
    //     }
    // }, [getIsFocused]);
    useEffect(() => {
        callApiForGetListCurrencies("");
    }, [])
    useEffect(() => {
        setSelectedCurrencies(paramsComponent.defaultSelected);
    }, [paramsComponent.defaultSelected])
    useEffect(() => {
        if (getIsFocused) {
            const handleClickOutside = (event: MouseEvent) => {
                const isClickInsideContainer = containerRef.current && containerRef.current.contains(event.target as Node);
                const isClickInsideBox = containerBoxRef.current && containerBoxRef.current.contains(event.target as Node);

                if (!isClickInsideContainer && !isClickInsideBox) {
                    setIsFocused(false);
                    setSearchText("");
                }
            }

            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [getIsFocused]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', position: "relative", width: "100%" }}
            ref={containerRef}
        >
            <label style={{
                fontWeight: "300",

                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
            }}>
                {confComps.translate.currency}
            </label>
            <div style={{
                background: Colors.theme.white.white90,
                border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                borderRadius: '0.4rem',
                fontFamily: confComps.font.poppins.Regular,
                fontSize: '1.12rem',
                width: "100%",
                color: Colors.theme.blue.dark,
                outline: 'none',
                lineHeight: "1.68rem",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: 'center',
                paddingInline: '0.64rem',
                boxSizing: "border-box",
            }}>
                {getSelectedCurrencies.map((currency, index, arr) => {
                    return (
                        <div style={{
                            maxWidth: "calc(100% - 6px)",
                            margin: "3px",
                            fontSize: "1rem",
                            fontFamily: confComps.font.poppins.SemiBold,
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            // height: "32px",
                            color: "rgba(0, 0, 0, 0.87)",
                            background: "rgba(0, 0, 0, 0.08)",
                            fontWeight: "400",
                            lineHeight: "1.43em",
                            boxSizing: "border-box",
                            borderRadius: "500px",
                            paddingInlineStart: "0.8rem",
                            paddingBlock: "0.1rem",
                            paddingInlineEnd: "0.5rem",
                        }}>
                            {currency.title}
                            <div
                                style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    paramsComponent.onChangeSelectedCurrency(getSelectedCurrencies.filter(x => x.title !== currency.title || x.id !== currency.id));
                                }}>
                                <CancelRounded
                                    style={{
                                        color: "rgba(0, 0, 0, 0.26)",
                                        cursor: "pointer",
                                    }} />
                            </div>
                        </div>
                    )
                })
                }

                <input

                    autoComplete="off"
                    type="text"
                    onFocus={() => setIsFocused(true)}
                    style={{
                        boxSizing: "border-box",
                        paddingInline: '0.64rem',
                        paddingBlock: '0.32rem',
                        flexGrow: "1",
                        flexBasis: "40px",
                        fontFamily: confComps.font.poppins.Regular,
                        fontSize: '1.12rem',
                        lineHeight: "1.68rem",
                        color: Colors.theme.blue.dark,
                        outline: 'none',
                        textOverflow: "ellipsis",
                        cursor: "pointer"
                    }}
                    placeholder={confComps.translate.search}

                    value={getSearchText}
                    onChange={handleChangeInput}

                />
            </div>
            {

                <DropdownBox
                    isDropdownOpen={getIsFocused || getStateIsSearching}
                    ref={containerBoxRef}
                >
                    {getStateIsSearching &&
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                            <SpinnerIcon />
                        </div>
                    }
                    {!getStateIsSearching && (getResponseAllCurrency
                        .filter(cur => cur.title.toLowerCase().includes(getSearchText.toLowerCase()))
                        .length === 0) &&
                        <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'center' }}>
                            {confComps.translate.notFound}
                        </div>
                    }
                    {getResponseAllCurrency
                        .filter(cur => cur.title.toLowerCase().includes(getSearchText.toLowerCase()))
                        .flatMap((cur, index, arr) => {
                            if ((index + 1) === arr.length) {
                                return (
                                    [<RowOfList onClick={() => {
                                        setSearchText("");
                                        if (getSelectedCurrencies.includes(cur)) {
                                            paramsComponent.onChangeSelectedCurrency(getSelectedCurrencies.filter(x => x.id !== cur.id || x.title !== cur.title));
                                        }
                                        else {
                                            paramsComponent.onChangeSelectedCurrency([cur, ...getSelectedCurrencies]);
                                        }
                                        // setIsFocused(false);
                                    }}>
                                        {cur.title}
                                        {getSelectedCurrencies.includes(cur) &&
                                            <DoneRounded style={{ color: Colors.theme.blue.blue }} />
                                        }
                                    </RowOfList>
                                    ]
                                )
                            }
                            return (
                                [<RowOfList onClick={() => {
                                    setSearchText("");
                                    if (getSelectedCurrencies.includes(cur)) {
                                        paramsComponent.onChangeSelectedCurrency(getSelectedCurrencies.filter(x => x.id !== cur.id || x.title !== cur.title));
                                    }
                                    else {
                                        paramsComponent.onChangeSelectedCurrency([cur, ...getSelectedCurrencies]);
                                    }
                                    // setIsFocused(false);
                                }}>
                                    {cur.title}
                                    {getSelectedCurrencies.includes(cur) &&
                                        <DoneRounded style={{ color: Colors.theme.blue.blue }} />
                                    }
                                </RowOfList>
                                    ,
                                <LineSeparator />]

                            );
                        })
                    }
                </DropdownBox>


            }
        </div>



    );
}
export default MultiSelectCurrenciesV2;