import { ChangeEvent, useState } from "react";
import { InterfaceTextFieldSimple } from "./InterfaceTextFieldSimple";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";

function TextFieldSimple(paramsComponent: InterfaceTextFieldSimple) {
    const [getIsFocused, setIsFocused] = useState(false);
    var confComps = ConfigComps();
    return (
        <div style={{
            display: 'flex', flexDirection: 'column',
            ...paramsComponent.style,

        }}>
            {paramsComponent.title && 
            <label style={{
                fontWeight: "300",

                fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                lineHeight: "1.68rem",
                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
            }}>
                {paramsComponent.title}
            </label>}
            <input
                autoComplete="off"
                type={paramsComponent.type ?? "text"}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                style={{
                    background: Colors.theme.white.white90,
                    paddingInline: '0.64rem',
                    paddingBlock: '0.32rem',
                    border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                    boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                    borderRadius: '0.4rem',
                    fontFamily: confComps.font.poppins.Regular,
                    fontSize: '1.12rem',
                    color: Colors.theme.blue.dark,
                    outline: 'none',
                    lineHeight: "1.68rem",
                }}
                value={paramsComponent.textValue}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    paramsComponent.onChangeValue(event.target.value);
                }}
            />
        </div>
    )
}
export default TextFieldSimple;