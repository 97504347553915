import { CSSProperties } from "react";
import { InterfaceIconSVG } from "../../../../assets/icons/InterfaceIconSVG";

export interface InterfaceTextFieldWithIconBottomLine {
    defaultValue?: string;
    type?: string;
    hintText: string;
    isLockValue?: boolean;
    styleMain?: CSSProperties;
    iconStart?: React.FC<InterfaceIconSVG>;
    iconEnd?: React.FC<InterfaceIconSVG>;
    onChangeValue: (value: string) => any;
    isDataMustLTR: boolean;
    isSmallHintShow: boolean;
    patternInput?: string;
    maxCountCharacter?: number;
    customType?: EnumTypeInput;
}
export enum EnumTypeInput {
    nationalID = 1,
}