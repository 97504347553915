import { styled } from "@mui/material";
import Colors from "../../../config/Colors";

const borderRadius = "1rem";
const paddingInline = "16px";
export const SortingBoxParent = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            border: isDropdownOpen ? undefined : "1px solid rgba(112,119,161,0.01)",
            borderStartStartRadius: borderRadius,
            borderStartEndRadius: borderRadius,
            borderEndEndRadius: isDropdownOpen ? "0" : borderRadius,
            borderEndStartRadius: isDropdownOpen ? "0" : borderRadius,
            boxShadow: Colors.shadow.shadowEditTextBoxClicked,
            // boxShadow: isDropdownOpen ? "0px 1px 6px rgba(112,119,161,0.3)" : "0px 0px 5px rgba(112,119,161,0.4)",
            background: "#F8F8F8",
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingInline: paddingInline,
            paddingBlock: "8px",
            boxSizing: "border-box",
            borderInline: isDropdownOpen ? "1px solid #ccc" : undefined,
            borderBlockStart: isDropdownOpen ? "1px solid #ccc" : undefined,
        }
    )
});
export const SortingDropdownBox = styled("div", { shouldForwardProp: (prop) => prop !== 'isDropdownOpen' })<{ isDropdownOpen: boolean; }>(({
    theme,
    isDropdownOpen
}) => {
    return (
        {
            position: "absolute",
            top: "calc(100% + 0.5rem)",
            left: "0",
            zIndex: isDropdownOpen ? "3" : "-1",
            minWidth: "100%",
            background: "#F8F8F8",
            marginTop: "-10px",
            boxShadow: "0px 4px 6px rgba(112,119,161,0.3)",
            borderInline: "1px solid #ccc",
            borderBlockStart: "1px solid #DFDFDF",
            borderBlockEnd: "1px solid #ccc",
            boxSizing: "border-box",

            borderEndEndRadius: borderRadius,
            borderEndStartRadius: borderRadius,
            opacity: isDropdownOpen ? 1 : 0,
            transition: "zIndex,opacity 0.3s ease, transform 0.5s ease",
            transform: isDropdownOpen ? "translateY(0%)" : "translateY(-20%)",
            visibility: isDropdownOpen ? "visible" : "hidden",



        }
    )
});
export const DropdownItemTitle = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    textWrap: "nowrap",
    flexWrap: "nowrap",
    width: "100%",
    fontSize: "0.9rem",
    boxSizing: "border-box",
    color: Colors.theme.blue.blue,
    borderBlockEnd: "1px solid #DFDFDF",
    paddingInline: paddingInline,
    paddingBlock: "4px",
    gap: "1rem",
    userSelect: "none",
    "&:hover": {
        background: Colors.theme.orange.lemonLight,
    }

}));
