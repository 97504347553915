import { Box, Grid, useTheme } from "@mui/material";
import { InterfaceRowOfForeignAccount } from "./InterfaceRowOfForeignAccount";
import KeyTitleH6 from "../../../../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import ValueP from "../../../../../../../../components/basic/Texts/Titles/ValueP/ValueP";
import Colors from "../../../../../../../../config/Colors";
import { ConfigComps } from "../../../../../../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../../../../../store/profileStateSlice";
import { EditPenIcon } from "../../../../../../../../assets/icons/svg/EditPenIcon";
import { TrashIcon } from "../../../../../../../../assets/icons/svg/TrashIcon";
import { isVisibilityProgressBar } from "../../../../../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, urlsList } from "../../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../../api/API";
import { ShowAlert } from "../../../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../../api/APIErrorResponse";

function RowOfForeignAccount(paramsComponent: InterfaceRowOfForeignAccount) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const callApiForDeleteBankAccount = async (idBankAccount: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.foreignBankAccount.deleteTheBankAccount;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idBankAccount}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackReloadAccount();
                dispatch(ShowAlert({ text: confComps.translate.removedCashAccount, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }

    return (
        <Box sx={{
            width: "100%",
            paddingInline: { xs: 1, sm: 1, md: 1, lg: 2, xl: 3 },
            // paddingBlock: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
            boxSizing: "border-box",
            border: `1px solid ${Colors.theme.orange.light}`,
            borderRadius: "0.8rem",
            paddingBlock: { xs: 1, sm: 1, md: 2, lg: 3, xl: 4 },
        }}>
            <Grid container
                justifyContent="space-between" alignItems="flex-start"
                columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 3, xl: 5 }}
                rowSpacing={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}
                columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
            >

                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.accountNumber} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.account_number} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.iBan} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.iban} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.iBan} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.iban} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.swiftCode} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.swift_code} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.currency} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.currency.symbol} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.country} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.country.name} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.city} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.city.name} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.bankName} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.bank_name} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.branchName} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.branch_name} />
                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                    <div style={{
                        display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "start", width: "100%",
                        boxSizing: "border-box",
                        gap: "0.32rem",
                    }}>
                        <KeyTitleH6 title={confComps.translate.bankAddress} style={{ color: Colors.theme.blue.light }}></KeyTitleH6>
                        <ValueP style={{ color: Colors.theme.blue.dark }} title={paramsComponent.bank_address} />
                    </div>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <div style={{
                        width: "100%", borderRadius: "0.4rem",
                        background: "#F3F6FC", padding: "0.5rem",
                        boxSizing: "border-box",
                        border: `1px solid ${Colors.theme.blue.light_2}`
                    }}>
                        {paramsComponent.description}

                    </div>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={6} xl={6}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "end", width: "100%", gap: "0.5rem" }}>
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                            onClick={paramsComponent.callbackClickOnEdit}
                        >
                            <EditPenIcon style={{
                                background: Colors.theme.blue.light,
                                width: "0.75rem",
                                height: "0.75rem",
                                cursor: "pointer"
                            }}
                            />
                        </div>
                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", cursor: "pointer" }}
                            onClick={() => { callApiForDeleteBankAccount(paramsComponent.id) }}
                        >
                            <TrashIcon />
                        </div>

                    </div>

                </Grid>

            </Grid>
        </Box>
    )
}
export default RowOfForeignAccount;