export function ConvertPercentToGradientWhiteAndGreen(percentage: number): string {
    // Ensure the percentage is within bounds
    const clampedPercentage = Math.max(0, Math.min(percentage, 100));

    // Start color (white) and end color (green)
    const startColor = { r: 255, g: 255, b: 255 }; // white
    const endColor = { r: 116, g: 226, b: 145 }; // green

    // Calculate the color based on the percentage
    const r = Math.round(startColor.r + (endColor.r - startColor.r) * (clampedPercentage / 100));
    const g = Math.round(startColor.g + (endColor.g - startColor.g) * (clampedPercentage / 100));
    const b = Math.round(startColor.b + (endColor.b - startColor.b) * (clampedPercentage / 100));

    // Return the color as a CSS-compatible string
    return `rgb(${r}, ${g}, ${b})`;
};
export function ConvertPercentToGradientWhiteAndOrange(percentage: number): string {
    // Ensure the percentage is within bounds
    const clampedPercentage = Math.max(0, Math.min(percentage, 100));

    // Start color (white) and end color (green)
    const startColor = { r: 255, g: 255, b: 255 }; // white
    const endColor = { r: 255, g: 64, b: 0 }; // green

    // Calculate the color based on the percentage
    const r = Math.round(startColor.r + (endColor.r - startColor.r) * (clampedPercentage / 100));
    const g = Math.round(startColor.g + (endColor.g - startColor.g) * (clampedPercentage / 100));
    const b = Math.round(startColor.b + (endColor.b - startColor.b) * (clampedPercentage / 100));

    // Return the color as a CSS-compatible string
    return `rgb(${r}, ${g}, ${b})`;
};