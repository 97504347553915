import { useEffect, useState } from "react";
import { InterfaceCreateOneStepOfFreestyle } from "./InterfaceCreateOneStepOfFreestyle";
import { Box, Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AddRounded, ArrowRightRounded, CancelRounded, DoneRounded, KeyboardArrowDownRounded, PaymentsRounded } from "@mui/icons-material";
import ParentWithHeightAnimation from "../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import Colors, { ColorThemForButton } from "../../../../../../config/Colors";
import { InterfaceNTTFreestyleMain } from "../../../../../../NTTs/Freestyle/InterfaceNTTFreestyleMain";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import ButtonColor from "../../../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import SelectTrustyOrCustomer from "../../../../../../components/complex/Selector/SelectTrustyAndCustomer/SelectTrustyOrCustomer";
import { InterfaceNTTCurrencyWithTypeAndLocation } from "../../../../../../NTTs/InterfaceNTTCurrency";
import { InterfaceGetAPITrustyOrCustomer } from "../../../../../../components/complex/Selector/SelectTrustyAndCustomer/InterfaceSelectTrustyOrCustomer";
import TextFieldMultiLine from "../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import TextFieldSimple from "../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple";
import SelectCurrencyWithTypeAndLocation from "../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation";
import SelectYesOrNo from "../../../../../../components/complex/Selector/SelectYesOrNo/SelectYesOrNo";
import { WalletIcon } from "../../../../../../assets/icons/svg/WalletIcon";
import { CurrencySymbols } from "../../../../../../helper/CurrencySymbols";
import ChangeRateWithAmount from "../../../../../../components/complex/ChangeRateWithAmount/ChangeRateWithAmount";
import SelectTypeOfFreestyleStep from "../../../../../../components/complex/Selector/SelectTypeOfFreestyleStep/SelectTypeOfFreestyleStep";
import { InterfaceNTTTypeOfFreestyleStep } from "../../../../../../NTTs/Freestyle/InterfaceNTTTypeOfFreestyleStep";
import SelectPriority from "../../../../../../components/complex/Selector/SelectPriority/SelectPriority";
import { TrashIcon } from "../../../../../../assets/icons/svg/TrashIcon";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import { HandlerLangUrl, urlsList } from "../../../../../../api/UrlsList";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";

function CreateOneStepOfFreestyle(paramsComponent: InterfaceCreateOneStepOfFreestyle) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getFreestyleMain, setFreestyleMain] = useState<InterfaceNTTFreestyleMain>();
    const [getIsShowThisBox, setIsShowThisBox] = useState<boolean>(false);
    const [getFromAmountValue, setFromAmountValue] = useState<string>("");
    const [getFromCustomerSelected, setFromCustomerSelected] = useState<InterfaceGetAPITrustyOrCustomer>();
    const [getFromSelectedCurrency, setFromSelectedCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();

    const [getFromCommissionActive, setFromCommissionActive] = useState<boolean>(false);
    const [getFromCommissionSelectedCurrency, setFromCommissionSelectedCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getFromCommissionChangeRate, setFromCommissionChangeRate] = useState<string>("0");
    const [getFromCommissionFixPrice, setFromCommissionFixPrice] = useState<string>("0");
    const [getFromCommissionPercentPrice, setFromCommissionPercentPrice] = useState<string>("0");


    const [getToCustomerSelected, setToCustomerSelected] = useState<InterfaceGetAPITrustyOrCustomer>();
    const [getToSelectedCurrency, setToSelectedCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation | undefined>();
    const [getToChangeRate, setToChangeRate] = useState<string>("0");

    const [getToCommissionActive, setToCommissionActive] = useState<boolean>(false);
    const [getToCommissionSelectedCurrency, setToCommissionSelectedCurrency] = useState<InterfaceNTTCurrencyWithTypeAndLocation>();
    const [getToCommissionChangeRate, setToCommissionChangeRate] = useState<string>("0");
    const [getToCommissionFixPrice, setToCommissionFixPrice] = useState<string>("0");
    const [getToCommissionPercentPrice, setToCommissionPercentPrice] = useState<string>("0");
    // 
    const [getStepType, setStepType] = useState<InterfaceNTTTypeOfFreestyleStep>();
    const [getPriorityStep, setPriorityStep] = useState<number>();
    const [getDocumentTitles, setDocumentTitles] = useState<string[]>([""]);

    const [getDescriptionValue, setDescriptionValue] = useState<string>("");

    useEffect(() => {
        setIsShowThisBox(paramsComponent.isShowThisBox);
    }, [paramsComponent.isShowThisBox])
    useEffect(() => {
        setFreestyleMain(paramsComponent.freestyle);
    }, [paramsComponent.freestyle])
    const addNewEmptyDocumentationTitle = async () => {
        setDocumentTitles(prev => [...prev, ""]);
    }
    const callAPIForCreateThisStepInServer = async () => {
        if (!getFromCustomerSelected) {
            dispatch(ShowAlert({ text: 'Please Select Customer In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (!getFromSelectedCurrency) {
            dispatch(ShowAlert({ text: 'Please Select Currency Type In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (!getFromAmountValue) {
            dispatch(ShowAlert({ text: 'Please Fill Amount In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (getFromCommissionActive && !getFromCommissionFixPrice) {
            dispatch(ShowAlert({ text: 'Please Fill Commission Fix In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (getFromCommissionActive && !getFromCommissionPercentPrice) {
            dispatch(ShowAlert({ text: 'Please Fill Commission Percent In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (getFromCommissionActive && !getFromCommissionPercentPrice) {
            dispatch(ShowAlert({ text: 'Please Fill Commission Percent In Part "From".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        // to
        else if (!getToCustomerSelected) {
            dispatch(ShowAlert({ text: 'Please Select Customer In Part "To".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (!getToSelectedCurrency) {
            dispatch(ShowAlert({ text: 'Please Select Currency Type In Part "To".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (getToCommissionActive && !getToCommissionFixPrice) {
            dispatch(ShowAlert({ text: 'Please Fill Commission Fix In Part "To".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (getToCommissionActive && !getToCommissionPercentPrice) {
            dispatch(ShowAlert({ text: 'Please Fill Commission Percent In Part "To".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (getToCommissionActive && !getToCommissionPercentPrice) {
            dispatch(ShowAlert({ text: 'Please Fill Commission Percent In Part "To".', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (!getStepType) {
            dispatch(ShowAlert({ text: 'Please Select Step Type.', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        else if (!getPriorityStep) {
            dispatch(ShowAlert({ text: 'Please Select Priority.', typeAlert: enumTypeOfAlert.warning, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            return;
        }
        let urlObj = urlsList.panel.deals.freestyle.postCreateStepOfFreestyle;

        let bodyObj = [{
            "from_person": getFromCustomerSelected.user.id,
            "from_currency_unit": getFromSelectedCurrency.id,
            "from_amount": getFromAmountValue,
            "from_commission_fix": getFromCommissionActive ? getFromCommissionFixPrice : 0,
            "from_commission_percent": getFromCommissionActive ? getFromCommissionPercentPrice : 0,
            "from_commission_currency_unit": getFromCommissionActive ? getFromCommissionSelectedCurrency?.id : null,
            "from_commission_change_rate": getFromCommissionActive ? getFromCommissionChangeRate : 0,
            //to section
            "to_person": getToCustomerSelected?.user.id,
            "to_currency_unit": getToSelectedCurrency.id,
            "to_amount_change_rate": getToChangeRate,
            "to_commission_fix": getToCommissionActive ? getToCommissionFixPrice : 0,
            "to_commission_percent": getToCommissionActive ? getToCommissionPercentPrice : 0,
            "to_commission_currency_unit": getToCommissionActive && getToCommissionSelectedCurrency ? getToCommissionSelectedCurrency.id : null,
            "to_commission_change_rate": getToCommissionActive ? getToCommissionChangeRate : 0,
            /* Other Details */
            "deal_type": getStepType.id,
            "priority": getPriorityStep, // form 1 (lowest) to 5 (highest)
            "description": getDescriptionValue,
            /* Attachments */
            "attachments": getDocumentTitles.map((doc, index) => { return { "title": doc, "number": index } }),
        }]
        dispatch(isVisibilityProgressBar(true));
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.freestyle.id}/steps/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                paramsComponent.callbackOnClickStepAdded();
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'This Step Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });
    }
    return (
        <ParentWithHeightAnimation in={getIsShowThisBox} style={{ width: '100%' }}>
            <Box sx={{
                width: '100%',
                border: `0.5px solid ${Colors.theme.blue.border}`,
                boxSizing: 'border-box',
                boxShadow: Colors.shadow.cardList,
                paddingInline: { xs: "0.7rem", sm: "1rem", md: "2.66rem", lg: "2.96rem", xl: "3.25rem" },
                paddingBlock: "1.18rem",
                borderRadius: '0.38rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'flex-start',
                gap: "1rem",
            }}>
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'start',
                    width: '100%',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                    >
                        <h6 style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'start',
                            justifyContent: 'start',
                            color: Colors.theme.blue.Secondary,
                            fontSize: "1.3rem",
                            marginTop: "0.5rem",
                            lineHeight: "1.77rem",
                            fontFamily: confComps.font.poppins.Medium,
                        }}>
                            {"From"}
                        </h6>
                    </div>
                    <div style={{
                        width: '100%',
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'flex-start',
                    }}>
                        <Grid container
                            justifyContent="space-between" alignItems="flex-start"
                            columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                            rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                <SelectTrustyOrCustomer
                                    title={confComps.translate.customer}
                                    defaultUser={getFromCustomerSelected}
                                    onChangeSelectedUser={setFromCustomerSelected}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <SelectCurrencyWithTypeAndLocation
                                    title={confComps.translate.fromCurrencyType}
                                    onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                        setFromSelectedCurrency(selectCountry)
                                    }}
                                    style={{
                                        minWidth: "380px",
                                        alignItems: "stretch",
                                    }}
                                    defaultCurrency={getFromSelectedCurrency}
                                />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <TextFieldSimple
                                    title={`${confComps.translate.amount} ${getFromSelectedCurrency?.currency.symbol ? `(${getFromSelectedCurrency?.currency.symbol})` : ""}`}
                                    textValue={getFromAmountValue}
                                    type="number"
                                    onChangeValue={function (newText: string): void {
                                        setFromAmountValue(newText)
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectYesOrNo
                                    title={"Is Get Commission?"}
                                    onChangeSelectYesOrNo={(isYes?: boolean) => {
                                        setFromCommissionActive(isYes ?? false);
                                    }}
                                />
                            </Grid>

                            {getFromCommissionActive && getFromSelectedCurrency &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectCurrencyWithTypeAndLocation
                                        title={"Commission unit"}
                                        onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                            setFromCommissionSelectedCurrency(selectCountry)
                                        }}
                                        style={{
                                            minWidth: "380px",
                                            alignItems: "stretch",
                                        }}
                                        defaultCurrency={getFromCommissionSelectedCurrency}
                                    />

                                </Grid>}
                            {getFromCommissionActive && getFromSelectedCurrency && getFromCommissionSelectedCurrency &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ChangeRateWithAmount
                                        callbackOnChangeRate={(rate: string) => {
                                            setFromCommissionChangeRate(rate);
                                        }}
                                        defaultRate={getFromCommissionChangeRate}
                                        defaultFromCurrency={getFromSelectedCurrency}
                                        defaultToCurrency={getFromCommissionSelectedCurrency}
                                        title="Commission Change Rate"
                                    />
                                </Grid>
                            }
                            {getFromCommissionActive && getFromSelectedCurrency &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldSimple
                                        title={`Fix Commission ${getFromCommissionSelectedCurrency?.currency.symbol ? `(${getFromCommissionSelectedCurrency?.currency.symbol})` : ""}`}
                                        textValue={getFromCommissionFixPrice}
                                        type="number"
                                        onChangeValue={function (newText: string): void {
                                            setFromCommissionFixPrice(newText)
                                        }}
                                    />
                                </Grid>
                            }
                            {getFromCommissionActive && getFromSelectedCurrency &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldSimple
                                        title={`Percent Commission ${getFromCommissionSelectedCurrency?.currency.symbol ? `(${getFromCommissionSelectedCurrency?.currency.symbol})` : ""}`}
                                        textValue={getFromCommissionPercentPrice}
                                        type="number"
                                        onChangeValue={function (newText: string): void {
                                            setFromCommissionPercentPrice(newText)
                                        }}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </div>
                </div>
                {/* start section to */}
                <div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'start',
                    width: '100%',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                    >
                        <h6 style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'start', justifyContent: 'start',
                            color: Colors.theme.blue.Secondary,
                            fontSize: "1.3rem",
                            marginTop: "0.5rem",
                            lineHeight: "1.77rem",
                            fontFamily: confComps.font.poppins.Medium,
                        }}>
                            {"To"}
                        </h6>
                    </div>
                    <div style={{
                        width: '100%',
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'flex-start',
                    }}>
                        <Grid container
                            justifyContent="space-between" alignItems="flex-start"
                            columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                            rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                                <SelectTrustyOrCustomer
                                    title={confComps.translate.customer}
                                    defaultUser={getToCustomerSelected}
                                    onChangeSelectedUser={setToCustomerSelected}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectCurrencyWithTypeAndLocation
                                    title={confComps.translate.fromCurrencyType}
                                    onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                        setToSelectedCurrency(selectCountry)
                                    }}
                                    style={{
                                        minWidth: "380px",
                                        alignItems: "stretch",
                                    }}
                                    defaultCurrency={getToSelectedCurrency}
                                />
                            </Grid>
                            {getFromSelectedCurrency && getToSelectedCurrency &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ChangeRateWithAmount
                                        callbackOnChangeRate={(rate: string) => {
                                            setToChangeRate(rate);
                                        }}
                                        defaultRate={getToChangeRate}
                                        defaultFromCurrency={getFromSelectedCurrency}
                                        defaultToCurrency={getToSelectedCurrency}
                                        title="Change Rate"
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectYesOrNo
                                    title={"Is Get Commission?"}
                                    onChangeSelectYesOrNo={(isYes?: boolean) => {
                                        setToCommissionActive(isYes ?? false);
                                    }}
                                />
                            </Grid>
                            {getToCommissionActive && getToSelectedCurrency &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                    <SelectCurrencyWithTypeAndLocation
                                        title={"Commission unit"}
                                        onChangeSelectedCurrency={(selectCountry?: InterfaceNTTCurrencyWithTypeAndLocation) => {
                                            setToCommissionSelectedCurrency(selectCountry)
                                        }}
                                        style={{
                                            minWidth: "380px",
                                            alignItems: "stretch",
                                        }}
                                        defaultCurrency={getToCommissionSelectedCurrency}
                                    />

                                </Grid>
                            }
                            {getToCommissionActive && getToSelectedCurrency && getToCommissionSelectedCurrency &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <ChangeRateWithAmount
                                        callbackOnChangeRate={(rate: string) => {
                                            setToCommissionChangeRate(rate);
                                        }}
                                        defaultRate={getToCommissionChangeRate}
                                        defaultFromCurrency={getToSelectedCurrency}
                                        defaultToCurrency={getToCommissionSelectedCurrency}
                                        title="Commission Change Rate"
                                    />
                                </Grid>
                            }
                            {getToCommissionActive && getToSelectedCurrency &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldSimple
                                        title={`Fix Commission ${getToCommissionSelectedCurrency?.currency.symbol ? `(${getToCommissionSelectedCurrency?.currency.symbol})` : ""}`}
                                        textValue={getToCommissionFixPrice}
                                        type="number"
                                        onChangeValue={(newText: string) => {
                                            setToCommissionFixPrice(newText)
                                        }}
                                    />
                                </Grid>
                            }
                            {getToCommissionActive && getToSelectedCurrency &&
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <TextFieldSimple
                                        title={`Percent Commission ${getToCommissionSelectedCurrency?.currency.symbol ? `(${getToCommissionSelectedCurrency?.currency.symbol})` : ""}`}
                                        textValue={getToCommissionPercentPrice}
                                        type="number"
                                        onChangeValue={(newText: string) => {
                                            setToCommissionPercentPrice(newText)
                                        }}
                                    />
                                </Grid>
                            }
                        </Grid>

                    </div>
                </div >
                {/* other section */}
                < div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'start',
                    width: '100%',
                }
                }>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                    }}
                    >
                        <h6 style={{
                            display: 'flex', flexDirection: 'row',
                            alignItems: 'start', justifyContent: 'start',
                            color: Colors.theme.blue.Secondary,
                            fontSize: "1.3rem",
                            marginTop: "0.5rem",
                            lineHeight: "1.77rem",
                            fontFamily: confComps.font.poppins.Medium,
                        }}>
                            {"Other Details"}
                        </h6>
                    </div>
                    <Box sx={{
                        width: '100%',
                        display: 'flex', flexDirection: 'column',
                        alignItems: 'start',
                        justifyContent: 'flex-start',
                    }}>
                        <Grid container
                            justifyContent="space-between" alignItems="flex-start"
                            columnSpacing={{ xs: 0, sm: 0, md: "6.3rem", lg: "7.1rem", xl: "7.8rem" }}
                            rowSpacing={{ xs: "0.86rem", sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.30rem" }}
                            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectTypeOfFreestyleStep
                                    callbackOnChangeTypeOfFreestyleStep={(selected?: InterfaceNTTTypeOfFreestyleStep) => {
                                        setStepType(selected);
                                    }}
                                    defaultSelected={getStepType}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectPriority
                                    callbackOnChangeTypeOfFreestyleStep={(selected?: number) => {
                                        setPriorityStep(selected);
                                    }}
                                    defaultSelected={getPriorityStep}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box sx={{
                                    boxSizing: 'border-box',
                                    display: 'flex', flexDirection: 'column',
                                    alignItems: 'start',
                                    width: '100%',
                                }}>

                                    <label style={{
                                        fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                                        lineHeight: "1.68rem",
                                        color: Colors.theme.blue.light,
                                        marginBottom: '0.22rem',
                                    }}>
                                        {"Add Documentation Title:"}
                                    </label>


                                    <div style={{
                                        display: 'flex', flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        border: `1px solid ${Colors.theme.gray.gray}`,
                                        background: Colors.theme.white.white90,
                                        borderRadius: '0.37rem',
                                        paddingBlock: '0.37rem',
                                        boxSizing: 'border-box',
                                        width: '100%',
                                    }}>
                                        {getDocumentTitles.flatMap((doc, index, arr) => {
                                            return [
                                                <div style={{
                                                    width: '100%',
                                                    display: 'flex', flexDirection: 'row',
                                                    flexWrap: 'nowrap',
                                                    alignItems: 'center',
                                                    justifyContent: 'start',
                                                    boxSizing: 'border-box',
                                                    paddingInline: "0.88rem",
                                                    marginBlockEnd: '0.4rem',
                                                }}>
                                                    <p style={{
                                                        display: 'inline-flex', flexDirection: 'row',
                                                        alignItems: 'start',
                                                        justifyContent: 'start',
                                                        flexGrow: 0,
                                                        flexBasis: '2rem',
                                                        fontSize: '1.03rem',
                                                        lineHeight: '1.55rem',
                                                        flexWrap: 'nowrap',
                                                    }}>
                                                        {`${index + 1} :`}
                                                    </p>
                                                    <textarea
                                                        // onFocus={() => setIsFocused(true)}
                                                        // onBlur={() => setIsFocused(false)}
                                                        value={doc}
                                                        rows={1}
                                                        placeholder={'write new solution'}
                                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                                            // Create a new array of solutions with the updated text
                                                            const newValue = e.target.value;
                                                            const updatedSolutions = getDocumentTitles.map((item, i) => {
                                                                if (i === index) {
                                                                    return newValue;
                                                                }
                                                                return item;
                                                            });
                                                            setDocumentTitles(updatedSolutions);

                                                            // Adjust the height of the textarea
                                                            const textAreaElement = e.target;
                                                            textAreaElement.style.height = 'auto'; // Reset height to auto for recalculating
                                                            textAreaElement.style.height = `${textAreaElement.scrollHeight}px`; // Set new height

                                                            // Update the state for the height
                                                            // setTextAreasHeight((prevHeights) => {
                                                            //     const newHeights = [...prevHeights];
                                                            //     newHeights[index] = `${textAreaElement.scrollHeight}px`;
                                                            //     return newHeights;
                                                            // });
                                                        }}
                                                        style={{
                                                            flexGrow: 1,
                                                            flexBasis: 0,
                                                            resize: 'none', // Prevent manual resizing by user
                                                            boxSizing: "border-box",
                                                            width: "100%",
                                                            alignItems: 'center',
                                                            color: Colors.theme.blue.dark,
                                                            textDecoration: "none",
                                                            fontFamily: confComps.font.poppins.Regular,
                                                            fontSize: "0.96rem",
                                                            outline: "none",
                                                            border: "none",
                                                            // height: textAreasHeight[index], // Dynamically set height
                                                        }}
                                                    />
                                                    <div style={{
                                                        display: 'flex', flexDirection: 'row',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        cursor: 'pointer',
                                                    }}
                                                        onClick={() => {
                                                            const updatedSolutions = getDocumentTitles.filter((_, i) => i !== index);
                                                            setDocumentTitles(updatedSolutions);

                                                            // Update the height state
                                                            // setTextAreasHeight((prevHeights) => {
                                                            //     return prevHeights.filter((_, i) => i !== index);
                                                            // });
                                                        }}>
                                                        <TrashIcon style={{ marginInlineStart: '0.58rem' }} />
                                                    </div>
                                                </div>,
                                                <div style={{
                                                    width: '100%',
                                                    height: '1px',
                                                    borderRadius: '10px',
                                                    background: '#E2E4EC',
                                                }} />
                                            ]
                                        })}
                                        <div style={{//add button design
                                            display: 'flex', flexDirection: 'row', alignItems: 'center',
                                            justifyContent: 'start',
                                            width: '100%',
                                            paddingInline: '0.88rem',
                                            boxSizing: 'border-box',

                                        }}>
                                            <div style={{
                                                display: 'inline-flex', flexDirection: 'row',
                                                alignItems: 'center', justifyContent: 'start',
                                                fontFamily: confComps.font.poppins.Medium,
                                                fontSize: '0.88rem',
                                                lineHeight: '1.33rem',
                                                color: Colors.theme.orange.light,
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                            }}
                                                onClick={addNewEmptyDocumentationTitle}
                                            >
                                                <AddRounded style={{
                                                    color: Colors.theme.orange.light, fontSize: '0.88rem',
                                                    userSelect: 'none',
                                                }} />

                                                {confComps.translate.addSolution}


                                            </div>

                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldMultiLine
                                    title={confComps.translate.explanation}
                                    text={getDescriptionValue}
                                    onChangeText={setDescriptionValue}
                                    styleParent={{
                                        boxSizing: 'border-box',
                                        border: `1px solid ${Colors.theme.blue.border}`,
                                        background: Colors.theme.white.white90,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <div style={{
                                    display: 'flex', flexDirection: "row",
                                    bottom: "1vh",
                                    alignItems: "center",
                                    justifyContent: "end", width: "100%",
                                }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "end", alignItems: "center", gap: "1rem" }}>
                                        <ButtonColor text={confComps.translate.cancel} iconStart={<CancelRounded />}
                                            color={ColorThemForButton.orangeColorAndTransparentBackground}
                                            onclick={() => paramsComponent.callbackOnClickCancel()}
                                        />
                                        <ButtonColor text={confComps.translate.submit} iconStart={<DoneRounded />} color={ColorThemForButton.greenColorAndTransparentBackground}
                                            onclick={() => {
                                                callAPIForCreateThisStepInServer();
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                    </Box>
                </div >
            </Box >

        </ParentWithHeightAnimation >
    )
}
export default CreateOneStepOfFreestyle;