import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const ArrowDown2Line: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.64707 3.79407C3.84238 3.9845 4.15957 3.9845 4.35488 3.79407L7.35488 0.869072C7.5502 0.678643 7.5502 0.369385 7.35488 0.178955C7.15957 -0.0114746 6.84238 -0.0114746 6.64707 0.178955L4.0002 2.75966L1.35332 0.180478C1.15801 -0.00995131 0.84082 -0.00995131 0.645508 0.180478C0.450195 0.370908 0.450195 0.680166 0.645508 0.870596L3.64551 3.7956L3.64707 3.79407Z"
                fill={style?.color ?? "#2D3250"} />
        </svg>
    );
}
