import { IconButton, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { DialogFromMUI } from "../../../MUIConfig/DialogFromMUI";
import { interfaceDialogShowImage } from "./interfaceDialogShowImage";

export const DialogShowImage = (paramsDialog: interfaceDialogShowImage) => {
    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getOpen, setOpen] = useState<boolean>(false);
    useEffect(() => {
        setOpen(paramsDialog.isOpen)
    }, [paramsDialog])
    const handleClose = async () => {
        // setOpen(false);
        paramsDialog.callbackOnCloseDialog();
    };


    const handleOnclickCancelDialog = () => {
        paramsDialog.callbackOnCloseDialog();
    }



    return (
        <DialogFromMUI onClose={handleClose} open={getOpen} dir={mTheme.direction}>
            <div style={{
                display: "flex", flexDirection: "column",
                justifyContent: "space-between", alignItems: "start",
            }}>
                {paramsDialog.appendData?.title ?
                    <h3 style={{
                        margin: "10px calc(16px + 2vw) 10px calc(16px + 2vw)",
                        color: "black",
                        alignSelf: "center",
                        padding: "0 0px",
                    }}
                    >
                        {"عنوان: "}{paramsDialog.appendData.title}
                    </h3>
                    : undefined
                }
                <img src={paramsDialog.imgUrl} onClick={handleClose}
                    style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                    }}
                />

                {paramsDialog.appendData?.description ?
                    <p
                        style={{
                            margin: "10px calc(8px + 1vw)",
                            color: "black",
                            padding: "0 0px"
                        }}>
                        {"توضیحات: "}{paramsDialog.appendData.description}
                    </p>
                    : undefined
                }
            </div>

        </DialogFromMUI>
    );
}
