import { useDispatch } from "react-redux";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { InterfaceSelectedSolutionsPreview } from "./InterfaceSelectedSolutionsPreview";
import { useNavigate } from "react-router-dom";
import { Box, Grid, styled, useTheme } from "@mui/material";
import { ApartmentRounded, CorporateFareRounded, KeyboardArrowDownRounded, Person2Rounded } from "@mui/icons-material";
import { useState } from "react";
import ParentWithHeightAnimation from "../../../basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import { InterfaceNTTProfile } from "../../../../NTTs/InterfaceNTTProfile";
import { InterfaceNTTProfileForeignLegal } from "../../../../NTTs/InterfaceNTTProfileForeignLegal";
import { InterfaceNTTProfileIranLegal } from "../../../../NTTs/InterfaceNTTProfileIranLegal";
import { EnumProfileTypeNameInServer } from "../../../../NTTs/InterfaceNTTProfileType";
import { ImageSquare } from "../../imageSquare/ImageSquare";
import { baseUrl } from "../../../../api/UrlsList";
import ShowDescriptionWithUsers from "../../ShowDescriptionWithUsers/ShowDescriptionWithUsers";
import TextFieldMultiLine from "../../../basic/TextField/TextFieldMultiLine/TextFieldMultiLine";
import dayjs from "dayjs";
const Text = styled("p")(({ theme }) => ({
    lineHeight: "1.55rem",
    fontSize: "1.03rem",
    userSelect: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    color: Colors.theme.blue.light,
    textAlign: "center",
    boxSizing: "border-box",
}));
function SelectedSolutionsPreview(paramsComponent: InterfaceSelectedSolutionsPreview) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const [getIsBoxShow, setIsBoxShow] = useState<boolean>(true);
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
            justifyContent: 'start',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '0.88rem',
            }}
                onClick={() => {
                    setIsBoxShow(prev => !prev);
                }}
            >
                <h6 style={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'start', justifyContent: 'start',
                    color: Colors.theme.blue.dark,
                    fontSize: "1.18rem",
                    lineHeight: "1.77rem",
                    fontFamily: confComps.font.poppins.Medium,
                }}>
                    {paramsComponent.title ?? "Recaps:"}{` (${paramsComponent.recaps.length})`}
                </h6>

                <KeyboardArrowDownRounded
                    style={{
                        fontSize: "1.9rem",

                        color: Colors.theme.blue.dark,
                        cursor: "pointer",
                        transform: getIsBoxShow ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "all 0.5s ease-in-out",
                    }}
                />
            </div>
            <ParentWithHeightAnimation in={getIsBoxShow} style={{ width: '100%' }}>
                <div style={{
                    width: '100%',
                    paddingInline: '1.18rem',
                    border: `0.5px solid ${Colors.theme.blue.borderWhite}`,
                    boxSizing: 'border-box',
                    boxShadow: Colors.shadow.cardList,
                    paddingBlock: "1.18rem",
                    borderRadius: '0.38rem',
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'flex-start',

                }}>
                    {paramsComponent.recaps.flatMap(recap => [
                        ...recap.receipts.flatMap(service => service.solution_choose_result.flatMap(
                            solution => {
                                return {
                                    ...solution,
                                    managerResponse: service.manager_approval_result,
                                    serviceName: "Money Receive",
                                }
                            }
                        )),
                        ...recap.payments.flatMap(service => service.solution_choose_result.flatMap(
                            solution => {
                                return {
                                    ...solution,
                                    managerResponse: service.manager_approval_result,
                                    serviceName: "Money Payment",
                                }
                            }
                        )),
                        ...recap.buy_cottages.flatMap(service => service.solution_choose_result.flatMap(
                            solution => {
                                return {
                                    ...solution,
                                    managerResponse: service.manager_approval_result,
                                    serviceName: "Buy Cottage",
                                }
                            }
                        )),
                        ...recap.sell_cottages.flatMap(service => service.solution_choose_result.flatMap(
                            solution => {
                                return {
                                    ...solution,
                                    managerResponse: service.manager_approval_result,
                                    serviceName: "Sell Cottage",
                                }
                            }
                        )),
                    ]).map(solution => {
                        return (
                            <div style={{
                                display: 'flex', flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'start',
                                width: '100%',
                            }}>
                                <p style={{
                                    fontSize: '1.03rem', color: Colors.theme.blue.light,
                                    fontFamily: confComps.font.poppins.Medium,
                                    marginBottom: '0.59rem',
                                }}>
                                    {`${solution.serviceName} (${dayjs.unix(solution.chosen_solution.created_at).format("YYYY/MM/DD")})`}
                                </p>

                                <ShowDescriptionWithUsers
                                    description_list={solution.chosen_solution.description_list}
                                    user_orders={solution.chosen_solution.user_orders}
                                    style={{
                                        paddingInlineStart: '0.3rem',
                                    }}
                                />
                                {solution.managerResponse.length > 0 && <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        justifyContent: 'start',
                                        borderRadius: '0.74rem',
                                        width: '100%',
                                        border: `1px solid ${Colors.theme.green.dark}`,
                                        boxSizing: 'border-box',
                                        paddingBlock: '0.29rem',
                                        paddingInline: '0.88rem',
                                        boxShadow: Colors.shadow.greenCard,
                                        marginTop: '0.88rem',
                                        gap: '0.2rem',
                                    }}
                                >
                                    <p style={{
                                        display: 'flex', flexDirection: 'row', alignItems: 'start',
                                        justifyContent: 'start',
                                        color: Colors.theme.blue.dark,
                                        fontFamily: confComps.font.poppins.Regular,
                                        fontSize: '1.03rem',
                                        lineHeight: '1.77rem',
                                    }}>
                                        {solution.managerResponse[0].description}
                                    </p>
                                    <div
                                        style={{
                                            display: 'flex', flexDirection: 'row',
                                            alignItems: 'center', justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <p style={{
                                            display: "inline-flex", alignItems: 'center', justifyContent: 'end',
                                            color: Colors.theme.green.dark,
                                            fontSize: '1.037rem',
                                            lineHeight: '1.55rem',
                                        }}>
                                            {`Manager (${solution.managerResponse[0].submitted_by.profile.last_name})`}
                                        </p>
                                        <p style={{
                                            display: "inline-flex", alignItems: 'center', justifyContent: 'end',
                                            color: Colors.theme.green.dark,
                                            fontSize: '1.037rem',
                                            lineHeight: '1.55rem',
                                        }}>
                                            {`${dayjs.unix(solution.managerResponse[0].created_at).format("YYYY/MM/DD")}`}
                                        </p>

                                    </div>


                                </div>}
                            </div>
                        )
                    })}
                </div>
            </ParentWithHeightAnimation>

        </div>
    )
};
export default SelectedSolutionsPreview;