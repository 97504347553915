import { EnumSTepsParent, EnumSTepsRecap, EnumSTepsSolutionRecap } from "../../../../config/enums/EnumSteps";
import { EnumRecapServiceNameInServer } from "../InterfaceNTTWorkFlowMainRecap";

export interface InterfaceNTTOneStepOfWorkflowSellCottageRecap {
    id: number;
    recap: number;
    recap_service_ct?: EnumRecapServiceNameInServer;
    recap_service_id?: number;
    previous_review_ct: EnumRecapServiceStepNameInServerForSellCottage | null;
    previous_review_id: number | null;
    review_ct: EnumRecapServiceStepNameInServerForSellCottage;
    review_id: number;
    created_at: number;

}
export enum EnumRecapServiceStepNameInServerForSellCottage {
    undefined = "undefined",
    fundControlCheckWalletCustomer = "recapservicefundcontrol",
    checkRisk = "recapservicecompliance",
    addSolution = "recapservicesolutionmeta",
    checkRiskOfSolution = "recapservicesolutionriskmeta",
    checkWalletOfTrustByFinanceForSolution = "recapservicesolutionfinancecheckmeta",
    checkSolutionByTrusties = "recapservicesolutiontradercheckmeta",
    chooseOneSolution = "recapservicesolutionchoose",
    approveSolution = "recapserviceapproval",
}

export const StepNameMappingToKeyStepForSellCottageRecap: { [key in EnumRecapServiceStepNameInServerForSellCottage]: string } = {
    [EnumRecapServiceStepNameInServerForSellCottage.undefined]: `0`,
    [EnumRecapServiceStepNameInServerForSellCottage.fundControlCheckWalletCustomer]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.checkWalletOfTheRequesterByFundControl}`,
    [EnumRecapServiceStepNameInServerForSellCottage.checkRisk]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.complianceRiskMeasurement}`,
    [EnumRecapServiceStepNameInServerForSellCottage.addSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.addSolution}`,
    [EnumRecapServiceStepNameInServerForSellCottage.checkRiskOfSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}`,
    [EnumRecapServiceStepNameInServerForSellCottage.checkWalletOfTrustByFinanceForSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkWalletOfTrustyForSolution}`,
    [EnumRecapServiceStepNameInServerForSellCottage.checkSolutionByTrusties]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionByTrusty}`,
    [EnumRecapServiceStepNameInServerForSellCottage.chooseOneSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.chooseSolution}`,
    [EnumRecapServiceStepNameInServerForSellCottage.approveSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.acceptByManager}`,
};