import { useTheme } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditPenIcon } from '../../../../../../../assets/icons/svg/EditPenIcon';
import DatePickerMUIWithUnderLine from '../../../../../../../components/basic/Dates/DatePickerMUIWithUnderLine/DatePickerMUIWithUnderLine';
import TextFieldWithIconBottomLine from '../../../../../../../components/basic/TextField/TextFieldWithIconBottomLine/TextFieldWithIconBottomLine';
import Colors from '../../../../../../../config/Colors';
import { ConfigComps } from '../../../../../../../config/ConfigCOMP';
import { SelectedProfile } from '../../../../../../../store/profileStateSlice';
import { InterfaceCreateSubProfileForeignLegal, InterfaceSubProfileInformationForeignLegalUpdate } from './InterfaceCreateSubProfileForeignLegal';

function CreateSubProfileForeignLegal(paramsPage: InterfaceCreateSubProfileForeignLegal) {

    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getAllValues, setAllValues] = useState<InterfaceSubProfileInformationForeignLegalUpdate>({});
    useEffect(() => {
        setAllValues({
            name: paramsPage.defaultValue?.profile.name,
            company_code: paramsPage.defaultValue?.profile.company_code,
            authenticity_date: paramsPage.defaultValue?.profile.authenticity_date,
            country: paramsPage.defaultValue?.profile.country,
            city: paramsPage.defaultValue?.profile.city,
            landline: paramsPage.defaultValue?.profile.landline,
        });
    }, [paramsPage.defaultValue])
    useEffect(() => {
        if (Object.keys(getAllValues).length !== 0)
            paramsPage.callbackUpdateValues(getAllValues);
    }, [getAllValues])

    return (

        <div style={{ width: '100%' }}>


            <h5 style={{
                fontFamily: confComps.font.poppins.Medium,
                fontSize: '1.28rem',
                lineHeight: '1.92rem',
                color: Colors.theme.blue.dark,
                fontWeight: '100',
                marginBlock: '0px',
                marginBottom: '2.4rem',
            }}>
                {"Foreign Legal Information"}
            </h5>
            <Box sx={{
                marginInlineStart: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 2 },
                marginInlineEnd: { xs: 0, sm: 16, md: 24, lg: 32, xl: 40 },
                boxSizing: 'border-box',
            }}>
                <Grid container
                    justifyContent='space-between' alignItems='flex-start'
                    columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 22, xl: 30 }}
                    rowSpacing={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 6 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                >



                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={"Company Name"}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        name: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.name}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={"Company Code"}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        company_code: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.company_code}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <DatePickerMUIWithUnderLine
                            title={"Authenticity Date"}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        authenticity_date: value,
                                    }

                                })
                            }}
                            defaultTimeInSecond={getAllValues.authenticity_date}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.country}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        country: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.country}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.city}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        city: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.city}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>



                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.landline}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        landline: value,
                                    }

                                })
                            }}
                            type='tel'
                            defaultValue={getAllValues.landline}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>
                </Grid>
            </Box>

        </div>
    );
};
export default CreateSubProfileForeignLegal;