import { useTheme } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { EditPenIcon } from '../../../../../../../assets/icons/svg/EditPenIcon';
import DatePickerMUIWithUnderLine from '../../../../../../../components/basic/Dates/DatePickerMUIWithUnderLine/DatePickerMUIWithUnderLine';
import TextFieldWithIconBottomLine from '../../../../../../../components/basic/TextField/TextFieldWithIconBottomLine/TextFieldWithIconBottomLine';
import Colors from '../../../../../../../config/Colors';
import { ConfigComps } from '../../../../../../../config/ConfigCOMP';
import { SelectedProfile } from '../../../../../../../store/profileStateSlice';
import { InterfaceCreateSubProfileIndividual, InterfaceSubProfileInformationIndividualUpdate } from './InterfaceCreateSubProfileIndividual';
import { EnumTypeInput } from '../../../../../../../components/basic/TextField/TextFieldWithIconBottomLine/InterfaceTextFieldWithIconBottomLine';

function CreateSubProfileIndividual(paramsPage: InterfaceCreateSubProfileIndividual) {

    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);
    const [getAllValues, setAllValues] = useState<InterfaceSubProfileInformationIndividualUpdate>({});
    useEffect(() => {
        setAllValues({
            first_name: paramsPage.defaultValue?.profile.first_name,
            first_name_fa: paramsPage.defaultValue?.profile.first_name_fa,
            last_name: paramsPage.defaultValue?.profile.last_name,
            last_name_fa: paramsPage.defaultValue?.profile.last_name_fa,
            phone: paramsPage.defaultValue?.profile.phone,
            national_id: paramsPage.defaultValue?.profile.national_id,
            landline: paramsPage.defaultValue?.profile.landline,
            dob: paramsPage.defaultValue?.profile.dob,
            address: paramsPage.defaultValue?.profile.address,
            bio: paramsPage.defaultValue?.profile.bio,
        });
    }, [paramsPage.defaultValue])
    useEffect(() => {
        if (Object.keys(getAllValues).length !== 0)
            paramsPage.callbackUpdateValues(getAllValues);
    }, [getAllValues])

    return (

        <div style={{ width: '100%' }}>


            <h5 style={{
                fontFamily: confComps.font.poppins.Medium,
                fontSize: '1.28rem',
                lineHeight: '1.92rem',
                color: Colors.theme.blue.dark,
                fontWeight: '100',
                marginBlock: '0px',
                marginBottom: '2.4rem',
            }}>
                {"Individual Information"}
            </h5>
            <Box sx={{
                marginInlineStart: { xs: 1, sm: 1, md: 1, lg: 1.5, xl: 2 },
                marginInlineEnd: { xs: 0, sm: 16, md: 24, lg: 32, xl: 40 },
                boxSizing: 'border-box',
            }}>
                <Grid container
                    justifyContent='space-between' alignItems='flex-start'
                    columnSpacing={{ xs: 0, sm: 6, md: 14, lg: 22, xl: 30 }}
                    rowSpacing={{ xs: 2, sm: 3, md: 4, lg: 6, xl: 6 }}
                    columns={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}
                >



                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.firstName}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        first_name: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.first_name}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.firstNameFa}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        first_name_fa: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.first_name_fa}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.lastName}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        last_name: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.last_name}
                            isDataMustLTR={true}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />

                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.lastNameFa}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        last_name_fa: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.last_name_fa}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>

                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.phone}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        phone: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.phone}
                            type='tel'
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>



                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.nationalID}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        national_id: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.national_id}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                            customType={EnumTypeInput.nationalID}
                            maxCountCharacter={10}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>

                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.landline}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        landline: value,
                                    }

                                })
                            }}
                            type='tel'
                            defaultValue={getAllValues.landline}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />

                    </Grid>



                    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
                        <DatePickerMUIWithUnderLine
                            title={confComps.translate.authenticityDate}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        dob: value,
                                    }

                                })
                            }}
                            defaultTimeInSecond={getAllValues.dob}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.translate.address}
                            isDataMustLTR={false}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        address: value,
                                    }

                                })
                            }}
                            defaultValue={getAllValues.address}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <TextFieldWithIconBottomLine
                            hintText={confComps.panel.people.customerInfo.pageInfo.subInfo.customerBasicInformation.bio}
                            onChangeValue={(value) => {
                                setAllValues(prev => {
                                    return {
                                        ...prev,
                                        bio: value,
                                    }
                                })
                            }}
                            defaultValue={getAllValues.bio}
                            isDataMustLTR={false}
                            isSmallHintShow={true}
                            iconEnd={EditPenIcon}
                        />

                    </Grid>

                </Grid>
            </Box>

        </div>
    );
};
export default CreateSubProfileIndividual;