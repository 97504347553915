import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const HomeIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="25" height="29" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.4836 10.2557C23.4836 10.9782 22.8848 11.5442 22.2062 11.5442H20.9289L20.9569 17.9746C20.9569 18.0829 20.9489 18.1913 20.9369 18.2997V18.9459C20.9369 19.833 20.2224 20.5515 19.3403 20.5515H18.7016C18.6577 20.5515 18.6138 20.5515 18.5699 20.5475C18.514 20.5515 18.4581 20.5515 18.4023 20.5515H17.105H16.147C15.2649 20.5515 14.5504 19.833 14.5504 18.9459V17.9826V15.4136C14.5504 14.7032 13.9796 14.1292 13.2731 14.1292H10.7185C10.012 14.1292 9.44115 14.7032 9.44115 15.4136V17.9826V18.9459C9.44115 19.833 8.72666 20.5515 7.84452 20.5515H6.88654H5.61322C5.55335 20.5515 5.49347 20.5475 5.4336 20.5435C5.3857 20.5475 5.3378 20.5515 5.2899 20.5515H4.65125C3.76911 20.5515 3.05462 19.833 3.05462 18.9459V14.4503C3.05462 14.4142 3.05462 14.374 3.05861 14.3379V11.5442H1.77731C1.05882 11.5442 0.5 10.9822 0.5 10.2557C0.5 9.89444 0.619748 9.57332 0.899159 9.29234L11.1336 0.321118C11.413 0.0401397 11.7323 0 12.0117 0C12.2911 0 12.6105 0.0802794 12.85 0.280978L23.0445 9.29234C23.3638 9.57332 23.5235 9.89444 23.4836 10.2557Z"
                fill={style?.background ? `${style?.background}` : undefined} />
        </svg>
    )
};
