import { InterfaceIconSVG } from "../InterfaceIconSVG"


export const Small8RightIcon: React.FC<InterfaceIconSVG> = ({ style = { width: "5", height: "13" } }) => {
    return (
        <svg width={style.width} height={style.height} viewBox="0 0 5 13" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{
                ...style,
            }}>
            <path d="M4.61191 6.1291C4.72816 6.29863 4.72816 6.57754 4.61191 6.74707L1.01191 11.9971C0.895664 12.1666 0.704414 12.1666 0.588164 11.9971C0.471914 11.8275 0.471914 11.5486 0.588164 11.3791L3.97629 6.43809L0.588164 1.49707C0.471914 1.32754 0.471914 1.04863 0.588164 0.879102C0.704414 0.70957 0.895664 0.70957 1.01191 0.879102L4.61191 6.1291Z"
                fill={style?.color ? style?.color : "#59618C"}
            />
        </svg>
    )
}
