import { styled, useTheme } from "@mui/material/styles";
import Colors from "../../../../../config/Colors";
export const MoneyBox = styled("div")(({ theme }) => ({
    border: `1px solid ${Colors.theme.blue.light}`,
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr 1fr",
    borderRadius: "1.2rem",
    paddingInline: "min(1vw , 24px)",
    paddingBlock: "min(1vh , 12px)",
    gridColumn: "1 / 2",
    gridRow: "1 / 3",
}));
export const ItemOfMoenyBox = styled("div")(({ theme }) => ({

}));
export const ItemOfReceiptItem = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    gap: "4px"
}));