
import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const DownloadIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 9H10H1ZM5.5 1V7V1ZM5.5 7L8.125 5.25L5.5 7ZM5.5 7L2.875 5.25L5.5 7Z" fill="#41B06E" />
            <path d="M1 9H10M5.5 1V7M5.5 7L8.125 5.25M5.5 7L2.875 5.25" stroke="#41B06E" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
};
