import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const PinLocationIcon: React.FC<InterfaceIconSVG> = ({ style = { width: "1rem", height: "1rem" } }) => {//{ width: "8", height: "11" } }) => {

    return (
        <svg width={style.width} height={style.height} viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{ ...style }}>
            <path d="M7 4.54134C7 2.9541 5.65625 1.66634 4 1.66634C2.34375 1.66634 1 2.9541 1 4.54134C1 4.78891 1.09375 5.17224 1.31875 5.68336C1.5375 6.17849 1.84792 6.72554 2.20417 7.27858C2.79792 8.20098 3.48542 9.09143 4 9.72632C4.51667 9.09143 5.20417 8.20098 5.79583 7.27858C6.15208 6.72554 6.4625 6.17849 6.68125 5.68336C6.90625 5.17224 7 4.78891 7 4.54134ZM8 4.54134C8 6.28631 5.5625 9.3929 4.49375 10.6747C4.2375 10.9801 3.7625 10.9801 3.50625 10.6747C2.4375 9.3929 0 6.28631 0 4.54134C0 2.42502 1.79167 0.708008 4 0.708008C6.20833 0.708008 8 2.42502 8 4.54134ZM4.66667 4.54134C4.66667 4.3719 4.59643 4.20939 4.4714 4.08958C4.34638 3.96976 4.17681 3.90245 4 3.90245C3.82319 3.90245 3.65362 3.96976 3.5286 4.08958C3.40357 4.20939 3.33333 4.3719 3.33333 4.54134C3.33333 4.71079 3.40357 4.87329 3.5286 4.9931C3.65362 5.11292 3.82319 5.18023 4 5.18023C4.17681 5.18023 4.34638 5.11292 4.4714 4.9931C4.59643 4.87329 4.66667 4.71079 4.66667 4.54134ZM2.33333 4.54134C2.33333 4.11773 2.50893 3.71147 2.82149 3.41193C3.13405 3.1124 3.55797 2.94412 4 2.94412C4.44203 2.94412 4.86595 3.1124 5.17851 3.41193C5.49107 3.71147 5.66667 4.11773 5.66667 4.54134C5.66667 4.96495 5.49107 5.37121 5.17851 5.67075C4.86595 5.97029 4.44203 6.13856 4 6.13856C3.55797 6.13856 3.13405 5.97029 2.82149 5.67075C2.50893 5.37121 2.33333 4.96495 2.33333 4.54134Z"
                fill={style?.color ? `${style?.color}` : "#59618C"} />
        </svg>
    )
}
