import { useEffect, useState } from "react";
import Colors from "../../../../../../../../../../config/Colors";
import { InterfaceViewRiskMeasurementSolution } from "./InterfaceViewRiskMeasurementSolution";
import { isVisibilityProgressBar } from "../../../../../../../../../../store/ProgressbarInRootSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../../../../api/APIErrorResponse";
import { HandlerLangUrl, urlsList } from "../../../../../../../../../../api/UrlsList";
import { enumTypeOfAlert } from "../../../../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { SelectedProfile } from "../../../../../../../../../../store/profileStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { ConfigComps } from "../../../../../../../../../../config/ConfigCOMP";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { RequestHandler } from "../../../../../../../../../../api/API";
import { ShowAlert } from "../../../../../../../../../../store/AlertStateModeSlice";
import { InterfaceNTTSolutionRisk } from "../../../../../../../../../../NTTs/Recap/solution/steps/InterfaceNTTSolutionRisk";

function ViewRiskMeasurementSolution(paramsComponent: InterfaceViewRiskMeasurementSolution) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getInfoThisStepOfSolution, setInfoThisStepOfSolution] = useState<InterfaceNTTSolutionRisk>();
    const callApiForGetInfoThisStep = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyPayment.steps.addSolutions.stepSolution.stepRiskMeasurement.getRiskMeasurementSolution;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.infoThisStepOfSolution.review_id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                let t = response as InterfaceNTTSolutionRisk;
                setInfoThisStepOfSolution(t);
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'Your Comment Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        callApiForGetInfoThisStep();
    }, [paramsComponent.infoThisStepOfSolution])
    return (
        <div
            style={{
                display: 'flex', flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'start',
                borderRadius: '0.74rem',
                width: '100%',
                border: (+(getInfoThisStepOfSolution?.risk_percentage ?? "0") > 50) ? `1px solid ${Colors.theme.orange.sharp}` : `1px solid ${Colors.theme.green.dark}`,
                boxSizing: 'border-box',
                paddingBlock: '0.29rem',
                paddingInline: '0.88rem',
                boxShadow: (+(getInfoThisStepOfSolution?.risk_percentage ?? "0") > 50) ? Colors.shadow.orangeCard : Colors.shadow.greenCard,
                gap: '0.2rem',
            }}
        >
            <p style={{
                display: 'flex', flexDirection: 'row', alignItems: 'start',
                justifyContent: 'start',
                color: Colors.theme.blue.dark,
                fontFamily: confComps.font.poppins.Regular,
                fontSize: '1.03rem',
                lineHeight: '1.77rem',
            }}>
                {getInfoThisStepOfSolution?.description}
            </p>
            <div
                style={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center', justifyContent: 'space-between',
                    width: '100%',
                }}
            >
                <p style={{
                    display: "inline-flex", alignItems: 'center', justifyContent: 'end',
                    color: (+(getInfoThisStepOfSolution?.risk_percentage ?? "0") > 50) ? Colors.theme.orange.sharp : Colors.theme.green.dark,
                    fontSize: '1.037rem',
                    lineHeight: '1.55rem',
                }}>
                    Checked Risk Of This Solution
                </p>
                <p style={{
                    display: "inline-flex", alignItems: 'center', justifyContent: 'end',
                    color: (+(getInfoThisStepOfSolution?.risk_percentage ?? "0") > 50) ? Colors.theme.orange.sharp : Colors.theme.green.dark,
                    fontSize: '1.037rem',
                    lineHeight: '1.55rem',
                }}>
                    {`${+(getInfoThisStepOfSolution?.risk_percentage ?? 0)} % Risk`}
                </p>

            </div>


        </div >
    );
}
export default ViewRiskMeasurementSolution;


