import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const RecapIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="25" height="28" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2.5C0 1.12109 1.11516 0 2.48677 0H8.7037V5C8.7037 5.69141 9.25934 6.25 9.94709 6.25H14.9206V7.75781C12.0492 8.57422 9.94709 11.2266 9.94709 14.375C9.94709 16.6836 11.0778 18.7227 12.8108 19.9727C12.6864 19.9922 12.5621 20 12.4339 20H2.48677C1.11516 20 0 18.8789 0 17.5V2.5ZM14.9206 5H9.94709V0L14.9206 5ZM16.7857 8.75C18.2697 8.75 19.6928 9.34263 20.7421 10.3975C21.7915 11.4524 22.381 12.8832 22.381 14.375C22.381 15.8668 21.7915 17.2976 20.7421 18.3525C19.6928 19.4074 18.2697 20 16.7857 20C15.3018 20 13.8786 19.4074 12.8293 18.3525C11.78 17.2976 11.1905 15.8668 11.1905 14.375C11.1905 12.8832 11.78 11.4524 12.8293 10.3975C13.8786 9.34263 15.3018 8.75 16.7857 8.75ZM17.4074 11.875C17.4074 11.5312 17.1276 11.25 16.7857 11.25C16.4438 11.25 16.164 11.5312 16.164 11.875V13.75H14.2989C13.957 13.75 13.6772 14.0312 13.6772 14.375C13.6772 14.7188 13.957 15 14.2989 15H16.164V16.875C16.164 17.2188 16.4438 17.5 16.7857 17.5C17.1276 17.5 17.4074 17.2188 17.4074 16.875V15H19.2725C19.6144 15 19.8942 14.7188 19.8942 14.375C19.8942 14.0312 19.6144 13.75 19.2725 13.75H17.4074V11.875Z"
                fill={style?.background?`${style?.background}`:undefined} />
        </svg>
    )
};
