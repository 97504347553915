import React, { useState, useEffect, useRef } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Colors from '../../../config/Colors';
import { InterfaceAutoCompleteWithUnderLine } from './InterfaceAutoCompleteWithUnderLine';
import { InterfaceItemOfAutoComplete } from '../AutoCompleteSimple/InterfaceAutoCompleteSimple';
import { ConfigComps } from '../../../config/ConfigCOMP';
import { CancelRounded } from '@mui/icons-material';
import { BottomTriangleIcon } from '../../../assets/icons/svg/BottomTriangleIcon';

const AutoCompleteWithUnderLine = (componentsParams: InterfaceAutoCompleteWithUnderLine) => {
  var confComps = ConfigComps();
  const [isFocused, setIsFocused] = useState(false);
  const [selectedValues, setSelectedValues] = useState<InterfaceItemOfAutoComplete[]>(componentsParams.selectedValueTitle ?? []);
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<InterfaceItemOfAutoComplete[]>(componentsParams.valuesList);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // if (componentsParams.selectedValueTitle) {
    //   const newSelectedValues = componentsParams.valuesList.filter((item) =>
    //     componentsParams.selectedValueTitle.includes(item.title)
    //   );
    setSelectedValues(componentsParams.selectedValueTitle);
    // }
  }, [componentsParams.selectedValueTitle, componentsParams.valuesList]);

  useEffect(() => {
    setFilteredOptions(
      componentsParams.valuesList.filter((item) =>
        item.title.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  }, [inputValue, componentsParams.valuesList]);

  const handleFocus = () => {
    setIsFocused(true);
    componentsParams.onFocus && componentsParams.onFocus();
  };

  // const handleBlur = () => {
  //   setIsFocused(false);
  //   componentsParams.onBlur && componentsParams.onBlur();
  // };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleOptionClick = (option: InterfaceItemOfAutoComplete) => {
    console.log("clicked", option);
    if (!selectedValues.some((item) => item.title === option.title)) {
      const newSelectedValues = [...selectedValues, option];
      setSelectedValues(newSelectedValues);
      if (componentsParams.limitSelect) {
        componentsParams.onChangeSelectedItem(newSelectedValues.slice(-componentsParams.limitSelect));
      }
      else {
        componentsParams.onChangeSelectedItem(newSelectedValues);
      }
    }
    else {
      const newSelectedValues = selectedValues.filter(selected => selected.title !== option.title);
      setSelectedValues(newSelectedValues);
      if (componentsParams.limitSelect) {
        componentsParams.onChangeSelectedItem(newSelectedValues.slice(-componentsParams.limitSelect));
      }
      else {
        componentsParams.onChangeSelectedItem(newSelectedValues);
      }
    }
    setInputValue('');
  };
  useEffect(() => {
    if (isFocused) {
      const handleClickOutside = (event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
          setIsFocused(false);
          componentsParams.onBlur && componentsParams.onBlur();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [isFocused]);

  const handleTagRemove = (option: InterfaceItemOfAutoComplete) => {
    const newSelectedValues = selectedValues.filter((item) => item.title !== option.title);
    setSelectedValues(newSelectedValues);
    if (componentsParams.limitSelect) {
      componentsParams.onChangeSelectedItem(newSelectedValues.slice(-componentsParams.limitSelect));
    }
    else {
      componentsParams.onChangeSelectedItem(newSelectedValues);
    }
  };

  return (
    <div style={{
      position: 'relative',
    }}
      ref={containerRef}
    >
      <div style={{
        width: '100%', boxSizing: 'border-box',
        borderRadius: '4px',
        paddingBlock: '1px',
        display: 'flex',
        flexDirection: "row", flexWrap: 'wrap',
        cursor: 'pointer',
        justifyContent: 'space-between',
      }}
        onClick={() => inputRef.current?.focus()}
      >
        <div style={{
          width: 0, flexGrow: 1, flexBasis: 0, display: 'flex', flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'start',
          justifyContent: 'start',
        }}>
          {selectedValues.map((option, index) => (
            <div key={index} style={{
              display: 'flex', alignItems: 'center', height: '24px', margin: '2px', lineHeight: '22px',
              backgroundColor: componentsParams.limitSelect !== 1 ? '#fafafa' : undefined,
              borderRadius: '2px', boxSizing: 'content-box',
              padding: componentsParams.limitSelect !== 1 ? '0 4px 0 10px' : undefined,
              outline: 0, overflow: 'hidden',
              gap: "0.4rem",
              paddingBlock: componentsParams.limitSelect !== 1 ? "0.24rem" : undefined,
              ...componentsParams.styleItems,
            }}
            >
              <span style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {option.title}
              </span>
              {componentsParams.limitSelect !== 1 &&
                <CancelRounded
                  style={{
                    color: Colors.theme.orange.sharp,
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    handleTagRemove(option)
                    event.stopPropagation();
                  }}
                />
              }
            </div>
          ))}
          <input
            ref={inputRef}
            value={inputValue}
            onChange={handleInputChange}
            onFocus={handleFocus}

            style={{
              height: '30px',
              boxSizing: 'border-box',
              padding: '4px 6px',
              width: 0, minWidth: '40px',
              flexGrow: 1, border: 0, margin: 0, outline: 0
            }}
          />
        </div>
        {componentsParams.isShowIconInEnd &&
          <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }}>
            {

              <BottomTriangleIcon
                style={{
                  justifySelf: 'end',
                  transform: isFocused ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.5s ease-in-out",
                  color: isFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                  width: "0.9rem",
                }}
              />

            }
          </div>
        }
      </div>
      {isFocused && filteredOptions.length > 0 && (
        <ul
          style={{
            width: '100%', margin: '2px 0 0', padding: 0,
            top: "20",
            position: 'absolute', listStyle: 'none', backgroundColor: '#fff', overflow: 'auto',
            borderRadius: '4px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', zIndex: 1
          }}
        >
          {filteredOptions.map((option, index) => (
            <li
              onClick={() => handleOptionClick(option)}
              style={{
                padding: '5px 12px',
                display: 'flex',
                cursor: 'pointer',
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: selectedValues.some((item) => item.title === option.title) ? '#fafafa' : '#fff',
                fontFamily: selectedValues.some((item) => item.title === option.title) ? confComps.font.poppins.Medium : confComps.font.poppins.Regular,
                //  fontWeight: selectedValues.some((item) => item.title === option.title) ? 300 : 200,
                fontSize: "0.9rem",
              }}
            >
              <span>{option.title}</span>
              {selectedValues.some((item) => item.title === option.title) && <CheckIcon fontSize="small" />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutoCompleteWithUnderLine;
