import { InterfaceIconSVG } from "../InterfaceIconSVG"

export const LongArrowRight: React.FC<InterfaceIconSVG> = ({ style = { width: "97", height: "6" } }) => {
    return (
        <svg width={style.width} height={style.height} viewBox="0 0 97 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 2.6C0.779086 2.6 0.6 2.77909 0.6 3C0.6 3.22091 0.779086 3.4 1 3.4L1 2.6ZM97 2.99999L93 0.690591L93 5.30939L97 2.99999ZM1 3.4L93.4 3.39999L93.4 2.59999L1 2.6L1 3.4Z"
                fill={style.color ?? "#22357E"}
            />
        </svg>
    )
}
