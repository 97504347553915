import { EnumSTepsParent, EnumSTepsRecap, EnumSTepsSolutionRecap } from "../../../config/enums/EnumSteps";

export interface InterfaceNTTWorkflowSolution {
    id: number;
    previous_review_ct?: EnumRecapServiceStepSolutionNameInServer;
    previous_review_id?: number;
    review_ct: EnumRecapServiceStepSolutionNameInServer;
    review_id: number;
    created_at: number;
}
export enum EnumRecapServiceStepSolutionNameInServer {
    undefined = "undefined",
    checkRiskOfSolution = "recapservicesolutionrisk",
    checkWalletOfTrustyForSolution = "recapservicesolutionfinancecheck",
    checkSolutionByTrusty = "recapservicesolutiontradercheck",

}
export const StepOfSolutionNameMappingToKeyStepForMoneyReceiveRecap: { [key in EnumRecapServiceStepSolutionNameInServer]: string } = {
    [EnumRecapServiceStepSolutionNameInServer.undefined]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.0`,
    [EnumRecapServiceStepSolutionNameInServer.checkRiskOfSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}`,
    [EnumRecapServiceStepSolutionNameInServer.checkWalletOfTrustyForSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkWalletOfTrustyForSolution}`,
    [EnumRecapServiceStepSolutionNameInServer.checkSolutionByTrusty]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionByTrusty}`,
};
export const StepOfSolutionNameMappingToKeyStepForMoneyPaymentRecap: { [key in EnumRecapServiceStepSolutionNameInServer]: string } = {
    [EnumRecapServiceStepSolutionNameInServer.undefined]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.0`,
    [EnumRecapServiceStepSolutionNameInServer.checkRiskOfSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}`,
    [EnumRecapServiceStepSolutionNameInServer.checkWalletOfTrustyForSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkWalletOfTrustyForSolution}`,
    [EnumRecapServiceStepSolutionNameInServer.checkSolutionByTrusty]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionByTrusty}`,
};
export const StepOfSolutionNameMappingToKeyStepForBuyCottageRecap: { [key in EnumRecapServiceStepSolutionNameInServer]: string } = {
    [EnumRecapServiceStepSolutionNameInServer.undefined]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.0`,
    [EnumRecapServiceStepSolutionNameInServer.checkRiskOfSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}`,
    [EnumRecapServiceStepSolutionNameInServer.checkWalletOfTrustyForSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkWalletOfTrustyForSolution}`,
    [EnumRecapServiceStepSolutionNameInServer.checkSolutionByTrusty]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionByTrusty}`,
};
export const StepOfSolutionNameMappingToKeyStepForSellCottageRecap: { [key in EnumRecapServiceStepSolutionNameInServer]: string } = {
    [EnumRecapServiceStepSolutionNameInServer.undefined]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.0`,
    [EnumRecapServiceStepSolutionNameInServer.checkRiskOfSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.solutionRiskMeasurement}`,
    [EnumRecapServiceStepSolutionNameInServer.checkWalletOfTrustyForSolution]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkWalletOfTrustyForSolution}`,
    [EnumRecapServiceStepSolutionNameInServer.checkSolutionByTrusty]: `${EnumSTepsParent.recap}.${EnumSTepsRecap.solutions}.${EnumSTepsSolutionRecap.checkSolutionByTrusty}`,
};