import { useEffect, useState } from "react";
import { InterfaceAPIGetDomesticCompanies, InterfacePageDomesticCompanies } from "./InterfaceDomesticCompany";
import { ConfigComps } from "../../../../../../config/ConfigCOMP";
import CreateDomesticCompany from "./CreateDomesticCompany/CreateDomesticCompany";
import { isVisibilityProgressBar } from "../../../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../api/API";
import { useTheme } from "@mui/material";
import { SelectedProfile } from "../../../../../../store/profileStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { ShowAlert } from "../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../api/APIErrorResponse";
import RowDomesticCompany from "./RowDomesticCompany/RowDomesticCompany";
import ButtonSave from "../../../../../../components/basic/Buttons/ButtonSave/ButtonSave";
import { EnumStateFetchList } from "../../../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfacePaginationInServer } from "../../../../../../api/interfaces/InterfaceSearchInServer";
import ParentWithHeightAnimation from "../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import InfiniteScrollList from "../../../../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import { InterfaceNTTDomesticCompany } from "../../../../../../NTTs/InterfaceNTTDomesticCompany";

function DomesticCompany(paramsComponent: InterfacePageDomesticCompanies) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getResponseGetDomesticCompanies, setResponseGetDomesticCompanies] = useState<InterfaceAPIGetDomesticCompanies>();
    const [getIsActiveCreateCompany, setIsActiveCreateCompany] = useState<boolean>(false);
    const [getSelectedCompany, setSelectedCompany] = useState<InterfaceNTTDomesticCompany>();

    const callApiForGetDomesticCompanies = async (paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.company.postGetAllDomesticCompany;

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.userInfoBasic.user.id}/domestic-companies/`, mTheme, paginationParams as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceAPIGetDomesticCompanies;
                if (paginationParams.page === 1) {
                    setResponseGetDomesticCompanies(t);
                }
                else {
                    setResponseGetDomesticCompanies(prev => {
                        return (prev ? {
                            ...t,
                            data: [...prev.data, ...t.data],
                        } : {
                            ...t,
                        });
                    }
                    );
                }
                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }

                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                setStateFetchList(EnumStateFetchList.errorInRequest);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            callApiForGetDomesticCompanies(getPaginationForLastRequest);
        }
    }, [getStateFetchList])


    return (
        <div style={{
            display: "flex", width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center",
            gap: "1.6rem",
        }}>
            <ParentWithHeightAnimation in={!getIsActiveCreateCompany} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                <InfiniteScrollList
                    isDataExist={(getResponseGetDomesticCompanies?.data ?? []).length !== 0}
                    onEndReached={async () => {
                        let oldPage = getPaginationForLastRequest;
                        oldPage = {
                            ...oldPage,
                            page: (oldPage.page + 1)
                        };
                        setPaginationForLastRequest(oldPage);
                        console.log("callback for change state response2:", oldPage);
                        callApiForGetDomesticCompanies(oldPage);
                    }}
                    onRetryClicked={() => {
                        callApiForGetDomesticCompanies(getPaginationForLastRequest);
                    }}
                    stateResponseList={getStateFetchList}
                    styleSpacingMUI={{
                        columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                        rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                        columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                    }}

                >
                    {!getIsActiveCreateCompany && getResponseGetDomesticCompanies?.data && getResponseGetDomesticCompanies.data.map((domesticCompany, index, arr) => {
                        return (
                            <RowDomesticCompany {...domesticCompany}
                                callbackClickOnEdit={() => {
                                    setSelectedCompany(domesticCompany);
                                    setIsActiveCreateCompany(true);
                                }}
                                callbackReloadPage={() => {
                                    setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                                    callApiForGetDomesticCompanies(confComps.defaultValue.paginationConfigForServer);
                                }} />

                        )
                    })}
                </InfiniteScrollList>
            </ParentWithHeightAnimation>
            <ParentWithHeightAnimation in={getIsActiveCreateCompany} timeout={300} style={{
                gap: "0.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
            }}>
                {getIsActiveCreateCompany &&
                    <CreateDomesticCompany userInfoBasic={paramsComponent.userInfoBasic}
                        callbackCancel={() => {
                            setIsActiveCreateCompany(false);
                            setSelectedCompany(undefined);
                            callApiForGetDomesticCompanies(confComps.defaultValue.paginationConfigForServer);
                        }}

                        companyInformation={getSelectedCompany}
                    />
                }
            </ParentWithHeightAnimation>
            {!getIsActiveCreateCompany &&
                <div style={{ display: 'flex', flexDirection: "row", position: "sticky", bottom: "1vh", alignItems: "center", justifyContent: "end", width: "100%" }}>
                    <ButtonSave text={confComps.translate.addNewCompany}
                        callbackOnClick={() => {
                            setIsActiveCreateCompany(true);
                        }} />
                </div>
            }
        </div>
    );
}
export default DomesticCompany;