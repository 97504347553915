
import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const PaperIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="23" height="25" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9076 0.872014H5.94935C3.14864 0.862107 0.852869 2.92286 0.78689 5.50689V18.8223C0.724952 21.4485 2.98033 23.6249 5.82547 23.6833C5.86721 23.6833 5.90761 23.6846 5.94935 23.6833H16.7065C19.526 23.5777 21.7478 21.4274 21.7277 18.8223V7.42968L14.9076 0.872014Z" 
            stroke="#2D3250" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" 
            />
            <path d="M14.5537 0.857147V4.47279C14.5537 6.23774 16.0995 7.66833 18.0115 7.67331H21.7211" stroke="#2D3250" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.3018 16.5285H7.03076" stroke="#2D3250" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11.5486 11.8644H7.02979" stroke="#2D3250" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
};
