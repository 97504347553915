import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const TwoArrowIcon: React.FC<InterfaceIconSVG> = ({ style = { width: "12", height: "14" } }) => {
    return (
        <svg width={style.width} height={style.height} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8741 3.80859C12.0402 3.63906 12.0402 3.36016 11.8741 3.19062L9.30268 0.565625C9.13661 0.396094 8.86339 0.396094 8.69732 0.565625C8.53125 0.735156 8.53125 1.01406 8.69732 1.18359L10.5375 3.06211H0.428571C0.192857 3.06211 0 3.25898 0 3.49961C0 3.74023 0.192857 3.93711 0.428571 3.93711H10.5375L8.69732 5.81562C8.53125 5.98516 8.53125 6.26406 8.69732 6.43359C8.86339 6.60313 9.13661 6.60313 9.30268 6.43359L11.8741 3.80859ZM2.69732 13.4336C2.86339 13.6031 3.13661 13.6031 3.30268 13.4336C3.46875 13.2641 3.46875 12.9852 3.30268 12.8156L1.4625 10.9371H11.5714C11.8071 10.9371 12 10.7402 12 10.4996C12 10.259 11.8071 10.0621 11.5714 10.0621H1.4625L3.30268 8.18359C3.46875 8.01406 3.46875 7.73516 3.30268 7.56563C3.13661 7.39609 2.86339 7.39609 2.69732 7.56563L0.125893 10.1906C-0.0401786 10.3602 -0.0401786 10.6391 0.125893 10.8086L2.69732 13.4336Z"
                fill={style.color ?? "#59618C"} />
        </svg>
    )
}
