import { useEffect, useState } from "react";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { EnumStateStepGroup } from "../../basic/ArrowStep/InterfaceArrowStep";
import { InterfaceKeyStepForShowStateAllServices, InterfaceSteperGuide } from "./InterfaceSteperGuide";
import OneStep from "./SteperGroup/OneStep/OneStep";
import SteperGroup from "./SteperGroup/SteperGroup";
import Colors from "../../../config/Colors";
import { InterfaceNTTWorkFlowMainRecap } from "../../../NTTs/Recap/WorkflowMainRecap/InterfaceNTTWorkFlowMainRecap";
import { StepNameMappingToKeyStepForMoneyReceiveRecap } from "../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap";
import { StepNameMappingToKeyStepForSellCottageRecap } from "../../../NTTs/Recap/WorkflowMainRecap/SellCottage/InterfaceNTTOneStepOfWorkflowSellCottageRecap";
import { StepNameMappingToKeyStepForBuyCottageRecap } from "../../../NTTs/Recap/WorkflowMainRecap/BuyCottage/InterfaceNTTOneStepOfWorkflowBuyCottageRecap";
import { StepNameMappingToKeyStepForMoneyPaymentRecap } from "../../../NTTs/Recap/WorkflowMainRecap/MoneyPayment/InterfaceNTTOneStepOfWorkflowMoneyPaymentRecap";
import { InterfaceNTTRecapMain } from "../../../NTTs/Recap/InterfaceNTTRecapMain";
export function isTwoKeyHasSameParent(key1: string, key2: string) {
    let parents1 = key1.split(".");
    let parents2 = key2.split(".");
    let lengthThatMustReview = Math.min(parents1.length, parents2.length);

    for (let i = 0; i < (lengthThatMustReview - 1); i++) {
        if (parents1[i] != parents2[i]) {
            return false;
        }
    }
    return true;

}
export function getKeyOfParentOfKey(key1: string) {
    if (!key1.includes(".")) {
        return "";
    }
    let parents1 = key1.split(".");
    parents1.pop();
    return parents1.join(".");





}
export function isKey1BiggerThanKey2(key1: string, key2: string) {
    let parents1 = key1.split(".");
    let parents2 = key2.split(".");
    let maxLength = Math.max(parents1.length, parents2.length);
    const paddedList1 = parents1.concat(Array(maxLength - parents1.length).fill("0"));
    const paddedList2 = parents2.concat(Array(maxLength - parents2.length).fill("0"));
    if (key1 === key2) {
        return false;
    }
    for (let i = 0; i < paddedList1.length; i++) {
        if (+(`${paddedList1[i]}`) < +(`${paddedList2[i]}`)) {
            return false;
        }
        else if (+(`${paddedList1[i]}`) > +(`${paddedList2[i]}`)) {
            return true;
        }
    }
    return true;
}
function SteperGuide(paramsComponent: InterfaceSteperGuide) {
    var confComps = ConfigComps();
    const [getKeyOfOneStepThatSelected, setKeyOfOneStepThatSelected] = useState<string>(paramsComponent.keyOfOneStepThatSelected);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>(paramsComponent.keyOfGroupThatSelected);
    const [getKeysStepThatShowStateServices, setKeysStepThatShowStateServices] = useState<InterfaceKeyStepForShowStateAllServices>({
        lastKeyStepThatAllServiceCompleted: "1.0",
        maxStepThatCompleted: "1.0"
    });
    const findMaxStepThatCompleted = (recaps: InterfaceNTTWorkFlowMainRecap[], recapMain: InterfaceNTTRecapMain) => {
        let countOfServices = recapMain.receipts.length +
            recapMain.payments.length +
            recapMain.buy_cottages.length +
            recapMain.sell_cottages.length
        // store last steps of all services
        let keysLastStepOfMoneyReceives = recaps.flatMap(recap => recap.receipts)
            .filter(moneyReceive => moneyReceive.workflow_reviews.length > 0)
            .flatMap(moneyReceive => moneyReceive.workflow_reviews[moneyReceive.workflow_reviews.length - 1])
            //string key of last step of services
            .flatMap(lastStep => StepNameMappingToKeyStepForMoneyReceiveRecap[lastStep.review_ct])
        let keysLastStepOfMoneyPayments = recaps.flatMap(recap => recap.payments)
            .filter(moneyPayment => moneyPayment.workflow_reviews.length > 0)
            .flatMap(moneyPayment => moneyPayment.workflow_reviews[moneyPayment.workflow_reviews.length - 1])
            //string key of last step of services
            .flatMap(lastStep => StepNameMappingToKeyStepForMoneyPaymentRecap[lastStep.review_ct])
        let keysLastStepOfCottageBuys = recaps.flatMap(recap => recap.sell_cottages)
            .filter(cottageBuy => cottageBuy.workflow_reviews.length > 0)
            .flatMap(cottageBuy => cottageBuy.workflow_reviews[cottageBuy.workflow_reviews.length - 1])
            //string key of last step of services
            .flatMap(lastStep => StepNameMappingToKeyStepForBuyCottageRecap[lastStep.review_ct])
        let keysLastStepOfCottageSells = recaps.flatMap(recap => recap.buy_cottages)
            .filter(cottageSell => cottageSell.workflow_reviews.length > 0)
            .flatMap(cottageSell => cottageSell.workflow_reviews[cottageSell.workflow_reviews.length - 1])
            //string key of last step of services
            .flatMap(lastStep => StepNameMappingToKeyStepForSellCottageRecap[lastStep.review_ct])
        let allLastKeyStepOfAllSteps = [...keysLastStepOfMoneyReceives, ...keysLastStepOfMoneyPayments, ...keysLastStepOfCottageBuys, ...keysLastStepOfCottageSells];
        if (allLastKeyStepOfAllSteps.length !== countOfServices) {
            allLastKeyStepOfAllSteps.push("1.1");
        }
        allLastKeyStepOfAllSteps = allLastKeyStepOfAllSteps.sort((a, b) => {
            if (isKey1BiggerThanKey2(a, b) && isKey1BiggerThanKey2(b, a)) {
                return 0;
            }
            else {
                return (isKey1BiggerThanKey2(a, b) ? 1 : -1)
            }
        })
        let valueKey = {
            lastKeyStepThatAllServiceCompleted: "",
            maxStepThatCompleted: "",
        }
        console.log("allKeyOfAllSteps:", allLastKeyStepOfAllSteps)
        if (allLastKeyStepOfAllSteps.length > 0) {
            valueKey = {
                lastKeyStepThatAllServiceCompleted: allLastKeyStepOfAllSteps[0],
                maxStepThatCompleted: allLastKeyStepOfAllSteps[allLastKeyStepOfAllSteps.length - 1],
            }
        }
        setKeysStepThatShowStateServices(valueKey);


    }
    useEffect(() => {
        if (paramsComponent.workFlowOfAllService.length > 0 && paramsComponent.recapMain) {
            findMaxStepThatCompleted(paramsComponent.workFlowOfAllService, paramsComponent.recapMain);
        }
    }, [paramsComponent.workFlowOfAllService, paramsComponent.recapMain])
    useEffect(() => {
        setKeyOfOneStepThatSelected(paramsComponent.keyOfOneStepThatSelected);
    }, [paramsComponent.keyOfOneStepThatSelected])
    useEffect(() => {
        setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected);
    }, [paramsComponent.keyOfGroupThatSelected])
    return (
        <div style={{
            display: "inline-flex",
            position: "relative",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            flexGrow: 0,
            height: "calc(4rem + 4px)",
            borderTop: `2px solid ${Colors.theme.blue.light}`,
            borderBottom: `2px solid ${Colors.theme.blue.light}`,
            borderInlineStart: `2px solid ${Colors.theme.blue.light}`,
            borderStartStartRadius: "0.37rem",
            borderEndStartRadius: "0.37rem",
            boxSizing: "border-box",
        }}>
            {paramsComponent.steps.map((steps, index, arr) => {
                if (steps.steps.length === 0) {
                    return (
                        <OneStep
                            callbackOnClick={function (): void {
                                paramsComponent.callbackOnClickOneStep(steps.keyStep);
                                paramsComponent.callbackOnClickOneGroup("1");
                            }}
                            keyOfOneStepThatSelected={getKeyOfOneStepThatSelected}
                            keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                            keyOfStepThatLastCompleted={getKeysStepThatShowStateServices}
                            {...steps}
                        />
                    )
                }
                else {
                    return (
                        <SteperGroup
                            {...steps}
                            callbackOnClickItem={(keySelected) => {
                                paramsComponent.callbackOnClickOneStep(keySelected);
                            }}
                            callbackOnClickGroup={(keySelected) => {
                                paramsComponent.callbackOnClickOneGroup(keySelected);
                            }}
                            keyOfOneStepThatSelected={getKeyOfOneStepThatSelected}
                            keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                            keyOfStepThatLastCompleted={getKeysStepThatShowStateServices}
                        />
                    )

                }
            })
            }

        </div>
    )
};
export default SteperGuide;