import { ArrowSmallRightIcon } from "../../../../assets/icons/svg/ArrowSmallRightIcon";
import Colors from "../../../../config/Colors";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { InterfaceHeaderSubSidebar } from "./InterfaceHeaderSubSidebar";

function HeaderSubSidebar(componentsParams: InterfaceHeaderSubSidebar) {
    var confComps = ConfigComps();
    return (
        <div style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            gap: "0.2rem",
            boxSizing: "border-box",
            fontFamily: confComps.font.poppins.Medium,
            paddingBlock: "0.55rem",
            lineHeight: "1.55rem",
            fontSize: "1.03rem",
            paddingInlineStart: "1.4rem",
            borderRadius: "0.88rem",
            background: (componentsParams.isSelected && !componentsParams.hasChild) ? Colors.theme.blue.dark : undefined,
            color: (componentsParams.isSelected && !componentsParams.hasChild) ? "#F1F2F4" : Colors.theme.blue.dark,
            userSelect: "none",
            cursor: "pointer",

        }} onClick={() => componentsParams.callbackOnClick()}>

            {componentsParams.hasChild ?
                <ArrowSmallRightIcon style={{
                    background: componentsParams.isSelected ? Colors.theme.blue.blue : Colors.theme.blue.light,
                    transition: "transform 0.3s ease",
                    transform: componentsParams.isSelected ? "rotate(90deg)" : "rotate(0deg)"
                }} /> : undefined}
            {componentsParams.title}

        </div>
    );
}
export default HeaderSubSidebar;