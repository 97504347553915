import { ConfigComps } from "../../../config/ConfigCOMP";
import { MainCard } from "../../myProfile/MyProfileStyle";


function UnderConstruction() {
    var confComps = ConfigComps();
    return (
        // <ContainerPage title={confComps.panel.myProfile.title}>
        <MainCard style={{
            display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexGrow: "1", height: "100%",
            width: "100%",
            background: "red",
        }}>
            <h1>this screen under the cons</h1>


        </MainCard>

        // </ContainerPage>
    )
}
export default UnderConstruction;