
import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const AscDownIcon: React.FC<InterfaceIconSVG> = ({ style = { width: "20", height: "20" } }) => {
    return (

        <svg height={"20"} version="1.1" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
            <g id="layer1">
                <path d="M 3 0 L 3 18 L 0 15 L 0 16.5 L 3.5 20 L 7 16.5 L 7 15 L 4 18 L 4 0 L 3 0 z M 11.638672 1 L 9.5253906 7.3417969 L 10.474609 7.6582031 L 11.027344 6 L 13.972656 6 L 14.525391 7.6582031 L 15.474609 7.3417969 L 13.359375 1 L 11.638672 1 z M 12.359375 2 L 12.638672 2 L 13.638672 5 L 11.359375 5 L 12.359375 2 z M 10 11 L 10 12 L 13.859375 12 L 10 16.824219 L 10 18 L 15 18 L 15 17 L 11.140625 17 L 15 12.175781 L 15 11 L 10 11 z "
                    // style={"fill:#222222; fill-opacity:1; stroke:none; stroke-width:0px;"}
                    fill={style?.color ?? "#222222"}
                    strokeWidth={0}
                />
            </g>
        </svg>
    )
}