import zIndex from '@mui/material/styles/zIndex';
import ArrowStep from '../../../../basic/ArrowStep/ArrowStep';
import { InterfaceOneStep } from './InterfaceOneStep';
import ArrowStepHeader from '../ArrowStepHeader/ArrowStepHeader';
import { EnumStateStepGroup, InterfaceColors } from '../../../../basic/ArrowStep/InterfaceArrowStep';
import { useEffect, useRef, useState } from 'react';
import { BoxStepArrowSVG } from '../../../../../assets/icons/svg/BoxStepArrowSVG';
import Colors from '../../../../../config/Colors';
import { styled } from '@mui/material';
import { getKeyOfParentOfKey, isKey1BiggerThanKey2, isTwoKeyHasSameParent } from '../../SteperGuide';
import { ImageSquare } from '../../../imageSquare/ImageSquare';
import ParentWithWidthAnimation from '../../../../basic/animation/ParentWithWidthAnimation/ParentWithWidthAnimation';
import { ConfigComps } from '../../../../../config/ConfigCOMP';
import { InterfaceKeyStepForShowStateAllServices } from '../../InterfaceSteperGuide';
import { DoneRounded } from '@mui/icons-material';

export const ParentDiv = styled('div', { shouldForwardProp: (prop) => prop !== 'isFirstBox' && prop !== 'isShow' })<{ isFirstBox: boolean; isShow: boolean; }>(({
    theme,
    isFirstBox,
    isShow,
}) => {
    return (
        {
            // flexGrow: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            position: 'relative',
            boxSizing: 'border-box',
            // marginInlineStart: isFirstBox || (!isShow) ? undefined : '-21px',


        }
    )
});



const configColors = (state: EnumStateStepGroup): InterfaceColors => {
    var color: InterfaceColors;
    if (state === EnumStateStepGroup.completed) {
        color = {
            background: '#D8EFE0',
            color: Colors.theme.green.dark,
        };
    }
    else {
        throw ' we do not set other state color'
    }
    return color;
}
enum EnumStateOfThisStep {
    notCompleteByAnyService = "noCompleted",
    allServiceCompletedThisStep = "fullCompleted",
    someServiceCompletedThisStep = "littleCompleted",
}
function OneStep(paramsComponent: InterfaceOneStep) {
    var confComps = ConfigComps();
    const [getKeyOfOneStepThatSelected, setKeyOfOneStepThatSelected] = useState<string>(paramsComponent.keyOfOneStepThatSelected);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>(paramsComponent.keyOfGroupThatSelected);
    const [getKeyOfStepThatLastCompleted, setKeyOfStepThatLastCompleted] = useState<InterfaceKeyStepForShowStateAllServices>(paramsComponent.keyOfStepThatLastCompleted);
    const [getStateOfThisState, setStateOfThisState] = useState<EnumStateOfThisStep>(EnumStateOfThisStep.notCompleteByAnyService);

    // const [getColor, setColor] = useState<InterfaceColors>({ background: 'white', color: Colors.theme.blue.dark });
    const contentRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(true);
    useEffect(() => {
        setKeyOfStepThatLastCompleted(paramsComponent.keyOfStepThatLastCompleted);

    }, [paramsComponent.keyOfStepThatLastCompleted])
    useEffect(() => {
        setKeyOfOneStepThatSelected(paramsComponent.keyOfOneStepThatSelected);
    }, [paramsComponent.keyOfOneStepThatSelected])
    useEffect(() => {
        setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected);
    }, [paramsComponent.keyOfGroupThatSelected])
    useEffect(() => {
        //this step lower than minStep that all service completed
        console.log("keys object:", getKeyOfStepThatLastCompleted)
        if (isKey1BiggerThanKey2(getKeyOfStepThatLastCompleted.lastKeyStepThatAllServiceCompleted, paramsComponent.keyStep) ||
            (!isKey1BiggerThanKey2(getKeyOfStepThatLastCompleted.lastKeyStepThatAllServiceCompleted, paramsComponent.keyStep) &&
                !isKey1BiggerThanKey2(paramsComponent.keyStep, getKeyOfStepThatLastCompleted.lastKeyStepThatAllServiceCompleted))) {

            setStateOfThisState(EnumStateOfThisStep.allServiceCompletedThisStep);
        }
        //this step lower than maxStep that all service completed
        else if (isKey1BiggerThanKey2(getKeyOfStepThatLastCompleted.maxStepThatCompleted, paramsComponent.keyStep) ||
            (!isKey1BiggerThanKey2(getKeyOfStepThatLastCompleted.maxStepThatCompleted, paramsComponent.keyStep) &&
                !isKey1BiggerThanKey2(paramsComponent.keyStep, getKeyOfStepThatLastCompleted.maxStepThatCompleted))) {
            setStateOfThisState(EnumStateOfThisStep.someServiceCompletedThisStep);
        }
        else {
            setStateOfThisState(EnumStateOfThisStep.notCompleteByAnyService);
        }
    }, [getKeyOfStepThatLastCompleted])

    const isStepMustShow = () => {
        return getKeyOfGroupThatSelected.indexOf(getKeyOfParentOfKey(paramsComponent.keyStep)) === 0
    }
    useEffect(() => {
        if (!isStepMustShow()) {
            const timer = setTimeout(() => {
                setVisible(false);
            }, 400); // Wait for 2 seconds, the duration of the animation

            return () => clearTimeout(timer); // Clean up the timer if the component unmounts
        }
        else {
            setVisible(true);
        }
    }, [isStepMustShow()]);

    return (

        <div style={{
            display: visible ? 'flex' : 'none',
            position: 'relative', flexDirection: 'row', alignItems: 'center',
            cursor: 'pointer',
            boxSizing: 'border-box',
            height: isStepMustShow() ? '4.0rem' : '0rem',
            opacity: isStepMustShow() ? 1 : 0,

            transition: 'all 0.2s ease',

            // zIndex: -1,
        }}
            onClick={() => {
                paramsComponent.callbackOnClick();
            }}>

            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    position: 'relative',
                    boxSizing: 'border-box',
                    transformOrigin: 'left', // Ensures scaling happens from the left side
                    // zIndex: '-1',
                }}
            // in={isStepMustShow()}
            >
                <div style={{ position: 'relative', height: '100%' }}>
                    <div style={{
                        minHeight: '100%',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'start',
                        paddingInlineStart: '32px',
                        // paddingInlineEnd: '16px',
                        alignItems: 'center',
                        // opacity: 0,
                        zIndex: -2,
                        background: getStateOfThisState === EnumStateOfThisStep.notCompleteByAnyService ? 'white' : '#D8EFE0',
                        gap: '0.8rem',

                    }}>

                        < ImageSquare width={'2rem'}
                            borderRadius='500px'
                            background={getStateOfThisState !== EnumStateOfThisStep.notCompleteByAnyService ? Colors.theme.green.dark : 'white'}
                            color={getStateOfThisState === EnumStateOfThisStep.notCompleteByAnyService ? Colors.theme.blue.dark : Colors.theme.green.lightLemon}
                            element={paramsComponent.keyStep}
                            elementStyle={{
                                fontSize: '1.03rem',
                            }}
                            style={{
                                flexGrow: 0,
                                userSelect: 'none',
                                opacity: 0,
                                transition: 'opacity 500ms ease-in',
                            }} />


                        <p style={{
                            flexGrow: 1,
                            opacity: 0,
                            fontSize: '1rem',
                            transition: 'opacity 500ms ease-in',
                            userSelect: 'none',
                            textWrap:"nowrap",

                        }}>
                            {paramsComponent.title}
                        </p>
                    </div>
                </div>
            </div >
            {/* main value */}
            <div style={{
                position: 'absolute',
                minHeight: '100%',
                boxSizing: 'border-box',
                display: 'inline-flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
                zIndex: 100,
                gap: '0.8rem',
                left: 'calc(50% + 4px)',
                top: '50%',
                transform: 'translate(-50%,-50%)',

            }}>

                < ImageSquare width={'2rem'}
                    borderRadius='500px'
                    border={`1px solid ${getStateOfThisState === EnumStateOfThisStep.notCompleteByAnyService ? Colors.theme.blue.dark : Colors.theme.green.dark}`}
                    background={getStateOfThisState === EnumStateOfThisStep.notCompleteByAnyService ? 'white' : Colors.theme.green.dark}
                    color={getStateOfThisState === EnumStateOfThisStep.notCompleteByAnyService ? Colors.theme.blue.dark : Colors.theme.green.lightLemon}
                    elementStyle={{
                        fontSize: '1.03rem',
                    }}
                    element={
                        getStateOfThisState === EnumStateOfThisStep.allServiceCompletedThisStep ?
                            <DoneRounded /> :
                            paramsComponent.keyStep.split('.').pop()
                    }
                    style={{
                        flexGrow: 0,
                        userSelect: 'none',
                        opacity: isStepMustShow() ? 1 : 0,
                        transition: 'opacity 500ms ease-in',
                    }} />



                <p style={{
                    flexGrow: 1,
                    fontSize: '1rem',
                    opacity: isStepMustShow() ? 1 : 0,
                    transition: 'opacity 500ms ease-in',
                    userSelect: 'none',
                    color: Colors.theme.blue.dark,
                    fontFamily: getKeyOfOneStepThatSelected === paramsComponent.keyStep ? confComps.font.poppins.Medium : confComps.font.poppins.Regular,
                    textDecorationThickness: 1,
                    textDecoration: getKeyOfOneStepThatSelected === paramsComponent.keyStep ? 'underline' : undefined,
                    textDecorationColor: Colors.theme.blue.light,
                    textWrap:"nowrap",

                }}>
                    {paramsComponent.title}
                </p>

            </div>
            {isStepMustShow() &&

                <BoxStepArrowSVG style={{
                    flexGrow: 0,
                    position: 'absolute',
                    right: '1px',
                    zIndex: 40,
                    userSelect: 'none',
                    width: 'auto',
                    transform: 'translate(100%,0px)',
                    scale: '1.04',
                    // marginLeft: '-1px',//do not change it because the last block wrong shape
                    boxSizing: 'border-box',
                    background: getStateOfThisState === EnumStateOfThisStep.notCompleteByAnyService ? 'white' : '#D8EFE0',
                    color: Colors.theme.blue.light,
                }}
                />
            }
        </div>


    )
}
export default OneStep;