import zIndex from "@mui/material/styles/zIndex";
import ArrowStep from "../../../basic/ArrowStep/ArrowStep";
import ArrowStepHeader from "./ArrowStepHeader/ArrowStepHeader";
import { EnumStateStepGroup } from "../../../basic/ArrowStep/InterfaceArrowStep";
import { useEffect, useState } from "react";
import { InterfaceFunctionOfSteperGroup, InterfaceSteperGroup } from "./InterfaceSteperGroup";
import OneStep from "./OneStep/OneStep";
import { getKeyOfParentOfKey } from "../SteperGuide";
import { InterfaceKeyStepForShowStateAllServices } from "../InterfaceSteperGuide";

function SteperGroup(paramsComponent: (InterfaceSteperGroup & InterfaceFunctionOfSteperGroup)) {
    const [getKeyOfOneStepThatSelected, setKeyOfOneStepThatSelected] = useState<string>(paramsComponent.keyOfOneStepThatSelected);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>(paramsComponent.keyOfGroupThatSelected);
    const [getKeyOfStepThatLastCompleted, setKeyOfStepThatLastCompleted] = useState<InterfaceKeyStepForShowStateAllServices>(paramsComponent.keyOfStepThatLastCompleted);
    useEffect(() => {
        setKeyOfStepThatLastCompleted(paramsComponent.keyOfStepThatLastCompleted);
    }, [paramsComponent.keyOfStepThatLastCompleted])
    useEffect(() => {
        setKeyOfOneStepThatSelected(paramsComponent.keyOfOneStepThatSelected);
    }, [paramsComponent.keyOfOneStepThatSelected])
    useEffect(() => {
        setKeyOfGroupThatSelected(paramsComponent.keyOfGroupThatSelected);
    }, [paramsComponent.keyOfGroupThatSelected])
    return (
        <div style={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            boxSizing: "border-box",
            // zIndex: paramsComponent.zIndexGroup,
        }}>
            <ArrowStepHeader
                {...paramsComponent}
                callbackOnClickHeader={function (): void {
                    if (getKeyOfGroupThatSelected.indexOf(paramsComponent.keyStep) === 0) {
                        paramsComponent.callbackOnClickGroup(getKeyOfParentOfKey(paramsComponent.keyStep))
                    }
                    else {
                        paramsComponent.callbackOnClickGroup(paramsComponent.keyStep)

                    }
                }}
                keyOfOneStepThatSelected={getKeyOfOneStepThatSelected}
                keyOfStepThatLastCompleted={getKeyOfStepThatLastCompleted}
                keyOfGroupThatSelected={getKeyOfGroupThatSelected}
            />


            {paramsComponent.steps.map((steps, index, arr) => {
                if (steps.steps.length === 0) {
                    return (
                        <OneStep
                            {...steps}
                            callbackOnClick={function (): void {
                                paramsComponent.callbackOnClickItem(steps.keyStep)
                                paramsComponent.callbackOnClickGroup(paramsComponent.keyStep);
                            }}
                            keyOfGroupThatSelected={getKeyOfGroupThatSelected}
                            keyOfOneStepThatSelected={getKeyOfOneStepThatSelected}
                            keyOfStepThatLastCompleted={getKeyOfStepThatLastCompleted}
                        />
                    )
                }
                else {
                    return (
                        <SteperGroup
                            {...steps}
                            callbackOnClickItem={function (keySelected: string): void {
                                paramsComponent.callbackOnClickItem(keySelected);
                            }} callbackOnClickGroup={function (keySelected: string): void {
                                paramsComponent.callbackOnClickGroup(keySelected);
                            }}
                            keyOfOneStepThatSelected={getKeyOfOneStepThatSelected}
                            keyOfStepThatLastCompleted={getKeyOfStepThatLastCompleted}
                            keyOfGroupThatSelected={getKeyOfGroupThatSelected} />
                    )

                }
            })
            }

        </div>
    )
}
export default SteperGroup;