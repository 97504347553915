import { useDispatch } from "react-redux";
import Colors from "../../../config/Colors";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { InterfaceShowDescriptionWithUsers } from "./InterfaceShowDescriptionWithUsers";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import Links from "../../../config/Links";

function ShowDescriptionWithUsers(paramsComponent: InterfaceShowDescriptionWithUsers) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    return (

        <Box sx={{
            width: '100%', display: 'flex', flexDirection: 'row',
            alignItems: 'start',
            justifyContent: 'start',
            ...paramsComponent.style
        }}>
            {paramsComponent.description_list.length >= (paramsComponent.user_orders ?? []).length &&
                <p style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'start',
                    justifyContent: 'start',
                    width: '100%',
                    boxSizing: 'border-box',
                    fontSize: '1.1rem',
                    lineHeight: '1.62rem',
                    color: Colors.theme.blue.dark,
                    gap: "0.1rem",
                    fontFamily: confComps.font.poppins.Regular,
                }}>
                    {paramsComponent.description_list.flatMap((text, index, arr) => {
                        if (index < (paramsComponent.user_orders ?? []).length) {
                            return [
                                <p style={{
                                    color: Colors.theme.blue.dark,
                                    fontSize: '0.96rem',
                                    fontFamily: confComps.font.poppins.Regular,
                                }}>
                                    {`${text}`}
                                </p>,
                                <p style={{
                                    color: Colors.theme.blue.Secondary,
                                    cursor: "pointer",
                                    fontSize: '0.96rem',
                                    fontFamily: confComps.font.poppins.Regular,
                                }}
                                    onClick={() => {
                                        if ((paramsComponent.user_orders ?? [])[index].user.is_trusty) {
                                            navigate(`${Links.screens.panel.people.trusties.trustyProfile}${(paramsComponent.user_orders ?? [])[index].user.id}/`)
                                        }
                                        else if ((paramsComponent.user_orders ?? [])[index].user.is_customer) {
                                            navigate(`${Links.screens.panel.people.customers.customersProfile}${(paramsComponent.user_orders ?? [])[index].user.id}/`)
                                        }
                                        else if ((paramsComponent.user_orders ?? [])[index].user.is_employee) {
                                            navigate(`${Links.screens.panel.people.employees.employeeProfile}${(paramsComponent.user_orders ?? [])[index].user.id}/`)
                                        }
                                    }}>
                                    {`@${(paramsComponent.user_orders ?? [])[index].user.username}`}
                                </p>
                            ]
                        }
                        else {
                            return [
                                <p style={{
                                    color: Colors.theme.blue.dark,
                                    fontSize: '0.96rem',
                                    fontFamily: confComps.font.poppins.Regular,
                                }}>
                                    {`${text}`}
                                </p>
                            ]
                        }
                    })}
                </p>
            }
            {paramsComponent.description_list.length < (paramsComponent.user_orders ?? []).length &&
                <p style={{
                    display: 'flex', flexDirection: 'row', alignItems: 'start',
                    justifyContent: 'start',
                    width: '100%',
                    boxSizing: 'border-box',
                    fontSize: '1.03rem',
                    lineHeight: '1.62rem',
                    color: Colors.theme.blue.dark,
                    fontFamily: confComps.font.poppins.Regular,
                }}>
                    {(paramsComponent.user_orders ?? []).flatMap((userMentioned, index, arr) => {
                        if (index < paramsComponent.description_list.length) {

                            return [
                                <p style={{
                                    color: Colors.theme.blue.dark,
                                    fontSize: '0.96rem',
                                    fontFamily: confComps.font.poppins.Regular,
                                }}>
                                    {paramsComponent.description_list[index]}
                                </p>
                                ,
                                <p style={{
                                    color: Colors.theme.blue.Secondary,
                                    cursor: "pointer",
                                    fontSize: '0.96rem',
                                    fontFamily: confComps.font.poppins.Regular,
                                }}
                                    onClick={() => {
                                        if ((paramsComponent.user_orders ?? [])[index].user.is_trusty) {
                                            navigate(`${Links.screens.panel.people.trusties.trustyProfile}${(paramsComponent.user_orders ?? [])[index].user.id}/`)
                                        }
                                        else if ((paramsComponent.user_orders ?? [])[index].user.is_customer) {
                                            navigate(`${Links.screens.panel.people.customers.customersProfile}${(paramsComponent.user_orders ?? [])[index].user.id}/`)
                                        }
                                        else if ((paramsComponent.user_orders ?? [])[index].user.is_employee) {
                                            navigate(`${Links.screens.panel.people.employees.employeeProfile}${(paramsComponent.user_orders ?? [])[index].user.id}/`)
                                        }
                                    }}>
                                    @{userMentioned.user.username}
                                </p>
                            ]
                        }
                        else {
                            return [
                                <p
                                    style={{
                                        color: Colors.theme.blue.Secondary,
                                        cursor: "pointer",
                                        fontSize: '0.96rem',
                                        fontFamily: confComps.font.poppins.Regular,
                                    }}
                                    onClick={() => {
                                        if ((paramsComponent.user_orders ?? [])[index].user.is_trusty) {
                                            navigate(`${Links.screens.panel.people.trusties.trustyProfile}${(paramsComponent.user_orders ?? [])[index].user.id}/`)
                                        }
                                        else if ((paramsComponent.user_orders ?? [])[index].user.is_customer) {
                                            navigate(`${Links.screens.panel.people.customers.customersProfile}${(paramsComponent.user_orders ?? [])[index].user.id}/`)
                                        }
                                        else if ((paramsComponent.user_orders ?? [])[index].user.is_employee) {
                                            navigate(`${Links.screens.panel.people.employees.employeeProfile}${(paramsComponent.user_orders ?? [])[index].user.id}/`)
                                        }
                                    }}>
                                    @{userMentioned.user.username}
                                </p>
                            ]
                        }
                    })}
                </p>
            }
        </Box>
    );
}
export default ShowDescriptionWithUsers;