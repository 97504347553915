import { InterfaceIconSVG } from "../InterfaceIconSVG";

export const WalletIcon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg"
            style={{ ...style }}>
            <path d="M4.6499 5.33334H8.30569"
                stroke={style?.color ?? "#387ADF"}
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.2065 6.25H15.8285C14.1971 6.25 12.8755 7.48108 12.8755 9C12.8755 10.5189 14.198 11.75 15.8275 11.75H18.2065C18.2833 11.75 18.3208 11.75 18.3528 11.7482C18.8463 11.7179 19.2393 11.3522 19.2713 10.8929C19.2731 10.8636 19.2731 10.8278 19.2731 10.7572V7.24275C19.2731 7.17217 19.2731 7.13642 19.2713 7.10708C19.2384 6.64783 18.8463 6.28208 18.3528 6.25183C18.3217 6.25 18.2833 6.25 18.2065 6.25Z"
                stroke={style?.color ?? "#387ADF"} stroke-width="1.5" />
            <path d="M18.3271 6.25C18.2558 4.534 18.0274 3.48167 17.288 2.741C16.2177 1.66667 14.494 1.66667 11.0476 1.66667H8.30571C4.85922 1.66667 3.13552 1.66667 2.06529 2.741C0.994141 3.81442 0.994141 5.54326 0.994141 9C0.994141 12.4568 0.994141 14.1856 2.06529 15.259C3.13552 16.3333 4.85922 16.3333 8.30571 16.3333H11.0476C14.494 16.3333 16.2177 16.3333 17.288 15.259C18.0274 14.5183 18.2568 13.466 18.3271 11.75"
                stroke={style?.color ?? "#22357E"} stroke-width="1.5" />
            <path d="M15.6089 9H15.6172"
                stroke={style?.color ?? "#387ADF"}
                stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
};
