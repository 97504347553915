import { InterfaceIconSVG } from "../../InterfaceIconSVG";

export const Priority4Icon: React.FC<InterfaceIconSVG> = ({ style }) => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.75 8.25L8.5 5.25L4.25 8.25" stroke="#FF2E00" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.75 11.75L8.5 8.75L4.25 11.75" stroke="#FF7900" stroke-opacity="0.65" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
