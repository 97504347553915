import { useNavigate } from "react-router-dom";
import { InterfaceAllServiceOfFinalRecap, InterfaceCreateFreestyle, InterfaceFinalRecap, InterfaceGetAPIFinalRecapsList } from "./InterfaceCreateFreestyle";
import { useDispatch, useSelector } from "react-redux";
import { Grid, useTheme } from "@mui/material";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import ContainerPage from "../../../../components/basic/containerPage/ContainerPage";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { MainCard } from "../../../myProfile/MyProfileStyle";
import { useEffect, useState } from "react";
import { EnumStateStepGroup } from "../../../../components/basic/ArrowStep/InterfaceArrowStep";
import SteperGroups from "../../../../components/complex/SteperGuide/SteperGroup/SteperGroup";
import SteperGuide from "../../../../components/complex/SteperGuide/SteperGuide";
import { enumTypeOfAlert } from "../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ShowAlert } from "../../../../store/AlertStateModeSlice";
import TitleMedium18 from "../../../../components/basic/Texts/Titles/TitleMedium18/TitleMedium18";
import { isVisibilityProgressBar } from "../../../../store/ProgressbarInRootSlice";
import { HandlerLangUrl, TypeQueryParams, urlsList } from "../../../../api/UrlsList";
import { ConvertSmartSearchItemToItemSearchForServer, RequestHandler } from "../../../../api/API";
import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from "../../../../api/APIErrorResponse";
import { InterfaceNTTFreestyleMain } from "../../../../NTTs/Freestyle/InterfaceNTTFreestyleMain";
import { InterfacePaginationInServer, InterfaceSearchInServer } from "../../../../api/interfaces/InterfaceSearchInServer";
import { EnumStateFetchList } from "../../../../components/basic/ShowStateFetchList/InterfaceShowStateFetchList";
import { InterfaceAdvanceSearchParams } from "../../../../components/complex/advanceSearchBox/InterfaceAdvanceSearchBox";
import { InterfaceItemOfSorting } from "../../../../components/complex/SortingBox/InterfaceSortingBox";
import { InterfaceGetAPIRecapsList } from "../../../recap/RecapsList/InterfaceRecapsList";
import AddButtonWithShadowEffect from "../../../../components/basic/Buttons/AddButtonWithShadowEffect/AddButtonWithShadowEffect";
import InfiniteScrollList from "../../../../components/basic/InfiniteScrollList/InfiniteScrollList";
import AdvanceSearchBox from "../../../../components/complex/advanceSearchBox/AdvanceSearchBox";
import SortingBox from "../../../../components/complex/SortingBox/SortingBox";
import { GridFromMUI } from "../../../../components/MUIConfig/GridFromMUI";
import Colors from "../../../../config/Colors";
import Links from "../../../../config/Links";
import RowRecap from "../../../recap/RecapsList/RowRecap/RowRecap";
import RowFinalRecap from "./RowFinalRecap/RowFinalRecap";
import MergeSelectedRecaps from "./MergeSelectedRecaps/MergeSelectedRecaps";

function CreateFreestyle(paramsComponent: InterfaceCreateFreestyle) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getKeyOfGroupThatSelected, setKeyOfGroupThatSelected] = useState<string>("1.");
    const [getResponseRecaps, setResponseRecaps] = useState<InterfaceFinalRecap[]>([]);
    const [getSelectedRecap, setSelectedRecap] = useState<InterfaceFinalRecap[]>([]);
    const [getStateFetchList, setStateFetchList] = useState<EnumStateFetchList>(EnumStateFetchList.initiate);
    const [getPaginationForLastRequest, setPaginationForLastRequest] = useState<InterfacePaginationInServer>(confComps.defaultValue.paginationConfigForServer);
    const [getSmartSearch, setSmartSearch] = useState<InterfaceAdvanceSearchParams>({
        smartSearch: "", ...confComps.component.advanceSearchBox.allKinds.recap,

    });
    const [getSelectedItemOfSorting, setSelectedItemOfSorting] = useState<InterfaceItemOfSorting[]>(confComps.sorting.recapList);
    const callApiForCreateFreestyleMain = async () => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.deals.freestyle.postCreateFreestyleInitiate;
        let bodyObj = {

        }

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                // setFreestyleMain(response);
                // let t = response as InterfaceGetAPICustomersList;

                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    const getApiListOfRecaps = async (sortingFilter: InterfaceItemOfSorting[], advanceSearch: InterfaceAdvanceSearchParams, paginationParams: InterfacePaginationInServer) => {
        dispatch(isVisibilityProgressBar(true));
        setStateFetchList(EnumStateFetchList.searching);
        let urlObj = urlsList.panel.recap.getAllRecapsThatNotAssigned;
        let bodyObj: InterfaceSearchInServer = ConvertSmartSearchItemToItemSearchForServer(sortingFilter, advanceSearch)

        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url, mTheme, paginationParams as any as TypeQueryParams),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log("response:):)");
                console.log(response);
                let t = response as InterfaceGetAPIFinalRecapsList;

                if (paginationParams.page === 1) {
                    setResponseRecaps(t.data);
                }
                else {
                    setResponseRecaps(prev => {
                        return (prev ?
                            [...prev, ...t.data]
                            : t.data
                        );
                    }
                    );
                }
                console.log("safajoon list recap:", t.data);



                if (t.next_page === null) {
                    setStateFetchList(EnumStateFetchList.NotFounded);
                }
                else {
                    setStateFetchList(EnumStateFetchList.foundData);
                }
                dispatch(isVisibilityProgressBar(false));

                // dispatch(ShowAlert({ text: confComps.panel.people.customerList.api.message.success.title, typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));



            })
            .catch((e: any) => {
                setStateFetchList(EnumStateFetchList.errorInRequest);
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    useEffect(() => {
        if (getStateFetchList === EnumStateFetchList.initiate) {
            getApiListOfRecaps(getSelectedItemOfSorting, getSmartSearch, getPaginationForLastRequest);
        }
    }, [getStateFetchList])

    return (
        <ContainerPage title={confComps.translate.newFreestyle}>
            {(getResponseRecaps
                .filter(pred => getSelectedRecap.findIndex(selectRecap => selectRecap.id === pred.id) > -1).length > 0) &&
                // getResponseRecaps
                // .filter(pred => getSelectedRecap.findIndex(selectRecap => selectRecap.id === pred.id) > -1)
                // .reduce<InterfaceAllServiceOfFinalRecap>((consensusRecap, item) => {

                //     return {
                //         receipts: [...consensusRecap.receipts, ...item.receipts],
                //         payments: [...consensusRecap.payments, ...item.payments],
                //         sell_cottages: [...consensusRecap.sell_cottages, ...item.sell_cottages],
                //         buy_cottages: [...consensusRecap.buy_cottages, ...item.buy_cottages],
                //     }

                // }, {
                //     receipts: [], payments: [], sell_cottages: [], buy_cottages: []
                // })


                <MergeSelectedRecaps
                    selectedRecap={
                        getResponseRecaps
                            .filter(pred => getSelectedRecap.findIndex(selectRecap => selectRecap.id === pred.id) > -1)
                    }
                    callbackOnClickRemoveTheRecap={(id) => {
                        setSelectedRecap(prev => prev.filter(recap => recap.id !== id))
                    }}
                />
            }



            <div style={{
                width: "100%", display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                boxSizing: "border-box",
                gap: "0.96rem"
            }}>
                <AdvanceSearchBox
                    defaultValue={getSmartSearch}
                    doneSearching={(params: InterfaceAdvanceSearchParams) => {
                        getApiListOfRecaps(getSelectedItemOfSorting, params, confComps.defaultValue.paginationConfigForServer);
                        setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                        setSmartSearch(params);
                    }}
                />
                <div style={{
                    flex: 0.2,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    boxSizing: "border-box",
                    gap: "0.96rem",

                }}>
                    <SortingBox
                        items={getSelectedItemOfSorting}
                        onclickDone={(items: InterfaceItemOfSorting[]) => {
                            getApiListOfRecaps(items, getSmartSearch, confComps.defaultValue.paginationConfigForServer);
                            setPaginationForLastRequest(confComps.defaultValue.paginationConfigForServer);
                            setSelectedItemOfSorting(items);
                        }}
                        limitClick={-1}
                    />
                </div>
            </div>
            <div style={{
                display: "flex", boxSizing: "border-box", flexDirection: "column", alignItems: "center", justifyContent: "space-between",
                width: "100%", flexGrow: 1,
                background: Colors.theme.white.white80,
                border: `1px solid ${Colors.theme.white.white70}`,
                padding: "1.6rem",
                borderRadius: "0.32rem",
            }}
            >
                <InfiniteScrollList
                    isDataExist={(getResponseRecaps ?? [])
                        .filter(pred => getSelectedRecap.findIndex(selectRecap => selectRecap.id === pred.id) === -1)
                        .length !== 0}
                    onEndReached={async () => {
                        let oldPage = getPaginationForLastRequest;
                        oldPage = {
                            ...oldPage,
                            page: (oldPage.page + 1)
                        };
                        setPaginationForLastRequest(oldPage);
                        console.log("callback for change state response2:", oldPage);
                        getApiListOfRecaps(getSelectedItemOfSorting, getSmartSearch, oldPage);
                    }}
                    onRetryClicked={() => {
                        getApiListOfRecaps(getSelectedItemOfSorting, getSmartSearch, getPaginationForLastRequest);
                    }}
                    stateResponseList={getStateFetchList}
                    styleSpacingMUI={{
                        columns: { xs: 1, sm: 1, md: 1, lg: 2, xl: 4 },
                        rowSpacing: { xs: 2, sm: 3, md: 3, lg: 3, xl: 4 },
                        columnSpacing: { xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }
                    }}

                >
                    <GridFromMUI container
                        justifyContent="flex-start" alignItems="stretch"
                        columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 5 }}
                        rowSpacing={{ xs: 2, sm: 3, md: 3, lg: 3, xl: 4 }}
                        columns={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                    >
                        {getResponseRecaps && getResponseRecaps
                            .filter(pred => getSelectedRecap.findIndex(selectRecap => selectRecap.id === pred.id) === -1)
                            .map((recap, index: number) => {
                                return (
                                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                        <RowFinalRecap
                                            isActive={getSelectedRecap.findIndex(pred => pred.id === recap.id) > -1}
                                            callbackOnClickActive={function (state: boolean): void {
                                                if (state) {
                                                    setSelectedRecap(prev => [recap, ...prev]);
                                                }
                                                else {
                                                    setSelectedRecap(prev => prev.filter(pr => pr.id !== recap.id));
                                                }
                                            }}
                                            recapMainInfo={recap}
                                        />
                                    </Grid>
                                )
                            })}
                    </GridFromMUI>
                </InfiniteScrollList>
            </div >


        </ContainerPage>

    );
}
export default CreateFreestyle;
