import Colors from "../../../config/Colors";
import { InterfaceIconSVG } from "../../icons/InterfaceIconSVG";

export const NoDataFoundImage: React.FC<InterfaceIconSVG> = ({ style }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" style={{ ...style }}>
      <defs>
        <style>
          {`.cls-1 { opacity: 0.4; }
.cls-1, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 { fill: none; }
.cls-2 { isolation: isolate; }
.cls-3 { stroke-dasharray: 0 5.52; }
.cls-3, .cls-4, .cls-5, .cls-6, .cls-7 { stroke-miterlimit: 10; }
.cls-3, .cls-4, .cls-5, .cls-7 { stroke-width: 0.75px; }
.cls-3, .cls-4, .cls-6 { stroke: #121d30; }
.cls-8 { fill: #fff; }
.cls-9, .cls-7 { fill: ${Colors.theme.orange.sharp}; }
.cls-10 { fill: #e69230; }
.cls-11, .cls-12 { fill: #969696; }
.cls-13 { fill: #cb705d; }
.cls-14 { fill: #dedede; }
.cls-15 { fill: #d28a7b; }
.cls-16 { fill: #dba195; }
.cls-17 { fill: #121d30; }
.cls-18 { fill: #5c5c5c; }
.cls-19, .cls-20 { fill: #2e363d; }
.cls-12 { mix-blend-mode: multiply; opacity: 0.41; }
.cls-5 { stroke: #fff; }
.cls-7 { stroke: #2e363d; }
.cls-20 { opacity: 0.53; }`}
        </style>
      </defs>

      <g className="cls-2">
        <g id="OBJECTS">
          {/* <rect className="cls-8" width="500" height="500" /> */}
          <g className="cls-1">
            <path className="cls-11" d="m77.9,402.41s-47.16-28.38-29.91-66.36c10.14-22.33,54.17-14.01,49.54-51.17-6.07-48.72-54.45-81.53-26.53-123.2,28.75-42.9,100.52,8.27,170.67-37.12,45.28-29.3,125.88-45.69,155.09.89,27.91,44.5-27.88,94,12.62,127.25,40.5,33.25,86.89,83.42-15.5,142.54-5.1,2.94-9.58,8.07-15.13,8.07-85.28,0-163.89-.26-163.89-.26" />
            <path className="cls-11" d="m122.52,130.65c2.84,8.39,37.82,7.82,55.87,4.69,18.05-3.13,19.78-9.55,8.46-17.06-11.31-7.5-69.81-3.82-64.33,12.37Z" />
            <path className="cls-11" d="m217.88,120.71c-7.96-5.55-23.87,9.95-9.06,9.94,14.81-.01,14.54-6.11,9.06-9.94Z" />
            <path className="cls-8" d="m73.84,361.74c-10.6-1.69-.68,29.42,14.49,25.41,15.17-4.01-5.59-23.98-14.49-25.41Z" />
            <path className="cls-8" d="m67.9,344.12c-6.07,2.19-4,15.38,4.98,12.58,8.98-2.8.99-14.73-4.98-12.58Z" />
            <path className="cls-11" d="m378.74,403.32c-95.06-.26-163.89-.26-163.89-.26" />
          </g>
          <g>
            <g>
              <path className="cls-19" d="m325.1,114.54c-.09,1.79.84,3.28,2.07,3.34,1.23.06,2.3-1.34,2.38-3.13.09-1.79-.84-3.28-2.07-3.35-1.23-.06-2.3,1.34-2.38,3.13Z" />
              <path className="cls-20" d="m320.84,115.13c.65,1.95,2.27,3.18,3.61,2.73,1.34-.45,1.91-2.39,1.26-4.34-.65-1.95-2.26-3.18-3.61-2.73-1.34.45-1.91,2.39-1.26,4.35Z" />
              <path className="cls-17" d="m317.42,118.51c-.98,2.69,1.11,5.93,4.67,7.23,3.56,1.3,7.25.17,8.23-2.52.98-2.69-1.11-5.93-4.67-7.23-3.56-1.3-7.25-.17-8.23,2.52Z" />
            </g>
            <path className="cls-3" d="m313.66,118.07c-18.32-2.81-45.23,9.63-42.7,32.15,1.8,16.06,23.68,23.32,30.52,7.56,9.27-21.33-19.49-25.58-33.38-24.6-15.96,1.12-26.41,1.58-44.66,22.58-7.47,8.6-9.34,41.67,9.18,43.91" />
            <path className="cls-3" d="m108.99,119.77c-6.22,9.15,4.67,21,12.97,24.48,19.15,8.03,31.95-11.58,50.3-9.71,15.76,1.6,25.53,12.24,17.18,29.9-5.18,10.95-30.17,14.1-29.3-1.64" />
            <g>
              <path className="cls-19" d="m108.19,106.37c-.87,1.56-2.45,2.35-3.52,1.75-1.08-.6-1.24-2.35-.37-3.91.87-1.56,2.45-2.35,3.52-1.75,1.08.6,1.24,2.35.37,3.92Z" />
              <path className="cls-20" d="m111.49,109.12c-1.58,1.31-3.6,1.5-4.51.41-.91-1.09-.36-3.04,1.23-4.35,1.58-1.32,3.6-1.5,4.5-.41.91,1.09.36,3.04-1.23,4.35Z" />
              <path className="cls-17" d="m112.61,113.8c-.59,2.8-4.07,4.45-7.79,3.67-3.71-.78-6.24-3.68-5.65-6.49.59-2.8,4.07-4.45,7.79-3.67,3.71.78,6.24,3.68,5.65,6.49Z" />
            </g>
          </g>
          <g>
            <g>
              <g>
                <g>
                  <rect className="cls-18" x="295.97" y="225.81" width="26.28" height="177.39" />
                  <rect className="cls-8" x="298.73" y="232.38" width="20.76" height="95.63" />
                  <g>
                    <rect className="cls-18" x="300.04" y="234.93" width="18.13" height="2.14" />
                    <rect className="cls-18" x="300.04" y="321.8" width="18.13" height="2.14" />
                  </g>
                  <g>
                    <circle className="cls-11" cx="309.11" cy="369.98" r="9.49" transform="translate(-121.78 586.23) rotate(-76.77)" />
                    <circle className="cls-14" cx="309.11" cy="369.98" r="8.05" transform="translate(-121.82 586.16) rotate(-76.76)" />
                  </g>
                </g>
                <g>
                  <line className="cls-4" x1="298.26" y1="400.27" x2="302.65" y2="400.27" />
                  <line className="cls-4" x1="298.26" y1="396.99" x2="300.81" y2="396.99" />
                </g>
              </g>
              <g>
                <g>
                  <rect className="cls-9" x="269.69" y="225.81" width="26.28" height="177.39" />
                  <rect className="cls-8" x="272.45" y="232.38" width="20.76" height="95.63" />
                  <g>
                    <rect className="cls-14" x="273.76" y="234.93" width="18.13" height="2.14" />
                    <rect className="cls-14" x="273.76" y="321.8" width="18.13" height="2.14" />
                  </g>
                  <g>
                    <circle className="cls-18" cx="282.83" cy="369.98" r="9.49" transform="translate(-178.78 308.35) rotate(-45)" />
                    <circle className="cls-14" cx="282.83" cy="369.98" r="8.05" transform="translate(-178.78 308.35) rotate(-45)" />
                  </g>
                </g>
                <g>
                  <line className="cls-4" x1="289.53" y1="397.35" x2="287.31" y2="397.35" />
                  <line className="cls-4" x1="282.93" y1="400.64" x2="288.78" y2="400.64" />
                </g>
              </g>
              <g>
                <g>
                  <rect className="cls-11" x="341.55" y="225.04" width="26.28" height="177.39" transform="translate(-61.28 87.45) rotate(-12.95)" />
                  <rect className="cls-8" x="336.62" y="232.48" width="20.76" height="95.63" transform="translate(-53.98 84.87) rotate(-12.95)" />
                  <g>
                    <rect className="cls-11" x="328.04" y="236.16" width="18.13" height="2.14" transform="translate(-44.58 81.56) rotate(-12.95)" />
                    <rect className="cls-11" x="347.5" y="320.82" width="18.13" height="2.14" transform="translate(-63.06 88.08) rotate(-12.95)" />
                  </g>
                  <g>
                    <path className="cls-18" d="m376.37,365.68c1.17,5.11-2.01,10.2-7.12,11.37-5.11,1.17-10.2-2.01-11.37-7.12-1.17-5.11,2.01-10.2,7.12-11.37,5.11-1.17,10.2,2.01,11.37,7.12Z" />
                    <path className="cls-14" d="m374.97,366c1,4.33-1.71,8.66-6.04,9.65s-8.65-1.71-9.65-6.04c-1-4.33,1.71-8.65,6.04-9.65,4.33-1,8.65,1.71,9.65,6.04Z" />
                  </g>
                </g>
                <path className="cls-4" d="m363.59,399.18l8.03-2.19" />
              </g>
            </g>
            <g>
              <rect className="cls-8" x="127.86" y="211.23" width="141.83" height="191.96" />
              <g>
                <g>
                  <rect className="cls-14" x="134.68" y="220.46" width="128.19" height="173.51" />
                  <g>
                    <g>
                      <rect className="cls-8" x="182.72" y="317.88" width="32.1" height="10.55" />
                      <rect className="cls-18" x="178.69" y="333.71" width="40.17" height="4.34" />
                    </g>
                    <g>
                      <path className="cls-17" d="m149.32,390.66c-.91.1-1.81.18-2.72.22-.91.04-1.81.1-2.72.12-1.81.05-3.63.05-5.44,0v-.68c.92.01,1.84.08,2.76.12.92.04,1.84.12,2.76.22-.92.1-1.84.18-2.76.22-.92.04-1.84.1-2.76.12v-.68c1.81-.05,3.63-.05,5.44,0,.91.01,1.81.08,2.72.12.91.04,1.81.12,2.72.22Z" />
                      <path className="cls-17" d="m138.44,387.03c.66-.29,1.32-.36,1.99-.37.66.01,1.32.08,1.99.37-.66.3-1.32.36-1.99.37-.66-.01-1.32-.08-1.99-.37Z" />
                    </g>
                    <g>
                      <line className="cls-4" x1="195.12" y1="319.58" x2="203.21" y2="319.58" />
                      <line className="cls-4" x1="190.09" y1="322.55" x2="208.23" y2="322.55" />
                      <line className="cls-4" x1="195.6" y1="326.01" x2="202.72" y2="326.01" />
                    </g>
                  </g>
                </g>
                <g>
                  <g>
                    <polygon className="cls-11" points="286.82 345.98 275.68 369.54 262.93 363.51 262.93 334.68 278.05 341.83 286.82 345.98" />
                    <g>
                      <polygon className="cls-18" points="287.57 334.94 278.05 341.83 262.93 334.68 262.93 320.6 272.29 313.82 287.57 334.94" />
                      <path className="cls-4" d="m269.76,320.09c.76-.49,1.52-.97,2.28-1.46" />
                    </g>
                    <g>
                      <path className="cls-4" d="m276.62,344.8c1.55.78,3.1,1.56,4.65,2.34" />
                      <path className="cls-4" d="m277.64,349.34c1.23.53,2.45,1.05,3.68,1.58" />
                    </g>
                  </g>
                  <g>
                    <polygon className="cls-9" points="134.74 335.28 134.74 369.56 98.32 345.52 114.08 321.64 134.74 335.28" />
                    <path className="cls-4" d="m105.29,342.72c.89-1.1,1.78-2.21,2.67-3.31" />
                  </g>
                </g>
              </g>
              <g>
                <g>
                  <rect className="cls-11" x="132.82" y="193.41" width="41.72" height="69.92" rx="1.08" ry="1.08" transform="translate(-23.56 17.76) rotate(-6.15)" />
                  <rect className="cls-8" x="136.78" y="193.79" width="41.72" height="69.92" transform="translate(-79.67 84.32) rotate(-24.1)" />
                  <g>
                    <path className="cls-18" d="m230.8,201.55l-3.79,7.28h-39.03c-1.12,0-2.03.91-2.03,2.03v51.25c0,1.12.91,2.03,2.03,2.03h74.85c1.12,0,2.03-.91,2.03-2.03v-59.63c0-1.12-.91-2.03-2.03-2.03h-30.23c-.76,0-1.45.42-1.8,1.09Z" />
                    <rect className="cls-8" x="187.98" y="202.17" width="74.98" height="57.72" />
                    <path className="cls-11" d="m230.8,205.16l-3.79,7.28h-39.03c-1.12,0-2.03.91-2.03,2.03v51.25c0,1.12.91,2.03,2.03,2.03h74.85c1.12,0,2.03-.91,2.03-2.03v-59.63c0-1.12-.91-2.03-2.03-2.03h-30.23c-.76,0-1.45.42-1.8,1.09Z" />
                  </g>
                  <g>
                    <path className="cls-10" d="m219.81,165.18l-7.81,10.14-59.91-10.68c-1.72-.31-3.36.84-3.67,2.56l-14.02,78.67c-.31,1.72.84,3.36,2.56,3.67l114.9,20.48c1.72.31,3.36-.84,3.67-2.56l13.53-75.95,1.04-5.84,1.74-9.74c.31-1.72-.84-3.36-2.56-3.67l-46.4-8.27c-1.16-.21-2.34.25-3.06,1.18Z" />
                    <g>
                      <polygon className="cls-8" points="253.22 263.53 138.13 243.02 151.66 167.07 164.04 156.22 269.01 174.93 253.22 263.53" />
                      <polygon className="cls-14" points="151.66 167.07 164.04 156.22 167.3 170.06 151.66 167.07" />
                    </g>
                    <path className="cls-9" d="m218.82,170.73l-7.81,10.14-59.91-10.68c-1.72-.31-3.36.84-3.67,2.56l-14.02,78.67c-.31,1.72.84,3.36,2.56,3.67l114.9,20.48c1.72.31,3.36-.84,3.67-2.56l13.53-75.95,1.04-5.84,1.74-9.74c.31-1.72-.84-3.36-2.56-3.67l-46.41-8.27c-1.16-.21-2.34.25-3.06,1.18Z" />
                  </g>
                  <g>
                    <g>
                      <path className="cls-17" d="m234.28,193.81c-.73,4.18-3.16,7.23-5.45,6.83-2.29-.4-3.57-4.09-2.84-8.28.59-3.37,2.33-6.02,4.18-6.67h.03s4.29,5.39,4.29,5.39c.02.84-.04,1.78-.21,2.73Z" />
                      <path className="cls-17" d="m190.77,186.21c-.73,4.18-3.16,7.23-5.45,6.83-2.32-.4-3.57-4.09-2.84-8.28.21-1.21.57-2.31,1.03-3.26l6.36-2.09c1.01,1.53,1.38,4.06.9,6.8Z" />
                    </g>
                    <path className="cls-4" d="m187.46,219.63c14.52-13.84,28.19-4.73,30.31,5.3" />
                  </g>
                </g>
                <g>
                  <rect className="cls-12" x="132.26" y="308.75" width="133.03" height="3.95" />
                  <g>
                    <rect className="cls-14" x="132.26" y="218.82" width="133.03" height="89.92" />
                    <g>
                      <rect className="cls-8" x="182.72" y="228.16" width="32.1" height="10.55" />
                      <rect className="cls-18" x="178.69" y="243.99" width="40.17" height="4.34" />
                    </g>
                    <g>
                      <line className="cls-4" x1="243" y1="305.23" x2="259.17" y2="305.23" />
                      <line className="cls-4" x1="256.13" y1="302.59" x2="259.17" y2="302.59" />
                    </g>
                    <g>
                      <line className="cls-4" x1="189.9" y1="230.14" x2="208.38" y2="230.14" />
                      <line className="cls-4" x1="186.26" y1="233.22" x2="212.03" y2="233.22" />
                    </g>
                    <g>
                      <line className="cls-4" x1="133.82" y1="222.77" x2="140.23" y2="222.77" />
                      <line className="cls-4" x1="133.82" y1="227.06" x2="153.62" y2="227.06" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g>
            <path className="cls-19" d="m402.04,152.93c-1.72-.39-3.36-1.18-4.74-2.28l.45-.56c1.3,1.03,2.84,1.77,4.45,2.14l-.16.7Zm5.26,0l-.16-.7c1.61-.37,3.15-1.11,4.45-2.15l.45.56c-1.38,1.1-3.02,1.89-4.74,2.29Zm-13.29-6.38c-.78-1.61-1.17-3.33-1.18-5.13h.72c0,1.69.38,3.31,1.11,4.81l-.65.31Zm21.3-.02l-.65-.31c.73-1.51,1.1-3.13,1.1-4.82l.51-.03.2.02c0,1.81-.39,3.54-1.17,5.14Zm-20.67-9.92l-.65-.31c.76-1.59,1.89-3.01,3.27-4.12l.45.56c-1.29,1.04-2.36,2.38-3.07,3.87Zm20.01-.06c-.72-1.49-1.79-2.82-3.1-3.85l.44-.56c1.38,1.1,2.52,2.51,3.29,4.1l-.64.31Zm-12.49-5.97l-.16-.7c.87-.2,1.76-.3,2.66-.3h0c.87,0,1.75.1,2.59.29l-.16.7c-.79-.18-1.62-.27-2.44-.27h0c-.84,0-1.68.1-2.5.28Z" />
            <path className="cls-19" d="m412.86,293.33s0,0,0,0l.11-.18.61.38-.11.17s0,0,0,0c-.83,1.34-1.52,2.77-2.04,4.25l-.68-.24c.54-1.53,1.25-3,2.11-4.39Zm3.29-4.15c1.2-1.2,2.55-2.26,3.99-3.15l.38.61c-1.4.87-2.7,1.89-3.86,3.05l-.5-.51Zm-6.54,13.5l.71.08c-.18,1.63-.18,3.29,0,4.92l-.71.08c-.19-1.69-.19-3.4,0-5.08Zm15.13-18.82c1.61-.55,3.28-.92,4.96-1.09l.07.71c-1.63.17-3.25.53-4.81,1.06l-.23-.68Zm-13.98,28.86l.68-.24c.55,1.55,1.27,3.04,2.15,4.43l-.61.38c-.91-1.43-1.65-2.97-2.22-4.58Zm24.03-29.91c1.69.2,3.35.6,4.94,1.18l-.24.67c-1.54-.56-3.15-.95-4.79-1.14l.09-.71Zm-18.62,38.45l.5-.51c1.16,1.15,2.46,2.17,3.87,3.04l-.38.61c-1.45-.9-2.79-1.96-4-3.14Zm27.73-34.4l.39-.6c1.43.92,2.76,2.01,3.94,3.22l-.51.5c-1.14-1.17-2.43-2.22-3.81-3.11Zm-19.67,38.77c1.53.59,3.14,1,4.77,1.23l-.1.71c-1.68-.24-3.34-.67-4.92-1.27l.26-.67Zm26.49-31.77l.61-.37c.88,1.45,1.6,3,2.13,4.62l-.68.23c-.52-1.56-1.21-3.07-2.06-4.47Zm-16.81,33.13c1.64-.14,3.26-.47,4.83-.97l.22.68c-1.62.52-3.29.86-4.98,1l-.06-.71Zm19.89-23.84l.71-.07c.16,1.69.13,3.4-.09,5.08l-.71-.09c.21-1.63.24-3.28.08-4.92Zm-10.57,20.85c1.41-.84,2.73-1.84,3.92-2.98l.5.52c-1.23,1.17-2.59,2.21-4.05,3.08l-.37-.62Zm9.3-11.15l.67.25c-.52,1.39-1.18,2.73-1.97,4-.11.18-.22.36-.34.53l-.6-.39c.11-.17.24-.34.33-.51.76-1.23,1.4-2.53,1.9-3.88Z" />
            <path className="cls-19" d="m406.67,322.36c-3.2-1.98-4.18-6.18-2.2-9.37,1.98-3.2,6.18-4.18,9.37-2.2,3.2,1.98,4.18,6.18,2.2,9.37-1.98,3.2-6.18,4.18-9.37,2.2Z" />
            <path className="cls-19" d="m397.58,136.29c0,1.98-1.6,3.58-3.58,3.58s-3.58-1.6-3.58-3.58,1.6-3.58,3.58-3.58,3.58,1.6,3.58,3.58Z" />
            <path className="cls-19" d="m79.3,252.32c0,2.97-2.41,5.37-5.37,5.37s-5.37-2.41-5.37-5.37,2.41-5.37,5.37-5.37,5.37,2.41,5.37,5.37Z" />
            <path className="cls-19" d="m83.6,268.8c-.43,0-.85-.03-1.27-.08l.09-.71c1.59.21,3.24-.01,4.72-.65l.28.66c-1.21.52-2.5.79-3.83.79Zm-6.01-2.09c-1.37-1.09-2.42-2.52-3.04-4.15l.67-.25c.57,1.51,1.55,2.84,2.81,3.84l-.45.56Zm13.85-1.91l-.58-.42c.95-1.31,1.52-2.85,1.66-4.46l.71.06c-.15,1.74-.77,3.41-1.8,4.82Zm-16.79-5.67h-.72c0-1.75.47-3.47,1.37-4.96l.61.37c-.83,1.38-1.26,2.97-1.26,4.59Zm17.02-3.9c-.7-1.45-1.79-2.69-3.14-3.58l.39-.6c1.46.96,2.63,2.3,3.39,3.87l-.65.31Zm-12.31-3.99l-.34-.63c1.52-.82,3.29-1.22,5.01-1.15l-.03.72c-1.59-.07-3.23.3-4.64,1.06Z" />
          </g>
          <g>
            <g>
              <path className="cls-17" d="m348.29,136.76s-7.69-11.22-17.3-5.62c-12.04,7,2.5,23.68-6.4,28.5-8.9,4.83-18.1,5.2-20.66,18.55-3.35,17.45,28.22,41.99,54.91,26.45,26.69-15.53,17.2-44.61,4.66-50.39-15.08-6.96-15.21-17.49-15.21-17.49Z" />
              <path className="cls-15" d="m343.02,163.09v15.41c0,2,.81,3.8,2.12,5.11,1.3,1.31,3.11,2.12,5.1,2.12,3.99,0,7.22-3.24,7.22-7.23v-15.41h-14.44Z" />
              <g>
                <path className="cls-15" d="m368.48,158.33c-.6,2.93-3.46,4.82-6.39,4.22-2.93-.6-4.82-3.46-4.22-6.39.6-2.93,3.46-4.82,6.39-4.22,2.93.6,4.82,3.46,4.22,6.39Z" />
                <line className="cls-4" x1="366.99" y1="156.28" x2="362.71" y2="159.18" />
              </g>
              <g>
                <path className="cls-15" d="m337.85,157.24c-.6,2.93-3.46,4.82-6.39,4.22-2.93-.6-4.82-3.46-4.22-6.39.6-2.93,3.46-4.82,6.39-4.22s4.82,3.46,4.22,6.39Z" />
                <path className="cls-17" d="m333.05,156.16s-3.09-3.38-2.01-11.41h4.34l-2.33,11.41Z" />
                <line className="cls-4" x1="333.44" y1="158.54" x2="329.24" y2="154.48" />
              </g>
              <path className="cls-15" d="m346.62,135.01c-7.77,0-14.07,6.3-14.07,14.07v11.56c0,.29.02.57.06.85.2,1.36.9,2.62,1.98,3.51l5.7,4.74c1.73,1.44,4.15,1.72,6.17.7l11.11-5.57c1.92-.97,3.12-2.93,3.12-5.06v-10.73c0-7.77-6.3-14.07-14.07-14.07h0Z" />
              <path className="cls-4" d="m353.22,166.97l-6.46,3.24c-1.09.55-2.3.83-3.52.83h-.48" />
              <polyline className="cls-4" points="344.41 135.01 340.83 159.64 345.32 159.64" />
              <path className="cls-13" d="m332.55,152.9v7.22c.17.02.34.04.52.04,2.01,0,3.65-1.63,3.65-3.65s-1.64-3.64-3.65-3.64c-.18,0-.36.02-.52.04Z" />
              <path className="cls-13" d="m349.69,156.51c0,2.01,1.63,3.65,3.65,3.65s3.65-1.63,3.65-3.65-1.63-3.65-3.65-3.65-3.65,1.63-3.65,3.65Z" />
              <path className="cls-17" d="m360.69,147.76v9.12s-3.62-3.22-3.52-9.55l3.52.43Z" />
              <path className="cls-17" d="m328.51,133.07c9.62-5.59,19.78,3.69,19.78,3.69,0,0-4.91,10.57,15.2,17.49,3.31,1.14,7.44,6.7,9.44,9.88,0,0-4.41-12.71-5.8-20.94-1.4-8.23-8.71-6.96-8.71-6.96,0,0-4.43-10.82-16.68-10.77-8.49.04-13.24,7.61-13.24,7.61Z" />
              <path className="cls-17" d="m335.34,153.68c0,1.27.56,2.29,1.25,2.29s1.26-1.02,1.26-2.29c0-.37-.05-.71-.14-1.02l-1.77-.94c-.38.4-.62,1.13-.62,1.96Z" />
              <path className="cls-17" d="m337.02,146.04c.03-2.09-.78-2.88-2.69-1.36l2.69,1.36Z" />
              <path className="cls-17" d="m352.52,145.41c-1.4-1.55-2.53-1.58-2.9.83l2.9-.83Z" />
              <path className="cls-17" d="m348.56,153.68c0,1.27.56,2.29,1.25,2.29s1.26-1.02,1.26-2.29c0-1.02-.38-1.89-.89-2.18h0l-1.54,1.37c-.05.25-.08.53-.08.82Z" />
            </g>
            <g>
              <path className="cls-17" d="m343.79,166.69c1.07-.11,1.36-1.52,1.06-2.91-.18-.81-.54-1.58-1.06-1.97-.64-.48-1.41.66-1.73,1.97-.37,1.54-.15,3.11,1.73,2.91Z" />
              <path className="cls-8" d="m342.16,163.41h2.59c-.2-.66-.53-1.26-.96-1.59-.58-.44-1.26.45-1.63,1.59Z" />
            </g>
            <g>
              <g>
                <polygon className="cls-9" points="318.93 201.31 331.99 200.45 345.74 204.92 331.99 205.46 318.93 201.31" />
                <path className="cls-9" d="m323.21,189.12l-2.14,17.12,20.61,2.84-3.01-30.66-5.63.77c-5.14.7-9.17,4.77-9.82,9.93Z" />
                <polygon className="cls-8" points="318.93 201.31 331.99 205.46 345.74 204.92 343.1 212.63 331.03 212 318.23 208 318.93 201.31" />
              </g>
              <path className="cls-9" d="m333.65,232.77h27.25l17.51-33.97c2.88-5.6.48-12.46-5.27-15.04l-18.09-8.1-16.39,2.77c-5.67.96-9.64,6.14-9.09,11.87l4.08,42.47Z" />
              <polygon className="cls-9" points="340.98 187.92 338.95 174.56 361.89 172.33 359.75 187.92 340.98 187.92" />
              <path className="cls-8" d="m359.21,182.41c-.62,2.12-1.48,4.81-2.56,7.55-3.06,7.77-6.48,12.45-9.88,13.53-.33.11-.66.16-.96.16-.73,0-1.39-.31-2.01-.94-1.91-1.94-3.27-6.73-3.95-13.84-.18-1.87-.29-3.68-.35-5.25,1.78,3.48,4.73,5.5,8.34,5.5,3.94,0,7.95-2.4,11.37-6.72m1.85-4.06c-4.01,6.86-8.97,10.03-13.22,10.03-4.49,0-8.19-3.52-9.18-9.95,0,0-.48,25.98,7.15,25.98.38,0,.77-.06,1.19-.2,8.86-2.82,14.06-25.86,14.06-25.86h0Z" />
              <g>
                <polygon className="cls-16" points="327.63 391.18 313.9 391.18 319.49 348.71 333.23 348.71 327.63 391.18" />
                <polygon className="cls-9" points="328.94 391.12 313.48 391.12 315.95 370.91 331.27 372.08 328.94 391.12" />
                <g>
                  <path className="cls-19" d="m330.15,403.73h-35.08l.92-3.13c.3-1.02.75-1.97,1.33-2.83,1.22-1.82,3.01-3.23,5.13-3.98l.95-.33c.93-.33,1.81-.76,2.62-1.28.7-.45,1.34-.96,1.93-1.54,1.27-1.24,2.28-2.76,2.92-4.48h19.71l-.33,11.61-.1,5.96Z" />
                  <line className="cls-5" x1="316.67" y1="388.15" x2="309.74" y2="388.15" />
                  <line className="cls-5" x1="314.88" y1="390.56" x2="307.95" y2="390.56" />
                  <g>
                    <path className="cls-5" d="m323.56,395.29c.01.47-.36.86-.83.86s-.87-.38-.88-.86c-.01-.47.36-.86.83-.86s.87.38.88.86Z" />
                    <path className="cls-5" d="m319.38,395.29c.01.47-.36.86-.83.86s-.87-.38-.88-.86c-.01-.47.36-.86.83-.86s.87.38.88.86Z" />
                  </g>
                  <path className="cls-8" d="m330.15,403.73h-35.08l.92-3.13c.3-1.02.75-1.97,1.33-2.83h32.93l-.1,5.96Z" />
                  <line className="cls-4" x1="330.34" y1="400.75" x2="308.27" y2="400.75" />
                  <path className="cls-8" d="m314.75,403.73h-19.69l.92-3.13c.3-1.02.75-1.97,1.33-2.83,1.22-1.82,3.01-3.23,5.13-3.98l.95-.33c.93-.33,1.81-.76,2.62-1.28,4.57,2.11,7.97,6.42,8.75,11.55Z" />
                  <path className="cls-19" d="m310.86,386.17l-.45-1.75c-.32-1.23.58-2.4,1.84-2.4h1.76c1.39,0,2.7.7,3.49,1.87l1.53,2.27h-8.17Z" />
                </g>
              </g>
              <g>
                <polygon className="cls-19" points="368.38 226.01 330.5 223.96 333.82 233.56 365.35 233.22 368.38 226.01" />
                <path className="cls-19" d="m333.82,233.56s-12.97,11.99-13.74,33.39h58.02c0-20.11-12.74-33.73-12.74-33.73l-31.53.34Z" />
                <path className="cls-4" d="m367.21,241.6h-9.1s4.49,10.36,13.44,11.04" />
                <path className="cls-4" d="m327.61,241.6h5.01s-1.92,9.81-10.06,11.04" />
                <path className="cls-4" d="m344.61,234.21l.88,15.88s2.95-1.56,2.32-6.85" />
                <rect className="cls-17" x="355.21" y="231.92" width="8.26" height="3.28" transform="translate(718.68 467.12) rotate(180)" />
                <rect className="cls-17" x="335.13" y="231.92" width="2.87" height="3.28" transform="translate(673.14 467.12) rotate(180)" />
                <rect className="cls-17" x="339.9" y="231.92" width="13.01" height="3.28" transform="translate(692.81 467.12) rotate(180)" />
                <rect className="cls-8" x="342.34" y="231.26" width="5.57" height="4.6" transform="translate(690.24 467.12) rotate(180)" />
              </g>
              <g>
                <polygon className="cls-16" points="380.79 391.15 367.06 391.77 360.04 345.68 373.77 345.06 380.79 391.15" />
                <polygon className="cls-9" points="381.58 391.12 366.14 391.81 363.47 370.81 378.89 371.74 381.58 391.12" />
                <g>
                  <path className="cls-19" d="m384.59,403.73h-35.08l.31-3.13c.1-1.02.37-1.97.78-2.83.86-1.82,2.38-3.23,4.36-3.98l.89-.33c.87-.33,1.66-.76,2.37-1.28.61-.45,1.16-.96,1.63-1.54,1.03-1.24,1.74-2.76,2.05-4.48h19.71l1.93,11.61,1.06,5.96Z" />
                  <line className="cls-5" x1="368.09" y1="388.15" x2="361.16" y2="388.15" />
                  <line className="cls-5" x1="366.77" y1="390.56" x2="359.84" y2="390.56" />
                  <g>
                    <path className="cls-5" d="m376.37,395.29c.11.47-.19.86-.66.86s-.94-.38-1.05-.86c-.11-.47.19-.86.66-.86s.94.38,1.05.86Z" />
                    <path className="cls-5" d="m372.18,395.29c.11.47-.19.86-.66.86s-.94-.38-1.05-.86c-.11-.47.19-.86.66-.86s.94.38,1.05.86Z" />
                  </g>
                  <path className="cls-8" d="m384.59,403.73h-35.08l.31-3.13c.1-1.02.37-1.97.78-2.83h32.93l1.06,5.96Z" />
                  <line className="cls-4" x1="361.88" y1="400.75" x2="383.95" y2="400.75" />
                  <path className="cls-8" d="m369.2,403.73h-19.69l.31-3.13c.1-1.02.37-1.97.78-2.83.86-1.82,2.38-3.23,4.36-3.98l.89-.33c.87-.33,1.66-.76,2.37-1.28,4.98,2.11,9.22,6.42,10.99,11.55Z" />
                  <path className="cls-19" d="m361.89,386.17l-.79-1.75c-.56-1.23.11-2.4,1.37-2.4h1.76c1.39,0,2.84.7,3.85,1.87l1.98,2.27h-8.17Z" />
                </g>
              </g>
              <polygon className="cls-19" points="347.8 265.91 339.53 366.45 310.8 366.45 320.16 266.32 347.8 265.91" />
              <polygon className="cls-19" points="373.55 311.99 383.66 366.45 354.94 366.45 345.91 312.4 373.55 311.99" />
              <path className="cls-19" d="m373.55,311.99s4.55-29.85,4.52-45.2h-28.72l-3.44,45.61,27.64-.41Z" />
              <path className="cls-9" d="m333.49,193.44c-9.73,14.41-9.23,21.31,2.72,26.59l-2.72-26.59Z" />
              <polyline className="cls-4" points="326.67 205.46 332.04 195.65 329.57 190.29" />
              <polyline className="cls-4" points="335.13 366.45 345.12 263.7 350.16 263.7" />
              <path className="cls-4" d="m363.47,235.2s12.16,16.99,9.65,35.58-4.75,41.2-4.75,41.2l11.26,54.46" />
              <line className="cls-4" x1="369.68" y1="191.73" x2="374.36" y2="199.55" />
              <line className="cls-4" x1="379.43" y1="375.65" x2="364.01" y2="375.06" />
              <line className="cls-7" x1="331.27" y1="375.36" x2="315.56" y2="374.08" />
              <path className="cls-16" d="m344.85,208.77h0c1.47,3.46,4.22,5.61,6.14,4.79l18.01-7.2c1.92-.82,2.28-4.28.81-7.74h0c-1.47-3.46-4.22-5.61-6.14-4.79l-17.27,7.18c-1.38.61-2.24,2.31-2.19,4.7.02.97.2,2.02.64,3.06Z" />
              <g>
                <path className="cls-9" d="m380.77,191.84h0c-1.1-4.03-4.4-7.08-8.51-7.84h-.05c-3.06-.58-6.21.4-8.4,2.61l-7.11,7.14,15.74,14.41,6.47-7.72c2-2.39,2.7-5.6,1.86-8.6Z" />
                <polygon className="cls-8" points="375.8 207.04 358.02 190.72 354.02 195.74 372.82 210.33 375.8 207.04" />
              </g>
              <g>
                <g>
                  <path className="cls-16" d="m353.43,173.22c3.32-5.51,9.08-8.85,9.08-8.85l-7.85-8.89c-8.87,7.04-9.35,12.99-9.08,16.44l7.84,1.3Z" />
                  <path className="cls-16" d="m362.51,164.37s3.49-4.82,3.26-9.89l-5.66-4.27s-1.15,5.08-3.69,7.25c0,0,1.91-5.23,1.02-8.24l-2.07.51s-.63,5.14-3.88,9.17l11.03,5.47Z" />
                </g>
                <path className="cls-4" d="m364.3,153.43s-1.16,5.06-2.97,8.31" />
                <path className="cls-4" d="m362.11,151.72s-.72,4.64-3.32,8.35" />
              </g>
              <path className="cls-16" d="m358.02,201.14l-4-28.85-8.49-1.48c-4.37,12.46-5.2,25.72-.76,37.77l13.25-7.44Z" />
              <g>
                <g>
                  <path className="cls-16" d="m331.23,175.82c-2.06-6.1-6.97-10.59-6.97-10.59l9.58-7c7.15,8.78,6.34,14.7,5.33,18.01l-7.94-.42Z" />
                  <path className="cls-16" d="m324.26,165.22s-2.37-5.46-1.06-10.36l6.45-2.95s.03,5.2,2.05,7.87c0,0-.74-5.52.77-8.26l1.91.95s-.49,5.15,1.82,9.79l-11.94,2.97Z" />
                </g>
                <path className="cls-4" d="m324.86,154.15s.04,5.19,1.12,8.75" />
                <path className="cls-4" d="m327.36,152.95s-.29,4.68,1.45,8.87" />
              </g>
              <path className="cls-16" d="m339.43,175.24l-8.2.57s-12.28,29.51-12.28,31.45c0,4.16,3.37,7.53,7.53,7.53,3.11,0,5.75-1.9,6.9-4.6.08-.19,7.53-22.88,6.06-34.96Z" />
            </g>
          </g>
          <line className="cls-6" x1="49.59" y1="403.06" x2="445.57" y2="403.06" />
        </g>
      </g>
    </svg>
  )
}