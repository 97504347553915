import { InterfaceNTTDocument } from "./InterfaceNTTDocument";

export interface InterfaceNTTProfileIranLegal {
    name: string;
    name_fa: string;
    profile_pic?: string;
    company_type: { title: string; id: number; description: string };
    doe: number;
    registration_code: string;
    economic_code: string;
    status: EnumActivitiesStatusCompany;
    address: string;
    postal_code: string;
    national_id: string;
    id: number;
    landline: string;
}
export enum EnumActivitiesStatusCompany {
    active = "ACT",
    inactive = "INA",
    suspended = "SUS",
}