import { Box, Grid, styled, useTheme } from "@mui/material";
import { InterfaceRowFinalRecap } from "./InterfaceRowFinalRecap";
import Colors, { ColorThemForButton } from "../../../../../config/Colors";
import { ConfigComps } from "../../../../../config/ConfigCOMP";
import { ArrowRightRounded, CheckBoxOutlineBlankRounded, CheckBoxRounded, CheckCircleOutlineRounded, DoneRounded, HistoryToggleOffRounded, KeyboardArrowDownRounded, PaymentsRounded, RectangleRounded } from "@mui/icons-material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TickWithCircleIcon } from "../../../../../assets/icons/svg/TickWithCircleIcon";
import ButtonColor from "../../../../../components/basic/Buttons/ButtonColor/ButtonColor";
import OpenProfile from "../../../../../components/basic/OpenProfile/OpenProfile";
import ProgressBarGradient from "../../../../../components/basic/progressBar/ProgressBarGradient/ProgressBarGradient";
import ArrayText from "../../../../../components/basic/Texts/ArrayText/ArrayText";
import Links from "../../../../../config/Links";
import { SelectedProfile } from "../../../../../store/profileStateSlice";
import { useEffect, useState } from "react";
import ParentWithHeightAnimation from "../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation";
import ShowDescriptionWithUsers from "../../../../../components/complex/ShowDescriptionWithUsers/ShowDescriptionWithUsers";
import { WalletIcon } from "../../../../../assets/icons/svg/WalletIcon";
import { BottomTriangleIcon } from "../../../../../assets/icons/svg/BottomTriangleIcon";
import { CurrencySymbols } from "../../../../../helper/CurrencySymbols";
const ParentDiv = styled("div", { shouldForwardProp: (prop) => prop !== 'isActive' })<{ isActive: boolean; }>(({
    theme,
    isActive,
}) => {
    return (
        {
            width: "100%", padding: "1px",
            background: isActive ? Colors.gradients.blueGreenBlue : "white",
            boxShadow: isActive ? Colors.shadow.greenCard : Colors.shadow.cardList,
            borderRadius: "0.5rem",
            // cursor: "pointer",
            "&:hover": {
                background: Colors.gradients.blueGreenBlue,
            }
        }
    )
});
const TagService = styled("div")(({ theme }) => ({
    background: Colors.theme.white.PrimaryLight,
    color: Colors.theme.blue.dark,
    boxShadow: Colors.shadow.cardList,
    paddingInline: "0.5rem",
    paddingBlock: "0.16rem",
    borderRadius: "500px",
    boxSizing: "border-box",
    lineHeight: "1.2rem",
    fontSize: "1rem",


}));
function RowFinalRecap(paramsComponent: InterfaceRowFinalRecap) {
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const [getStateIsActive, setStateIsActive] = useState<boolean>(false);
    const [getMoneyPaymentIdThatSelected, setMoneyPaymentIdThatSelected] = useState<number[]>([]);
    const [getMoneyReceiveIdThatSelected, setMoneyReceiveIdThatSelected] = useState<number[]>([]);
    const [getSellCottageIdThatSelected, setSellCottageIdThatSelected] = useState<number[]>([]);
    const [getBuyCottageIdThatSelected, setBuyCottageIdThatSelected] = useState<number[]>([]);
    useEffect(() => {
        setStateIsActive(paramsComponent.isActive);
    }, [paramsComponent.isActive])
    return (
        <ParentDiv style={{ height: "100%" }}
            isActive={getStateIsActive}
            onClick={() => {
                paramsComponent.callbackOnClickActive(!getStateIsActive);
                // navigate(Links.screens.panel.recap.ShowStateTheRecap + `${paramsComponent.recapMainInfo.id}/`)
            }}>
            <Box
                sx={{
                    background: "white",
                    height: "100%",
                    width: "100%", display: "flex", flexDirection: "column",
                    alignItems: "center", justifyContent: "space-between",
                    borderRadius: "0.5rem",
                    paddingInline: { xs: '1rem', sm: "1.2rem", md: "1.33rem", lg: "1.48rem", xl: "1.62rem" },//{{ xs: "0.96rem" }},
                    paddingTop: "1.28rem",
                    paddingBottom: { xs: '0.8rem', sm: "0.96rem", md: "1.06rem", lg: "1.18rem", xl: "1.3rem" },
                    boxSizing: "border-box",

                }}>
                <div style={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: "100%",
                    gap: "0.4rem",
                    marginBlockEnd: "1.18rem",
                }}>
                    <div style={{
                        display: 'inline-flex', flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: "0.4rem",
                    }}>
                        <p style={{
                            fontFamily: confComps.font.poppins.Medium,
                            fontSize: '1.03rem',
                            lineHeight: '1.55rem',
                            color: Colors.theme.blue.dark,

                        }}>
                            {`${confComps.translate.recapID}: #${paramsComponent.recapMainInfo.id}`}
                        </p>
                        <p style={{
                            color: Colors.theme.blue.Secondary,
                            fontFamily: confComps.font.poppins.SemiBold,
                            fontSize: "0.9rem",
                            lineHeight: "1.6rem",
                            flexWrap: "nowrap",
                            textWrap: "nowrap",
                            cursor: "pointer",
                            userSelect: "none",
                        }}
                            onClick={(event) => {
                                navigate(Links.screens.panel.recap.ShowStateTheRecap + `${paramsComponent.recapMainInfo.id}/`)
                                event.stopPropagation();

                            }}
                        >
                            {confComps.translate.showDetails}
                        </p>
                        <ArrayText
                            texts={[
                                {
                                    text: `(${dayjs.unix(paramsComponent.recapMainInfo.created_at).format("YYYY/MM/DD")})`,
                                    style: {
                                        color: Colors.theme.blue.dark,
                                    }

                                }
                            ]}
                        />
                    </div>

                    <div style={{
                        display: 'inline-flex',
                    }}>
                        {getStateIsActive ?
                            <DoneRounded style={{
                                color: Colors.theme.blue.white2,
                                background: Colors.theme.green.dark,
                                boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.15)",
                                borderRadius: "0.2rem",
                                fontSize: '1.7rem',
                            }} />
                            :
                            <CheckBoxOutlineBlankRounded style={{
                                color: Colors.theme.transparent,
                                fontSize: '1.7rem',
                                borderRadius: "0.2rem",
                                background: Colors.theme.blue.white2,
                                boxShadow: "0px 0px 2px 1px rgba(0, 0, 0, 0.15)",
                            }} />

                        }
                    </div>
                </div>
                <Box sx={{
                    width: '100%',
                    overflow: 'hidden',
                }}>
                    {/* money receive  */}
                    {paramsComponent.recapMainInfo.receipts.map((receipts, index, arr) => {
                        return [
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}>
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Medium,
                                        fontSize: "1.03rem",
                                        color: Colors.theme.blue.light,
                                        display: 'inline-flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        gap: '0.2rem',

                                    }}>
                                        {confComps.translate.moneyReceive}
                                        (<OpenProfile user={receipts.customer.user}
                                            profile={receipts.customer.profile}
                                            is_trusty={receipts.customer.is_trusty}
                                        />)
                                        (
                                        {receipts.from_currency_unit.is_cash ?
                                            <PaymentsRounded />
                                            : <WalletIcon />
                                        }
                                        {receipts.from_currency_unit.country && receipts.from_currency_unit.country.iso && <img
                                            src={`https://flagcdn.com/w320/${receipts.from_currency_unit.country.iso}.png`} // Fallback image
                                            alt={`${receipts.from_currency_unit.country.iso}`}
                                            style={{ width: '2rem', height: 'auto' }}
                                        />}
                                        {CurrencySymbols[receipts.from_currency_unit.currency.symbol]}
                                        {/*  */}
                                        <ArrowRightRounded
                                            style={{
                                                fontSize: '2.4rem',
                                                marginInline: "-0.4rem",
                                            }}
                                        />
                                        {`${receipts.amount} AED`}
                                        <ArrowRightRounded
                                            style={{
                                                fontSize: '2.4rem',
                                                marginInline: "-0.4rem",
                                            }}
                                        />
                                        {receipts.to_currency_unit.is_cash ?
                                            <PaymentsRounded />
                                            : <WalletIcon />
                                        }
                                        {receipts.to_currency_unit.country && receipts.to_currency_unit.country.iso && <img
                                            src={`https://flagcdn.com/w320/${receipts.to_currency_unit.country.iso}.png`} // Fallback image
                                            alt={`${receipts.to_currency_unit.country.iso}`}
                                            style={{ width: '2rem', height: 'auto' }}
                                        />}
                                        {CurrencySymbols[receipts.to_currency_unit.currency.symbol]}
                                        )
                                    </p>
                                    <div onClick={(event) => {
                                        event.stopPropagation();
                                        if (getMoneyReceiveIdThatSelected.findIndex(pred => pred === receipts.id) > -1) {
                                            setMoneyReceiveIdThatSelected(prev => prev.filter(id => id !== receipts.id));
                                        }
                                        else {
                                            setMoneyReceiveIdThatSelected(prev => [...prev, receipts.id])
                                        }
                                    }}
                                        style={{}}>
                                        <KeyboardArrowDownRounded
                                            style={{
                                                fontSize: "1.9rem",
                                                color: Colors.theme.blue.dark,
                                                cursor: "pointer",
                                                transform: (getMoneyReceiveIdThatSelected.findIndex(pred => pred === receipts.id) > -1) ? "rotate(180deg)" : "rotate(0deg)",
                                                transition: "all 0.5s ease-in-out",
                                            }}
                                        />
                                    </div>
                                </div>
                                {receipts.solution_choose_result.length > 0 &&
                                    <ParentWithHeightAnimation
                                        in={getMoneyReceiveIdThatSelected.findIndex(pred => pred === receipts.id) > -1}
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            marginTop: '0.5rem',
                                            // paddingInline: '0.4rem',
                                        }}
                                    >
                                        <ShowDescriptionWithUsers
                                            {...receipts.solution_choose_result[0].chosen_solution}
                                        />
                                        <div style={{
                                            display: 'flex', flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            paddingInline: '0.6rem',
                                            marginTop: '0.6rem',
                                            paddingBlock: '0.6rem',
                                            borderRadius: '0.5rem',
                                            boxSizing: 'border-box',
                                            background: Colors.theme.blue.white2,
                                            boxShadow: Colors.shadow.shadowEditTextMultiLineBoxNC,
                                            width: '100%',
                                        }}>
                                            <ShowDescriptionWithUsers
                                                description_list={[receipts.manager_approval_result[0].description]}
                                                user_orders={[]}
                                            />
                                        </div>
                                    </ParentWithHeightAnimation>
                                }
                            </div>
                        ]
                    })}
                    {/* money payment  */}
                    {paramsComponent.recapMainInfo.payments.map((payment, index, arr) => {
                        return (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}>
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Medium,
                                        fontSize: "1.03rem",
                                        color: Colors.theme.blue.light,
                                        display: 'inline-flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        gap: '0.2rem',

                                    }}>
                                        {confComps.translate.moneyPayment}
                                        (<OpenProfile user={payment.customer.user}
                                            profile={payment.customer.profile}
                                            is_trusty={payment.customer.is_trusty}
                                        />)
                                        (
                                        {payment.from_currency_unit.is_cash ?
                                            <PaymentsRounded />
                                            : <WalletIcon />
                                        }
                                        {payment.from_currency_unit.country && payment.from_currency_unit.country.iso && <img
                                            src={`https://flagcdn.com/w320/${payment.from_currency_unit.country.iso}.png`} // Fallback image
                                            alt={`${payment.from_currency_unit.country.iso}`}
                                            style={{ width: '2rem', height: 'auto' }}
                                        />}
                                        {CurrencySymbols[payment.from_currency_unit.currency.symbol]}
                                        {/*  */}
                                        <ArrowRightRounded
                                            style={{
                                                fontSize: '2.4rem',
                                                marginInline: "-0.4rem",
                                            }}
                                        />
                                        {`${payment.amount} AED`}
                                        <ArrowRightRounded
                                            style={{
                                                fontSize: '2.4rem',
                                                marginInline: "-0.4rem",
                                            }}
                                        />
                                        {payment.to_currency_unit.is_cash ?
                                            <PaymentsRounded />
                                            : <WalletIcon />
                                        }
                                        {payment.to_currency_unit.country && payment.to_currency_unit.country.iso && <img
                                            src={`https://flagcdn.com/w320/${payment.to_currency_unit.country.iso}.png`} // Fallback image
                                            alt={`${payment.to_currency_unit.country.iso}`}
                                            style={{ width: '2rem', height: 'auto' }}
                                        />}
                                        {CurrencySymbols[payment.to_currency_unit.currency.symbol]}
                                        )
                                    </p>
                                    <div onClick={(event) => {
                                        event.stopPropagation();
                                        if (getMoneyPaymentIdThatSelected.findIndex(pred => pred === payment.id) > -1) {
                                            setMoneyPaymentIdThatSelected(prev => prev.filter(id => id !== payment.id));
                                        }
                                        else {
                                            setMoneyPaymentIdThatSelected(prev => [...prev, payment.id])
                                        }
                                    }}
                                        style={{}}>
                                        <KeyboardArrowDownRounded
                                            style={{
                                                fontSize: "1.9rem",
                                                color: Colors.theme.blue.dark,
                                                cursor: "pointer",
                                                transform: (getMoneyReceiveIdThatSelected.findIndex(pred => pred === payment.id) > -1) ? "rotate(180deg)" : "rotate(0deg)",
                                                transition: "all 0.5s ease-in-out",
                                            }}
                                        />
                                    </div>
                                </div>
                                {payment.solution_choose_result.length > 0 &&
                                    <ParentWithHeightAnimation
                                        in={getMoneyPaymentIdThatSelected.findIndex(pred => pred === payment.id) > -1}
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            marginTop: '0.5rem',
                                            // paddingInline: '0.4rem',
                                        }}
                                    >
                                        <ShowDescriptionWithUsers
                                            {...payment.solution_choose_result[0].chosen_solution}
                                        />
                                        <div style={{
                                            display: 'flex', flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            paddingInline: '0.6rem',
                                            marginTop: '0.6rem',
                                            paddingBlock: '0.6rem',
                                            borderRadius: '0.5rem',
                                            boxSizing: 'border-box',
                                            background: Colors.theme.blue.white2,
                                            boxShadow: Colors.shadow.shadowEditTextMultiLineBoxNC,
                                            width: '100%',
                                        }}>
                                            <ShowDescriptionWithUsers
                                                description_list={[payment.manager_approval_result[0].description]}
                                                user_orders={[]}
                                            />
                                        </div>
                                    </ParentWithHeightAnimation>
                                }
                            </div>
                        )
                    })}
                    {/* buy cottage  */}
                    {paramsComponent.recapMainInfo.buy_cottages.map((buy_cottage, index, arr) => {
                        return (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}>
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Medium,
                                        fontSize: "1.03rem",
                                        color: Colors.theme.blue.light,
                                        display: 'inline-flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        gap: '0.2rem',

                                    }}>
                                        {confComps.translate.moneyPayment}
                                        (<OpenProfile user={buy_cottage.customer.user}
                                            profile={buy_cottage.customer.profile}
                                            is_trusty={buy_cottage.customer.is_trusty}
                                        />)
                                        (
                                        {buy_cottage.currency_unit.is_cash ?
                                            <PaymentsRounded />
                                            : <WalletIcon />
                                        }
                                        {buy_cottage.currency_unit.country && buy_cottage.currency_unit.country.iso && <img
                                            src={`https://flagcdn.com/w320/${buy_cottage.currency_unit.country.iso}.png`} // Fallback image
                                            alt={`${buy_cottage.currency_unit.country.iso}`}
                                            style={{ width: '2rem', height: 'auto' }}
                                        />}
                                        {CurrencySymbols[buy_cottage.currency_unit.currency.symbol]}
                                        {`${buy_cottage.amount} AED`}
                                        )
                                    </p>
                                    <div onClick={(event) => {
                                        event.stopPropagation();
                                        if (getBuyCottageIdThatSelected.findIndex(pred => pred === buy_cottage.id) > -1) {
                                            setBuyCottageIdThatSelected(prev => prev.filter(id => id !== buy_cottage.id));
                                        }
                                        else {
                                            setBuyCottageIdThatSelected(prev => [...prev, buy_cottage.id])
                                        }
                                    }}
                                        style={{}}>
                                        <KeyboardArrowDownRounded
                                            style={{
                                                fontSize: "1.9rem",
                                                color: Colors.theme.blue.dark,
                                                cursor: "pointer",
                                                transform: (getBuyCottageIdThatSelected.findIndex(pred => pred === buy_cottage.id) > -1) ? "rotate(180deg)" : "rotate(0deg)",
                                                transition: "all 0.5s ease-in-out",
                                            }}
                                        />
                                    </div>
                                </div>
                                {buy_cottage.solution_choose_result.length > 0 &&
                                    <ParentWithHeightAnimation
                                        in={getBuyCottageIdThatSelected.findIndex(pred => pred === buy_cottage.id) > -1}
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            marginTop: '0.5rem',
                                            // paddingInline: '0.4rem',
                                        }}
                                    >
                                        <ShowDescriptionWithUsers
                                            {...buy_cottage.solution_choose_result[0].chosen_solution}
                                        />
                                        <div style={{
                                            display: 'flex', flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            paddingInline: '0.6rem',
                                            marginTop: '0.6rem',
                                            paddingBlock: '0.6rem',
                                            borderRadius: '0.5rem',
                                            boxSizing: 'border-box',
                                            background: Colors.theme.blue.white2,
                                            boxShadow: Colors.shadow.shadowEditTextMultiLineBoxNC,
                                            width: '100%',
                                        }}>
                                            <ShowDescriptionWithUsers
                                                description_list={[buy_cottage.manager_approval_result[0].description]}
                                                user_orders={[]}
                                            />
                                        </div>
                                    </ParentWithHeightAnimation>
                                }
                            </div>
                        )
                    })}
                    {/* sell cottage  */}
                    {paramsComponent.recapMainInfo.sell_cottages.map((sell_cottage, index, arr) => {
                        return (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row', alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                }}>
                                    <p style={{
                                        fontFamily: confComps.font.poppins.Medium,
                                        fontSize: "1.03rem",
                                        color: Colors.theme.blue.light,
                                        display: 'inline-flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                        gap: '0.2rem',

                                    }}>
                                        {confComps.translate.moneyPayment}
                                        (<OpenProfile user={sell_cottage.customer.user}
                                            profile={sell_cottage.customer.profile}
                                            is_trusty={sell_cottage.customer.is_trusty}
                                        />)
                                        (
                                        {sell_cottage.currency_unit.is_cash ?
                                            <PaymentsRounded />
                                            : <WalletIcon />
                                        }
                                        {sell_cottage.currency_unit.country && sell_cottage.currency_unit.country.iso && <img
                                            src={`https://flagcdn.com/w320/${sell_cottage.currency_unit.country.iso}.png`} // Fallback image
                                            alt={`${sell_cottage.currency_unit.country.iso}`}
                                            style={{ width: '2rem', height: 'auto' }}
                                        />}
                                        {CurrencySymbols[sell_cottage.currency_unit.currency.symbol]}
                                        {`${sell_cottage.amount} AED`}
                                        )
                                    </p>
                                    <div onClick={(event) => {
                                        event.stopPropagation();
                                        if (getBuyCottageIdThatSelected.findIndex(pred => pred === sell_cottage.id) > -1) {
                                            setBuyCottageIdThatSelected(prev => prev.filter(id => id !== sell_cottage.id));
                                        }
                                        else {
                                            setBuyCottageIdThatSelected(prev => [...prev, sell_cottage.id])
                                        }
                                    }}
                                        style={{}}>
                                        <KeyboardArrowDownRounded
                                            style={{
                                                fontSize: "1.9rem",
                                                color: Colors.theme.blue.dark,
                                                cursor: "pointer",
                                                transform: (getBuyCottageIdThatSelected.findIndex(pred => pred === sell_cottage.id) > -1) ? "rotate(180deg)" : "rotate(0deg)",
                                                transition: "all 0.5s ease-in-out",
                                            }}
                                        />
                                    </div>
                                </div>
                                {sell_cottage.solution_choose_result.length > 0 &&
                                    <ParentWithHeightAnimation
                                        in={getBuyCottageIdThatSelected.findIndex(pred => pred === sell_cottage.id) > -1}
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            marginTop: '0.5rem',
                                            // paddingInline: '0.4rem',
                                        }}
                                    >
                                        <ShowDescriptionWithUsers
                                            {...sell_cottage.solution_choose_result[0].chosen_solution}
                                        />
                                        <div style={{
                                            display: 'flex', flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            paddingInline: '0.6rem',
                                            marginTop: '0.6rem',
                                            paddingBlock: '0.6rem',
                                            borderRadius: '0.5rem',
                                            boxSizing: 'border-box',
                                            background: Colors.theme.blue.white2,
                                            boxShadow: Colors.shadow.shadowEditTextMultiLineBoxNC,
                                            width: '100%',
                                        }}>
                                            <ShowDescriptionWithUsers
                                                description_list={[sell_cottage.manager_approval_result[0].description]}
                                                user_orders={[]}
                                            />
                                        </div>
                                    </ParentWithHeightAnimation>
                                }
                            </div>
                        )
                    })}

                </Box>

            </Box>
        </ParentDiv >
    );
}
export default RowFinalRecap;