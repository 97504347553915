import React, { useEffect, useState } from 'react';
import Colors from '../../../../config/Colors';
import { InterfaceSelectGloballyWithUnderLine } from './InterfaceSelectGloballyWithUnderLine';
import { useDispatch, useSelector } from 'react-redux';
import { SelectedProfile } from '../../../../store/profileStateSlice';
import { useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigComps } from '../../../../config/ConfigCOMP';
import { isVisibilityProgressBar } from '../../../../store/ProgressbarInRootSlice';
import { HandlerLangUrl, urlsList } from '../../../../api/UrlsList';
import { RequestHandler } from '../../../../api/API';
import { InterfaceNTTActivity } from '../../../../NTTs/InterfaceNTTActivity';
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from '../../../../api/APIErrorResponse';
import { ShowAlert } from '../../../../store/AlertStateModeSlice';
import { enumTypeOfAlert } from '../../../MUIConfig/alert_popup/interfaceAlertPopup';
import AutoCompleteWithUnderLine from '../../../complex/AutoCompleteWithUnderLine/AutoCompleteWithUnderLine';
import { InterfaceNTTGlobalSelectorArray } from '../../../../NTTs/InterfaceNTTGlobalSelectorArray';
import { BottomTriangleIcon } from '../../../../assets/icons/svg/BottomTriangleIcon';



function SelectGloballyWithUnderLine(componentParams: InterfaceSelectGloballyWithUnderLine) {
    var confComps = ConfigComps();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mtheme = useTheme();
    const { role } = useParams();
    const mTheme = useTheme();
    const profileInStore = useSelector(SelectedProfile);

    const [getIsFocused, setIsFocused] = useState(false);

    const [getSelectedItems, setSelectedItems] = useState<InterfaceNTTGlobalSelectorArray[]>([]);
    const [getAllItems, setAllItems] = useState<InterfaceNTTGlobalSelectorArray[]>([]);


    useEffect(() => {
        setAllItems(componentParams.allItems);
        if (componentParams.allItems.length > 0) {
            setSelectedItems(componentParams.allItems.filter(item => componentParams.selectedItems.filter(sel => sel === item.id).length > 0));
        }
    }, [componentParams.selectedItems, componentParams.allItems])
    useEffect(() => {
        setAllItems(componentParams.allItems);
    }, [componentParams.allItems])
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                // borderRadius: ConstStyle.borderRadius,
                fontSize: "1rem",
                position: "relative",
                boxShadow: Colors.shadow.shadowTextFieldWithIconBottomLineClicked,
                ...componentParams.styleMain,
                paddingBlockStart: "0.32rem",
                boxSizing: "border-box",

            }}
        >
            {componentParams.iconStart && (
                <div style={{ display: "flex", alignItems: "center", marginInlineStart: "0.96rem" }}>
                    <componentParams.iconStart style={{ color: getIsFocused ? Colors.theme.blue.dark : Colors.theme.blue.light }} />
                </div>
            )}
            <div style={{
                flex: 1,
                // paddingInlineEnd: "1rem",
                paddingInlineStart: "0.32rem",
                boxSizing: "border-box",
            }}>
                <AutoCompleteWithUnderLine
                    isShowIconInEnd={true}
                    styleItems={componentParams.styleItems}
                    valuesList={getAllItems}
                    onChangeSelectedItem={(items) => {
                        componentParams.onChangeSelectedItems(componentParams.limitSelector > 0 ?
                            items.map((item, index, arr) => {
                                return ({
                                    ...getAllItems.filter(ac => ac.id === item.id)[0]
                                })

                            }).slice(-componentParams.limitSelector)
                            :
                            items.map((item, index, arr) => {
                                return ({
                                    ...getAllItems.filter(ac => ac.id === item.id)[0]
                                })

                            }));
                    }}
                    onBlur={() => setIsFocused(false)}
                    onFocus={() => setIsFocused(true)}
                    selectedValueTitle={getSelectedItems}
                    limitSelect={componentParams.limitSelector}

                />
                <label
                    style={{
                        position: "absolute",
                        boxSizing: "border-box",
                        left: ((getIsFocused || (getSelectedItems.length > 0)) || !componentParams.iconStart) ? "0.32rem" : "2.4rem",
                        transition: "all 0.2s ease",
                        pointerEvents: "none",
                        top: getIsFocused || (getSelectedItems.length > 0) ? '0px' : 'unset',
                        bottom: getIsFocused || (getSelectedItems.length > 0) ? 'unset' : "0rem",
                        transform: getIsFocused || (getSelectedItems.length > 0) ? "translate(0%, -50%)" : "translate(0%, 0%)",
                        fontSize: (getIsFocused || (getSelectedItems.length > 0)) ? "0.75rem" : "1em",
                        color: Colors.theme.blue.light,
                        backgroundColor: "transparent",
                        padding: "0",
                    }}
                >
                    {componentParams.title}
                </label>
            </div>
            {
                // <div style={{ display: "flex", alignItems: "center", marginInlineEnd: "0.96rem" }}>
                //     {

                //         <BottomTriangleIcon
                //             style={{
                //                 transform: getIsFocused ? "rotate(180deg)" : "rotate(0deg)",
                //                 transition: "transform 0.5s ease-in-out",
                //                 color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                //                 width: "0.9rem",
                //             }} />

                //     }
                // </div>
            }
            <fieldset
                // aria-hidden="true"
                style={{
                    position: "absolute",
                    boxSizing: "border-box",
                    top: getIsFocused || (getSelectedItems.length > 0) ? "calc(-1.02rem - 0.5px)" : "0px",
                    // height: "100%",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    borderRadius: componentParams.styleMain?.borderRadius,
                    border: "unset",
                    borderBottom: (getIsFocused) ? `1px solid ${Colors.theme.blue.blue}` : `1px solid #C4C7D9`,
                    pointerEvents: "none",
                    padding: "0",
                    margin: "0",
                    display: "block",
                    paddingInline: "0.75rem",
                    // overflow: "hidden"
                }}
            />

        </div>
    );
}

export default SelectGloballyWithUnderLine;
