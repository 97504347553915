import { useEffect, useState } from 'react';
import ParentWithHeightAnimation from '../../../../../../../components/basic/animation/ParentWithHeightAnimation/ParentWithHeightAnimation';
import { InterfaceCreateCheckWalletCustomerOfMoneyReceive } from './InterfaceCheckWalletCustomerOfMoneyReceive';
import { Box, Grid, useTheme } from '@mui/material';
import TextFieldSimple from '../../../../../../../components/basic/TextField/TextFieldSimple/TextFieldSimple';
import { ConfigComps } from '../../../../../../../config/ConfigCOMP';
import { InterfaceNTTCurrencyWithTypeAndLocation } from '../../../../../../../NTTs/InterfaceNTTCurrency';
import SelectCurrencyWithTypeAndLocation from '../../../../../../../components/complex/Selector/SelectCurrencyWithTypeAndLocation/SelectCurrencyWithTypeAndLocation';
import SubTitleSection from '../../../../../../../components/basic/Texts/Titles/SubTitleSection/SubTitleSection';
import TextFieldMultiLine from '../../../../../../../components/basic/TextField/TextFieldMultiLine/TextFieldMultiLine';
import Colors from '../../../../../../../config/Colors';
import ButtonSave from '../../../../../../../components/basic/Buttons/ButtonSave/ButtonSave';
import { InterfaceNTTRecapMain } from '../../../../../../../NTTs/Recap/InterfaceNTTRecapMain';

import { isAnySimilarInterfaceResponseErrorFromServer, interfaceResponseErrorFromServer } from '../../../../../../../api/APIErrorResponse';
import { urlsList, HandlerLangUrl, TypeQueryParams } from '../../../../../../../api/UrlsList';
import { enumTypeOfAlert } from '../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup';
import { ShowAlert } from '../../../../../../../store/AlertStateModeSlice';
import { isVisibilityProgressBar } from '../../../../../../../store/ProgressbarInRootSlice';
import { InterfaceGetAPICustomersList } from '../../../../../../people/TrustyOrCustomer/customers/customersList/InterfaceCustomersList';
import { SelectedProfile } from '../../../../../../../store/profileStateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Links from '../../../../../../../config/Links';
import ButtonCancel from '../../../../../../../components/basic/Buttons/ButtonCancel/ButtonCancel';
import ShowAllWalletsTheUser from '../../../../../../../components/complex/ShowAllWalletsTheUser/ShowAllWalletsTheUser';
import { ThumbDownRounded, ThumbUpRounded } from '@mui/icons-material';
import { RequestHandler } from '../../../../../../../api/API';
import { InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap } from '../../../../../../../NTTs/Recap/WorkflowMainRecap/MoneyReceive/InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap';

function CreateCheckWalletCustomerOfMoneyReceive(paramsComponent: InterfaceCreateCheckWalletCustomerOfMoneyReceive) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mTheme = useTheme();
    var confComps = ConfigComps();
    const profileInStore = useSelector(SelectedProfile);
    const [getDescriptionValue, setDescriptionValue] = useState<string>('');
    const [getIsWaitingForRecapMain, setIsWaitingForRecapMain] = useState<boolean>();
    const [getAllWorkflowReviews, setAllWorkflowReviews] = useState<InterfaceNTTOneStepOfWorkflowMoneyReceiveRecap[]>([]);
    const handleOnClickSubmitButtons = async (isAgree: boolean) => {

        callApiForCreateCommentFinanceForTheCustomer(isAgree);

    }

    useEffect(() => {
        setAllWorkflowReviews(paramsComponent.allWorkflowReviews);
    }, [paramsComponent.allWorkflowReviews])
    const callApiForCreateCommentFinanceForTheCustomer = async (isAgree: boolean) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.recap.MainServices.moneyReceive.steps.foundControlCheckWalletTheCustomer.createSteps;
        let bodyObj = {
            'status': isAgree ? 'AG' : 'NA',
            'description': getDescriptionValue,
        }
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${paramsComponent.infoServiceInMainRecap.id}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, bodyObj, profileInStore)
            .then((response: any) => {
                console.log('response:):)');
                console.log(response);
                // let t = response as InterfaceGetAPICustomersList;
                let getOldStateWaiting = getIsWaitingForRecapMain;
                setIsWaitingForRecapMain(false);
                paramsComponent.callBackReLoad();
                paramsComponent.callBackSetWaiting(false);
                dispatch(isVisibilityProgressBar(false));
                dispatch(ShowAlert({ text: 'Your Comment Added.', typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }))
            })
            .catch((e: any) => {
                setIsWaitingForRecapMain(false);
                paramsComponent.callBackSetWaiting(false);
                console.log('response:(:(');
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    // useEffect(() => {
    //     setRecapMain(paramsComponent.recapMain);
    //     if (getIsWaitingForRecapMain && paramsComponent.recapMain) {
    //         callApiForCreateCommentFinanceForTheCustomer(paramsComponent.recapMain)
    //     }
    // }, [paramsComponent.recapMain, getIsWaitingForRecapMain])
    return (
        <ParentWithHeightAnimation in={true}
            loading={getIsWaitingForRecapMain}
            style={{
                boxSizing: 'border-box',
                width: '100%',
            }}
        >
            <Box sx={{
                boxSizing: 'border-box',
                paddingInlineStart: { xs: '0.8rem', sm: '0.9rem', md: '1.0rem', lg: '1.18rem', xl: '1.3rem' },
                paddingInlineEnd: { xs: '0.8rem', sm: '3.33rem', md: '3.7rem', lg: '4.14rem', xl: '6rem' },
                display: 'flex', flexDirection: 'column',
                alignItems: 'start',
                width: '100%',
                marginTop: '1.18rem',
            }}>
                <Grid container
                    justifyContent='space-between' alignItems='flex-start'
                    columnSpacing={{ xs: 0, sm: 0, md: '1rem', lg: '1.18rem', xl: '2rem' }}
                    rowSpacing={{ xs: '1.2rem', sm: '1.39rem', md: '1.53rem', lg: '1.70rem', xl: '1.87rem' }}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <ShowAllWalletsTheUser
                            idUser={paramsComponent.infoServiceInMainRecap.customer.user.id}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextFieldMultiLine
                            title={confComps.translate.explanation}
                            text={getDescriptionValue}
                            onChangeText={setDescriptionValue}
                            styleParent={{
                                boxSizing: 'border-box',
                                border: `1px solid ${Colors.theme.blue.border}`,
                                background: Colors.theme.white.white90,
                            }}
                        />
                    </Grid>
                </Grid>

            </Box>
            <div style={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'end',
                display: 'flex',
                flexDirection: 'row',
                marginTop: '1.18rem',
                gap: '0.88rem',
            }}>
                <ButtonCancel
                    text={confComps.translate.disagree}
                    callbackOnClick={() => {
                        handleOnClickSubmitButtons(false);
                    }}
                    iconStart={<ThumbDownRounded
                        style={{
                            fontSize: '1.1rem', marginBottom: '-5%'
                        }}
                    />}
                />
                <ButtonSave
                    text={confComps.translate.agree}
                    callbackOnClick={() => {
                        handleOnClickSubmitButtons(true);
                    }}
                    iconStart={<ThumbUpRounded
                        style={{
                            fontSize: '1.1rem',
                        }} />
                    }
                />

            </div>
        </ParentWithHeightAnimation>
    )

}
export default CreateCheckWalletCustomerOfMoneyReceive;