import * as React from 'react';
import { ConfigComps } from '../../../../config/ConfigCOMP';
import Colors from '../../../../config/Colors';
import { InterfaceDatePickerMUI } from './InterfaceDatePickerMUI';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { InputAdornment } from '@mui/material';
import { DateRangeRounded } from '@mui/icons-material';


function DatePickerMUI(paramsComponent: InterfaceDatePickerMUI) {
    const confComps = ConfigComps();
    const [getIsFocused, setIsFocused] = useState(false);
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div style={{ display: 'flex', flexDirection: 'column', }}>
                <label style={{
                    fontWeight: "300",

                    fontFamily: confComps.font.poppins.Regular, fontSize: '1.12rem',
                    lineHeight: "1.68rem",
                    color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light, marginBottom: '0.22rem'
                }}>
                    {paramsComponent.title}
                </label>

                {/* <DatePicker
                    format="YYYY/MM/DD"
                    onChange={(value: Dayjs | null) => {
                        if (value != null) {
                            paramsComponent.onChangeValue(value.unix());//return second
                        }
                        else {
                            paramsComponent.onChangeValue(undefined);
                        }
                    }}

                    defaultValue={paramsComponent.defaultTimeInSecond ? dayjs.unix(paramsComponent.defaultTimeInSecond) : undefined}
                    slotProps={{
                        textField: {
                            onFocus: () => setIsFocused(true),
                            onBlur: () => setIsFocused(false),
                            InputProps: {
                                endAdornment: (
                                    <InputAdornment position="end" style={{ marginInlineEnd: "-8px" }}>
                                        <DateRangeRounded style={{ color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light }} />
                                    </InputAdornment>
                                ),
                            },
                        },
                    }}
                    sx={{
                        fontSize: '1.12rem',
                        lineHeight: "1.68rem",
                        outline: "unset",
                        "& div": {
                            outline: "unset",
                        },
                        "& input": {
                            background: Colors.theme.white.white90,
                            outline: "unset",
                            paddingBlock: '0.32rem',
                            paddingInlineStart: '0.64rem',
                            // border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                            // boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                            // borderRadius: '0.4rem',
                            fontFamily: confComps.font.poppins.Regular,
                            fontSize: '1.12rem',
                            color: Colors.theme.blue.dark,
                            lineHeight: "1.68rem",

                        },
                        "& fieldset": {
                            outline: "unset",
                            border: getIsFocused ? `1px solid ${Colors.theme.blue.blue} !important` : `1px solid ${Colors.theme.blue.border} !important`,
                        },
                        "& button": {
                            marginBottom: "0px",
                            color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                            // paddingInline: "0px",
                            padding: "0.32rem",
                        }

                    }}
                /> */}
                <DatePicker
                    format="YYYY/MM/DD"
                    onChange={(value: Dayjs | null) => {
                        if (value != null) {
                            paramsComponent.onChangeValue(value.unix()); // return seconds
                        } else {
                            paramsComponent.onChangeValue(undefined);
                        }
                    }}
                    defaultValue={paramsComponent.defaultTimeInSecond ? dayjs.unix(paramsComponent.defaultTimeInSecond) : undefined}
                    slots={{
                        openPickerIcon: DateRangeRounded,
                    }}
                    slotProps={{
                        openPickerIcon: {
                            style: {
                                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                            },
                        },
                        openPickerButton: {
                            sx: {
                                marginBottom: "0px",
                                padding: "0.32rem",
                                color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                            },
                        },
                        textField: {
                            onFocus: () => setIsFocused(true),
                            onBlur: () => setIsFocused(false),
                        },
                    }}
                    sx={{
                        fontSize: '1.12rem',
                        lineHeight: "1.68rem",
                        outline: "unset",
                        "& .MuiInputBase-root": {
                            background: Colors.theme.white.white90,
                            // paddingBlock: '0.32rem',
                            // paddingInlineStart: '0.96rem',
                            fontFamily: confComps.font.poppins.Regular,
                            fontSize: '1.12rem',
                            color: Colors.theme.blue.dark,
                            lineHeight: "1.68rem",
                            "&:hover fieldset": {
                                borderColor: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: Colors.theme.blue.blue,
                            },
                        },
                        "& div": {
                            outline: "unset",
                        },
                        "& input": {
                            background: Colors.theme.white.white90,
                            outline: "unset",
                            paddingBlock: '0.32rem',
                            paddingInlineStart: '0.96rem',
                            // border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                            // boxShadow: getIsFocused ? Colors.shadow.shadowEditTextBoxClicked : undefined,
                            // borderRadius: '0.4rem',
                            fontFamily: confComps.font.poppins.Regular,
                            fontSize: '1.12rem',
                            color: Colors.theme.blue.dark,
                            lineHeight: "1.68rem",

                        },
                        "& fieldset": {
                            outline: "unset",
                            border: getIsFocused ? `1px solid ${Colors.theme.blue.blue}` : `1px solid ${Colors.theme.blue.border}`,
                        },
                        "& button": {
                            marginBottom: "0px",
                            color: getIsFocused ? Colors.theme.blue.blue : Colors.theme.blue.light,
                            // paddingInline: "0px",
                            padding: "0.32rem",
                        }
                    }}
                />

            </div>
        </LocalizationProvider>
    );
}

export default DatePickerMUI;
