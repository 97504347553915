import { Typography } from "@mui/material";
import { InterfaceValueP } from "./InterfaceValueP";
import Colors from "../../../../../config/Colors";
import { ConfigComps } from "../../../../../config/ConfigCOMP";

function ValueP(paramsComponent: InterfaceValueP) {
    var confComps = ConfigComps();
    return (
        <Typography variant="h6" sx={{
            lineHeight: "1.55rem",//{ xs: 1.1, sm: 1.1, md: 1.25, lg: 1.25, xl: 1.25 },
            fontSize: "1.037rem",
            marginBlock: { xs: 0 },
            fontFamily: confComps.font.poppins.Regular,
            fontWeight: "100",
            boxSizing: "border-box",
            color: Colors.theme.blue.light,
            ...paramsComponent.style
        }} >
            {paramsComponent.title}
        </Typography>
    );
}
export default ValueP;