import { Box, Grid, styled, useTheme } from "@mui/material";
import Colors from "../../../../../../../config/Colors";
import KeyTitleH6 from "../../../../../../../components/basic/Texts/Titles/KeyTitleH6/KeyTitleH6";
import { useDispatch, useSelector } from "react-redux";
import { ConfigComps } from "../../../../../../../config/ConfigCOMP";
import { SelectedProfile } from "../../../../../../../store/profileStateSlice";
import ValueP from "../../../../../../../components/basic/Texts/Titles/ValueP/ValueP";
import { Person2Rounded } from "@mui/icons-material";
import { TrashIcon } from "../../../../../../../assets/icons/svg/TrashIcon";
import { baseUrl, HandlerLangUrl, urlsList } from "../../../../../../../api/UrlsList";
import { RequestHandler } from "../../../../../../../api/API";
import { isVisibilityProgressBar } from "../../../../../../../store/ProgressbarInRootSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../../../../../api/APIErrorResponse";
import { ShowAlert } from "../../../../../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../../../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { ImageSquare } from "../../../../../../../components/complex/imageSquare/ImageSquare";
import dayjs from "dayjs";
import { EditPenIcon } from "../../../../../../../assets/icons/svg/EditPenIcon";
import ShowCurrencyLocationWithValue from "../../../../../../../components/basic/ShowCurrencyLocationWithValue/ShowCurrencyLocationWithValue";
import { XELogoIcon } from "../../../../../../../assets/icons/svg/XELogoIcon";
import { AddIcon } from "../../../../../../../assets/icons/svg/AddIcon";
import { ExchangeMoneyIcon } from "../../../../../../../assets/icons/svg/ExchangeMoneyIcon";
import { LongArrowRight } from "../../../../../../../assets/icons/svg/LongArrowRight";
import { InterfaceRowNestaWallet } from "./InterfaceRowNestaWallet";
import { WalletIcon } from "../../../../../../../assets/icons/svg/WalletIcon";
import { CurrencySymbols } from "../../../../../../../helper/CurrencySymbols";
const TagOfUser = styled("p")(({ theme }) => ({
    boxSizing: "border-box",
    fontSize: "1.12rem",
    lineHeight: "1.6rem",
    color: Colors.theme.blue.dark,
    textWrap: "nowrap",
}));

function RowNestaWallet(paramsComponent: InterfaceRowNestaWallet) {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const dispatch = useDispatch();
    const profileInStore = useSelector(SelectedProfile);
    const callApiForDeleteWallet = async (idRecommended: number) => {
        dispatch(isVisibilityProgressBar(true));
        let urlObj = urlsList.panel.wallet.nestaWallet.deleteNestaWallet;
        await RequestHandler(dispatch, HandlerLangUrl(urlObj.url + `${idRecommended}/`, mTheme),
            urlObj.method, urlObj.isTokenNecessary, undefined, profileInStore, urlObj.isFileInclude)
            .then((response: any) => {
                console.log("deleted");
                console.log(response);
                paramsComponent.callbackReloadPage();
                dispatch(ShowAlert({ text: "Removed The Wallet", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                dispatch(isVisibilityProgressBar(false));


            })
            .catch((e: any) => {
                console.log("response:(:(");
                console.log(e);
                dispatch(isVisibilityProgressBar(false));
                if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
                    dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
                else {
                    dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
                }
            });


    }
    return (
        <Box sx={{
            width: "100%",
            border: `1px solid ${Colors.theme.blue.border}`,
            paddingInline: "0.1rem",
            paddingBlock: "0.1rem",
            boxSizing: "border-box",
            borderRadius: "0.8rem",
            boxShadow: Colors.shadow.shadowEditTextBoxNC,
            background: "red",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: "relative",
            backgroundImage: 'linear-gradient(109.6deg, rgba(62, 161, 219, 1) 11.2%, rgba(93, 52, 236, 1) 100.2%)',
            overflow: "hidden",
        }}>

            <div style={{
                position: 'absolute',
                width: "100%",
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}>
                <div style={{
                    position: 'absolute',
                    top: '-50px',
                    borderRadius: '50%',
                    background: 'radial-gradient(#006db3, #29b6f6)',
                    left: '10px',
                    height: '160px',
                    width: '160px'
                }}></div>
                <div style={{
                    position: 'absolute',
                    borderRadius: '50%',
                    background: 'radial-gradient(#006db3, #29b6f6)',
                    right: '40px',
                    top: '80px',
                    opacity: 0.8,
                    height: '160px',
                    width: '160px'
                }}></div>
            </div>
            <div style={{
                width: "100%",
                border: '2px solid rgba(255, 255, 255, 0.2)',
                background: 'rgba(255, 255, 255, 0.4)',
                borderRadius: '10px',
                zIndex: 1,
                overflow: 'hidden',
                backdropFilter: 'blur(5px)',
                WebkitBackdropFilter: 'blur(5px)',
                position: 'relative'
            }}>
                <div style={{ float: 'right', padding: '10px' }}>
                    <img src={confComps.images.logoWithoutText}
                        alt=""
                    />
                </div>
                <div style={{
                    padding: '10px', margin: '60px 0',
                }}>
                    <div style={{
                        display: "flex", flexDirection: "row",
                        alignItems: "center", justifyContent: "start",
                        gap: "0.5rem",
                    }}>
                        {paramsComponent.currency_unit.country.iso &&
                            <img
                                src={`https://flagcdn.com/w320/${paramsComponent.currency_unit.country.iso}.png`} // Fallback image
                                alt={`${paramsComponent.currency_unit.country.iso}`}
                                style={{ width: '1.8rem', height: 'auto' }}
                            />
                        }
                        <p style={{
                            fontSize: '1.5rem',
                            padding: '10px 0', letterSpacing: '2px', color: '#ffffff',
                            textAlign: "start",
                        }}>
                            {paramsComponent.currency_unit.country.name}
                        </p>
                    </div>
                    <p style={{
                        fontSize: '22px',
                        padding: '10px 0', letterSpacing: '2px', color: '#ffffff',
                    }}>
                        {`${paramsComponent.balance}  `}
                        {paramsComponent.currency_unit.currency.symbol}
                        {CurrencySymbols[paramsComponent.currency_unit.currency.symbol]}
                    </p>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'start',
                    padding: '0 10px',
                    gap: "0.5rem",
                    color: '#ffffff'
                }}>
                    <WalletIcon />
                    <p>
                        {paramsComponent.currency_unit.is_cash ? confComps.translate.cash : confComps.translate.noneCash}
                    </p>
                    {/* <p>Nikhil Bobade</p> */}
                </div>
            </div>

        </Box >
    );
}
export default RowNestaWallet;